import { combineReducers } from 'redux';
import userReducer from './reducers/userReducer';
import tableContentReucer from './reducers/tableContentReducer';
import detailsReducer from './reducers/detailsReducer';
import detailsPopupReducer from './reducers/detailsPopupReducer';
import offersWizzardReducer from './reducers/offersWizzardReducer';
import acceptOffer from './reducers/acceptOfferReducer';
import frameworkWizzardReducer from './reducers/frameworkWizzardReducer';
import axiosErrorReducer from './reducers/axiosErrorReducer';

const rootReducer = combineReducers({
    user: userReducer,
    tableContent: tableContentReucer,
    details: detailsReducer,
    detailsPopup: detailsPopupReducer,
    offersWizzardReducer: offersWizzardReducer,
    acceptOffer: acceptOffer,
    frameworkWizzardReducer: frameworkWizzardReducer,
    axiosErrorReducer: axiosErrorReducer,
});

export default rootReducer;
