import classes from './Icons.module.scss';

function EditIcon() {
    return (
        <svg
            className={classes['c-icons__icon']}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className="icon-path"
                d="M9.375 6L10 6.625L3.9375 12.6563H3.34375V12.0625L9.375 6ZM11.7813 2C11.5938 2 11.4375 2.0625 11.3125 2.1875L10.0938 3.40625L12.5938 5.90625L13.8125 4.6875C14.0625 4.4375 14.0625 4 13.8125 3.75L12.25 2.1875C12.125 2.0625 11.9375 2 11.7813 2ZM9.375 4.125L2 11.5V14H4.5L11.875 6.625L9.375 4.125Z"
                fill="#111522"
            />
        </svg>
    );
}

export default EditIcon;
