import { useState, useEffect, useCallback } from 'react';
// import { useSearchParams } from 'react-router-dom';
import _debounce from 'lodash/debounce';
// import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getTableData } from '../../../store/thunks/tableDataThunks';
import { useTranslation } from 'react-i18next';
import { onSearchTableContent } from '../../../store/actions/detailsActions';

function PageSearch(props) {
    const dispatch = useDispatch();
    const tableContentLoading = useSelector(
        (state) => state.tableContent.tableContentLoading
    );

    const { t } = useTranslation();

    const [searchValue, setSearchValue] = useState('');
    //eslint-disable-next-line
    // const [searchParams, setSearchParams] = useSearchParams();

    const [iconColor, setIconColor] = useState(false);

    const focusIconHandler = () => {
        setIconColor(true);
    };

    const blurIconHandler = () => {
        setIconColor(searchValue.length !== 0 ? true : false);
    };

    const setParams = (val, clear) => {
        let searchVal = val;
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.get('searchFilter')) {
            if (clear || searchVal.length === 0) {
                searchParams.delete('searchFilter');
                window.history.pushState(
                    null,
                    null,
                    `${props.type}?${searchParams}`
                );
            } else {
                searchParams.set('searchFilter', searchVal);
                window.history.pushState(
                    null,
                    null,
                    `${props.type}?${searchParams}`
                );
            }
        } else if (searchVal.length > 0) {
            searchParams.set('searchFilter', searchVal);
            window.history.pushState(
                null,
                null,
                `${props.type}?${searchParams}`
            );
        } else {
            searchParams.delete('searchFilter');
            window.history.pushState(
                null,
                null,
                `${props.type}?${searchParams}`
            );
        }
        if (!tableContentLoading) {
            dispatch(
                getTableData(
                    props.type === 'offers'
                        ? 'offer'
                        : props.type === 'orders'
                        ? 'order'
                        : props.type === 'framework-agreements'
                        ? 'frameworkAgreement'
                        : ''
                )
            );
        }
    };

    const inputChangeHandler = (e) => {
        if (props.componentSearch) {
            dispatch(onSearchTableContent(e.target.value, props.tableType));
        } else {
            debounceSearch(e.target.value);
        }

        if (props.javascriptSearch) {
            const initialSearchState = e.target.value.length < 3;
            props.onInitialSearchValue(initialSearchState);
        }
        setSearchValue(e.target.value);
    };

    //eslint-disable-next-line
    const debounceSearch = useCallback(_debounce(setParams, 1000), []);

    const resetSearchValueHandler = () => {
        setSearchValue('');
        setIconColor(false);
        if (!props.componentSearch) {
            setParams('', true);
            if (props.onSearchChangeHandler) {
                props.onSearchChangeHandler('');
            }
        } else {
            dispatch(onSearchTableContent('', props.tableType));
        }

        if (props.javascriptSearch) {
            props.onInitialSearchValue(true);
        }
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const searchFilter = searchParams.get('searchFilter');

        if (searchFilter) {
            setSearchValue(searchFilter);
        }
    }, []);

    useEffect(
        () => () => {
            //reset search filter when switching tabs
            dispatch(onSearchTableContent('', props.tableType));
        },
        //eslint-disable-next-line
        []
    );

    return (
        <div className="c-input c-input--search">
            <i
                className={`icon-search icon-search--${
                    iconColor ? 'black' : 'grey'
                }`}
            ></i>
            <input
                type="text"
                placeholder={`${t('offers_component-search-placeholder')}`}
                onChange={inputChangeHandler}
                value={searchValue}
                disabled={props.disabled}
                onFocus={focusIconHandler}
                onBlur={blurIconHandler}
            />
            {searchValue && (
                <button
                    className="c-input__reset"
                    type="button"
                    onClick={resetSearchValueHandler}
                    aria-label="reset input"
                >
                    <i className="icon-x-sign"></i>
                </button>
            )}
        </div>
    );
}

export default PageSearch;
