import { Link } from 'react-router-dom';
import classes from './LoginRedirection.module.scss';
import { useTranslation } from 'react-i18next';

function LoginRedirection() {
    const { t } = useTranslation();

    return (
        <p className={classes['c-login-redirection']}>
            {t('registration_already-have-account')}
            <Link to="/login">{t('registration_login-here-link')}</Link>
        </p>
    );
}

export default LoginRedirection;
