import * as actionTypes from '../actions/detailsPopupActions';

const initialState = {
    detailsPopup: {},
    popupType: '',
};

const detailsPopup = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.OPEN_DETAILS_POPUP:
            return {
                ...state,
                detailsPopup: action.details,
                popupType: action.popupType,
            };
        case actionTypes.CLOSE_DETAILS_POPUP:
            return {
                ...state,
                detailsPopup: {},
                popupType: '',
            };
        default:
            return state;
    }
};

export default detailsPopup;
