import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from '../../../../../components/Table/Table';
import WizzardFooter from '../../../../../components/Wizzard/WizzardFooter/WizzardFooter';
import wizardStyles from '../../../../../components/Wizzard/WizzardGeneral.module.scss';
import classes from '../../../../../components/Wizzard/WizzardSummary.module.scss';
import UploadedFile from '../../../../../components/UploadedFile/UploadedFile';
import { useTranslation } from 'react-i18next';
import { acceptOffer } from '../../../../../store/thunks/acceptOfferThunk';
import { useNavigate } from 'react-router-dom';
import { onClearWizzard } from '../../../../../store/actions/offersWizzardActions';
import { getTax } from '../../../../../store/thunks/offersWizzardThunk';
import moment from 'moment';
import FormatedPrice from '../../../../../components/FormatedPrice/FormatedPrice';
import TranslatedComponent from '../../../../../components/TranslatedComponent/TranslatedComponent';

function Summary() {
    const [tableData, setTableData] = useState([]);
    const [frameworkTableData, setFrameworkTableData] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [taxPrice, setTaxPrice] = useState(0);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [countries, setCountries] = useState();
    const [contacts, setContacts] = useState();
    const offersWizzardReducer = useSelector(
        (state) => state.offersWizzardReducer
    );
    const userReducer = useSelector((state) => state.user);
    const { t } = useTranslation();

    const navigate = useNavigate();

    const dispatch = useDispatch();

    // has origin states
    const [singleOrderHasOrigin, setSingleOrderHasOrigin] = useState();
    const [frameworkOrderHasOrigin, setFrameworkOrderHasOrigin] = useState();

    const tableTitles = [
        {
            id: 1,
            name: t('wizard-table_designation'),
        },
        {
            id: 2,
            name: t('wizard-table_article-num'),
        },
        {
            id: 3,
            name: t('wizard-table_material'),
        },
        {
            id: 4,
            name: t('wizard-table_additional-prod-processes'),
        },
        {
            id: 5,
            name: t('wizard-table_delivery-time'),
        },
        {
            id: 5,
            name: t('offers-single-order-sidebar_origin'),
        },
        {
            id: 6,
            name: t('summary-table_pieces-num'),
        },
        {
            id: 7,
            name: t('wizard-table_discount'),
        },
        {
            id: 8,
            name: t('summary-table_total-netto'),
        },
    ];

    const frameworkTableTitles = [
        {
            id: 1,
            name: t('wizard-table_designation'),
        },
        {
            id: 2,
            name: t('wizard-table_article-num'),
        },
        {
            id: 3,
            name: t('wizard-table_running-time'),
        },
        {
            id: 4,
            name: t('wizard-table_total-quantity'),
        },
        {
            id: 5,
            name: t('offers-fa-table_retrieval-2'),
        },
        {
            id: 6,
            name: t('offers-single-order-table-columns_quantity'),
        },
        {
            id: 7,
            name: t('offers-single-order-sidebar_origin'),
        },
        {
            id: 8,
            name: t('wizard-table_delivery-time'),
        },
        {
            id: 9,
            name: t('summary-table_total-netto'),
        },
    ];

    useEffect(() => {
        // get countries list
        const countriesList = JSON.parse(t('countries'));
        setCountries(countriesList);

        // contacts list
        const contactsList = userReducer?.user.contacts;
        setContacts(contactsList);

        const params = new URLSearchParams();
        if (offersWizzardReducer.data.addresses.billingAddress.country.length) {
            params.append(
                'country',
                offersWizzardReducer.data.addresses.billingAddress.country
            );
            params.append(
                'taxId',
                offersWizzardReducer.data.addresses.billingAddress.taxNumber
            );
        } else {
            params.append(
                'country',
                offersWizzardReducer.data.addresses.shippingAddress.country
            );
        }
        dispatch(getTax(params));

        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (offersWizzardReducer.wizzardType === 'single-offer') {
            setTotalPrice(offersWizzardReducer.data.order.totalPrice);
        } else {
            setTotalPrice(
                offersWizzardReducer.data.frameworkAgreement.totalPrice
            );
        }
        setTaxPrice((offersWizzardReducer.tax?.taxPercent / 100) * totalPrice);
    }, [offersWizzardReducer, totalPrice]);

    useEffect(() => {
        let formatedTableData = offersWizzardReducer?.offerLines?.map(
            (item, index) => {
                return {
                    id: item.uuid,
                    showCertificates: item.certificateLines?.length,
                    certificates: item.certificateLines?.map((certificate) => {
                        const object = {
                            certificateName: {
                                certificate: certificate?.partName
                                    ? certificate.partName
                                    : '-',
                            },
                            articleNumberColumn: '',
                            material: {
                                material: certificate?.material
                                    ? certificate.material
                                    : '-',
                                materialGroup: certificate?.materialGroup
                                    ? certificate.materialGroup
                                    : '-',
                            },
                            additionalProductionMethods: '-',
                            deliveryTimeColumn: '',
                            origin: '',
                            quantity: certificate?.quantity
                                ? certificate.quantity +
                                  ` ${t('global_pieces-short')}`
                                : '-',
                            discount: certificate?.discountPercent
                                ? certificate?.discountPercent + '%'
                                : '-',
                            totalPriceNet: {
                                price:
                                    certificate?.totalPriceNet ||
                                    certificate?.totalPriceNet === 0
                                        ? certificate?.totalPriceNet
                                        : '-',
                                priceBeforeDiscount:
                                    certificate.totalPriceNetBeforeDiscount,
                                discount: certificate?.discountPercent
                                    ? certificate?.discountPercent + '%'
                                    : null,
                                targetPrice: certificate?.targetPriceNet,
                                isOnlyComment:
                                    item.certificateLines?.isOnlyComment,
                                noPriceAvailableComment:
                                    item.certificateLines
                                        ?.noPriceAvailableComment,
                                // status: details.status,
                                priceStatus: item.certificateLines?.status,
                                noTargetPrice: true,
                            },
                        };
                        return object;
                    }),
                    data: {
                        partName: item.partName ? item.partName : '-',
                        partNumber: item.partNumber ? item.partNumber : '-',
                        material: {
                            material: item.material ? item.material : '-',
                            materialGroup: item.materialGroup
                                ? item.materialGroup
                                : '-',
                            materialComments: item.materialComments,
                        },
                        producationMethod: item.producationMethod
                            ? item.productionMethod.join(', ')
                            : '-',
                        deliveryTime: `${
                            item.quantities[
                                [offersWizzardReducer.offerLinesIndex[index]]
                            ]?.deliveryTime
                        } ${t('global_calendar-days')}`,
                        origin: {
                            originCountry: item.quantities[
                                [offersWizzardReducer.offerLinesIndex[index]]
                                    ?.originCountry
                                    ? offersWizzardReducer.offerLinesIndex[
                                          index
                                      ]
                                    : 0
                            ]?.originCountry
                                ? item.quantities[
                                      [
                                          offersWizzardReducer.offerLinesIndex[
                                              index
                                          ],
                                      ]?.originCountry
                                          ? offersWizzardReducer
                                                .offerLinesIndex[index]
                                          : 0
                                  ]?.originCountry
                                : '-',
                            hsCode: item.quantities[
                                [offersWizzardReducer.offerLinesIndex[index]]
                                    ?.HSCode
                                    ? offersWizzardReducer.offerLinesIndex[
                                          index
                                      ]
                                    : 0
                            ]?.HSCode
                                ? item.quantities[
                                      [
                                          offersWizzardReducer.offerLinesIndex[
                                              index
                                          ],
                                      ]?.HSCode
                                          ? offersWizzardReducer
                                                .offerLinesIndex[index]
                                          : 0
                                  ]?.HSCode
                                : '-',
                        },
                        number: `${
                            item.quantities[
                                [offersWizzardReducer.offerLinesIndex[index]]
                            ]?.quantity
                        } ${t('global_pieces-short')}`,
                        discount: item.quantities[
                            [offersWizzardReducer.offerLinesIndex[index]]
                        ]?.discountPercent
                            ? `${
                                  item.quantities[
                                      [
                                          offersWizzardReducer.offerLinesIndex[
                                              index
                                          ],
                                      ]
                                  ]?.discountPercent
                              }%`
                            : '-',
                        totalPriceNet: {
                            price: item.quantities[
                                [offersWizzardReducer.offerLinesIndex[index]]
                            ].totalPriceNet
                                ? item.quantities[
                                      [
                                          offersWizzardReducer.offerLinesIndex[
                                              index
                                          ],
                                      ]
                                  ].totalPriceNet
                                : '-',
                            priceBeforeDiscount: item.quantities[
                                [offersWizzardReducer.offerLinesIndex[index]]
                            ].totalPriceNetBeforeDiscount
                                ? item.quantities[
                                      [
                                          offersWizzardReducer.offerLinesIndex[
                                              index
                                          ],
                                      ]
                                  ].totalPriceNetBeforeDiscount
                                : '-',
                            targetPrice: item.quantities[
                                [offersWizzardReducer.offerLinesIndex[index]]
                            ].targetPriceNet
                                ? item.quantities[
                                      [
                                          offersWizzardReducer.offerLinesIndex[
                                              index
                                          ],
                                      ]
                                  ].targetPriceNet
                                : '-',
                            isOnlyComment:
                                item.quantities[
                                    [
                                        offersWizzardReducer.offerLinesIndex[
                                            index
                                        ],
                                    ]
                                ].isOnlyComment,
                            status: offersWizzardReducer.details.status,
                        },
                    },
                };
            }
        );

        let formatedSingleOrderHasOrigin = formatedTableData.some(
            (item) =>
                item.data.origin.originCountry !== '-' ||
                item.data.origin.hsCode !== '-'
        );
        setSingleOrderHasOrigin(formatedSingleOrderHasOrigin);

        let formatedFrameworkData =
            offersWizzardReducer?.frameworkOfferLines?.map((offerLine) => {
                return {
                    id: offerLine.id,
                    showCertificates: offerLine.certificateLines?.length,
                    certificates: offerLine.certificateLines?.map(
                        (certificate) => {
                            const object = {
                                certificateName: {
                                    certificate: certificate?.partName
                                        ? certificate.partName
                                        : '-',
                                },
                                articleNumberColumn: '',
                                material: {
                                    material: certificate?.material
                                        ? certificate.material
                                        : '-',
                                    materialGroup: certificate?.materialGroup
                                        ? certificate.materialGroup
                                        : '-',
                                },
                                additionalProductionMethods: '',
                                retrieval: '',
                                quantity: certificate?.quantity
                                    ? certificate.quantity +
                                      ` ${t('global_pieces-short')}`
                                    : '-',
                                deliveryTimeColumn: '',
                                discount: certificate?.discountPercent
                                    ? certificate?.discountPercent + '%'
                                    : '-',
                                totalPriceNet: {
                                    price:
                                        certificate?.totalPriceNet ||
                                        certificate?.totalPriceNet === 0
                                            ? certificate?.totalPriceNet
                                            : '-',
                                    priceBeforeDiscount:
                                        certificate.totalPriceNetBeforeDiscount,
                                    discount: certificate?.discountPercent
                                        ? certificate?.discountPercent + '%'
                                        : null,
                                    targetPrice: certificate?.targetPriceNet,
                                    isOnlyComment:
                                        offerLine.certificateLines
                                            ?.isOnlyComment,
                                    noPriceAvailableComment:
                                        offerLine.certificateLines
                                            ?.noPriceAvailableComment,
                                    priceStatus:
                                        offerLine.certificateLines?.status,
                                    noTargetPrice: true,
                                },
                            };
                            return object;
                        }
                    ),
                    data: {
                        partName: offerLine.partName ? offerLine.partName : '-',
                        articleNumber: offerLine.articleNumber
                            ? offerLine.articleNumber
                            : '-',
                        durationAgreement: offerLine.quantities[0]
                            ?.durationAgreement
                            ? `${offerLine.quantities[0].durationAgreement} ${
                                  offerLine.quantities[0].durationAgreement > 1
                                      ? t('global_months')
                                      : t('global_month')
                              }`
                            : '-',
                        totalQuantity: {
                            selectQuantity: offerLine.quantities[0]?.quantity
                                ? offerLine.quantities[0].quantity
                                : '-',
                        },
                        abrufe: {
                            sample: {
                                sample: t('global_sample'),
                                quantitySample:
                                    offerLine.quantities[0]?.quantitySample,
                            },
                            firstBatch: {
                                firstBatch: t('global_first-batch'),
                                quantityFirstBatch:
                                    offerLine.quantities[0]?.quantityFirstBatch,
                            },
                            perBatch: {
                                perBatch: t('global_per-batch'),
                                quantityPerBatches:
                                    offerLine.quantities[0]?.quantityPerBatches,
                            },
                        },
                        amount: {
                            quantitySample: offerLine.quantities[0]
                                ?.quantitySample
                                ? offerLine.quantities[0]?.quantitySample
                                : '-',
                            quantityFirstBatch: offerLine.quantities[0]
                                ?.quantityFirstBatch
                                ? offerLine.quantities[0]?.quantityFirstBatch
                                : '-',
                            quantityPerBatches: offerLine.quantities[0]
                                ?.quantityPerBatches
                                ? offerLine.quantities[0]?.quantityPerBatches
                                : '-',
                        },
                        origin: {
                            originCountry: offerLine.quantities[0]
                                ?.originCountry
                                ? offerLine.quantities[0]?.originCountry
                                : '-',
                            hsCode: offerLine.quantities[0]?.HSCode
                                ? offerLine.quantities[0]?.HSCode
                                : '-',
                        },
                        delivery: {
                            deliveryTimeSample: {
                                deliveryTimeSample: offerLine.quantities[0]
                                    ?.deliveryTimeSample
                                    ? offerLine.quantities[0]
                                          ?.deliveryTimeSample
                                    : '-',
                                quantitySample:
                                    offerLine.quantities[0]?.quantitySample,
                            },
                            deliveryTimeFirstBatch: {
                                deliveryTimeFirstBatch: offerLine.quantities[0]
                                    ?.deliveryTimeFirstBatch
                                    ? offerLine.quantities[0]
                                          ?.deliveryTimeFirstBatch
                                    : '-',
                                quantityFirstBatch:
                                    offerLine.quantities[0]?.quantityFirstBatch,
                            },
                            deliveryTimePerBatch: {
                                deliveryTimePerBatch: offerLine.quantities[0]
                                    ?.deliveryTimePerBatch
                                    ? offerLine.quantities[0]
                                          ?.deliveryTimePerBatch
                                    : '-',
                                quantityPerBatches:
                                    offerLine.quantities[0]?.quantityPerBatches,
                            },
                        },
                        price: {
                            totalPrice: offerLine.quantities[0]?.priceNet
                                ? offerLine.quantities[0]?.priceNet
                                : '-',
                        },
                    },
                };
            });

        let formatedFrameworkOrderHasOrigin = formatedFrameworkData.some(
            (item) => {
                return (
                    item.data.origin.originCountry !== '-' ||
                    item.data.origin.hsCode !== '-'
                );
            }
        );
        setFrameworkOrderHasOrigin(formatedFrameworkOrderHasOrigin);

        setFrameworkTableData(formatedFrameworkData);
        setTableData(formatedTableData);
    }, [offersWizzardReducer, t]);

    const handleOfferSubmit = () => {
        setButtonLoading(true);

        const data = {
            order: offersWizzardReducer.data.order?.orderLines.length
                ? {
                      comments: offersWizzardReducer.data.order.comments,
                      deliveryDate:
                          offersWizzardReducer.data.order.deliveryDate,
                      files: offersWizzardReducer.data.order.files,
                      orderLines: offersWizzardReducer.data.order.orderLines,
                      reference: offersWizzardReducer.data.order.reference,
                      totalPrice: offersWizzardReducer.data.order.totalPrice,
                  }
                : null,
            frameworkAgreement: offersWizzardReducer.data.frameworkAgreement
                ?.frameworkAgreementLines.length
                ? {
                      comments:
                          offersWizzardReducer.data.frameworkAgreement.comments,
                      deliveryDate:
                          offersWizzardReducer.data.frameworkAgreement
                              .deliveryDate,
                      files: offersWizzardReducer.data.frameworkAgreement.files,
                      frameworkAgreementLines:
                          offersWizzardReducer.data.frameworkAgreement
                              .frameworkAgreementLines,
                      reference:
                          offersWizzardReducer.data.frameworkAgreement
                              .reference,
                      totalPrice:
                          offersWizzardReducer.data.frameworkAgreement
                              .totalPrice,
                  }
                : null,
            addresses: {
                billingAddress:
                    offersWizzardReducer.data.addresses.billingAddress,
                shippingAddress:
                    offersWizzardReducer.data.addresses.shippingAddress,
            },
            defaultContacts: {
                contactInvoicing:
                    offersWizzardReducer.data.defaultContacts.contactInvoicing,
                contactPurchasing:
                    offersWizzardReducer.data.defaultContacts.contactPurchasing,
                contactOrderConfirmation:
                    offersWizzardReducer.data.defaultContacts
                        .contactOrderConfirmation,
            },
        };

        const reference = offersWizzardReducer.data.frameworkAgreement
            ? offersWizzardReducer.data.frameworkAgreement.reference
            : offersWizzardReducer.data.order.reference;
        dispatch(acceptOffer(data, reference)).then((r) => {
            dispatch(onClearWizzard());
            setButtonLoading(false);
            navigate('/offer-success');
        });
    };

    // get contact info
    const getContactInfo = (returnType, contactId) => {
        const chosenContact = userReducer?.user.contacts.find(
            (contact) => contact.id === contactId
        );

        if (returnType === 'email') {
            return chosenContact?.email;
        }

        return (
            chosenContact?.firstname +
            (chosenContact?.firstname ? ' ' : null) +
            chosenContact?.lastname
        );
    };

    return (
        <>
            <div className={classes.summary}>
                <span className={wizardStyles['wizzard__offer-num']}>
                    {offersWizzardReducer.details.offerNr}
                </span>
                <h3 className={classes['summary__intro-heading']}>
                    {t('summary_intro-heading')}
                </h3>
                <div className={classes['summary__wrapper']}>
                    <div className={classes['summary__left-side']}>
                        <h4 className={classes['summary__componets-heading']}>
                            {t('summary-table_heading')}
                        </h4>
                        {offersWizzardReducer.wizzardType === 'single-offer' ? (
                            <Table
                                tableTitles={tableTitles}
                                tableData={tableData}
                                tableType="offersSingleOrderWizardSummary"
                                hasOrigin={singleOrderHasOrigin}
                            />
                        ) : (
                            <Table
                                tableTitles={frameworkTableTitles}
                                tableData={frameworkTableData}
                                tableType="offersFaWizardSummary"
                                hasOrigin={frameworkOrderHasOrigin}
                            />
                        )}
                        <div>
                            <div
                                className={
                                    classes[
                                        'summary__ordering-instructions-sum'
                                    ]
                                }
                            >
                                <div
                                    className={
                                        classes[
                                            'summary__ordering-instructions'
                                        ]
                                    }
                                >
                                    <h6>{t('summary-instr_heading')}</h6>
                                    <ul>
                                        {offersWizzardReducer?.details.orderConditions?.map(
                                            (orderCondition, index) => {
                                                const textBullets = JSON.parse(
                                                    t('summary-text-bullets')
                                                );
                                                let value = textBullets[
                                                    orderCondition
                                                ]
                                                    ? textBullets[
                                                          orderCondition
                                                      ]
                                                    : '';
                                                if (
                                                    value.includes(
                                                        '{shippingCosts}'
                                                    )
                                                ) {
                                                    value = `${value.replace(
                                                        '{shippingCosts}',
                                                        offersWizzardReducer
                                                            .details
                                                            .shippingCosts
                                                    )} €`;
                                                } else if (
                                                    value.includes(
                                                        '{capacityReservedUntil}'
                                                    )
                                                ) {
                                                    value = value.replace(
                                                        '{capacityReservedUntil}',
                                                        offersWizzardReducer
                                                            .details
                                                            .capacityReservedUntil
                                                    );
                                                } else if (
                                                    value.includes(
                                                        '{paymentTerms}'
                                                    )
                                                ) {
                                                    value = value.replace(
                                                        '{paymentTerms}',
                                                        offersWizzardReducer
                                                            .details
                                                            .paymentTerms
                                                    );
                                                } else if (
                                                    value.includes(
                                                        '{improvedPaymentTerms}'
                                                    )
                                                ) {
                                                    value = value.replace(
                                                        '{improvedPaymentTerms}',
                                                        offersWizzardReducer
                                                            .details
                                                            .improvedPaymentTerms
                                                    );
                                                }
                                                return (
                                                    <li
                                                        key={`${index}=${value}`}
                                                    >
                                                        {value}
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                </div>
                                <div className={classes['summary__sum']}>
                                    <div
                                        className={classes['summary__sum-row']}
                                    >
                                        <div
                                            className={
                                                classes[
                                                    'summary__sum-row-label'
                                                ]
                                            }
                                        >
                                            {t('summary-table_sum')}
                                        </div>
                                        <span
                                            className={
                                                classes[
                                                    'summary__sum-row-value'
                                                ]
                                            }
                                        >
                                            <FormatedPrice price={totalPrice} />
                                        </span>
                                    </div>
                                    <div
                                        className={classes['summary__sum-row']}
                                    >
                                        <span
                                            className={
                                                classes[
                                                    'summary__sum-row-label'
                                                ]
                                            }
                                        >
                                            {t('summary-table_tax')}
                                            {
                                                offersWizzardReducer?.tax
                                                    ?.taxPercent
                                            }
                                            %
                                        </span>
                                        <span
                                            className={
                                                classes[
                                                    'summary__sum-row-value'
                                                ]
                                            }
                                        >
                                            <FormatedPrice price={taxPrice} />
                                        </span>
                                    </div>
                                    <div
                                        className={`${classes['summary__sum-row']} ${classes['summary__sum-row--brutto']}`}
                                    >
                                        <span
                                            className={`${classes['summary__sum-row-label']} ${classes['summary__sum-row-label--brutto']}`}
                                        >
                                            {t('summary-table_total-brutto')}
                                        </span>
                                        <span
                                            className={`${classes['summary__sum-row-value']} ${classes['summary__sum-row-value--brutto']}`}
                                        >
                                            <FormatedPrice
                                                price={totalPrice + taxPrice}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes['summary__right-side']}>
                        <div
                            className={
                                classes['summary__right-side-grey-section']
                            }
                        >
                            <h3
                                className={
                                    classes[
                                        'summary__right-side-grey-section-heading'
                                    ]
                                }
                            >
                                {t('summary_your-order-details')}
                            </h3>
                            <div
                                className={
                                    classes[
                                        'summary__order-details-boxes-wrapper'
                                    ]
                                }
                            >
                                {offersWizzardReducer.data.addresses
                                    .shippingAddress?.companyName.length ? (
                                    <div
                                        className={
                                            classes[
                                                'summary__order-details-box'
                                            ]
                                        }
                                    >
                                        <h6>
                                            {t(
                                                'wizard-ord-details_shipping-address'
                                            )}
                                        </h6>
                                        <div
                                            className={
                                                classes[
                                                    'summary__address-content'
                                                ]
                                            }
                                        >
                                            {offersWizzardReducer.data.addresses
                                                .shippingAddress
                                                ?.companyName && (
                                                <div>
                                                    {
                                                        offersWizzardReducer
                                                            .data.addresses
                                                            .shippingAddress
                                                            ?.companyName
                                                    }
                                                </div>
                                            )}
                                            {offersWizzardReducer.data.addresses
                                                .shippingAddress.street && (
                                                <div>
                                                    {
                                                        offersWizzardReducer
                                                            .data.addresses
                                                            .shippingAddress
                                                            .street
                                                    }
                                                </div>
                                            )}

                                            {(offersWizzardReducer.data
                                                .addresses.shippingAddress
                                                .postalCode ||
                                                offersWizzardReducer.data
                                                    .addresses.shippingAddress
                                                    .city ||
                                                offersWizzardReducer.data
                                                    .addresses.shippingAddress
                                                    .country) && (
                                                <div
                                                    className={
                                                        classes[
                                                            'summary__one-line'
                                                        ]
                                                    }
                                                >
                                                    {offersWizzardReducer.data
                                                        .addresses
                                                        .shippingAddress
                                                        .postalCode && (
                                                        <div>
                                                            {
                                                                offersWizzardReducer
                                                                    .data
                                                                    .addresses
                                                                    .shippingAddress
                                                                    .postalCode
                                                            }
                                                            {offersWizzardReducer
                                                                .data.addresses
                                                                .shippingAddress
                                                                .city
                                                                ? ' '
                                                                : offersWizzardReducer
                                                                      .data
                                                                      .addresses
                                                                      .shippingAddress
                                                                      .country
                                                                ? ', '
                                                                : null}
                                                        </div>
                                                    )}
                                                    {offersWizzardReducer.data
                                                        .addresses
                                                        .shippingAddress
                                                        .city && (
                                                        <div>
                                                            {
                                                                offersWizzardReducer
                                                                    .data
                                                                    .addresses
                                                                    .shippingAddress
                                                                    .city
                                                            }
                                                            {offersWizzardReducer
                                                                .data.addresses
                                                                .shippingAddress
                                                                .country
                                                                ? ', '
                                                                : null}
                                                        </div>
                                                    )}
                                                    {countries &&
                                                        offersWizzardReducer
                                                            .data.addresses
                                                            .shippingAddress
                                                            .country && (
                                                            <div>
                                                                {
                                                                    countries[
                                                                        offersWizzardReducer
                                                                            .data
                                                                            .addresses
                                                                            .shippingAddress
                                                                            .country
                                                                    ]
                                                                }
                                                            </div>
                                                        )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    '-'
                                )}
                                <div
                                    className={
                                        classes['summary__order-details-box']
                                    }
                                >
                                    <h6>
                                        {t(
                                            'wizard-ord-details_billing-address'
                                        )}
                                    </h6>
                                    {offersWizzardReducer.data.addresses
                                        .billingAddress?.companyName.length ? (
                                        <div
                                            className={[
                                                classes[
                                                    'summary__order-details-box'
                                                ],
                                                classes[
                                                    'summary__address-content'
                                                ],
                                            ].join(' ')}
                                        >
                                            {offersWizzardReducer.data.addresses
                                                .billingAddress.companyName && (
                                                <div>
                                                    {
                                                        offersWizzardReducer
                                                            .data.addresses
                                                            .billingAddress
                                                            .companyName
                                                    }
                                                </div>
                                            )}
                                            {offersWizzardReducer.data.addresses
                                                .billingAddress.street && (
                                                <div>
                                                    {
                                                        offersWizzardReducer
                                                            .data.addresses
                                                            .billingAddress
                                                            .street
                                                    }
                                                </div>
                                            )}

                                            {(offersWizzardReducer.data
                                                .addresses.billingAddress
                                                .postalCode ||
                                                offersWizzardReducer.data
                                                    .addresses.billingAddress
                                                    .city ||
                                                offersWizzardReducer.data
                                                    .addresses.billingAddress
                                                    .country) && (
                                                <div
                                                    className={
                                                        classes[
                                                            'summary__one-line'
                                                        ]
                                                    }
                                                >
                                                    {offersWizzardReducer.data
                                                        .addresses
                                                        .billingAddress
                                                        .postalCode && (
                                                        <div>
                                                            {
                                                                offersWizzardReducer
                                                                    .data
                                                                    .addresses
                                                                    .billingAddress
                                                                    .postalCode
                                                            }
                                                            {offersWizzardReducer
                                                                .data.addresses
                                                                .billingAddress
                                                                .city
                                                                ? ' '
                                                                : offersWizzardReducer
                                                                      .data
                                                                      .addresses
                                                                      .billingAddress
                                                                      .country
                                                                ? ', '
                                                                : null}
                                                        </div>
                                                    )}
                                                    {offersWizzardReducer.data
                                                        .addresses
                                                        .billingAddress
                                                        .city && (
                                                        <div>
                                                            {
                                                                offersWizzardReducer
                                                                    .data
                                                                    .addresses
                                                                    .billingAddress
                                                                    .city
                                                            }
                                                            {offersWizzardReducer
                                                                .data.addresses
                                                                .billingAddress
                                                                .country
                                                                ? ', '
                                                                : null}
                                                        </div>
                                                    )}
                                                    {countries &&
                                                        offersWizzardReducer
                                                            .data.addresses
                                                            .billingAddress
                                                            .country && (
                                                            <div>
                                                                {
                                                                    countries[
                                                                        offersWizzardReducer
                                                                            .data
                                                                            .addresses
                                                                            .billingAddress
                                                                            .country
                                                                    ]
                                                                }
                                                            </div>
                                                        )}
                                                </div>
                                            )}
                                            {offersWizzardReducer.data.addresses
                                                .billingAddress.taxNumber && (
                                                <div>
                                                    {
                                                        offersWizzardReducer
                                                            .data.addresses
                                                            .billingAddress
                                                            .taxNumber
                                                    }
                                                </div>
                                            )}
                                        </div>
                                    ) : offersWizzardReducer.data.addresses
                                          .shippingAddress?.companyName
                                          .length ? (
                                        <div
                                            className={[
                                                classes[
                                                    'summary__order-details-box'
                                                ],
                                                classes[
                                                    'summary__address-content'
                                                ],
                                            ].join(' ')}
                                        >
                                            {offersWizzardReducer.data.addresses
                                                .shippingAddress
                                                ?.companyName && (
                                                <div>
                                                    {
                                                        offersWizzardReducer
                                                            .data.addresses
                                                            .shippingAddress
                                                            ?.companyName
                                                    }
                                                </div>
                                            )}
                                            {offersWizzardReducer.data.addresses
                                                .shippingAddress.street && (
                                                <div>
                                                    {
                                                        offersWizzardReducer
                                                            .data.addresses
                                                            .shippingAddress
                                                            .street
                                                    }
                                                </div>
                                            )}

                                            {(offersWizzardReducer.data
                                                .addresses.shippingAddress
                                                .postalCode ||
                                                offersWizzardReducer.data
                                                    .addresses.shippingAddress
                                                    .city ||
                                                offersWizzardReducer.data
                                                    .addresses.shippingAddress
                                                    .country) && (
                                                <div
                                                    className={
                                                        classes[
                                                            'summary__one-line'
                                                        ]
                                                    }
                                                >
                                                    {offersWizzardReducer.data
                                                        .addresses
                                                        .shippingAddress
                                                        .postalCode && (
                                                        <div>
                                                            {
                                                                offersWizzardReducer
                                                                    .data
                                                                    .addresses
                                                                    .shippingAddress
                                                                    .postalCode
                                                            }
                                                            {offersWizzardReducer
                                                                .data.addresses
                                                                .shippingAddress
                                                                .city
                                                                ? ' '
                                                                : offersWizzardReducer
                                                                      .data
                                                                      .addresses
                                                                      .shippingAddress
                                                                      .country
                                                                ? ',1212qqwqw '
                                                                : null}
                                                        </div>
                                                    )}
                                                    {offersWizzardReducer.data
                                                        .addresses
                                                        .shippingAddress
                                                        .city && (
                                                        <div>
                                                            {
                                                                offersWizzardReducer
                                                                    .data
                                                                    .addresses
                                                                    .shippingAddress
                                                                    .city
                                                            }
                                                            {offersWizzardReducer
                                                                .data.addresses
                                                                .shippingAddress
                                                                .country
                                                                ? ', '
                                                                : null}
                                                        </div>
                                                    )}
                                                    {countries &&
                                                        offersWizzardReducer
                                                            .data.addresses
                                                            .shippingAddress
                                                            .country && (
                                                            <div>
                                                                {
                                                                    countries[
                                                                        offersWizzardReducer
                                                                            .data
                                                                            .addresses
                                                                            .shippingAddress
                                                                            .country
                                                                    ]
                                                                }
                                                            </div>
                                                        )}
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        '-'
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    classes[
                                        'summary__order-details-boxes-wrapper'
                                    ]
                                }
                            >
                                {offersWizzardReducer.wizzardType ===
                                'single-offer' ? (
                                    <div
                                        className={
                                            classes[
                                                'summary__order-details-box'
                                            ]
                                        }
                                    >
                                        <h6>
                                            {t(
                                                'wizard-ord-details_shipping-date'
                                            )}
                                        </h6>
                                        <span>
                                            {offersWizzardReducer.data.order
                                                ?.deliveryDate
                                                ? moment(
                                                      offersWizzardReducer.data
                                                          .order.deliveryDate
                                                  ).format('DD.MM.YYYY')
                                                : '-'}
                                        </span>
                                    </div>
                                ) : null}
                                <div
                                    className={
                                        classes['summary__order-details-box']
                                    }
                                >
                                    <h6>
                                        {t(
                                            'wizard-ord-details_internal-ord-num'
                                        )}
                                    </h6>
                                    <span>
                                        {offersWizzardReducer.wizzardType ===
                                        'single-offer'
                                            ? offersWizzardReducer.data.order
                                                  ?.reference
                                                ? offersWizzardReducer.data
                                                      .order?.reference
                                                : '-'
                                            : offersWizzardReducer.data
                                                  .frameworkAgreement?.reference
                                            ? offersWizzardReducer.data
                                                  .frameworkAgreement?.reference
                                            : '-'}
                                    </span>
                                </div>
                            </div>
                            <div
                                className={
                                    classes['summary__order-details-box']
                                }
                            >
                                <h6>{t('summary_order-document')}</h6>
                                <ul>
                                    {offersWizzardReducer.wizzardType ===
                                    'single-offer' ? (
                                        offersWizzardReducer.data.order?.files
                                            .length ? (
                                            <ul>
                                                {offersWizzardReducer.data.order.files.map(
                                                    (file, index) => (
                                                        <UploadedFile
                                                            key={`file-${index}`}
                                                            fileName={
                                                                file.fileName
                                                            }
                                                            extraClass="summary"
                                                        />
                                                    )
                                                )}
                                            </ul>
                                        ) : (
                                            '-'
                                        )
                                    ) : offersWizzardReducer.data
                                          .frameworkAgreement?.files.length ? (
                                        <ul>
                                            {offersWizzardReducer.data.frameworkAgreement.files.map(
                                                (file, index) => (
                                                    <UploadedFile
                                                        key={`file-${index}`}
                                                        fileName={file.fileName}
                                                        extraClass="summary"
                                                    />
                                                )
                                            )}
                                        </ul>
                                    ) : (
                                        '-'
                                    )}
                                </ul>
                            </div>
                            <div
                                className={`${classes['summary__order-details-box']} ${classes['summary__order-details-box--comment']}`}
                            >
                                <h6>{t('wizard-ord-details_comment')}</h6>
                                <p>
                                    {offersWizzardReducer.wizzardType ===
                                    'single-offer'
                                        ? offersWizzardReducer.data.order
                                              ?.comments
                                            ? offersWizzardReducer.data.order
                                                  ?.comments
                                            : '-'
                                        : offersWizzardReducer.data
                                              .frameworkAgreement?.comments
                                        ? offersWizzardReducer.data
                                              .frameworkAgreement?.comments
                                        : '-'}
                                </p>
                            </div>
                            <div
                                className={[
                                    classes['summary__multiple-items'],
                                    classes['summary__contacts'],
                                ].join(' ')}
                            >
                                <div
                                    className={`${classes['summary__order-details-box']} ${classes['summary__order-details-box--contact']}`}
                                >
                                    <h6>
                                        <TranslatedComponent
                                            translationFileName="contact-roles"
                                            translatedComponent={'purchasing'}
                                        />
                                    </h6>
                                    <div
                                        className={classes['summary__contact']}
                                    >
                                        <div
                                            className={
                                                classes['summary__contact-name']
                                            }
                                        >
                                            {contacts &&
                                            offersWizzardReducer?.data
                                                .defaultContacts
                                                .contactOrderConfirmation
                                                ? getContactInfo(
                                                      'name',
                                                      offersWizzardReducer?.data
                                                          .defaultContacts
                                                          .contactPurchasing
                                                  )
                                                : '-'}
                                        </div>
                                        {contacts &&
                                        offersWizzardReducer?.data
                                            .defaultContacts
                                            .contactPurchasing ? (
                                            <p
                                                className={
                                                    classes[
                                                        'summary__contact-email'
                                                    ]
                                                }
                                            >
                                                {getContactInfo(
                                                    'email',
                                                    offersWizzardReducer?.data
                                                        .defaultContacts
                                                        .contactPurchasing
                                                )}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                                <div
                                    className={`${classes['summary__order-details-box']} ${classes['summary__order-details-box--contact']}`}
                                >
                                    <h6>
                                        <TranslatedComponent
                                            translationFileName="contact-roles"
                                            translatedComponent={'invoicing'}
                                        />
                                    </h6>
                                    <div
                                        className={classes['summary__contact']}
                                    >
                                        <div
                                            className={
                                                classes['summary__contact-name']
                                            }
                                        >
                                            {contacts &&
                                            offersWizzardReducer?.data
                                                .defaultContacts
                                                .contactOrderConfirmation
                                                ? getContactInfo(
                                                      'name',
                                                      offersWizzardReducer?.data
                                                          .defaultContacts
                                                          .contactInvoicing
                                                  )
                                                : '-'}
                                        </div>
                                        {contacts &&
                                        offersWizzardReducer?.data
                                            .defaultContacts
                                            .contactInvoicing ? (
                                            <p
                                                className={
                                                    classes[
                                                        'summary__contact-email'
                                                    ]
                                                }
                                            >
                                                {getContactInfo(
                                                    'email',
                                                    offersWizzardReducer?.data
                                                        .defaultContacts
                                                        .contactInvoicing
                                                )}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                                <div
                                    className={`${classes['summary__order-details-box']} ${classes['summary__order-details-box--contact']}`}
                                >
                                    <h6>
                                        <TranslatedComponent
                                            translationFileName="contact-roles"
                                            translatedComponent={
                                                'orderConfirmation'
                                            }
                                        />
                                    </h6>
                                    <div
                                        className={classes['summary__contact']}
                                    >
                                        <div
                                            className={
                                                classes['summary__contact-name']
                                            }
                                        >
                                            {contacts &&
                                            offersWizzardReducer?.data
                                                .defaultContacts
                                                .contactOrderConfirmation
                                                ? getContactInfo(
                                                      'name',
                                                      offersWizzardReducer?.data
                                                          .defaultContacts
                                                          .contactOrderConfirmation
                                                  )
                                                : '-'}
                                        </div>
                                        {contacts &&
                                        offersWizzardReducer?.data
                                            .defaultContacts
                                            .contactOrderConfirmation ? (
                                            <p
                                                className={
                                                    classes[
                                                        'summary__contact-email'
                                                    ]
                                                }
                                            >
                                                {getContactInfo(
                                                    'email',
                                                    offersWizzardReducer?.data
                                                        .defaultContacts
                                                        .contactOrderConfirmation
                                                )}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <WizzardFooter
                    prevButtonText={t('global_go-back-btn')}
                    nextButtonText={t('summary_next-step-btn')}
                    handleSubmitApi={handleOfferSubmit}
                    nextSubmit={true}
                    nextButtonLoading={buttonLoading}
                    backUrl={`/offers/${offersWizzardReducer.details.offerId}/wizard/order-details?type=${offersWizzardReducer.wizzardType}`}
                />
            </div>
        </>
    );
}

export default Summary;
