import React from 'react';
import { useEffect } from 'react';
import DetailsSideScreen from '../../../components/Details/DetailsSideScreen/DetailsSideScreen';
import { useDispatch, useSelector } from 'react-redux';
import { getDetails } from '../../../store/thunks/detailsThunk';
import { Outlet, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { onDetailsCleanup } from '../../../store/actions/detailsActions';
import DetailsWrapper from '../../../components/Details/DetailsWrapper/DetailsWrapper';
import DetailsRigthSideWrapper from '../../../components/Details/DetailsRigthSideWrapper/DetailsRigthSideWrapper';
import DetailsPopup from '../../../components/Details/DetailsPopup/DetailsPopup';
import { onTableContentCleanup } from '../../../store/actions/tableContentActions';
import Tabs from '../../../components/PageHeader/Tabs/Tabs';

function OrderDetails() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { t } = useTranslation();

    const offerDetailsLoading = useSelector(
        (state) => state.details.detailsLoading
    );

    const detailsPopup = useSelector(
        (state) => state.detailsPopup.detailsPopup
    );

    useEffect(() => {
        const params = new URLSearchParams();
        dispatch(getDetails('order', id, params));
    }, [dispatch, id]);

    //cleanup
    useEffect(
        () => () => {
            dispatch(onTableContentCleanup());
            dispatch(onDetailsCleanup());
        },
        //eslint-disable-next-line
        []
    );

    return (
        <>
            <DetailsWrapper>
                <DetailsSideScreen
                    loading={offerDetailsLoading}
                    pageType="orders"
                    goBackLink="/orders?type=Open"
                />
                <DetailsRigthSideWrapper loading={offerDetailsLoading}>
                    <Tabs
                        tabs={[
                            {
                                name: t('order-details_components-tab'),
                                link: 'components',
                            },
                            {
                                name: t('order-details_deliveries-tab'),
                                link: 'deliveries',
                            },
                            {
                                name: t('order-details_invoices-tab'),
                                link: 'invoices',
                            },
                            {
                                name: t('account-tab_contacts'),
                                link: 'contacts',
                            },
                        ]}
                    />
                    <Outlet />
                </DetailsRigthSideWrapper>
            </DetailsWrapper>
            <DetailsPopup
                type="orders"
                open={Object.values(detailsPopup).length ? true : false}
            />
        </>
    );
}

export default OrderDetails;
