import React from 'react';
import Tab from './Tab/Tab';
import classes from './PageTabs.module.scss';

function PageTabs({ tabs, pageName }) {
    // const re

    return (
        <>
            <div
                className={
                    pageName === 'frameworkComponents'
                        ? classes['c-page-tabs']
                        : ''
                }
            >
                {tabs.map((tab) => {
                    return (
                        <Tab
                            key={`tab-${tab.label}`}
                            title={tab.label}
                            type={tab.value}
                            pageName={pageName}
                        />
                    );
                })}
            </div>
        </>
    );
}

export default PageTabs;
