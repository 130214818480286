import classes from '../Details/DetailsSideScreen/DetailsSideScreen.module.scss';
import { useSelector } from 'react-redux';

const SidebarDataTitleLabel = ({ labelName, additionalClass }) => {
	const loading = useSelector((state) => state.details.detailsLoading);

	return loading ? (
		<span
			className={`${classes['c-details-sidescreen__detail-title']} ${classes['c-details-sidescreen__detail-title--skeleton']} `}
		></span>
	) : (
		<div
			className={[
				classes['c-details-sidescreen__detail-title'],
				classes[`${additionalClass}`],
			].join(' ')}
		>
			{labelName}
		</div>
	);
};

SidebarDataTitleLabel.defaultProps = {
	labelName: '',
	additionalClass: '',
};

export default SidebarDataTitleLabel;
