import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button/Button';
import Input from '../../../../../components/Input/Input/Input';
import classes from './InviteContactModal.module.scss';
import SimpleSelect from '../../../../../components/Input/SimpleSelect/SimpleSelect';
import ApiService from '../../../../../services/api.service';
import ResultModal from '../../../../../components/ResultModal/ResultModal';
import ModalWrapper from '../../../../../components/ModalWrapper/ModalWrapper';
import CircledCheckmarkIcon from '../../../../../components/Icons/CircledCheckmarkIcon';
import PlusIcon from '../../../../../components/Icons/PlusIcon';
import ContactRadioInput from '../../../../../components/ContactRadioInput/ContactRadioInput';
import ErrorMessage from '../../../../../components/ErrorMessage/ErrorMessage';
import InputWithCustomEvent from '../../../../../components/Input/InputWithCustomEvent/InputWithCustomEvent';
import { useCallback } from 'react';
import debounce from 'lodash/debounce';

function InviteContactModal({ onClickHandler, showModal }) {
    const [step2, setStep2] = useState(false);
    const [customError, setCustomError] = useState();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const [jobRolePrefillValue, setJobRolePrefillValue] = useState();

    const initialValues = {
        email: '',
        portalRole: '',
        firstName: '',
        lastName: '',
        title: 'Mr',
        phone: '',
    };

    const submitInviteContactHandler = (values) => {
        setLoading(true);

        const data = {
            email: values.email,
            portalRole: values.portalRole,
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone,
            jobRole: values.jobRole,
        };

        ApiService.inviteContact(data)
            .then((response) => {
                setLoading(false);
                setStep2(true);
                values.resetForm();
                setCustomError('');
            })
            .catch((error) => {
                setLoading(false);
                setCustomError('404');
            });
    };

    const jobRoleBlur = (props) => {
        props.setFieldTouched('jobRole', true);
    };

    const jobRoleChange = (jobRole, props) => {
        props.setFieldValue('jobRole', jobRole.value);
    };

    const titleBlur = (props) => {
        props.setFieldTouched('title', true);
    };

    const titleChange = (title, props) => {
        props.setFieldValue('title', title.value);
    };

    const modalClickHandler = () => {
        onClickHandler(false);
    };

    useEffect(() => {
        if (!showModal) {
            setTimeout(() => {
                resetForm();
                setStep2(false);
            }, 500);
        }

        //eslint-disable-next-line
    }, [showModal]);

    const returnToStep1Handler = () => {
        setCustomError('');
        setStep2(false);
    };

    const emailChangeHandler = (target, props) => {
        if (target.value && target.name === 'email') {
            const email = target.value;

            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const emailIsValid = regex.test(email);

            if (emailIsValid) {
                const encodedEmail = encodeURIComponent(email);
                const data = {
                    email: encodedEmail,
                };
                ApiService.checkContact(data)
                    .then((response) => {
                        setCustomError(response.data.data.contactPortalStatus);
                        if (
                            response.data.data.contactPortalStatus ===
                            'ContactInSF'
                        ) {
                            props.setFieldValue(
                                'firstName',
                                response.data.data.firstName
                            );
                            props.setFieldValue(
                                'lastName',
                                response.data.data.lastName
                            );
                            props.setFieldValue(
                                'phone',
                                response.data.data.phone
                            );
                            setJobRolePrefillValue(response.data.data.jobRole);
                        }
                    })
                    .catch((error) => {
                        // console.log('error', error);
                    });
            }
        }
    };

    //eslint-disable-next-line
    const debouncedChangeHandler = useCallback(
        debounce(emailChangeHandler, 300),
        []
    );

    const resetForm = () => {
        const form = document.getElementById('contactInviteForm');
        if (showModal) {
            form.reset();
        }
        setCustomError('');
    };

    return (
        <ModalWrapper onModalClose={modalClickHandler} showModal={showModal}>
            <div className={classes['c-invite-contact-modal']}>
                {!step2 ? (
                    <div>
                        <h1>{t('contacts_invite')}</h1>
                        <Formik
                            onSubmit={submitInviteContactHandler}
                            initialValues={initialValues}
                        >
                            {(props) => (
                                <Form
                                    id="contactInviteForm"
                                    onChange={(e) =>
                                        debouncedChangeHandler(e.target, props)
                                    }
                                >
                                    <div
                                        className={
                                            classes[
                                                'c-invite-contact-modal__field'
                                            ]
                                        }
                                    >
                                        <InputWithCustomEvent
                                            type="email"
                                            label={t('login_email-label')}
                                            name="email"
                                            touched={props.touched.email}
                                            autoComplete="email"
                                            hascustomerror={
                                                customError ===
                                                    'ContactAlReadyRegistered' ||
                                                customError ===
                                                    'ContactExistsNewEmailSent'
                                                    ? 'true'
                                                    : ''
                                            }
                                        />
                                        {customError ===
                                        'ContactAlReadyRegistered' ? (
                                            <div className="c-field-error">
                                                {t(
                                                    'contacts_error-invited-and-registered'
                                                )}
                                            </div>
                                        ) : customError ===
                                          'ContactExistsNewEmailSent' ? (
                                            <div className="c-field-error">
                                                {t(
                                                    'contacts_error-email-already-invited'
                                                )}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div
                                        className={
                                            classes[
                                                'c-invite-contact-modal__portal-roles'
                                            ]
                                        }
                                    >
                                        <ContactRadioInput
                                            type="radio"
                                            name="portalRole"
                                            value="Admin"
                                            selectedvalue={
                                                props.values.portalRole
                                            }
                                            title={t('contacts_admin')}
                                            description={t(
                                                'contacts_admin-description'
                                            )}
                                            disabled={
                                                customError ===
                                                    'ContactAlReadyRegistered' ||
                                                customError ===
                                                    'ContactExistsNewEmailSent'
                                            }
                                        />
                                        <ContactRadioInput
                                            type="radio"
                                            name="portalRole"
                                            value="Member"
                                            selectedvalue={
                                                props.values.portalRole
                                            }
                                            title={t('contacts_member')}
                                            description={t(
                                                'contacts_member-description'
                                            )}
                                            disabled={
                                                customError ===
                                                    'ContactAlReadyRegistered' ||
                                                customError ===
                                                    'ContactExistsNewEmailSent'
                                            }
                                        />
                                    </div>
                                    {(customError === 'ContactInSF' ||
                                        customError === '404') &&
                                    props.values.portalRole ? (
                                        <ErrorMessage
                                            errorText={
                                                customError === 'ContactInSF'
                                                    ? t(
                                                          'contacts_error-exists-prefill'
                                                      )
                                                    : t('error-form-general')
                                            }
                                            bgColor={
                                                customError === 'ContactInSF'
                                                    ? 'lightBlue'
                                                    : 'errorMessage'
                                            }
                                            additionalClass="notification"
                                            hasIcon={
                                                customError === 'ContactInSF'
                                            }
                                            customIcon={
                                                customError === 'ContactInSF'
                                            }
                                            hasErrorText={true}
                                        />
                                    ) : null}
                                    {(customError === 'NewContact' ||
                                        customError === 'ContactInSF') &&
                                    props.values.portalRole ? (
                                        <div>
                                            <div
                                                className={[
                                                    classes[
                                                        'c-invite-contact-modal__fields'
                                                    ],
                                                    classes[
                                                        'c-invite-contact-modal__fields--2'
                                                    ],
                                                ].join(' ')}
                                            >
                                                <div
                                                    className={[
                                                        classes[
                                                            'c-invite-contact-modal__field'
                                                        ],
                                                        classes[
                                                            'c-invite-contact-modal__field--small'
                                                        ],
                                                    ].join(' ')}
                                                >
                                                    <label
                                                        className={
                                                            classes[
                                                                'c-invite-contact-modal__label'
                                                            ]
                                                        }
                                                    >
                                                        {t('contacts_title')}
                                                    </label>
                                                    <SimpleSelect
                                                        initialValue={
                                                            props.values.title
                                                        }
                                                        onChange={(title) =>
                                                            titleChange(
                                                                title,
                                                                props
                                                            )
                                                        }
                                                        errors={
                                                            props.errors.title
                                                        }
                                                        touched={
                                                            props.touched.title
                                                        }
                                                        onBlur={() =>
                                                            titleBlur(props)
                                                        }
                                                        disabled={
                                                            customError ===
                                                            'ContactInSF'
                                                        }
                                                        translationGroup="titles"
                                                        extraClass="normal"
                                                        isSearchable={false}
                                                    />
                                                </div>
                                                <Input
                                                    type="text"
                                                    label={t(
                                                        'contacts-first-name'
                                                    )}
                                                    name="firstName"
                                                    errors={
                                                        props.errors.firstName
                                                    }
                                                    touched={
                                                        props.touched.firstName
                                                    }
                                                    autoComplete="firstName"
                                                    disabled={
                                                        customError ===
                                                        'ContactInSF'
                                                    }
                                                />
                                            </div>
                                            <Input
                                                type="text"
                                                label={t('contacts_last-name')}
                                                name="lastName"
                                                errors={props.errors.lastName}
                                                touched={props.touched.lastName}
                                                autoComplete="lastName"
                                                disabled={
                                                    customError ===
                                                    'ContactInSF'
                                                }
                                            />
                                            <div
                                                className={[
                                                    classes[
                                                        'c-invite-contact-modal__fields'
                                                    ],
                                                ].join(' ')}
                                            >
                                                <Input
                                                    type="text"
                                                    name="phone"
                                                    label={t('contacts_phone')}
                                                    autoComplete="phone"
                                                    hideerror="true"
                                                    disabled={
                                                        customError ===
                                                        'ContactInSF'
                                                    }
                                                />
                                            </div>
                                            <div
                                                className={
                                                    classes[
                                                        'c-invite-contact-modal__field'
                                                    ]
                                                }
                                            >
                                                <label
                                                    className={
                                                        classes[
                                                            'c-invite-contact-modal__label'
                                                        ]
                                                    }
                                                >
                                                    {t('contacts_job-role')}
                                                </label>
                                                <SimpleSelect
                                                    initialValue={
                                                        props.values.jobRole
                                                    }
                                                    onChange={(jobRole) =>
                                                        jobRoleChange(
                                                            jobRole,
                                                            props
                                                        )
                                                    }
                                                    errors={
                                                        props.errors.jobRole
                                                    }
                                                    touched={
                                                        props.touched.jobRole
                                                    }
                                                    onBlur={() =>
                                                        jobRoleBlur(props)
                                                    }
                                                    translationGroup="contacts-roles"
                                                    prefillValue={
                                                        jobRolePrefillValue
                                                    }
                                                    extraClass="normal"
                                                    ariaLabel="Select job role"
                                                    customList={true}
                                                    disabled={
                                                        customError ===
                                                        'ContactInSF'
                                                    }
                                                    isSearchable={false}
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                    <Button
                                        className={`btn btn--purple btn--small btn--mobile-login  ${
                                            loading ? 'btn--loading' : ''
                                        }`}
                                        ariaLabel={''}
                                        type="submit"
                                        disabled={
                                            (customError !== 'NewContact' &&
                                                customError !==
                                                    'ContactInSF') ||
                                            !props.values.portalRole
                                        }
                                    >
                                        {t('contacts_invite-submit-btn')}
                                        {loading ? (
                                            <span className="loader"></span>
                                        ) : null}
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                ) : null}
                {step2 ? (
                    <ResultModal
                        title={t('contacts_invite-success-message')}
                        onConfirmHandler={returnToStep1Handler}
                        icon={<CircledCheckmarkIcon />}
                        additionButtonText={t('contacts_invite-more-contacts')}
                        additionButtonIcon={<PlusIcon />}
                    />
                ) : null}
            </div>
        </ModalWrapper>
    );
}

InviteContactModal.defaulProps = {
    showModal: false,
};

export default InviteContactModal;
