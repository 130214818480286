import React from 'react';
import TableEmptyState from './TableEmptyState/TableEmptyState';
import TableHeader from './TableHeader/TableHeader';
import TableItem from './TableItem/TableItem';
import TableSkeletonLoader from './TableSkeletonLoader/TableSkeletonLoader';
import SkeletonWithImage from '../SkeletonLoader/SkeletonWithImage/SkeletonWithImage';

function Table(props) {
    const tableSortAndDirectionChangeHandler = (value) => {
        props.onTableSortAndDirectionChangeHandler(value);
    };

    return (
        <div
            className={`c-table ${props.pb55 ? 'pb-55' : ''} ${
                props.pb35 ? 'pb-35' : ''
            } c-table--${props.tableTitles?.length} c-table--${
                props.tableType
            } ${props.hasDiscount ? 'hasDiscount' : 'noDiscount'} ${
                props.hasOrigin ? 'hasOrigin' : 'noOrigin'
            }`}
        >
            <TableHeader
                titles={props.tableTitles}
                onTableSortAndDirectionChangeHandler={
                    tableSortAndDirectionChangeHandler
                }
                tableType={props.tableType}
                tableList={props.tableList}
                hasDiscount={props.hasDiscount}
                hasOrigin={props.hasOrigin}
            />
            {props.loading ? (
                <TableSkeletonLoader
                    columns={props.tableTitles?.length}
                    tableType={props.tableType}
                />
            ) : (
                <>
                    {props.tableData?.length && props.tableData[0] !== '' ? (
                        props.tableData?.map((data, index) => {
                            return (
                                <TableItem
                                    key={`table-${index}`}
                                    fullClickableRow={props.fullClickableRow}
                                    data={data.data ? data.data : data}
                                    certificates={data?.certificates}
                                    showCertificates={data?.showCertificates}
                                    id={data.id}
                                    tableType={props.tableType}
                                    linkableRows={props.linkableRows}
                                    itemIndex={data.itemIndex}
                                    details={
                                        props.details
                                            ? props.details[index]
                                            : null
                                    }
                                    hasDiscount={props.hasDiscount}
                                    hasOrigin={props.hasOrigin}
                                    missingDataInfo={data.missingDataInfo}
                                    customErrorMessage={data.customErrorMessage}
                                />
                            );
                        })
                    ) : props.tableData?.length === 0 ? (
                        <TableEmptyState
                            heading={props.emptyStHeading}
                            description={props.emptyStText}
                            btnText={props.emptyStBtnText}
                            columns={props.tableTitles?.length}
                            tableType={props.tableType}
                        />
                    ) : props.skeletonWithImg ? (
                        <SkeletonWithImage hideOverflow={true} />
                    ) : (
                        <TableSkeletonLoader
                            columns={props.tableTitles?.length}
                            tableType={props.tableType}
                        />
                    )}
                </>
            )}
        </div>
    );
}

export default Table;
