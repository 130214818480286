import React, { useState, useEffect } from 'react';
import TableList from '../../../components/TableList/TableList';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import useBreakpoint from '../../../hooks/useBreakpoint';
import PageTabs from '../../../components/PageHeader/PageTabs/PageTabs';
import Select from 'react-select';
import { useSearchParams } from 'react-router-dom';
import Table from '../../../components/Table/Table';
import PageSearch from '../../../components/PageHeader/PageSearch/PageSearch';
import classes from './FrameworkAgreementsComponents.module.scss';
import SkeletonWithImage from '../../../components/SkeletonLoader/SkeletonWithImage/SkeletonWithImage';
import SkeletonWithImageTable from '../../../components/SkeletonLoader/SkeletonWithImageTable/SkeletonWithImageTable';
import PageMeta from '../../../shared/PageMeta/PageMeta';
import { useNavigate, useParams } from 'react-router-dom';
import { onOpenDetails } from '../../../store/actions/detailsPopupActions';
import setGoogleAnalytics from '../../../hooks/setGoogleAnalytics';

function FrameworkAgreementsComponents() {
    const [tableData, setTableData] = useState();
    const [tableData2, setTableData2] = useState(['']);
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const [type, setType] = useState({});
    const [showInitialMessage, setShowInitialMessage] = useState(true);
    const [loading, setLoading] = useState(true);

    //eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();

    const searchedFrameworkLines = useSelector(
        (state) => state.details.searchedFrameworkLines
    );

    const detailsLoading = useSelector((state) => state.details.detailsLoading);

    const faNumber = useSelector((state) => state.details.details.faNr);

    const details = useSelector((state) => state.details.details);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { componentId, order } = useParams();

    useEffect(() => {
        if (componentId && details.faId) {
            let detailsObject;
            if (details.frameworkLines?.length) {
                detailsObject = details.frameworkLines.find(
                    (frameworkLine) =>
                        frameworkLine.frameworkLineId === componentId
                );
                if (detailsObject) {
                    dispatch(
                        onOpenDetails(detailsObject, 'frameworkComponents')
                    );
                }
            }

            //fallback if componentId is not valid
            if (!detailsObject) {
                navigate(
                    `/framework-agreements/${order}/components?type=Overview`
                );
            }
        }
        //eslint-disable-next-line
    }, [details]);

    const tableTitles = [
        {
            id: 1,
            name: t('fa-contract-details_component'),
        },
        {
            id: 1,
            name: t('fa-contract-details_material'),
        },
        {
            id: 1,
            name: t('fa-contract-details_duration'),
        },
        {
            id: 1,
            name: t('fa-contract-details_total-quantity'),
        },
        {
            id: 1,
            name: t('fa-contract-details_retrieve'),
        },
        {
            id: 1,
            name: t('fa-contract-details_amounts'),
        },
        {
            id: 1,
            name: t('fa-contract-details_delivery'),
        },
        {
            id: 1,
            name: t('fa-contract-details_price'),
        },
    ];

    const orderComponentsTableTitles = [
        {
            id: 1,
            name: t('fa-details-comp_insert'),
        },
        {
            id: 2,
            name: t('fa-details-comp_open'),
        },
        {
            id: 3,
            name: t('fa-details-comp_retrieved'),
        },
        {
            id: 4,
            name: t('fa-details-comp_delivered'),
        },
        {
            id: 5,
            name: t('fa-details-comp_paid'),
        },
        {
            id: 6,
            name: t('fa-details-comp_total'),
        },
        {
            id: 7,
            name: t('fa-details-comp_price'),
        },
    ];

    const dropdownTitles = [
        {
            id: 1,
            name: '',
        },
        {
            id: 2,
            name: t('fa-details_confirmed-on'),
        },
        {
            id: 3,
            name: t('fa-details_order-num'),
        },
        {
            id: 4,
            name: t('fa-details_reference'),
        },
        {
            id: 5,
            name: t('fa-details_components'),
        },
        {
            id: 6,
            name: t('fa-details-comp_price'),
        },
        {
            id: 7,
            name: t('fa-details_delivery-date'),
        },
        {
            id: 8,
            name: t('fa-details_status'),
        },
    ];

    useEffect(() => {
        let filteredFrameworkLines = [];
        let filteredFrameworkLines2 = [];
        filteredFrameworkLines2 = searchedFrameworkLines?.map((item) => {
            return {
                id: item.uuid,
                files: item.files,
                data: {
                    part: {
                        partName: item.partName ? item.partName : '-',
                        partNumber: item.articleNumber
                            ? item.articleNumber
                            : '-',
                        partImage: item.files
                            ? item.files[0]?.fileThumbnailUrl
                            : null,
                    },
                    material: {
                        material: item.material ? item.material : '-',
                        materialGroup: item.materialGroup
                            ? item.materialGroup
                            : '-',
                        materialComments: item.materialComments
                            ? item.materialComments
                            : '-',
                    },
                    durationAgreement: item.durationAgreement
                        ? `${item.durationAgreement} months`
                        : '-',
                    quantity: {
                        number: item.quantity ? item.quantity : '-',
                    },
                    abrufe: {
                        sample: {
                            sample: t('global_sample'),
                            quantitySample: item.quantitySample,
                        },
                        firstBatch: {
                            firstBatch: t('global_first-batch'),
                            quantityFirstBatch: item.quantityFirstBatch,
                        },
                        perBatch: {
                            perBatch: t('global_per-batch'),
                            quantityPerBatches: item.quantityPerBatches,
                        },
                    },
                    amount: {
                        quantitySample: item.quantitySample
                            ? item.quantitySample
                            : '-',
                        quantityFirstBatch: item.quantityFirstBatch
                            ? item.quantityFirstBatch
                            : '-',
                        quantityPerBatches: item.quantityPerBatches
                            ? item.quantityPerBatches
                            : '-',
                    },
                    delivery: {
                        deliveryTimeSample: {
                            deliveryTimeSample: item.deliveryTimeSample
                                ? item.deliveryTimeSample
                                : '-',
                            quantitySample: item.quantitySample,
                        },
                        deliveryTimeFirstBatch: {
                            deliveryTimeFirstBatch: item.deliveryTimeFirstBatch
                                ? item.deliveryTimeFirstBatch
                                : '-',
                            quantityFirstBatch: item.quantityFirstBatch,
                        },
                        deliveryTimePerBatch: {
                            deliveryTimePerBatch: item.deliveryTimePerBatch
                                ? item.deliveryTimePerBatch
                                : '-',
                            quantityPerBatches: item.quantityPerBatches,
                        },
                    },
                    totalPriceNet: {
                        price: item.priceNet ? item.priceNet : '-',
                        targetPrice: item.targetPrice ? item.targetPrice : '-',
                        status: item.status,
                    },
                },
            };
        });

        filteredFrameworkLines = searchedFrameworkLines?.map((item) => {
            return {
                id: item.uuid,
                files: item.files,
                dropdown: item.orderLines?.map((orderLine) => {
                    return {
                        dateStatus: {
                            dateStatus: 'green',
                        },
                        orderDate: orderLine.orderDate
                            ? moment(orderLine.orderDate).format('DD.MM.YYYY')
                            : '-',
                        orderNr: {
                            orderNr: orderLine.orderNr
                                ? orderLine.orderNr
                                : '-',
                            orderId: orderLine.orderId
                                ? orderLine.orderId
                                : null,
                        },
                        reference: orderLine.reference
                            ? orderLine.reference
                            : '-',
                        quantity: {
                            number: orderLine.quantity
                                ? orderLine.quantity
                                : '-',
                        },
                        totalPrice: {
                            totalPrice: orderLine.totalPrice
                                ? orderLine.totalPrice
                                : '-',
                        },
                        deliveryDate: orderLine.deliveryDate
                            ? moment(orderLine.deliveryDate).format(
                                  'DD.MM.YYYY'
                              )
                            : '-',
                        status: {
                            status: orderLine.status ? orderLine.status : '-',
                            color: orderLine.status ? orderLine.status : null,
                            type: 'ordersStatus',
                        },
                    };
                }),
                data: {
                    part: {
                        partName: item.partName ? item.partName : '-',
                        partNumber: item.articleNumber
                            ? item.articleNumber
                            : '-',
                        partImage: item.files
                            ? item.files[0]?.fileThumbnailUrl
                            : null,
                    },
                    quantitiesOpen:
                        item.quantitiesOpen || item.quantitiesOpen === 0
                            ? item.quantitiesOpen
                            : '-',
                    quantitiesOrdered:
                        item.quantitiesOrdered || item.quantitiesOrdered === 0
                            ? item.quantitiesOrdered
                            : '-',
                    quantitiesShipped:
                        item.quantitiesShipped || item.quantitiesShipped === 0
                            ? item.quantitiesShipped
                            : '-',
                    quantitiesPaid:
                        item.quantitiesPaid || item.quantitiesPaid === 0
                            ? item.quantitiesPaid
                            : '-',
                    quantity:
                        item.quantity || item.quantity === 0
                            ? item.quantity
                            : '-',
                    totalPrice:
                        item.totalPrice || item.totalPrice === 0
                            ? item.totalPrice
                            : '-',
                },
            };
        });

        setTableData(filteredFrameworkLines);
        setTableData2(filteredFrameworkLines2);

        //eslint-disable-next-line
    }, [searchedFrameworkLines]);

    const orderTabs = [
        {
            id: '1',
            label: t('fa-details-filter_overview'),
            value: 'Overview',
        },
        {
            id: '2',
            label: t('fa-details-filter_contract-details'),
            value: 'Contract_Details',
        },
    ];

    const onTypeChange = (selectedType) => {
        const params = new URLSearchParams();
        const currentParams = new URLSearchParams(window.location.search);
        const type = currentParams.get('type');
        params.set(
            'type',
            type === selectedType.value ? 'Overview' : selectedType.value
        );
        setSearchParams(params);
    };

    useEffect(() => {
        const type = searchParams.get('type');
        if (type === 'Contract_Details') {
            setType({
                id: '1',
                label: t('fa-details-filter_contract-details'),
                value: 'Contract_Details',
            });
        } else {
            setType({
                id: '2',
                label: t('fa-details-filter_overview'),
                value: 'Overview',
            });
        }
        //eslint-disable-next-line
    }, [window.location.search]);

    const initialSearchValueHandler = (searchStarted) => {
        setShowInitialMessage(searchStarted);
    };

    // TODO REWORK WHEN DITTO WORKS AGAIN
    const getEmptyStateMessage = (whoseState, stateType, statePlace) => {
        const emptyStatesObject = t('emptyState');
        const emptyStateJson = JSON.parse(emptyStatesObject);
        return emptyStateJson[whoseState][stateType][statePlace];
    };

    // reactga4 for framework agreements details page components tab
    useEffect(() => {
        setGoogleAnalytics(
            'page_view',
            'page_view_fa_detail_component',
            'view fa detail page, component section'
        );
    }, []);

    // fix for table empty state showing briefly after loading
    useEffect(() => {
        setLoading(detailsLoading);
    }, [detailsLoading]);

    return (
        <>
            <PageMeta>
                <title>
                    Framework Agreement {faNumber ? faNumber : ''} - Components
                    | CNC24
                </title>
            </PageMeta>
            <div
                className={
                    classes['c-framework-agreeement-components__actions']
                }
            >
                <PageSearch
                    tableType="frameworkComponents"
                    componentSearch={true}
                    onInitialSearchValue={initialSearchValueHandler}
                    javascriptSearch={true}
                />
                <div
                    className={
                        classes['c-framework-agreeement-components__filters']
                    }
                >
                    {breakpoint > 575 ? (
                        <PageTabs
                            tabs={orderTabs}
                            pageName="frameworkComponents"
                        />
                    ) : (
                        <Select
                            className="c-react-select c-react-select--max-492"
                            name="language-selector"
                            options={orderTabs}
                            onChange={onTypeChange}
                            value={type}
                            classNamePrefix="c-react-select"
                        />
                    )}
                </div>
            </div>

            {type?.value === 'Overview' ? (
                loading ? (
                    <SkeletonWithImage />
                ) : (
                    <TableList
                        tableTitles={orderComponentsTableTitles}
                        tableData={tableData}
                        dropdownTitles={dropdownTitles}
                        tableType="frameworkComponents"
                        emptyStHeading={
                            showInitialMessage
                                ? getEmptyStateMessage(
                                      'ordersComponentsEmptyState',
                                      'InitialEmptyState',
                                      'heading'
                                  )
                                : t('fa-components-empty_heading')
                        }
                        emptyStText={
                            showInitialMessage
                                ? getEmptyStateMessage(
                                      'ordersComponentsEmptyState',
                                      'InitialEmptyState',
                                      'text'
                                  )
                                : t('fa-components-empty_paragraph')
                        }
                        emptyStBtnText={
                            showInitialMessage
                                ? getEmptyStateMessage(
                                      'ordersComponentsEmptyState',
                                      'InitialEmptyState',
                                      'button'
                                  )
                                : t('fa-components-empty_btn-text')
                        }
                        details={searchedFrameworkLines}
                    />
                )
            ) : loading ? (
                <SkeletonWithImageTable />
            ) : (
                <Table
                    tableTitles={tableTitles}
                    tableData={tableData2}
                    tableType="frameworkComponentsDetails"
                    emptyStHeading={
                        showInitialMessage
                            ? getEmptyStateMessage(
                                  'faAgreementsComponentsEmptyState',
                                  'InitialEmptyState',
                                  'heading'
                              )
                            : t('fa-components-empty_heading')
                    }
                    emptyStText={
                        showInitialMessage
                            ? getEmptyStateMessage(
                                  'faAgreementsComponentsEmptyState',
                                  'InitialEmptyState',
                                  'text'
                              )
                            : t('fa-components-empty_paragraph')
                    }
                    emptyStBtnText={
                        showInitialMessage
                            ? getEmptyStateMessage(
                                  'faAgreementsComponentsEmptyState',
                                  'InitialEmptyState',
                                  'button'
                              )
                            : t('fa-components-empty_btn-text')
                    }
                    details={searchedFrameworkLines}
                    linkableRows={false}
                    skeletonWithImg={true}
                />
            )}
        </>
    );
}

export default FrameworkAgreementsComponents;
