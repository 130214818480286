export const GET_DETAILS = 'GET_DETAILS';
export const DETAILS_LOADING = 'DETAILS_LOADING';
export const DETAILS_CLEANUP = 'DETAILS_CLEANUP';
export const SEARCH_DETAILS = 'SEARCH_DETAILS';
export const SORT_SINGLE_OFFERS = 'SORT_SINGLE_OFFERS';
export const SORT_FRAMEWORK_OFFERS = 'SORT_FRAMEWORK_OFFERS';

export const onSearchTableContent = (searchQuery, tableType) => {
    return (dispatch) => {
        dispatch({
            type: SEARCH_DETAILS,
            tableType: tableType,
            searchQuery: searchQuery,
        });
    };
};

export const onGetDetails = (details) => {
    return (dispatch) => {
        dispatch({
            type: GET_DETAILS,
            details: details,
        });
    };
};

export const onDetailsLoading = () => {
    return (dispatch) => {
        dispatch({
            type: DETAILS_LOADING,
        });
    };
};

export const onDetailsCleanup = () => {
    return (dispatch) => {
        dispatch({
            type: DETAILS_CLEANUP,
        });
    };
};

export const onSingleOfferLinesSort = (sortDirection, sortFilter) => {
    return (dispatch) => {
        dispatch({
            type: SORT_SINGLE_OFFERS,
            sortDirection: sortDirection,
            sortFilter: sortFilter,
        });
    };
};

export const onFrameworkOfferLinesSort = (sortDirection, sortFilter) => {
    return (dispatch) => {
        dispatch({
            type: SORT_FRAMEWORK_OFFERS,
            sortDirection: sortDirection,
            sortFilter: sortFilter,
        });
    };
};
