const Button = ({
    className,
    onClick,
    disabled,
    ariaLabel,
    type,
    children,
}) => {
    return (
        <button
            className={`btn ${className}`}
            onClick={onClick}
            disabled={disabled}
            aria-label={ariaLabel}
            type={type}
        >
            {children}
        </button>
    );
};

Button.defaultProps = {
    className: '',
    onClick: () => {},
    disabled: false,
    ariaLabel: '',
    type: '',
};

export default Button;
