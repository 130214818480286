import { useTranslation } from 'react-i18next';
import Button from '../Button/Button';
import EditIcon from '../Icons/EditIcon';
import Tooltip from '../Tooltip/Tooltip';
import classes from './SectionHeaderWrapper.module.scss';

function SectionHeaderWrapper({
    title,
    description,
    edit,
    type,
    onClickHandler,
    userPortalRole,
}) {
    const { t } = useTranslation();
    const clickHandler = () => {
        onClickHandler();
    };

    return (
        <div className={classes['c-section-header-wrapper']}>
            <div
                className={classes['c-section-header-wrapper__title-container']}
            >
                <h5>{title}</h5>
                {edit ? (
                    <span
                        className={classes['c-section-header-wrapper__action']}
                    >
                        <Button
                            className={[
                                classes[
                                    'c-section-header-wrapper__edit-button'
                                ],
                                userPortalRole !== 'Admin' &&
                                userPortalRole !== 'Owner'
                                    ? classes[
                                          'c-section-header-wrapper__edit-button--disabled'
                                      ]
                                    : '',
                            ].join(' ')}
                            onClick={
                                userPortalRole === 'Admin' ||
                                userPortalRole === 'Owner'
                                    ? clickHandler
                                    : null
                            }
                            ariaLabel="Edit contact persons"
                        >
                            <EditIcon />
                        </Button>
                        {userPortalRole !== 'Admin' &&
                        userPortalRole !== 'Owner' ? (
                            <Tooltip
                                text={t('contacts_no-permission-popup')}
                                extraClass="c-tooltip--small"
                            />
                        ) : null}
                    </span>
                ) : null}
            </div>
            <p>{description}</p>
        </div>
    );
}

SectionHeaderWrapper.defaultProps = {
    title: '',
    description: '',
    edit: false,
    type: '',
};

export default SectionHeaderWrapper;
