import FormatedPrice from '../../../FormatedPrice/FormatedPrice';
import SidebarDataTitleLabel from '../../../SkeletonLoader/SidebarDataTitleLabel';
import SidebarPrice from '../../../SkeletonLoader/SidebarPrice';
import classes from '../DetailsSideScreen.module.scss';

function TotalPrice({ details, loading, t, pageType }) {
    return (
        <div
            className={`${
                pageType !== 'offers'
                    ? [
                          classes['c-details-sidescreen__detail'],
                          classes['c-details-sidescreen__detail--full-width'],
                      ].join(' ')
                    : `${classes['c-details-sidescreen__detail-offers-price']} 
                      ${
                          details.productionCertificates ||
                          details.productionCountry
                              ? classes[
                                    'c-details-sidescreen__detail-offers-price--certs-origin'
                                ]
                              : ''
                      }
                      ${
                          details.status !== 'New'
                              ? classes[
                                    'c-details-sidescreen__detail-offers-price--diff-status'
                                ]
                              : ''
                      } ${classes['c-details-sidescreen__detail']}`
            }`}
        >
            <SidebarDataTitleLabel
                labelName={t('order-details-sidebar_total-cost')}
            />

            <SidebarPrice
                loading={loading}
                data={
                    (pageType === 'offers' && details.totalPriceMin) ||
                    (pageType !== 'offers' && details.totalPrice) ? (
                        <span>
                            <FormatedPrice
                                price={
                                    pageType === 'offers'
                                        ? details.totalPriceMin
                                        : details.totalPrice
                                }
                            />
                            {details.totalPriceMax &&
                                details.totalPriceMax !==
                                    details.totalPriceMin &&
                                ' - '}
                            {details.totalPriceMax &&
                                details.totalPriceMax !==
                                    details.totalPriceMin && (
                                    <FormatedPrice
                                        price={details.totalPriceMax}
                                    />
                                )}
                        </span>
                    ) : (
                        '-'
                    )
                }
            />
        </div>
    );
}

export default TotalPrice;
