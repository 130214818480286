import React, { useState, useEffect } from 'react';
import Table from '../../../components/Table/Table';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import TableSkeletonLoader from '../../../components/Table/TableSkeletonLoader/TableSkeletonLoader';
import PageSearch from '../../../components/PageHeader/PageSearch/PageSearch';
import PageMeta from '../../../shared/PageMeta/PageMeta';
import setGoogleAnalytics from '../../../hooks/setGoogleAnalytics';

function FrameworkAgreementsDeliveries() {
    const [tableData, setTableData] = useState(['']);
    const [showInitialMessage, setShowInitialMessage] = useState(true);
    const [loading, setLoading] = useState(true);

    const deliveryNotes = useSelector(
        (state) => state.details.searchedFrameworkOrders
    );

    const detailsLoading = useSelector((state) => state.details.detailsLoading);

    const { t } = useTranslation();
    const faNumber = useSelector((state) => state.details.details.faNr);

    const tableTitles = [
        {
            id: 1,
            name: '',
        },
        {
            id: 2,
            name: t('fa-details_confirmed-on'),
        },
        {
            id: 3,
            name: t('fa-details_order-num'),
        },
        {
            id: 4,
            name: t('fa-details_reference'),
        },
        {
            id: 5,
            name: t('fa-details_components'),
        },
        {
            id: 6,
            name: t('fa-details-comp_price'),
        },
        {
            id: 7,
            name: t('fa-details_delivery-date'),
        },
        {
            id: 8,
            name: t('fa-details_status'),
        },
    ];

    useEffect(() => {
        if (deliveryNotes) {
            const filteredTableData = deliveryNotes.map((note) => {
                return {
                    id: note.shipmentNumber,
                    data: {
                        dateStatus: {
                            dateStatus: 'green',
                        },
                        orderDate: note.orderDate
                            ? moment(note.orderDate).format('DD.MM.YYYY')
                            : '-',
                        orderNr: {
                            linkText: note.orderNr ? note.orderNr : '-',
                            linkUrl: note.orderId
                                ? '/orders/' + note.orderId + '/components'
                                : null,
                            linkWithIcon: true,
                            icon: 'rectangle-layer',
                        },
                        reference: note.reference ? note.reference : '-',
                        numberOfParts: {
                            number: note.numberOfParts
                                ? note.numberOfParts
                                : '-',
                        },
                        totalPrice: {
                            totalPrice: note.totalPrice ? note.totalPrice : '-',
                        },
                        deliveryDate: note.deliveryDate
                            ? moment(note.deliveryDate).format('DD.MM.YYYY')
                            : '-',
                        status: {
                            status: note.status ? note.status : '-',
                            color: note.status ? note.status : null,
                            type: 'ordersStatus',
                        },
                    },
                };
            });
            setTableData(filteredTableData);
        }
    }, [deliveryNotes]);

    const initialSearchValueHandler = (searchStarted) => {
        setShowInitialMessage(searchStarted);
    };

    // TODO REWORK WHEN DITTO WORKS AGAIN
    const getEmptyStateMessage = (whoseState, stateType, statePlace) => {
        const emptyStatesObject = t('emptyState');
        const emptyStateJson = JSON.parse(emptyStatesObject);
        return emptyStateJson[whoseState][stateType][statePlace];
    };

    // reactga4 for framework agreements details page orders tab
    useEffect(() => {
        setGoogleAnalytics(
            'page_view',
            'page_view_fa_detail_order',
            'view fa detail page, order section'
        );
    }, []);

    // fix for table empty state showing briefly after loading
    useEffect(() => {
        setLoading(detailsLoading);
    }, [detailsLoading]);

    return (
        <>
            <PageMeta>
                <title>
                    Framework Agreement {faNumber ? faNumber : ''} - Deliveries
                    | CNC24
                </title>
            </PageMeta>
            <PageSearch
                tableType="frameworkDeliveries"
                componentSearch={true}
                onInitialSearchValue={initialSearchValueHandler}
                javascriptSearch={true}
            />
            {deliveryNotes ? (
                <Table
                    fullClickableRow={true}
                    tableTitles={tableTitles}
                    tableData={tableData}
                    tableType="frameworkAgreementsOrders"
                    emptyStHeading={
                        showInitialMessage
                            ? getEmptyStateMessage(
                                  'faAgreementsDeliveriesEmptyState',
                                  'InitialEmptyState',
                                  'heading'
                              )
                            : t('fa-orders-empty_heading')
                    }
                    emptyStText={
                        showInitialMessage
                            ? getEmptyStateMessage(
                                  'faAgreementsDeliveriesEmptyState',
                                  'InitialEmptyState',
                                  'text'
                              )
                            : t('fa-orders-empty_paragraph')
                    }
                    emptyStBtnText={
                        showInitialMessage
                            ? getEmptyStateMessage(
                                  'faAgreementsDeliveriesEmptyState',
                                  'InitialEmptyState',
                                  'button'
                              )
                            : t('fa-orders-empty_btn-text')
                    }
                    loading={loading}
                />
            ) : (
                <TableSkeletonLoader
                    columns={5}
                    tableType="frameworkAgreementsOrders"
                    showSkeletonHeader={true}
                />
            )}
        </>
    );
}

export default FrameworkAgreementsDeliveries;
