import classes from './LabelWithoutBackground.module.scss';
import { useTranslation } from 'react-i18next';

function LabelWithoutBackground({ statusText, classText, type }) {
    const { t } = useTranslation();
    const trimedClassText = classText.replace(/ /g, '');
    const status = JSON.parse(t(type));

    return (
        <div
            className={[
                classes['c-label-without-bg'],
                classes[`c-label-without-bg--${trimedClassText}`],
                classes[`${type}`],
            ].join(' ')}
        >
            <span></span>
            {status[trimedClassText]}
        </div>
    );
}

export default LabelWithoutBackground;
