import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../Button/Button';
import classes from './WizzardFooter.module.scss';
import {
    onStepDown,
    onStepUp,
} from '../../../store/actions/offersWizzardActions';
import { useNavigate } from 'react-router-dom';

function WizzardFooter({
    prevButtonText,
    nextButtonText,
    nextButtonDisabled,
    nextSubmit,
    extraClass,
    handleSubmitApi,
    nextButtonLoading,
    handleValidateFields,
    url,
    backUrl,
}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleStepUp = () => {
        //step up is done on submit form no need for it here
        if (!nextSubmit) {
            dispatch(onStepUp());
        }

        if (handleValidateFields) {
            handleValidateFields();
        }

        if (handleSubmitApi) {
            handleSubmitApi();
        }

        if (url) {
            navigate(url);
        }
    };

    const handleStepDown = () => {
        dispatch(onStepDown());
        if (backUrl) {
            navigate(backUrl);
        }
    };

    return (
        <div
            className={[
                classes['wizzard-footer'],
                extraClass ? classes[`wizzard-footer--${extraClass}`] : '',
            ].join(' ')}
        >
            {prevButtonText && (
                <Button
                    onClick={handleStepDown}
                    className={`btn btn--small btn--transparent btn--transparent-grey ${classes['wizzard-footer__prev-btn']}`}
                >
                    {prevButtonText}
                </Button>
            )}
            {nextButtonText && (
                <Button
                    type={nextSubmit ? 'submit' : 'button'}
                    onClick={handleStepUp}
                    disabled={nextButtonDisabled}
                    className={`btn btn--small btn--purple ${classes['wizzard-footer__next-btn']}`}
                >
                    {nextButtonText}
                    {nextButtonLoading ? (
                        <span className="loader"></span>
                    ) : null}
                </Button>
            )}
        </div>
    );
}

export default WizzardFooter;
