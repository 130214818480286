import React, { useEffect } from 'react';
import Table from '../../../components/Table/Table';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import PageSearch from '../../../components/PageHeader/PageSearch/PageSearch';
import Tabs from '../../../components/PageHeader/Tabs/Tabs';
import classes from './OfferComponents.module.scss';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button/Button';
import { onToggleWizzard } from '../../../store/actions/offersWizzardActions';
import PageMeta from '../../../shared/PageMeta/PageMeta';
import SkeletonWithImageTable from '../../../components/SkeletonLoader/SkeletonWithImageTable/SkeletonWithImageTable';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import TableEmptyState from '../../../components/Table/TableEmptyState/TableEmptyState';
import TableHeader from '../../../components/Table/TableHeader/TableHeader';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { onOpenDetails } from '../../../store/actions/detailsPopupActions';

function OfferComponents() {
    const [singleOfferData, setSingleOfferData] = useState([]);
    //eslint-disable-next-line
    const [frameworkOfferData, setFrameworkOfferData] = useState([]);
    const [singleOrderHasDiscount, setSingleOrderHasDiscount] = useState();
    const [frameworkOrderHasDiscount, setFrameworkOrderHasDiscount] =
        useState();
    const [singleOrderHasOrigin, setSingleOrderHasOrigin] = useState();
    const [frameworkOrderHasOrigin, setFrameworkOrderHasOrigin] = useState();

    const [hasRequestedMaterial, seHasRequestedMaterial] = useState(false);
    const [loading, setLoading] = useState(true);

    const { t } = useTranslation();

    const singleOfferLines = useSelector(
        (state) => state.details.details?.singleOfferLines
    );
    const frameworkOfferLines = useSelector(
        (state) => state.details.details?.frameworkOfferLines
    );

    const searchedSingleOfferLines = useSelector(
        (state) => state.details.searchedSingleOfferLines
    );
    const searchedFrameworkOfferLines = useSelector(
        (state) => state.details.searchedFrameworkOfferLines
    );

    const navigate = useNavigate();

    const details = useSelector((state) => state.details.details);

    const offerDetailsLoading = useSelector(
        (state) => state.details.detailsLoading
    );

    const dispatch = useDispatch();

    const { componentId, id } = useParams();

    const offerNr = useSelector((state) => state.details.details.offerNr);

    const handleOpenWizzard = (type) => {
        dispatch(onToggleWizzard(type));
        localStorage.setItem('wizardType', type);
    };

    useEffect(() => {
        if (componentId && details.offerId) {
            let detailsObject;
            if (details.singleOfferLines?.length) {
                detailsObject = details.singleOfferLines.find(
                    (offerLine) => offerLine.offerLineId === componentId
                );
                if (detailsObject) {
                    dispatch(onOpenDetails(detailsObject, 'offersSingleOrder'));
                }
            } else if (details.frameworkOfferLines?.length) {
                detailsObject = details.frameworkOfferLines.find(
                    (frameworkOfferLine) =>
                        frameworkOfferLine.offerLineId === componentId
                );
                if (detailsObject) {
                    dispatch(
                        onOpenDetails(detailsObject, 'offerFrameworkOrder')
                    );
                }
            }

            //fallback if componentId is not valid
            if (!detailsObject) {
                navigate(`/offers/${id}/components`);
            }
        }
        //eslint-disable-next-line
    }, [details]);

    const singleOfferTableTitles = [
        {
            id: 1,
            name: t('offers-single-order-table-columns_component'),
            sortable: false,
            feSort: true,
        },
        {
            id: 2,
            name: t('offers-single-order-table-columns_material'),
            sortable: true,
            feSort: true,
            sortFilter: 'material',
        },
        {
            id: 3,
            name: t('offers-single-order-table-columns_delivery-time'),
            sortable: true,
            feSort: true,
            sortFilter: 'deliveryTime',
        },
        {
            id: 4,
            name: t('offers-single-order-sidebar_origin'),
            sortable: true,
            feSort: true,
            sortFilter: 'originCountry',
        },
        {
            id: 5,
            name: t('offers-single-order-table-columns_quantity'),
            sortable: true,
            feSort: true,
            sortFilter: 'quantity',
        },
        {
            id: 6,
            name: t('wizard-table_discount'),
            sortable: true,
            feSort: true,
            sortFilter: 'discountPercent',
        },
        {
            id: 7,
            name: t('offers-single-order-table-columns_price'),
            sortable: true,
            feSort: true,
            sortFilter: 'priceNet',
        },
    ];

    const emptyStOfferTableTitles = [
        {
            id: 1,
            name: t('offers-single-order-table-columns_component'),
            sortable: false,
        },
        {
            id: 2,
            name: t('offers-single-order-table-columns_material'),
            sortable: false,
        },
        {
            id: 3,
            name: t('offers-single-order-table-columns_delivery-time'),
            sortable: false,
        },
        {
            id: 4,
            name: t('offers-single-order-table-columns_quantity'),
            sortable: false,
        },
        {
            id: 5,
            name: t('offers-single-order-table-columns_price'),
            sortable: false,
        },
    ];

    const frameworkOfferTableTitles = [
        {
            id: 1,
            name: t('offers-fa-table_component-2'),
            sortable: false,
        },
        {
            id: 3,
            name: t('offers-single-order-table-columns_material'),
            sortable: true,
            feSort: true,
            sortFilter: 'material',
        },
        {
            id: 4,
            name: t('wizard-table_running-time'),
            sortable: true,
            feSort: true,
            sortFilter: 'durationAgreement',
        },
        {
            id: 5,
            name: t('wizard-table_total-quantity'),
            sortable: true,
            feSort: true,
            sortFilter: 'quantity',
        },
        {
            id: 6,
            name: t('offers-fa-table_retrieval-2'),
            // sortable: true,
            // feSort: true,
            // sortFilter: 'table_retrieval',
        },
        {
            id: 7,
            name: t('offers-single-order-table-columns_quantity'),
            // sortable: true,
            // feSort: true,
            // sortFilter: 'quantityPerBatches',
        },
        {
            id: 8,
            name: t('offers-single-order-sidebar_origin'),
            sortable: true,
            feSort: true,
            sortFilter: 'originCountry',
        },
        {
            id: 9,
            name: t('offers-single-order-table-columns_delivery-time'),
            // sortable: true,
            // feSort: true,
            // sortFilter: 'deliveryTime',
        },
        {
            id: 10,
            name: t('wizard-table_discount'),
            sortable: true,
            feSort: true,
            sortFilter: 'discountPercent',
        },
        {
            id: 11,
            name: t('offers-single-order-table-columns_price'),
            sortable: true,
            feSort: true,
            sortFilter: 'priceNet',
        },
    ];

    useEffect(() => {
        let filteredSingleOfferData = [];
        let filteredFrameworkOfferData = [];
        let offerSingleOrderHasDiscount;
        let offerFrameworkOrderHasDiscount;
        let offerSingleOrderHasOrigin;
        let offerFrameworkOrderHasOrigin;

        const hasRequestedMaterial =
            searchedSingleOfferLines?.some(
                (item) => item.requestedMaterial !== null
            ) ||
            searchedFrameworkOfferLines?.some(
                (item) => item.requestedMaterial !== null
            );
        seHasRequestedMaterial(hasRequestedMaterial);

        filteredSingleOfferData = searchedSingleOfferLines?.map((item) => {
            return {
                id: item.uuid,
                files: item.files,
                missingDataInfo: item.missingDataInfo,
                customErrorMessage: item.missingDataInfoMessage,
                showCertificates: item.certificateLines?.length,
                certificates: item.certificateLines?.map((certificate) => {
                    const object = {
                        certificateName: {
                            certificate: certificate?.partName
                                ? certificate.partName
                                : '-',
                        },
                        material: {
                            material: certificate?.material
                                ? certificate.material
                                : '-',
                            materialGroup: certificate?.materialGroup
                                ? certificate.materialGroup
                                : '-',
                        },
                        deliveryTime: '-',
                        origin: '-',
                        quantity: certificate?.quantity
                            ? certificate.quantity +
                              ` ${t('global_pieces-short')}`
                            : '-',
                        discount: certificate?.discountPercent
                            ? certificate?.discountPercent + '%'
                            : '-',
                        totalPriceNet: {
                            price:
                                certificate?.totalPriceNet ||
                                certificate?.totalPriceNet === 0
                                    ? certificate?.totalPriceNet
                                    : '-',
                            priceBeforeDiscount:
                                certificate.totalPriceNetBeforeDiscount ||
                                certificate?.totalPriceNetBeforeDiscount === 0
                                    ? certificate?.totalPriceNet
                                    : null,
                            discount: certificate?.discountPercent
                                ? certificate?.discountPercent + '%'
                                : null,
                            targetPrice: certificate?.targetPriceNet,
                            isOnlyComment: item.certificateLines?.isOnlyComment,
                            noPriceAvailableComment:
                                item.certificateLines?.noPriceAvailableComment,
                            status: details.status,
                            priceStatus: item.certificateLines?.status,
                            noTargetPrice: true,
                        },
                    };
                    return object;
                }),
                data: {
                    part: {
                        partName: item.partName ? item.partName : '-',
                        partNumber: item.articleNumber
                            ? item.articleNumber
                            : '-',
                        partImage: item.files
                            ? item.files[0]?.fileThumbnailUrl
                            : null,
                    },
                    quantities: item.quantities?.map((quantityItem) => {
                        const obj = {
                            material: {
                                material: item.material ? item.material : '-',
                                materialGroup: item.materialGroup
                                    ? item.materialGroup
                                    : '-',
                                materialComments: item.materialComments,
                                requestedMaterial: item.requestedMaterial,
                            },
                            deliveryTime: quantityItem.deliveryTime
                                ? quantityItem.deliveryTime +
                                  (quantityItem.showDays
                                      ? ` ${t('text_62e6e458b1f57b951f61dfaa')}`
                                      : '')
                                : '-',
                            origin: {
                                hsCode: quantityItem.HSCode
                                    ? quantityItem.HSCode
                                    : '-',
                                originCountry: quantityItem.originCountry
                                    ? quantityItem.originCountry
                                    : '-',
                            },
                            quantity: quantityItem.quantity
                                ? quantityItem.quantity +
                                  ` ${t('global_pieces-short')}`
                                : '-',
                            discount: quantityItem?.discountPercent
                                ? quantityItem?.discountPercent + '%'
                                : '-',
                            totalPriceNet: {
                                price:
                                    quantityItem?.priceNet ||
                                    quantityItem?.priceNet === 0
                                        ? quantityItem.priceNet
                                        : '-',
                                priceBeforeDiscount:
                                    quantityItem.priceNetBeforeDiscount,
                                discount: quantityItem?.discountPercent
                                    ? quantityItem?.discountPercent + '%'
                                    : null,
                                targetPrice: quantityItem.targetPriceNet
                                    ? quantityItem.targetPriceNet
                                    : '',
                                isOnlyComment: quantityItem.isOnlyComment,
                                noPriceAvailableComment:
                                    quantityItem.noPriceAvailableComment,
                                status: details.status,
                                priceStatus: quantityItem.status,
                            },
                        };
                        return obj;
                    }),
                },
            };
        });

        offerSingleOrderHasDiscount = searchedSingleOfferLines?.some((item) => {
            return item.quantities?.some((subItem) => subItem.discountPercent);
        });

        offerSingleOrderHasOrigin = searchedSingleOfferLines?.some((item) => {
            return item.quantities?.some(
                (subItem) => subItem.originCountry || subItem.hsCode
            );
        });

        filteredFrameworkOfferData = searchedFrameworkOfferLines?.map(
            (item) => {
                return {
                    id: item.uuid,
                    files: item.files,
                    missingDataInfo: item.missingDataInfo,
                    customErrorMessage: item.missingDataInfoMessage,
                    showCertificates: item.certificateLines?.length,
                    certificates: item.certificateLines?.map((certificate) => {
                        const object = {
                            certificateName: {
                                certificate: certificate?.partName
                                    ? certificate.partName
                                    : '-',
                            },
                            material: {
                                material: certificate?.material
                                    ? certificate.material
                                    : '-',
                                materialGroup: certificate?.materialGroup
                                    ? certificate.materialGroup
                                    : '-',
                            },
                            deliveryTime: '-',
                            quantity: certificate?.quantity
                                ? certificate.quantity +
                                  ` ${t('global_pieces-short')}`
                                : '-',
                            origin: '',
                            retrieval: '',
                            quality: '',
                            delivery: '',
                            discount: certificate?.discountPercent
                                ? certificate?.discountPercent + '%'
                                : '-',
                            totalPriceNet: {
                                price:
                                    certificate?.totalPriceNet ||
                                    certificate?.totalPriceNet === 0
                                        ? certificate?.totalPriceNet
                                        : '-',
                                priceBeforeDiscount:
                                    certificate.totalPriceNetBeforeDiscount,
                                discount: certificate?.discountPercent
                                    ? certificate?.discountPercent + '%'
                                    : null,
                                targetPrice: '-',
                                isOnlyComment:
                                    item.certificateLines?.isOnlyComment,
                                noPriceAvailableComment:
                                    item.certificateLines
                                        ?.noPriceAvailableComment,
                                status: details.status,
                                priceStatus: item.certificateLines?.status,
                                noTargetPrice: true,
                            },
                        };
                        return object;
                    }),
                    data: {
                        part: {
                            partName: item.partName ? item.partName : '-',
                            partNumber: item.articleNumber
                                ? item.articleNumber
                                : '-',
                            partImage: item.files
                                ? item.files[0]?.fileThumbnailUrl
                                : null,
                        },
                        material: {
                            material: item.material ? item.material : '-',
                            materialGroup: item.materialGroup
                                ? item.materialGroup
                                : '-',
                            materialComments: item.materialComments,
                            requestedMaterial: item.requestedMaterial,
                        },
                        quantities: item.quantities?.map((quantityItem) => {
                            const obj = {
                                durationAgreement:
                                    quantityItem.durationAgreement
                                        ? quantityItem.durationAgreement
                                        : '-',
                                quantity: quantityItem.quantity
                                    ? `${new Intl.NumberFormat('de-DE').format(
                                          quantityItem.quantity
                                      )} ${t('global_pieces-short')}`
                                    : '-',
                                abrufe: {
                                    sample: {
                                        sample: t('global_sample'),
                                        quantitySample:
                                            quantityItem.quantitySample,
                                    },
                                    firstBatch: {
                                        firstBatch: t('global_first-batch'),
                                        quantityFirstBatch:
                                            quantityItem.quantityFirstBatch,
                                    },
                                    perBatch: {
                                        perBatch: t('global_per-batch'),
                                        quantityPerBatches:
                                            quantityItem.quantityPerBatches,
                                    },
                                },
                                amount: {
                                    quantitySample: quantityItem.quantitySample
                                        ? quantityItem.quantitySample
                                        : '-',
                                    quantityFirstBatch:
                                        quantityItem.quantityFirstBatch
                                            ? quantityItem.quantityFirstBatch
                                            : '-',
                                    quantityPerBatches:
                                        quantityItem.quantityPerBatches
                                            ? quantityItem.quantityPerBatches
                                            : '-',
                                },
                                origin: {
                                    hsCode: quantityItem.HSCode
                                        ? quantityItem.HSCode
                                        : '-',
                                    originCountry: quantityItem.originCountry
                                        ? quantityItem.originCountry
                                        : '-',
                                },
                                delivery: {
                                    deliveryTimeSample: {
                                        deliveryTimeSample:
                                            quantityItem.deliveryTimeSample
                                                ? quantityItem.deliveryTimeSample
                                                : '-',
                                        quantitySample:
                                            quantityItem.quantitySample,
                                    },
                                    deliveryTimeFirstBatch: {
                                        deliveryTimeFirstBatch:
                                            quantityItem.deliveryTimeFirstBatch
                                                ? quantityItem.deliveryTimeFirstBatch
                                                : '-',
                                        quantityFirstBatch:
                                            quantityItem.quantityFirstBatch,
                                    },
                                    deliveryTimePerBatch: {
                                        deliveryTimePerBatch:
                                            quantityItem.deliveryTimePerBatch
                                                ? quantityItem.deliveryTimePerBatch
                                                : '-',
                                        quantityPerBatches:
                                            quantityItem.quantityPerBatches,
                                    },
                                    showDays: quantityItem.showDays,
                                },
                                discount: quantityItem?.discountPercent
                                    ? quantityItem?.discountPercent + '%'
                                    : '-',
                                totalPriceNet: {
                                    price:
                                        quantityItem?.priceNet ||
                                        quantityItem?.priceNet === 0
                                            ? quantityItem.priceNet
                                            : '-',
                                    priceBeforeDiscount:
                                        quantityItem.priceNetBeforeDiscount,
                                    discount: quantityItem?.discountPercent
                                        ? quantityItem?.discountPercent + '%'
                                        : null,
                                    targetPrice: quantityItem.targetPriceNet
                                        ? quantityItem.targetPriceNet
                                        : '',
                                    isOnlyComment: quantityItem.isOnlyComment,
                                    noPriceAvailableComment:
                                        quantityItem.noPriceAvailableComment,
                                    status: details.status,
                                    priceStatus: quantityItem.status,
                                },
                            };
                            return obj;
                        }),
                    },
                };
            }
        );

        offerFrameworkOrderHasDiscount = searchedFrameworkOfferLines?.some(
            (item) => {
                return item.quantities.some(
                    (subItem) => subItem.discountPercent
                );
            }
        );

        offerFrameworkOrderHasOrigin = searchedFrameworkOfferLines?.some(
            (item) => {
                return item.quantities?.some((subItem) => {
                    return subItem.originCountry || subItem.HSCode;
                });
            }
        );

        setSingleOfferData(filteredSingleOfferData);
        setFrameworkOfferData(filteredFrameworkOfferData);
        setSingleOrderHasOrigin(offerSingleOrderHasOrigin);
        setSingleOrderHasDiscount(offerSingleOrderHasDiscount);
        setFrameworkOrderHasDiscount(offerFrameworkOrderHasDiscount);
        setFrameworkOrderHasOrigin(offerFrameworkOrderHasOrigin);
        //eslint-disable-next-line
    }, [searchedSingleOfferLines, searchedFrameworkOfferLines]);

    // // TODO REWORK WHEN DITTO WORKS AGAIN
    // const getEmptyStateMessage = (whoseState, stateType, statePlace) => {
    //     const emptyStatesObject = t('emptyState');
    //     const emptyStateJson = JSON.parse(emptyStatesObject);
    //     return emptyStateJson[whoseState][stateType][statePlace];
    // };

    // fix for table empty state showing briefly after loading
    useEffect(() => {
        setLoading(offerDetailsLoading);
    }, [offerDetailsLoading]);

    return (
        <>
            <PageMeta>
                <title>
                    Offer {offerNr ? offerNr : ''} - Components | CNC24
                </title>
            </PageMeta>
            <div className={classes['c-offer-component']}>
                <div className={classes['c-offer-component__tabs-btn-wrapper']}>
                    <Tabs
                        onlyOneTab={true}
                        tabs={[
                            {
                                name: t('offers-fa_heading'),
                                link: 'components',
                            },
                        ]}
                    />
                    <div className={classes['c-offer-component__btns-wrapper']}>
                        {details?.displayOrderButton && (
                            <Button
                                onClick={() => {
                                    handleOpenWizzard('single-offer');
                                    navigate(
                                        `/offers/${details.offerId}/wizard/components?type=single-order`
                                    );
                                }}
                                className={
                                    details?.displayFrameworkOrderButton
                                        ? 'btn btn--small btn--transparent btn--transparent-purple'
                                        : 'btn btn--small btn--purple'
                                }
                            >
                                {t('wizard-offers_order-parts-btn')}
                            </Button>
                        )}
                        {details?.displayFrameworkOrderButton && (
                            <Button
                                onClick={() => {
                                    handleOpenWizzard('framework-agreement');
                                    navigate(
                                        `/offers/${details.offerId}/wizard/components?type=framework-agreement`
                                    );
                                }}
                                className={`btn btn--small btn--purple`}
                            >
                                {t('wizard-offers_accept-fa-btn')}
                            </Button>
                        )}
                        {details.status === 'Wizardnotfinished' ? (
                            <Link
                                className="btn btn btn--small btn--purple"
                                to={`/wizard?id=${details.offerId}`}
                            >
                                Offer
                            </Link>
                        ) : null}
                    </div>
                </div>
                <PageSearch
                    componentSearch={true}
                    tableType="offerComponents"
                />
                {hasRequestedMaterial && (
                    <ErrorMessage
                        bgColor="yellow"
                        errorText={t('offer-comp_note-msg')}
                        type="offerChanged"
                        hasErrorText={true}
                    />
                )}
                {frameworkOfferLines?.length ? (
                    <>
                        {singleOfferData?.length ? (
                            <h2>{t('global_as-frame-agree')}</h2>
                        ) : null}
                        <Table
                            tableTitles={frameworkOfferTableTitles}
                            tableData={frameworkOfferData}
                            loading={loading}
                            tableType="offerFrameworkOrder"
                            emptyStHeading={t('offer-comp-fa-empty_heading')}
                            emptyStText={t(
                                'offer-single-order-empty_paragraph'
                            )}
                            emptyStBtnText=""
                            linkableRows={false}
                            details={searchedFrameworkOfferLines}
                            hasDiscount={frameworkOrderHasDiscount}
                            hasOrigin={frameworkOrderHasOrigin}
                        />
                    </>
                ) : null}

                {!loading &&
                !frameworkOfferLines?.length &&
                !singleOfferLines?.length ? (
                    <>
                        <div
                            className={`c-table c-table--${emptyStOfferTableTitles?.length} c-table--offersEmpty`}
                        >
                            {details.status === 'New' ? (
                                <TableHeader
                                    titles={emptyStOfferTableTitles}
                                    tableType="offersEmpty"
                                />
                            ) : null}
                            <TableEmptyState
                                columns={emptyStOfferTableTitles?.length}
                                heading={
                                    details.status === 'New'
                                        ? t('offer-comp-empty_heading')
                                        : t('offer-comp-empty_heading-canceled')
                                }
                                description={
                                    details.status === 'New'
                                        ? t('offer-comp-empty_desc-1')
                                        : t('offer-comp-empty_desc-canceled')
                                }
                                descriptionSecond={
                                    details.status === 'New'
                                        ? t('offer-comp-empty_desc-2')
                                        : ''
                                }
                                tableType="offersEmpty"
                            />
                        </div>
                    </>
                ) : (
                    ''
                )}

                {loading ? (
                    <SkeletonWithImageTable />
                ) : singleOfferLines?.length ? (
                    <>
                        {frameworkOfferData?.length ? (
                            <h2
                                className={
                                    classes[
                                        'c-offer-component__framework-table-title'
                                    ]
                                }
                            >
                                {t('global_as-single-order')}
                            </h2>
                        ) : null}
                        <Table
                            tableTitles={singleOfferTableTitles}
                            tableData={singleOfferData}
                            loading={loading}
                            tableType="offersSingleOrder"
                            emptyStHeading={t(
                                'offer-single-order-empty_heading'
                            )}
                            emptyStText={t(
                                'offer-single-order-empty_paragraph'
                            )}
                            emptyStBtnText=""
                            linkableRows={false}
                            details={searchedSingleOfferLines}
                            hasDiscount={singleOrderHasDiscount}
                            hasOrigin={singleOrderHasOrigin}
                        />
                    </>
                ) : null}
            </div>
        </>
    );
}

export default OfferComponents;
