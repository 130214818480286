import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import PageSearch from '../../../components/PageHeader/PageSearch/PageSearch';
import classes from './OrderComponents.module.scss';
import moment from 'moment';
import TableList from '../../../components/TableList/TableList';
import { useTranslation } from 'react-i18next';
import PageMeta from '../../../shared/PageMeta/PageMeta';
import { useDispatch } from 'react-redux';
import { onOpenDetails } from '../../../store/actions/detailsPopupActions';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import setGoogleAnalytics from '../../../hooks/setGoogleAnalytics';

function OrderComponents() {
    const [orderComponentsData, setOrderComponentsData] = useState([]);
    const [showInitialMessage, setShowInitialMessage] = useState(true);
    const [loading, setLoading] = useState(true);

    const searchedOrderLines = useSelector(
        (state) => state.details.searchedOrderLines
    );

    const orderDetailsLoading = useSelector(
        (state) => state.details.detailsLoading
    );

    const { componentId, id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const details = useSelector((state) => state.details.details);

    const orderNm = useSelector((state) => state.details.details?.orderNr);

    const { t } = useTranslation();

    useEffect(() => {
        if (componentId && details.orderId) {
            let detailsObject;
            if (details.orderLines?.length) {
                detailsObject = details.orderLines.find(
                    (orderLine) => orderLine.orderLineId === componentId
                );
                if (detailsObject) {
                    dispatch(onOpenDetails(detailsObject, 'orderComponents'));
                }
            }

            //fallback if componentId is not valid
            if (!detailsObject) {
                navigate(`/orders/${id}/components`);
            }
        }
        //eslint-disable-next-line
    }, [details]);

    const orderComponentsTableTitles = [
        {
            id: 1,
            name: t('order-details-comp_insert'),
        },
        {
            id: 2,
            name: t('order-details-comp_open'),
        },
        {
            id: 3,
            name: t('order-details-comp_in-production'),
        },
        {
            id: 4,
            name: t('order-details-comp_in-qs'),
        },
        {
            id: 5,
            name: t('order-details-comp_sent'),
        },
        {
            id: 6,
            name: t('order-details-comp_total'),
        },
        {
            id: 7,
            name: t('order-details-comp_price'),
        },
    ];

    const dropdownTitles = [
        {
            id: 1,
            name: t('order-details-comp_shipping-date'),
        },
        {
            id: 2,
            name: t('order-details-comp_delivery-num'),
        },
        {
            id: 3,
            name: t('order-details-comp_tracking-num'),
        },
        {
            id: 4,
            name: t('order-details-comp_quantity'),
        },
        {
            id: 5,
            name: t('order-details-comp_status'),
        },
    ];

    useEffect(() => {
        let filteredOrderLines = [];
        filteredOrderLines = searchedOrderLines?.map((item) => {
            return {
                id: item.uuid,
                files: item.files,
                dropdown: item.deliveryNotes?.map((deliveryNote) => {
                    return {
                        deliveryDate: deliveryNote.deliveryDate
                            ? moment(deliveryNote.deliveryDate).format(
                                  'DD.MM.YYYY'
                              )
                            : '-',
                        deliveryNoteNumber: deliveryNote.deliveryNoteNumber
                            ? deliveryNote.deliveryNoteNumber
                            : '-',
                        tracking: {
                            trackingCode: deliveryNote.trackingCode
                                ? deliveryNote.trackingCode
                                : '-',
                            trackingUrl: deliveryNote.trackingUrl,
                        },
                        shippedQuantity: {
                            number: deliveryNote.shippedQuantity
                                ? deliveryNote.shippedQuantity
                                : '-',
                        },
                        status: {
                            status: deliveryNote.status
                                ? deliveryNote.status
                                : '-',
                            color: deliveryNote.status
                                ? deliveryNote.status
                                : null,
                            type: 'deliveryNotesStatus',
                        },
                    };
                }),
                data: {
                    part: {
                        partName: item.partName ? item.partName : '-',
                        partNumber: item.articleNumber
                            ? item.articleNumber
                            : '-',
                        partImage: item.files
                            ? item.files[0]?.fileThumbnailUrl
                            : null,
                    },
                    openParts: item.openParts,
                    partsInProduction: item.partsInProduction,
                    partsInQA: item.partsInQA,
                    partsShipped: item.partsShipped,
                    quantity: item.quantity,
                    totalPriceNet: item.totalPriceNet,
                },
            };
        });
        setOrderComponentsData(filteredOrderLines);
        //eslint-disable-next-line
    }, [searchedOrderLines]);

    const initialSearchValueHandler = (searchStarted) => {
        setShowInitialMessage(searchStarted);
    };

    // TODO REWORK WHEN DITTO WORKS AGAIN
    const getEmptyStateMessage = (whoseState, stateType, statePlace) => {
        const emptyStatesObject = t('emptyState');
        const emptyStateJson = JSON.parse(emptyStatesObject);
        return emptyStateJson[whoseState][stateType][statePlace];
    };

    // reactga4 for order components
    useEffect(() => {
        setGoogleAnalytics(
            'page_view',
            'page_view_order_detail_component',
            'view order detail page, component section'
        );
    }, []);

    // fix for table empty state showing briefly after loading
    useEffect(() => {
        setLoading(orderDetailsLoading);
    }, [orderDetailsLoading]);

    return (
        <>
            <PageMeta>
                <title>
                    {t('global_order-btn')} {orderNm ? orderNm : ''} -
                    {t('order-details_components-tab')} | CNC24
                </title>
            </PageMeta>
            <div className={classes['c-offer-component']}>
                <PageSearch
                    componentSearch={true}
                    tableType="orderComponents"
                    onInitialSearchValue={initialSearchValueHandler}
                    javascriptSearch={true}
                />
                <TableList
                    tableTitles={orderComponentsTableTitles}
                    tableData={orderComponentsData}
                    dropdownTitles={dropdownTitles}
                    tableType="orderComponents"
                    emptyStHeading={
                        showInitialMessage
                            ? getEmptyStateMessage(
                                  'ordersComponentsEmptyState',
                                  'InitialEmptyState',
                                  'heading'
                              )
                            : t('order-components-empty_heading')
                    }
                    emptyStText={
                        showInitialMessage
                            ? getEmptyStateMessage(
                                  'ordersComponentsEmptyState',
                                  'InitialEmptyState',
                                  'text'
                              )
                            : t('order-components-empty_paragraph')
                    }
                    emptyStBtnText={
                        showInitialMessage
                            ? getEmptyStateMessage(
                                  'ordersComponentsEmptyState',
                                  'InitialEmptyState',
                                  'button'
                              )
                            : t('order-components-empty_btn-text')
                    }
                    details={searchedOrderLines}
                    loading={loading}
                />
            </div>
        </>
    );
}

export default OrderComponents;
