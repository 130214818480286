import TableListItemHeader from './TableListItemHeader/TableListItemHeader';
import TableListItemContent from './TableListItemContent/TableListItemContent';
import classes from './TableListItem.module.scss';
import { useState } from 'react';

function TableListItem(props) {
    const [dropdownExpanded, setDropdownExpanded] = useState(false);
    const data = Object.values(props.item.data);
    const dropdownClickHandler = () => {
        if (props.dropdown) {
            setDropdownExpanded(!dropdownExpanded);
        }
    };

    return (
        <div
            className={[
                classes['c-table-list-item'],
                classes[`c-table-list-item--${props.tableType}`],
                dropdownExpanded ? classes['c-table-list-item--expanded'] : '',
            ].join(' ')}
        >
            <div
                className={[
                    classes['c-table-list-item__header'],
                    dropdownExpanded ? classes['expanded'] : '',
                ].join(' ')}
            >
                {props.titles.map((title, index) => {
                    return (
                        <TableListItemHeader
                            data={data[index]}
                            details={props.details[props.index]}
                            title={title.name}
                            tableType={props.tableType}
                            id={index}
                            key={`title-${index}`}
                        />
                    );
                })}
                <div className={classes['c-table-list-item__dropdown-box']}>
                    <button
                        onClick={dropdownClickHandler}
                        className={[
                            classes['c-table-list-item__dropdown-action'],
                            dropdownExpanded
                                ? classes[
                                      'c-table-list-item__dropdown-action--rotate'
                                  ]
                                : '',
                        ].join(' ')}
                        aria-label="dropdown"
                    >
                        <i className="icon-chevron-down-thin"></i>
                    </button>
                </div>
            </div>
            <div
                className={[
                    classes['c-table-list-item__collapsable-content'],
                    classes[
                        `c-table-list-item__collapsable-content--${props.tableType}`
                    ],
                ].join(' ')}
            >
                <TableListItemContent
                    content={props.dropdown}
                    titles={props.dropdownTitles}
                    expanded={dropdownExpanded}
                    tableType={props.tableType}
                />
            </div>
        </div>
    );
}

export default TableListItem;
