import classes from './SkeletonWithImage.module.scss';

const SkeletonWithImageLabelAndValue = () => {
    const numOfColumns = [1, 2, 3, 4, 5, 6];

    return (
        <>
            {numOfColumns.map((el) => {
                return (
                    <div
                        key={el}
                        className={
                            classes['skeleton-with-image__label-value-wrapper']
                        }
                    >
                        <div
                            className={
                                classes['skeleton-with-image__label-value']
                            }
                        >
                            <span
                                className={
                                    classes['skeleton-with-image__label']
                                }
                            ></span>
                            <span
                                className={
                                    classes['skeleton-with-image__value']
                                }
                            ></span>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default SkeletonWithImageLabelAndValue;
