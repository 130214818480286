function PlusIcon() {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className="icon-path"
                d="M14.2383 9.73828H9.73828V14.2383H8.26172V9.73828H3.76172V8.26172H8.26172V3.76172H9.73828V8.26172H14.2383V9.73828Z"
                fill="white"
            />
        </svg>
    );
}

export default PlusIcon;
