import { Formik, Form } from 'formik';
import Input from '../../../../../components/Input/Input/Input';
import WizzardFooter from '../../../../../components/Wizzard/WizzardFooter/WizzardFooter';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { onSetApiData } from '../../../../../store/actions/offersWizzardActions';
import Dropzone from '../../../../../components/Dropzone/Dropzone';
import classes from '../../../../../components/Wizzard/WizzardOrderDetails.module.scss';
import UploadedFile from '../../../../../components/UploadedFile/UploadedFile';
import { useEffect, useState } from 'react';
import DatePicker from '../../../../../components/DatePicker/DatePicker';
import CountrySelect from '../../../../../components/Input/CountrySelect/CountrySelect';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import ContactSelect from '../../../../../components/Input/ContactSelect/ContactSelect';
import TranslatedComponent from '../../../../../components/TranslatedComponent/TranslatedComponent';

function OrderDetails() {
    const [files, setFiles] = useState([]);
    const [minProductionDay, setMinProductionDay] = useState(true);

    const { t } = useTranslation();

    const offersWizzardReducer = useSelector(
        (state) => state.offersWizzardReducer
    );

    const userReducer = useSelector((state) => state.user);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    useEffect(() => {
        if (offersWizzardReducer.wizzardType === 'single-offer') {
            if (offersWizzardReducer.data.order?.files) {
                setFiles(offersWizzardReducer.data.order.files);
            }
        } else {
            if (offersWizzardReducer.data.frameworkAgreement?.files) {
                setFiles(offersWizzardReducer.data.frameworkAgreement.files);
            }
        }
    }, [offersWizzardReducer]);

    useEffect(() => {
        if (offersWizzardReducer.wizzardType === 'single-offer') {
            if (offersWizzardReducer.data?.order?.deliveryDate) {
                const selectedDate = moment(
                    offersWizzardReducer.data.order.deliveryDate
                );
                extraMessageChecker(selectedDate);
            }
        } else {
            if (offersWizzardReducer.data?.frameworkAgreement?.deliveryDate) {
                const selectedDate = moment(
                    offersWizzardReducer.data.frameworkAgreement.deliveryDate
                );
                extraMessageChecker(selectedDate);
            }
        }
        //eslint-disable-next-line
    }, []);

    const extraMessageChecker = (date) => {
        const selectedDate = moment(date).startOf('day');

        const productionDays = offersWizzardReducer.offerLines.map(
            (offerLine, index) => {
                return offerLine.quantities[
                    offersWizzardReducer.offerLinesIndex[index]
                ].minProductionDays;
            }
        );

        // minimum production days needed
        const minProductionDay = Math.max(...productionDays);

        // current date with 00 time
        const currentDate = moment().startOf('day');

        // min delivery date is current date + minproductiondays
        const minDeliveryDate = currentDate.add(minProductionDay, 'days');
        const selectedDateAfterMinDeliveryDate =
            minDeliveryDate.isBefore(selectedDate) ||
            minDeliveryDate.isSame(selectedDate);

        // max delivery date is current date + minproductiondays + 30 days
        const maxDeliveryDate = minDeliveryDate.add(30, 'days');
        const selectedDateAfterMaxDeliveryDate =
            selectedDate.isBefore(maxDeliveryDate) ||
            selectedDate.isSame(maxDeliveryDate);

        // check if selected date is higher then max delivery date or lower then min delivery date
        const dateValidation =
            selectedDateAfterMinDeliveryDate &&
            selectedDateAfterMaxDeliveryDate;

        setMinProductionDay(dateValidation);
    };

    const validationSchema = Yup.object().shape({
        shippingAddressCompanyName: Yup.string().required(
            `${t('error_field-required')}`
        ),
        shippingAddressAdditionalInfo: Yup.string(),
        shippingAddressStreet: Yup.string().required(
            `${t('error_field-required')}`
        ),
        shippingAddressPostalCode: Yup.string().required(
            `${t('error_field-required')}`
        ),
        shippingAddressCity: Yup.string().required(
            `${t('error_field-required')}`
        ),
        shippingAddressCountry: Yup.string().required(
            `${t('error_field-required')}`
        ),
        billingAddressCompanyName: Yup.string().required(
            `${t('error_field-required')}`
        ),
        billingAddressAdditionalInfo: Yup.string(),
        billingAddressStreet: Yup.string().required(
            `${t('error_field-required')}`
        ),
        billingAddressTaxNumber: Yup.string(),
        billingAddressPostalCode: Yup.string().required(
            `${t('error_field-required')}`
        ),
        billingAddressCity: Yup.string().required(
            `${t('error_field-required')}`
        ),
        billingAddressCountry: Yup.string().required(
            `${t('error_field-required')}`
        ),
        reference: Yup.string().required(`${t('error_field-required')}`),
        comments: Yup.string(),
        deliveryDate: Yup.string().required(`${t('error_field-required')}`),
        contactPurchasing: Yup.string(),
        contactInvoicing: Yup.string(),
        contactOrderConfirmation: Yup.string(),
    });

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const initialValues = {
        shippingAddressCompanyName: offersWizzardReducer.data.addresses
            .shippingAddress.companyName
            ? offersWizzardReducer.data.addresses.shippingAddress.companyName
            : offersWizzardReducer.details.shippingCompany
            ? offersWizzardReducer.details.shippingCompany
            : '',
        shippingAddressAdditionalInfo: offersWizzardReducer.data.addresses
            .shippingAddress.additionalInfo
            ? offersWizzardReducer.data.addresses.shippingAddress.additionalInfo
            : offersWizzardReducer.details.shippingAdditionalInfo
            ? offersWizzardReducer.details.shippingAdditionalInfo
            : '',
        shippingAddressStreet: offersWizzardReducer.data.addresses
            .shippingAddress.street
            ? offersWizzardReducer.data.addresses.shippingAddress.street
            : offersWizzardReducer.details.shippingStreet
            ? offersWizzardReducer.details.shippingStreet
            : '',
        shippingAddressPostalCode: offersWizzardReducer.data.addresses
            .shippingAddress.postalCode
            ? offersWizzardReducer.data.addresses.shippingAddress.postalCode
            : offersWizzardReducer.details.shippingPostalCode
            ? offersWizzardReducer.details.shippingPostalCode
            : '',
        shippingAddressCity: offersWizzardReducer.data.addresses.shippingAddress
            .city
            ? offersWizzardReducer.data.addresses.shippingAddress.city
            : offersWizzardReducer.details.shippingCity
            ? offersWizzardReducer.details.shippingCity
            : '',
        shippingAddressCountry: offersWizzardReducer.data.addresses
            .shippingAddress.country
            ? offersWizzardReducer.data.addresses.shippingAddress.country
            : offersWizzardReducer.details.shippingCountry
            ? offersWizzardReducer.details.shippingCountry
            : 'DE',
        billingAddressCompanyName: offersWizzardReducer.data.addresses
            .billingAddress.companyName
            ? offersWizzardReducer.data.addresses.billingAddress.companyName
            : offersWizzardReducer.details.billingCompany
            ? offersWizzardReducer.details.billingCompany
            : '',
        billingAddressAdditionalInfo: offersWizzardReducer.data.addresses
            .billingAddress.additionalInfo
            ? offersWizzardReducer.data.addresses.billingAddress.additionalInfo
            : offersWizzardReducer.details.billingAdditionalInfo
            ? offersWizzardReducer.details.billingAdditionalInfo
            : '',
        billingAddressStreet: offersWizzardReducer.data.addresses.billingAddress
            .street
            ? offersWizzardReducer.data.addresses.billingAddress.street
            : offersWizzardReducer.details.billingStreet
            ? offersWizzardReducer.details.billingStreet
            : '',
        billingAddressTaxNumber: offersWizzardReducer.data.addresses
            .billingAddress.taxNumber
            ? offersWizzardReducer.data.addresses.billingAddress.taxNumber
            : offersWizzardReducer.details.taxNumber
            ? offersWizzardReducer.details.taxNumber
            : '',
        billingAddressPostalCode: offersWizzardReducer.data.addresses
            .billingAddress.postalCode
            ? offersWizzardReducer.data.addresses.billingAddress.postalCode
            : offersWizzardReducer.details.billingPostalCode
            ? offersWizzardReducer.details.billingPostalCode
            : '',
        billingAddressCity: offersWizzardReducer.data.addresses.billingAddress
            .city
            ? offersWizzardReducer.data.addresses.billingAddress.city
            : offersWizzardReducer.details.billingCity
            ? offersWizzardReducer.details.billingCity
            : '',
        billingAddressCountry: offersWizzardReducer.data.addresses
            .billingAddress.country
            ? offersWizzardReducer.data.addresses.billingAddress.country
            : offersWizzardReducer.details.billingCountry
            ? offersWizzardReducer.details.billingCountry
            : 'DE',
        reference:
            offersWizzardReducer.wizzardType === 'single-offer'
                ? offersWizzardReducer.data.order.reference
                : offersWizzardReducer.data.frameworkAgreement.reference,
        comments:
            offersWizzardReducer.wizzardType === 'single-offer'
                ? offersWizzardReducer.data.order.comments
                : offersWizzardReducer.data.frameworkAgreement.comments,
        deliveryDate:
            offersWizzardReducer.wizzardType === 'single-offer'
                ? offersWizzardReducer.data.order.deliveryDate
                    ? offersWizzardReducer.data.order.deliveryDate
                    : tomorrow.toJSON().slice(0, 10)
                : offersWizzardReducer.data.frameworkAgreement.deliveryDate
                ? offersWizzardReducer.data.frameworkAgreement.deliveryDate
                : tomorrow.toJSON().slice(0, 10),
        contactPurchasing: offersWizzardReducer.data.defaultContacts
            .contactPurchasing
            ? offersWizzardReducer.data.defaultContacts.contactPurchasing
            : userReducer.user.contactPurchasing
            ? userReducer.user.contactPurchasing
            : '',
        contactInvoicing: offersWizzardReducer.data.defaultContacts
            .contactInvoicing
            ? offersWizzardReducer.data.defaultContacts.contactInvoicing
            : userReducer.user.contactInvoicing
            ? userReducer.user.contactInvoicing
            : '',
        contactOrderConfirmation: offersWizzardReducer.data.defaultContacts
            .contactOrderConfirmation
            ? offersWizzardReducer.data.defaultContacts.contactOrderConfirmation
            : userReducer.user.contactOrderConfirmation
            ? userReducer.user.contactOrderConfirmation
            : '',
    };

    const acceptFiles = { 'application/pdf': [] };

    const handleSubmit = (values) => {
        let orderLines = offersWizzardReducer.offerLines.map(
            (offerLine, index) => {
                return {
                    orderLineId:
                        offerLine.quantities[
                            offersWizzardReducer.offerLinesIndex[index]
                        ]?.offerLineId,
                };
            }
        );
        let frameworkAgreementLines =
            offersWizzardReducer.frameworkOfferLines.map((offerLines) => {
                return {
                    frameworkAgreementLineId:
                        offerLines.quantities[0]?.offerLineId,
                    sampleQuantity: offerLines.quantities[0]?.quantitySample,
                    batchQuantity: offerLines.quantities[0]?.quantityPerBatches,
                    musterDeliveryDate: offerLines.quantities[0]?.deliveryTime,
                    firstBatchDeliveryDate:
                        offerLines.quantities[0]?.deliveryTimeFirstBatch,
                    nextBatchesDeliveryDate:
                        offerLines?.quantities[0]?.deliveryTimePerBatch,
                };
            });
        let totalPrice = 0;
        if (offersWizzardReducer.wizzardType === 'single-offer') {
            offersWizzardReducer.offerLines.forEach((offerLine, index) => {
                totalPrice =
                    totalPrice +
                    offerLine.quantities[
                        offersWizzardReducer.offerLinesIndex[index]
                    ]?.totalPriceNet;
            });
        } else {
            offersWizzardReducer.frameworkOfferLines.forEach((offerLine) => {
                totalPrice =
                    totalPrice + offerLine.quantities[0]?.totalPriceNet;
            });
        }

        let data = {
            order:
                offersWizzardReducer.wizzardType === 'single-offer'
                    ? {
                          reference: values.reference,
                          comments: values.comments,
                          deliveryDate: values.deliveryDate,
                          orderLines: orderLines,
                          files: files,
                          totalPrice: totalPrice,
                      }
                    : null,
            frameworkAgreement:
                offersWizzardReducer.wizzardType === 'framework-agreement'
                    ? {
                          reference: values.reference,
                          comments: values.comments,
                          frameworkAgreementLines: frameworkAgreementLines,
                          deliveryDate:
                              offersWizzardReducer.details.startTermDate,
                          files: files,
                          totalPrice: totalPrice,
                      }
                    : null,
            addresses: {
                billingAddress: {
                    city: values.billingAddressCity,
                    companyName: values.billingAddressCompanyName,
                    country: values.billingAddressCountry,
                    postalCode: values.billingAddressPostalCode,
                    street: values.billingAddressStreet,
                    additionalInfo: values.billingAddressAdditionalInfo,
                    taxNumber: values.billingAddressTaxNumber,
                },
                shippingAddress: {
                    city: values.shippingAddressCity,
                    companyName: values.shippingAddressCompanyName,
                    country: values.shippingAddressCountry,
                    postalCode: values.shippingAddressPostalCode,
                    street: values.shippingAddressStreet,
                    additionalInfo: values.shippingAddressAdditionalInfo,
                },
            },
            defaultContacts: {
                contactPurchasing: values.contactPurchasing,
                contactInvoicing: values.contactInvoicing,
                contactOrderConfirmation: values.contactOrderConfirmation,
            },
        };

        dispatch(onSetApiData(data));
        navigate(
            `/offers/${offersWizzardReducer.details.offerId}/wizard/summary?type=${offersWizzardReducer.wizzardType}`
        );
    };

    const handleUploadSuccessfull = (uploadedFiles) => {
        let filesClone = [...files];
        let mergedArrays = [...filesClone, ...uploadedFiles];
        setFiles(mergedArrays);
    };

    const dateChangeHandler = (date, props) => {
        let selectedDate;
        if (typeof date === 'object') {
            selectedDate = moment(date).format('YYYY-MM-DD');
        } else {
            selectedDate = date;
        }
        props.setFieldValue('deliveryDate', selectedDate?.slice(0, 10));

        // checking if date extra message is needed
        extraMessageChecker(date);
    };

    const handleDatePickerBlur = (props) => {
        props.setFieldTouched('deliveryDate', true);
    };

    const handleCountryShippingChange = (country, props) => {
        props.setFieldValue('shippingAddressCountry', country.value);
    };

    const handleCountryBillingChange = (country, props) => {
        props.setFieldValue('billingAddressCountry', country.value);
    };

    const handleCountryBillingBlur = (props) => {
        props.setFieldTouched('billingAddressCountry', true);
    };

    const handleCountryShippingBlur = (props) => {
        props.setFieldTouched('shippingAddressCountry', true);
    };

    // contactPurchasing
    const handleContactPurchasingChange = (contact, props) => {
        props.setFieldValue('contactPurchasing', contact.value);
    };

    const handleContactPurchasingBlur = (props) => {
        props.setFieldTouched('contactPurchasing', true);
    };

    // contactInvoicing
    const handleContactInvoicingChange = (contact, props) => {
        props.setFieldValue('contactInvoicing', contact.value);
    };

    const handleContactInvoicingBlur = (props) => {
        props.setFieldTouched('contactInvoicing', true);
    };

    // contactOrderConfirmation
    const handleContactOrderConfirmationChange = (contact, props) => {
        props.setFieldValue('contactOrderConfirmation', contact.value);
    };

    const handleContactOrderConfirmationBlur = (props) => {
        props.setFieldTouched('contactOrderConfirmation', true);
    };

    const handleDelete = (fileId) => {
        let deletedFiles = files;
        deletedFiles = deletedFiles.filter((file) => file.fileId !== fileId);
        setFiles(deletedFiles);
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => handleSubmit(values)}
                validateOnMount={true}
            >
                {(props) => {
                    return (
                        <Form>
                            <div className={classes['c-wizzard-order-details']}>
                                <div
                                    className={
                                        classes[
                                            'c-wizzard-order-details__left-side'
                                        ]
                                    }
                                >
                                    <span
                                        className={
                                            classes[
                                                'c-wizzard-order-details__offer-num'
                                            ]
                                        }
                                    >
                                        {offersWizzardReducer.details.offerNr}
                                    </span>
                                    <h3>
                                        {t(
                                            'wizard-ord-details_shipping-address'
                                        )}
                                    </h3>
                                    <Input
                                        type="text"
                                        label={t(
                                            'wizard-order-details_company-name'
                                        )}
                                        name="shippingAddressCompanyName"
                                        errors={
                                            props.errors
                                                .shippingAddressCompanyName
                                        }
                                        touched={
                                            props.touched
                                                .shippingAddressCompanyName
                                        }
                                        autoComplete="shipping-address-company-name"
                                        inputisrequired="true"
                                    />
                                    <Input
                                        type="text"
                                        label={t(
                                            'wizard-ord-details_additional-info'
                                        )}
                                        name="shippingAddressAdditionalInfo"
                                        autoComplete="shipping-address-additional-info"
                                    />
                                    <div
                                        className={
                                            classes[
                                                'c-wizzard-order-details__input-layout'
                                            ]
                                        }
                                    >
                                        <div
                                            className={[
                                                classes[
                                                    'c-wizzard-order-details__input'
                                                ],
                                                classes[
                                                    'c-wizzard-order-details__input--large'
                                                ],
                                            ].join(' ')}
                                        >
                                            <Input
                                                type="text"
                                                label={t(
                                                    'wizard-ord-details_street'
                                                )}
                                                name="shippingAddressStreet"
                                                errors={
                                                    props.errors
                                                        .shippingAddressStreet
                                                }
                                                touched={
                                                    props.touched
                                                        .shippingAddressStreet
                                                }
                                                autoComplete="shipping-address-street"
                                                inputisrequired="true"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            classes[
                                                'c-wizzard-order-details__input-layout'
                                            ]
                                        }
                                    >
                                        <div
                                            className={[
                                                classes[
                                                    'c-wizzard-order-details__input'
                                                ],
                                                classes[
                                                    'c-wizzard-order-details__input--small'
                                                ],
                                            ].join(' ')}
                                        >
                                            <Input
                                                type="text"
                                                label={t(
                                                    'wizard-ord-details_plz'
                                                )}
                                                name="shippingAddressPostalCode"
                                                errors={
                                                    props.errors
                                                        .shippingAddressPostalCode
                                                }
                                                touched={
                                                    props.touched
                                                        .shippingAddressPostalCode
                                                }
                                                autoComplete="shipping-address-postal-code"
                                                inputisrequired="true"
                                            />
                                        </div>
                                        <div
                                            className={[
                                                classes[
                                                    'c-wizzard-order-details__input'
                                                ],
                                                classes[
                                                    'c-wizzard-order-details__input--large'
                                                ],
                                            ].join(' ')}
                                        >
                                            <Input
                                                type="text"
                                                label={t(
                                                    'wizard-ord-details_city'
                                                )}
                                                name="shippingAddressCity"
                                                errors={
                                                    props.errors
                                                        .shippingAddressCity
                                                }
                                                touched={
                                                    props.touched
                                                        .shippingAddressCity
                                                }
                                                autoComplete="shipping-address-city"
                                                inputisrequired="true"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            classes[
                                                'c-wizzard-order-details__item-container'
                                            ]
                                        }
                                    >
                                        <div
                                            className={
                                                classes[
                                                    'c-wizzard-order-details__item-label'
                                                ]
                                            }
                                        >
                                            {t('wizard-ord-details_country')}{' '}
                                            <span
                                                className={`c-input__required-error ${
                                                    props.errors
                                                        .shippingAddressCountry
                                                        ? 'c-input__required-error--red'
                                                        : ''
                                                }`}
                                            >
                                                *
                                            </span>
                                        </div>
                                        <CountrySelect
                                            initialValue={
                                                props.values
                                                    .shippingAddressCountry
                                            }
                                            onChange={(country) =>
                                                handleCountryShippingChange(
                                                    country,
                                                    props
                                                )
                                            }
                                            errors={
                                                props.errors
                                                    .shippingAddressCountry
                                            }
                                            touched={
                                                props.touched
                                                    .shippingAddressCountry
                                            }
                                            onBlur={() =>
                                                handleCountryShippingBlur(props)
                                            }
                                            extraClass="offersWizzard"
                                        />
                                    </div>
                                    <div
                                        className={
                                            classes[
                                                'c-wizzard-order-details__billing-address'
                                            ]
                                        }
                                    >
                                        <h3>
                                            {t(
                                                'wizard-ord-details_billing-address'
                                            )}
                                        </h3>
                                        <Input
                                            type="text"
                                            label={t(
                                                'wizard-order-details_company-name'
                                            )}
                                            name="billingAddressCompanyName"
                                            errors={
                                                props.errors
                                                    .billingAddressCompanyName
                                            }
                                            touched={
                                                props.touched
                                                    .billingAddressCompanyName
                                            }
                                            autoComplete="billing-address-company-name"
                                            inputisrequired="true"
                                        />
                                        <Input
                                            type="text"
                                            label={t(
                                                'wizard-ord-details_additional-info'
                                            )}
                                            name="billingAddressAdditionalInfo"
                                            autoComplete="billing-address-additional-info"
                                        />
                                        <Input
                                            type="text"
                                            label={t(
                                                'wizard-ord-details_tax-num'
                                            )}
                                            name="billingAddressTaxNumber"
                                            errors={
                                                props.errors
                                                    .billingAddressTaxNumber
                                            }
                                            touched={
                                                props.touched
                                                    .billingAddressTaxNumber
                                            }
                                            autoComplete="billing-address-street"
                                        />
                                        <div
                                            className={
                                                classes[
                                                    'c-wizzard-order-details__input-layout'
                                                ]
                                            }
                                        >
                                            <div
                                                className={[
                                                    classes[
                                                        'c-wizzard-order-details__input'
                                                    ],
                                                    classes[
                                                        'c-wizzard-order-details__input--large'
                                                    ],
                                                ].join(' ')}
                                            >
                                                <Input
                                                    type="text"
                                                    label={t(
                                                        'wizard-ord-details_street'
                                                    )}
                                                    name="billingAddressStreet"
                                                    errors={
                                                        props.errors
                                                            .billingAddressStreet
                                                    }
                                                    touched={
                                                        props.touched
                                                            .billingAddressStreet
                                                    }
                                                    autoComplete="billing-address-postal-code"
                                                    inputisrequired="true"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                classes[
                                                    'c-wizzard-order-details__input-layout'
                                                ]
                                            }
                                        >
                                            <div
                                                className={[
                                                    classes[
                                                        'c-wizzard-order-details__input'
                                                    ],
                                                    classes[
                                                        'c-wizzard-order-details__input--small'
                                                    ],
                                                ].join(' ')}
                                            >
                                                <Input
                                                    type="text"
                                                    label={t(
                                                        'wizard-ord-details_plz'
                                                    )}
                                                    name="billingAddressPostalCode"
                                                    errors={
                                                        props.errors
                                                            .billingAddressPostalCode
                                                    }
                                                    touched={
                                                        props.touched
                                                            .billingAddressPostalCode
                                                    }
                                                    autoComplete="billing-address-postal-code"
                                                    inputisrequired="true"
                                                />
                                            </div>
                                            <div
                                                className={[
                                                    classes[
                                                        'c-wizzard-order-details__input'
                                                    ],
                                                    classes[
                                                        'c-wizzard-order-details__input--large'
                                                    ],
                                                ].join(' ')}
                                            >
                                                <Input
                                                    type="text"
                                                    label={t(
                                                        'wizard-ord-details_city'
                                                    )}
                                                    name="billingAddressCity"
                                                    errors={
                                                        props.errors
                                                            .billingAddressCity
                                                    }
                                                    touched={
                                                        props.touched
                                                            .billingAddressCity
                                                    }
                                                    autoComplete="billing-address-city"
                                                    inputisrequired="true"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                classes[
                                                    'c-wizzard-order-details__item-container'
                                                ]
                                            }
                                        >
                                            <div
                                                className={
                                                    classes[
                                                        'c-wizzard-order-details__item-label'
                                                    ]
                                                }
                                            >
                                                {t(
                                                    'wizard-ord-details_country'
                                                )}{' '}
                                                <span
                                                    className={`c-input__required-error ${
                                                        props.errors
                                                            .billingAddressCountry
                                                            ? 'c-input__required-error--red'
                                                            : ''
                                                    }`}
                                                >
                                                    *
                                                </span>
                                            </div>
                                            <CountrySelect
                                                initialValue={
                                                    props.values
                                                        .billingAddressCountry
                                                }
                                                onChange={(country) =>
                                                    handleCountryBillingChange(
                                                        country,
                                                        props
                                                    )
                                                }
                                                errors={
                                                    props.errors
                                                        .billingAddressCountry
                                                }
                                                touched={
                                                    props.touched
                                                        .billingAddressCountry
                                                }
                                                onBlur={() =>
                                                    handleCountryBillingBlur(
                                                        props
                                                    )
                                                }
                                                extraClass="offersWizzard"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={
                                        classes[
                                            'c-wizzard-order-details__right-side'
                                        ]
                                    }
                                >
                                    {offersWizzardReducer.wizzardType !==
                                    'framework-agreement' ? (
                                        <>
                                            <h3>
                                                {t(
                                                    'wizard-ord-details_shipping-date'
                                                )}
                                            </h3>
                                            <div
                                                className={
                                                    classes[
                                                        'c-wizzard-order-details__item-container'
                                                    ]
                                                }
                                            >
                                                <div
                                                    className={
                                                        classes[
                                                            'c-wizzard-order-details__item-label'
                                                        ]
                                                    }
                                                >
                                                    {t(
                                                        'wizard-ord-details_desired-delivery-date'
                                                    )}
                                                </div>
                                                <DatePicker
                                                    onDateChange={(date) =>
                                                        dateChangeHandler(
                                                            date,
                                                            props
                                                        )
                                                    }
                                                    initialValue={
                                                        props.values
                                                            .deliveryDate
                                                    }
                                                    type="singleDate"
                                                    singleDate="true"
                                                    errors={
                                                        props.errors
                                                            .deliveryDate
                                                    }
                                                    touched={
                                                        props.touched
                                                            .deliveryDate
                                                    }
                                                    onBlur={() =>
                                                        handleDatePickerBlur(
                                                            props
                                                        )
                                                    }
                                                />
                                            </div>
                                        </>
                                    ) : null}
                                    {!minProductionDay && (
                                        <div
                                            className={
                                                classes[
                                                    'c-wizzard-order-details__error-message'
                                                ]
                                            }
                                        >
                                            {t(
                                                'wizard-copy_delivery-date-warning'
                                            )}
                                        </div>
                                    )}
                                    <div
                                        className={`${
                                            classes[
                                                'c-wizzard-order-details__order-information-container'
                                            ]
                                        } ${
                                            offersWizzardReducer.wizzardType ===
                                            'framework-agreement'
                                                ? classes[
                                                      'c-wizzard-order-details__order-information-container--reduced-margin'
                                                  ]
                                                : ''
                                        }`}
                                    >
                                        <h2>
                                            {t(
                                                'wizard-ord-details_your-order-info'
                                            )}
                                        </h2>
                                        <div
                                            className={
                                                classes[
                                                    'c-wizzard-order-details__order-information'
                                                ]
                                            }
                                        >
                                            <Input
                                                type="text"
                                                label={t(
                                                    'wizard-ord-details_internal-ord-num'
                                                )}
                                                name="reference"
                                                errors={props.errors.reference}
                                                touched={
                                                    props.touched.reference
                                                }
                                                autoComplete="contact-invoicing"
                                                inputisrequired="true"
                                            />
                                            <div
                                                className={
                                                    classes[
                                                        'c-wizzard-order-details__dropzone'
                                                    ]
                                                }
                                            >
                                                <Dropzone
                                                    recordId={
                                                        offersWizzardReducer
                                                            .details.offerId
                                                    }
                                                    uploadSuccessfull={
                                                        handleUploadSuccessfull
                                                    }
                                                    acceptFiles={acceptFiles}
                                                />
                                                {files.length ? (
                                                    <ul>
                                                        {files.map(
                                                            (file, index) => (
                                                                <UploadedFile
                                                                    key={`file-${index}`}
                                                                    fileName={
                                                                        file.fileName
                                                                    }
                                                                    fileId={
                                                                        file.fileId
                                                                    }
                                                                    onDelete={
                                                                        handleDelete
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                    </ul>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <Input
                                        component="textarea"
                                        type="textarea"
                                        label={t('wizard-ord-details_comment')}
                                        name="comments"
                                        className={
                                            classes[
                                                'c-wizzard-order-details__comment'
                                            ]
                                        }
                                    />
                                    <div
                                        className={
                                            classes[
                                                'c-wizzard-order-details__contacts'
                                            ]
                                        }
                                    >
                                        <h2>Contacts</h2>
                                        <div
                                            className={
                                                classes[
                                                    'c-wizzard-order-details__contacts-dropdowns'
                                                ]
                                            }
                                        >
                                            <div
                                                className={
                                                    classes[
                                                        'c-wizzard-order-details__contacts-dropdown'
                                                    ]
                                                }
                                            >
                                                <div
                                                    className={
                                                        classes[
                                                            'c-wizzard-order-details__item-label'
                                                        ]
                                                    }
                                                >
                                                    <TranslatedComponent
                                                        translationFileName="contact-roles"
                                                        translatedComponent={
                                                            'purchasing'
                                                        }
                                                    />
                                                </div>
                                                <ContactSelect
                                                    onChange={(contact) =>
                                                        handleContactPurchasingChange(
                                                            contact,
                                                            props
                                                        )
                                                    }
                                                    initialValue={
                                                        props.values
                                                            .contactPurchasing
                                                    }
                                                    errors={
                                                        props.errors
                                                            .contactPurchasing
                                                    }
                                                    touched={
                                                        props.touched
                                                            .contactPurchasing
                                                    }
                                                    onBlur={() =>
                                                        handleContactPurchasingBlur(
                                                            props
                                                        )
                                                    }
                                                    // extraClass='offersWizzard'
                                                />
                                            </div>
                                            <div
                                                className={
                                                    classes[
                                                        'c-wizzard-order-details__contacts-dropdown'
                                                    ]
                                                }
                                            >
                                                <div
                                                    className={
                                                        classes[
                                                            'c-wizzard-order-details__item-label'
                                                        ]
                                                    }
                                                >
                                                    <TranslatedComponent
                                                        translationFileName="contact-roles"
                                                        translatedComponent={
                                                            'invoicing'
                                                        }
                                                    />
                                                </div>
                                                <ContactSelect
                                                    onChange={(contact) =>
                                                        handleContactInvoicingChange(
                                                            contact,
                                                            props
                                                        )
                                                    }
                                                    initialValue={
                                                        props.values
                                                            .contactInvoicing
                                                    }
                                                    errors={
                                                        props.errors
                                                            .contactInvoicing
                                                    }
                                                    touched={
                                                        props.touched
                                                            .contactInvoicing
                                                    }
                                                    onBlur={() =>
                                                        handleContactInvoicingBlur(
                                                            props
                                                        )
                                                    }
                                                    // extraClass='offersWizzard'
                                                />
                                            </div>
                                            <div
                                                className={
                                                    classes[
                                                        'c-wizzard-order-details__contacts-dropdown'
                                                    ]
                                                }
                                            >
                                                <div
                                                    className={
                                                        classes[
                                                            'c-wizzard-order-details__item-label'
                                                        ]
                                                    }
                                                >
                                                    <TranslatedComponent
                                                        translationFileName="contact-roles"
                                                        translatedComponent={
                                                            'orderConfirmation'
                                                        }
                                                    />
                                                </div>
                                                <ContactSelect
                                                    onChange={(contact) =>
                                                        handleContactOrderConfirmationChange(
                                                            contact,
                                                            props
                                                        )
                                                    }
                                                    initialValue={
                                                        props.values
                                                            .contactOrderConfirmation
                                                    }
                                                    errors={
                                                        props.errors
                                                            .contactOrderConfirmation
                                                    }
                                                    touched={
                                                        props.touched
                                                            .contactOrderConfirmation
                                                    }
                                                    onBlur={() =>
                                                        handleContactOrderConfirmationBlur(
                                                            props
                                                        )
                                                    }
                                                    // extraClass='offersWizzard'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <WizzardFooter
                                prevButtonText={t('global_go-back-btn')}
                                nextButtonText={t('global_continue-btn')}
                                nextSubmit={true}
                                // url={`/offers/${offersWizzardReducer.details.offerId}/wizard/summary`}
                                backUrl={`/offers/${offersWizzardReducer.details.offerId}/wizard/components?type=${offersWizzardReducer.wizzardType}`}
                            />
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
}

export default OrderDetails;
