import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Table from '../../../../../components/Table/Table';
import RestoreItems from './RestoreItems/RestoreItems';
import { useTranslation } from 'react-i18next';
import WizzardFooter from '../../../../../components/Wizzard/WizzardFooter/WizzardFooter';
import ErrorMessage from '../../../../../components/ErrorMessage/ErrorMessage';
import classes from '../../../../../components/Wizzard/WizzardGeneral.module.scss';
import { onStepUp } from '../../../../../store/actions/frameworkWizzardActions';
import { useDispatch } from 'react-redux';

function Components() {
    const [tableData, setTableData] = useState([]);
    const [tableTitles, setTableTitles] = useState([]);
    const [submited, setSubmited] = useState(false);
    const [validFields, setValidFields] = useState(true);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const frameworkLines = useSelector(
        (state) => state.frameworkWizzardReducer.frameworkLines
    );
    const details = useSelector((state) => state.details.details);

    const frameworkLinesQuantityArr = useSelector(
        (state) => state.frameworkWizzardReducer.frameworkLinesQuantityArr
    );

    const handleValidateFields = () => {
        setSubmited(true);
        const isFormValid = frameworkLinesQuantityArr.every(checkValidation);
        setValidFields(isFormValid);

        function checkValidation(arrayItem) {
            return arrayItem > 0;
        }

        if (isFormValid) {
            dispatch(onStepUp());
        }
    };

    useEffect(() => {
        const filteredOfferLines = frameworkLines?.map(
            (frameworkLine, index) => {
                return {
                    data: {
                        partName: frameworkLine.partName,
                        articleNumber: frameworkLine.articleNumber,
                        material: {
                            material: frameworkLine.material
                                ? frameworkLine.material
                                : '-',
                            materialGroup: frameworkLine.materialGroup
                                ? frameworkLine.materialGroup
                                : '-',
                            materialComments: frameworkLine.materialComments
                                ? frameworkLine.materialComments
                                : '',
                        },
                        productionMethod: frameworkLine.productionMethod.length
                            ? frameworkLine.productionMethod.join(', ')
                            : '-',
                        durationAgreement: frameworkLine.durationAgreement
                            ? frameworkLine.durationAgreement
                            : '-',
                        quantity: {
                            index: index,
                            selectQuantity: frameworkLine.quantity
                                ? frameworkLine.quantity
                                : '-',
                            alignRight: true,
                        },
                        quantityPerBatch: frameworkLine.quantityPerBatches
                            ? `${new Intl.NumberFormat('de-DE').format(
                                  frameworkLine.quantityPerBatches
                              )} ${t('global_pieces-short')}`
                            : '-',
                        quantitiesOrdered: {
                            selectQuantity: frameworkLine.quantitiesOrdered
                                ? frameworkLine.quantitiesOrdered
                                : '-',
                            alignRight: true,
                        },
                        quantitiesOpen: {
                            selectQuantity: frameworkLine.quantitiesOpen
                                ? frameworkLine.quantitiesOpen
                                : '-',
                            alignRight: true,
                        },
                        quantityInput: {
                            show: true,
                            maxQuantity: frameworkLine.quantity,
                            placeholder: 'Stk.',
                            wizard: 'framework-agreement',
                            index: index,
                        },
                        totalPrice: {
                            price: frameworkLine.totalPrice
                                ? frameworkLine.totalPrice
                                : '-',
                        },
                        deletableRow: {
                            lineId: frameworkLine.frameworkLineId,
                            wizard: 'framework-agreement',
                            deletableRow:
                                frameworkLines.length > 1 ? true : false,
                            index: index,
                        },
                    },
                };
            }
        );
        setTableData(filteredOfferLines);
        //eslint-disable-next-line
    }, [frameworkLines]);

    useEffect(() => {
        setTableTitles([
            {
                id: 1,
                name: t('wizard-table_designation'),
            },
            {
                id: 2,
                name: t('wizard-table_article-num'),
            },
            {
                id: 3,
                name: t('wizard-table_material'),
            },
            {
                id: 4,
                name: t('wizard-table_additional-prod-processes'),
            },
            {
                id: 5,
                name: t('wizard-table_delivery-time'),
            },
            {
                id: 6,
                name: t('wizard-table_total-quantity'),
            },
            {
                id: 7,
                name: t('label_quantity-per-call'),
            },
            {
                id: 8,
                name: t('label_already-ordered'),
            },
            {
                id: 9,
                name: t('fa-details-comp_open'),
            },
            {
                id: 10,
                name: t('label_order-quantity'),
            },
            {
                id: 11,
                name: t('offers-table-columns_price'),
            },
            {
                id: 12,
                name: '',
            },
        ]);
        //eslint-disable-next-line
    }, []);

    return (
        <>
            <span className={classes['wizzard__offer-num']}>
                {details.offerNr}
            </span>
            <h3 className={classes['wizzard__step-1-heading']}>
                {t('wizard-heading_fa-step-1')}
            </h3>
            {details.frameworkLines > frameworkLines && <RestoreItems />}
            <Table
                tableTitles={tableTitles}
                tableData={tableData}
                tableType="faWizardStepOne"
                emptyStHeading="You have removed all items from your offer"
            />
            {!validFields && submited && (
                <ErrorMessage hasIcon={true} bgColor="errorMessage" />
            )}
            <WizzardFooter
                nextButtonText={t('global_continue-btn')}
                nextButtonDisabled={false}
                nextSubmit={true}
                handleValidateFields={handleValidateFields}
                url={`/framework-agreements/${details.faId}/wizard/order-details`}
            />
        </>
    );
}

export default Components;
