export const OPEN_DETAILS_POPUP = 'OPEN_DETAILS_POPUP';
export const CLOSE_DETAILS_POPUP = 'CLOSE_DETAILS_POPUP';

export const onOpenDetails = (details, popupType) => {
    return (dispatch) => {
        dispatch({
            type: OPEN_DETAILS_POPUP,
            details: details,
            popupType: popupType,
        });
    };
};

export const onCloseDetails = () => {
    return (dispatch) => {
        dispatch({
            type: CLOSE_DETAILS_POPUP,
        });
    };
};
