const local = {
    apiGateway: {
        clientId: 'hwL5n9oaNr31bFHnUDZxQEuptGpsRmhW',
        realm: 'CNCTeile24-Customer-Sandbox',
        domain: 'login.cnc24.com',
        apiBase: 'https://api-stg.cnc24.com/ws/api/v3',
        audience: 'https://cnc24gateway.cncteile24.xyz/',
        wizardUrl: 'https://inquiry-stg.cnc24.com/',
        googleAnalythicsId: 'G-JHP404DFMF',
        windowsClarityID: 'fbolhbdjlh',
    },
    environment: 'local',
};

const development = {
    apiGateway: {
        clientId: 'hwL5n9oaNr31bFHnUDZxQEuptGpsRmhW',
        realm: 'CNCTeile24-Customer-Sandbox',
        domain: 'login.cnc24.com',
        apiBase: 'https://api-stg.cnc24.com/ws/api/v3',
        audience: 'https://cnc24gateway.cncteile24.xyz/',
        wizardUrl: 'https://inquiry-stg.cnc24.com/',
        googleAnalythicsId: 'G-JHP404DFMF',
        windowsClarityID: 'fbolhbdjlh',
    },
    environment: 'development',
};

const staging = {
    apiGateway: {
        clientId: 'hwL5n9oaNr31bFHnUDZxQEuptGpsRmhW',
        realm: 'CNCTeile24-Customer-Sandbox',
        domain: 'login.cnc24.com',
        apiBase: 'https://api-stg.cnc24.com/ws/api/v3',
        audience: 'https://cnc24gateway.cncteile24.xyz/',
        wizardUrl: 'https://inquiry-stg.cnc24.com/',
        googleAnalythicsId: 'G-Q5SBCJS8WR',
        windowsClarityID: 'd5gubej6jm',
    },
    environment: 'staging',
};

const production = {
    apiGateway: {
        clientId: 'fyFD31MGOLeTXr2kPeZjFogLqbN8wZ4K',
        realm: 'CNCTeile24-Customer',
        domain: 'login.cnc24.com',
        apiBase: 'https://api.cnc24.com/ws/api/v3',
        audience: 'https://customer-mid.cnc24.com/',
        wizardUrl: 'https://inquiry.cnc24.com',
        googleAnalythicsId: 'G-PP8YTBCB3K',
        windowsClarityID: 'd5gtz0h8vr',
    },
    environment: 'production',
};

let config;

switch (process.env.REACT_APP_STAGE) {
    case 'development':
        config = development;
        break;
    case 'staging':
        config = staging;
        break;
    case 'production':
        config = production;
        break;
    default:
        config = local;
        break;
}

let defaultConfig = { ...config };

export default defaultConfig;
