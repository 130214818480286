import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import WizzardHeader from '../../../components/Wizzard/WizzardHeader/WizzardHeader';
import classes from './OfferSuccess.module.scss';
import { useTranslation } from 'react-i18next';

function OfferSuccess() {
    const offer = useSelector((state) => state.acceptOffer.offer);
    const reference = useSelector((state) => state.acceptOffer.reference);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if (!(offer.orderName || offer.frameworkAgreementId)) {
            navigate('/offers?type=All');
        }
    }, [offer, navigate]);
    return (
        <div className={classes['offer-success']}>
            <WizzardHeader />
            <div className={classes['offer-success__content-wrapper']}>
                <div className={classes['offer-success__content']}>
                    <h1 className={classes['offer-success__heading']}>
                        {offer.orderName
                            ? t('wizard-offers-single-order-confirm_heading')
                            : t('wizard-offers-fa-confirm_heading')}
                    </h1>
                    <p className={classes['offer-success__description']}>
                        {offer.orderName
                            ? t('wizard-offers-single-order-confirm_paragraph')
                            : t('wizard-offers-fa-confirm_paragraph')}
                    </p>
                    <div className={classes['offer-success__order-details']}>
                        <div
                            className={
                                classes['offer-success__order-details-info']
                            }
                        >
                            <h2
                                className={
                                    classes['offer-success__order-details-name']
                                }
                            >
                                {offer.orderName
                                    ? offer.orderName
                                    : offer.frameworkAgreementName
                                    ? offer.frameworkAgreementName
                                    : '-'}
                            </h2>
                            <p
                                className={
                                    classes['offer-success__order-details-id']
                                }
                            >
                                {reference ? reference : '-'}
                            </p>
                        </div>
                        <div
                            className={
                                classes[
                                    'offer-success__order-details-link-wrap'
                                ]
                            }
                        >
                            <Link
                                to={`/${
                                    offer.orderId
                                        ? 'orders'
                                        : 'framework-agreements'
                                }/${
                                    offer.orderId
                                        ? offer.orderId
                                        : offer.frameworkAgreementId
                                        ? offer.frameworkAgreementId
                                        : '-'
                                }/components`}
                            >
                                <i className="icon-arrow-right"></i>{' '}
                                {offer.orderId
                                    ? t(
                                          'wizard-offers-single-order-confirm_view-order'
                                      )
                                    : t('wizard-offers-fa-confirm_view-fa')}
                            </Link>
                        </div>
                    </div>
                    <Link
                        to={'/orders?type=Open'}
                        className={`btn btn--small btn--purple `}
                    >
                        {offer.orderId
                            ? t('wizard-offers-single-order-confirm_all-orders')
                            : t('wizard-offers-fa-confirm_all-fa')}
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default OfferSuccess;
