import { useEffect, useState } from 'react';
import classes from './DatePicker.module.scss';
import DatePickerDropdown from './DatePickerDropdown/DatePickerDropdown';
import moment from 'moment';
// import { useLocation } from 'react-router-dom';

function DatePicker({
    initialValue,
    onDateChange,
    type,
    singleDate,
    errors,
    touched,
    onBlur,
    datepickerType,
}) {
    const [showDate, setShowDate] = useState(false);
    // const location = useLocation();
    const [dates, setDates] = useState({
        startDate: initialValue.startDate,
        endDate: initialValue.endDate,
    });
    //eslint-disable-next-line
    const [dateSingle, setDateSingle] = useState();
    const [formatedDate, setFormatedDate] = useState([
        {
            startDate: moment(dates.startDate).format('DD.MM.YYYY'),
        },
        {
            endDate: moment(dates.endDate).format('DD.MM.YYYY'),
        },
    ]);

    const hasError = errors && touched;
    // const [formatedSingleDate, setFormatedSingleDate] = useState();

    // useEffect(() => {
    // setShowDate(false);
    // }, [location.search]);

    // fix for mobile height when datepicker is open
    useEffect(() => {
        const layoutContainers = document.querySelectorAll('.l-container');

        for (const layoutContainer of layoutContainers) {
            if (showDate) {
                if (type === 'offers') {
                    layoutContainer.classList.add('offersDatepicker');
                }
                layoutContainer.classList.add('minHeightRequired');
            } else {
                layoutContainer.classList.remove('minHeightRequired');
            }
        }
    }, [showDate, type]);

    useEffect(() => {
        setFormatedDate([
            {
                startDate: moment(dates.startDate).format('DD.MM.YYYY'),
            },
            {
                endDate: moment(dates.endDate).format('DD.MM.YYYY'),
            },
        ]);
    }, [dates]);

    useEffect(() => {
        setDates({
            startDate: initialValue.startDate,
            endDate: initialValue.endDate,
        });
    }, [initialValue]);

    // const dateChangeHandler = (dates) => {
    //     if (dates.startDate === undefined) return;
    //     let startDate = dates.startDate;
    //     let endDate = dates.endDate;

    //     setDates({
    //         startDate: startDate,
    //         endDate: endDate,
    //     });
    //     onDateChange({
    //         startDate: dates.startDate,
    //         endDate: dates.endDate,
    //     });
    // };

    const rangeDateChangeHandler = (dates) => {
        if (dates.startDate === undefined) return;
        let startDate = dates.startDate;
        let endDate = dates.endDate;

        setDates({
            startDate: startDate,
            endDate: endDate,
        });
        onDateChange({
            startDate: dates.startDate,
            endDate: dates.endDate,
        });
        setShowDate(false);
    };

    const singleDateChangeHandler = (date) => {
        onDateChange(date);
        setDateSingle(date);
        setShowDate(false);
    };

    const cancelSingleDateHandler = () => {
        setShowDate(false);
    };

    return (
        <div
            className={[
                classes['c-date-picker'],
                singleDate ? [classes['c-date-picker--single-date']] : null,
            ].join(' ')}
            onBlur={onBlur}
        >
            {singleDate ? (
                <button
                    className={[
                        classes['c-date-picker__action'],
                        showDate
                            ? classes['c-date-picker__action--focused']
                            : '',
                        singleDate
                            ? classes['c-date-picker__action--single-date']
                            : null,
                        hasError
                            ? classes['c-date-picker__action--error']
                            : null,
                    ].join(' ')}
                    onClick={() => {
                        setShowDate(!showDate);
                    }}
                    type="button"
                >
                    {moment(initialValue).format('DD.MM.YYYY')}
                    <i className="icon-calendar"></i>
                </button>
            ) : (
                <button
                    className={[
                        classes['c-date-picker__action'],
                        showDate
                            ? classes['c-date-picker__action--focused']
                            : '',
                    ].join(' ')}
                    onClick={() => {
                        setShowDate(!showDate);
                    }}
                    type="button"
                >
                    {formatedDate[0].startDate}
                    <i className="icon-arrow-right"></i>
                    {formatedDate[1].endDate}
                </button>
            )}
            {showDate ? (
                <DatePickerDropdown
                    // onChange={dateChangeHandler}
                    initialValue={initialValue}
                    type={type}
                    singleDate={singleDate}
                    onSingleDateChange={singleDateChangeHandler}
                    onCancelSingleDateHandler={cancelSingleDateHandler}
                    datepickerType={datepickerType}
                    onRangeDateChange={rangeDateChangeHandler}
                />
            ) : null}
            {hasError ? <div className="c-error__message">{errors}</div> : null}
        </div>
    );
}

export default DatePicker;
