export const AXIOS_INTERNAL_ERROR = 'AXIOS_INTERNAL_ERROR';
export const REMOVE_AXIOS_INTERNAL_ERROR = 'REMOVE_AXIOS_INTERNAL_ERROR';

export const onSetAxiosInternalError = () => {
    return (dispatch) => {
        dispatch({
            type: AXIOS_INTERNAL_ERROR,
        });
    };
};

export const onRemoveAxiosInternalError = () => {
    return (dispatch) => {
        dispatch({
            type: REMOVE_AXIOS_INTERNAL_ERROR,
        });
    };
};
