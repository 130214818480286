import React from 'react';
import classes from './TableHeader.module.scss';
import TableHeaderItem from './TableHeaderItem/TableHeaderItem';

function TableHeader(props) {
    return (
        <div
            className={[
                classes['c-table-header'],
                props.tableList ? classes['c-table-header--no-top-radius'] : '',
            ].join(' ')}
        >
            {props.titles?.map((title, index) => (
                <TableHeaderItem
                    key={`table-header-item-${title.id}-${index}`}
                    title={title}
                    titles={props.titles}
                    tableType={props.tableType}
                    tableList={props.tableList}
                    hasDiscount={props.hasDiscount}
                    hasOrigin={props.hasOrigin}
                />
            ))}
        </div>
    );
}

export default TableHeader;
