import React, { useEffect, useState } from 'react';
import PageMeta from '../../shared/PageMeta/PageMeta';
import { getTableData } from '../../store/thunks/tableDataThunks';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Table from '../../components/Table/Table';
import PageTitle from '../../components/PageHeader/PageTitle/PageTitle';
import { onTableContentCleanup } from '../../store/actions/tableContentActions';
import PageSearch from '../../components/PageHeader/PageSearch/PageSearch';
import lodash from 'lodash';
import PageTabs from '../../components/PageHeader/PageTabs/PageTabs';
import DatePicker from '../../components/DatePicker/DatePicker';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { months } from '../../data/data';
import classes from '../../components/Table/Table.module.scss';
import useBreakpoint from '../../hooks/useBreakpoint';
import Select from 'react-select';
import styles from './Orders.module.scss';
import setGoogleAnalytics from '../../hooks/setGoogleAnalytics';

function Orders() {
    const dispatch = useDispatch();
    const [filtersUsed, setfiltersUsed] = useState(false);
    const [loading, setLoading] = useState(true);

    const tableContent = useSelector(
        (state) => state.tableContent.tableContent
    );
    const tableContentLoading = useSelector(
        (state) => state.tableContent.tableContentLoading
    );

    const breakpoint = useBreakpoint();

    const [tableTitles, setTableTitles] = useState([]);
    const [tableData, setTableData] = useState('');
    const [dateTableData, setDateTableData] = useState('');
    const [finalDates, setFinalDates] = useState({
        startDate: moment().startOf('year'),
        endDate: moment().endOf('year'),
    });

    //eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();

    const [type, setType] = useState('');

    const { t } = useTranslation();

    useEffect(() => {
        //filter logic
        //on every search param change this gets triggered
        //dispatches thunk to get all orders
        //thunk takes search params from URL and voila
        const searchParams = new URLSearchParams(window.location.search);
        const type = searchParams.get('type');
        const startDate = searchParams.get('startDate');
        const endDate = searchParams.get('endDate');
        if (startDate && endDate) {
            setFinalDates({
                startDate: moment(startDate, 'YYYY-MM-DD'),
                endDate: moment(endDate, 'YYYY-MM-DD'),
            });
        }
        const searchFilter = searchParams.get('searchFilter');

        // check if there is any param
        const hasFilters = searchFilter || startDate || endDate;
        setfiltersUsed(hasFilters);

        if (!tableContentLoading) {
            if (type === 'Open') {
                setType({
                    label: t('orders-tab-nav_open'),
                    value: 'Open',
                });
            } else if (type === 'Sent') {
                setType({ label: t('orders-tab-nav_sent'), value: 'Sent' });
            } else if (type === 'Completed') {
                setType({
                    label: t('orders-tab-nav_completed'),
                    value: 'Completed',
                });
            } else {
                setType({ label: t('orders-tab-nav_all'), value: 'All' });
            }

            dispatch(getTableData('order'));
        }
        //eslint-disable-next-line
    }, [window.location.search]);

    useEffect(() => {
        //filtering and taking only neccessery data for table
        const filteredTableData = tableContent.map((item) => {
            return {
                id: item.orderId,
                data: {
                    deliveryDate: {
                        date: item.deliveryDate ? item.deliveryDate : '-',
                        iconType: item.iconType ? item.iconType : null,
                        iconHoverText: item.iconHoverText
                            ? item.iconHoverText
                            : null,
                    },
                    orderNr: item.orderNr ? item.orderNr : '-',
                    reference: item.reference ? item.reference : '-',
                    typeOfOrder: {
                        needTranslationItem: item.typeOfOrder
                            ? item.typeOfOrder
                            : '-',
                        translationObject: 'typeOfOrder',
                    },
                    offerNr: item.offerNr ? (
                        <span>
                            <i className="icon-contract"></i>
                            {item.offerNr}
                        </span>
                    ) : (
                        '-'
                    ),
                    nrOfParts: item.nrOfParts ? item.nrOfParts : '-',
                    nrOfDeliveries: item.nrOfDeliveries
                        ? item.nrOfDeliveries
                        : '-',
                    price: item.price ? item.price : '-',
                    status: {
                        status: item.status ? item.status : '-',
                        color: item.status ? item.status : null,
                        type: 'ordersStatus',
                    },
                },
            };
        });
        //splitting array into object of arrays with year key
        //if there's no year NaN is key
        setTableData(filteredTableData);
        let yearArray = lodash.groupBy(filteredTableData, (object) => {
            const date = new Date(object.data.deliveryDate.date);
            return date.getFullYear();
        });
        setDateTableData(Object.values(yearArray));
    }, [tableContent]);

    //sortFilter - sort filter that is going to appear in search params
    //sortFilter is not needed if sortable is false
    //sortable - whether field is sortable or not
    useEffect(() => {
        setTableTitles([
            {
                id: 1,
                name: t('orders-table-columns_delivery-date'),
                sortFilter: 'deliveryDate',
                sortable: true,
            },
            {
                id: 3,
                name: t('orders-table-columns-order_number'),
                sortFilter: 'orderNr',
                sortable: true,
            },
            {
                id: 4,
                name: t('orders-table-columns_reference'),
                sortFilter: 'reference',
                sortable: true,
            },
            {
                id: 2,
                name: t('orders-table-columns_kind-of-order'),
                sortFilter: 'typeOfOrder',
                sortable: true,
            },
            {
                id: 6,
                name: t('orders-table-columns_offer-number'),
                sortFilter: 'offerNr',
                sortable: true,
            },
            {
                id: 7,
                name: t('orders-table-columns_components'),
                sortFilter: 'nrOfParts',
                sortable: true,
            },
            {
                id: 5,
                name: t('orders-table-columns_deliveries'),
                sortFilter: 'nrOfDeliveries',
                sortable: true,
            },
            {
                id: 8,
                name: t('orders-table-columns_price-number'),
                sortFilter: 'totalPriceNet',
                sortable: true,
            },
            {
                id: 9,
                name: t('orders-table-columns_status'),
                sortable: false,
            },
        ]);
    }, [t, type]);

    const dateChangeHandler = (dates) => {
        setFinalDates({
            startDate: dates.startDate,
            endDate: dates.endDate,
        });
        if (
            (dates.endDate?._d !== finalDates.endDate?._d ||
                dates.startDate?._d !== finalDates.startDate?._d) &&
            finalDates.endDate?._d !== undefined &&
            dates.endDate?._d !== undefined
        ) {
            const searchParams = new URLSearchParams(window.location.search);

            const startDateParam = searchParams.get('startDate');
            const endDateParam = searchParams.get('endDate');

            if (startDateParam && endDateParam) {
                searchParams.set(
                    'startDate',
                    moment(dates.startDate).format('YYYY-MM-DD')
                );
                searchParams.set(
                    'endDate',
                    moment(dates.endDate).format('YYYY-MM-DD')
                );
            } else {
                searchParams.append(
                    'startDate',
                    moment(dates.startDate).format('YYYY-MM-DD')
                );
                searchParams.append(
                    'endDate',
                    moment(dates.endDate).format('YYYY-MM-DD')
                );
            }
            setSearchParams(searchParams);
        }
    };

    //cleanup
    //onTableContentCleanup sets loading to true and clears table array
    useEffect(
        () => () => {
            if (!window.location.href.includes('orders')) {
                dispatch(onTableContentCleanup());
            }
        },
        //eslint-disable-next-line
        []
    );

    useEffect(() => {
        // react ga4 for orders overview
        setGoogleAnalytics(
            'page_view',
            'page_view_order_overview',
            'view order overview page, regardless of tabs'
        );
    }, []);

    const orderTabs = [
        {
            id: '1',
            label: t('orders-tab-nav_open'),
            value: 'Open',
        },
        {
            id: '2',
            label: t('orders-tab-nav_sent'),
            value: 'Sent',
        },
        {
            id: '3',
            label: t('orders-tab-nav_completed'),
            value: 'Completed',
        },
        {
            id: '3',
            label: t('orders-tab-nav_all'),
            value: 'All',
        },
    ];

    const onTypeChange = (selectedType) => {
        const params = new URLSearchParams();
        const currentParams = new URLSearchParams(window.location.search);
        const type = currentParams.get('type');
        const date = currentParams.get('startDate');
        if (!date) {
            if (type === 'Completed' || type === 'All') {
                params.set('startDate', '2023-01-01');
                params.set('endDate', '2023-12-31');
            }
        }
        params.set(
            'type',
            type === selectedType.value ? 'All' : selectedType.value
        );
        setSearchParams(params);
    };

    // TODO REWORK WHEN DITTO WORKS AGAIN
    const getEmptyStateMessage = (whoseState, stateType, statePlace) => {
        const emptyStatesObject = t('emptyState');
        const emptyStateJson = JSON.parse(emptyStatesObject);
        return emptyStateJson[whoseState][stateType][statePlace];
    };

    // fix for table empty state showing briefly after loading
    useEffect(() => {
        setLoading(tableContentLoading);
    }, [tableContentLoading]);

    return (
        <>
            <PageMeta>
                <title>{t('orders_heading')} | CNC24</title>
            </PageMeta>

            <div className={[styles['c-orders'], 'l-container'].join(' ')}>
                <PageTitle title={t('orders_heading')} />
                <div className="l-container__actions">
                    <PageSearch type="orders" />
                    <div className="l-container__tabs-picker">
                        {(type.value === 'Completed' ||
                            type.value === 'All') && (
                            <DatePicker
                                onDateChange={dateChangeHandler}
                                initialValue={finalDates}
                                type="orders"
                                datepickerType="rangeDate"
                            />
                        )}
                        {breakpoint > 575 ? (
                            <PageTabs tabs={orderTabs} pageName="orders" />
                        ) : (
                            <Select
                                className="c-react-select c-react-select--max-492"
                                name="language-selector"
                                options={orderTabs}
                                onChange={onTypeChange}
                                value={type}
                                classNamePrefix="c-react-select"
                            />
                        )}
                    </div>
                </div>
                {type.value === 'Open' ? (
                    <>
                        {!loading && dateTableData.length ? (
                            dateTableData.map((year) => {
                                let date = new Date(
                                    year[0].data.deliveryDate?.date
                                );
                                let tableYear = date.getFullYear();
                                let monthObject = lodash.groupBy(
                                    year,
                                    (object) => {
                                        const date = new Date(
                                            object.data.deliveryDate?.date
                                        );
                                        return date.getMonth();
                                    }
                                );
                                let monthArray = Object.values(monthObject);
                                return (
                                    <div
                                        className={
                                            classes['c-table__table-info']
                                        }
                                        key={`year-${tableYear}`}
                                    >
                                        {tableYear ? (
                                            <h2
                                                className={
                                                    classes['c-table__year']
                                                }
                                            >
                                                {tableYear}
                                            </h2>
                                        ) : null}
                                        {monthArray.map((item) => {
                                            let date = new Date(
                                                item[0].data.deliveryDate?.date
                                            );
                                            let month = date.getMonth();
                                            return (
                                                <div
                                                    key={`month-${item[0].data.deliveryDate.date}`}
                                                >
                                                    <h3
                                                        className={
                                                            classes[
                                                                'c-table__month'
                                                            ]
                                                        }
                                                    >
                                                        {months[month]}
                                                    </h3>
                                                    <Table
                                                        tableTitles={
                                                            tableTitles
                                                        }
                                                        tableData={item}
                                                        tableType="orders"
                                                        emptyStHeading={
                                                            filtersUsed
                                                                ? `${t(
                                                                      'orders-empty-state_heading'
                                                                  )}`
                                                                : 'ordersInitialEmptyStateHeading'
                                                        }
                                                        emptyStText={
                                                            filtersUsed
                                                                ? `${t(
                                                                      'orders-empty-state_paragraph'
                                                                  )}`
                                                                : 'ordersInitialEmptyStateParagraph'
                                                        }
                                                        emptyStBtnText=""
                                                        linkableRows={true}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })
                        ) : (
                            <>
                                {dateTableData.length === 0 &&
                                !loading ? null : (
                                    <div
                                        className={
                                            classes['c-table__table-info']
                                        }
                                    >
                                        <span
                                            className={`${classes['c-table__year']} ${classes['c-table__year--skeleton-loader']}`}
                                        ></span>

                                        <span
                                            className={`${classes['c-table__month']} ${classes['c-table__month--skeleton-loader']}`}
                                        ></span>
                                    </div>
                                )}

                                <Table
                                    tableTitles={tableTitles}
                                    tableData={tableData}
                                    loading={loading}
                                    tableType="orders"
                                    emptyStHeading={
                                        filtersUsed
                                            ? `${t(
                                                  'orders-empty-state_heading'
                                              )}`
                                            : getEmptyStateMessage(
                                                  'ordersEmptyState',
                                                  'InitialEmptyState',
                                                  'heading'
                                              )
                                    }
                                    emptyStText={
                                        filtersUsed
                                            ? `${t(
                                                  'orders-empty-state_paragraph'
                                              )}`
                                            : getEmptyStateMessage(
                                                  'ordersEmptyState',
                                                  'InitialEmptyState',
                                                  'text'
                                              )
                                    }
                                    emptyStBtnText=""
                                    linkableRows={true}
                                />
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <Table
                            tableTitles={tableTitles}
                            tableData={tableData}
                            loading={loading}
                            tableType="orders"
                            emptyStHeading={
                                filtersUsed
                                    ? `${t('orders-empty-state_heading')}`
                                    : getEmptyStateMessage(
                                          'ordersEmptyState',
                                          'InitialEmptyState',
                                          'heading'
                                      )
                            }
                            emptyStText={
                                filtersUsed
                                    ? `${t('orders-empty-state_paragraph')}`
                                    : getEmptyStateMessage(
                                          'ordersEmptyState',
                                          'InitialEmptyState',
                                          'text'
                                      )
                            }
                            emptyStBtnText=""
                            linkableRows={true}
                        />
                    </>
                )}
            </div>
        </>
    );
}

export default Orders;
