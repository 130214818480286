export const GET_TABLE_CONTENT = 'GET_TABLE_CONTENT';
export const GET_TABLE_CONTENT_LOADING = 'GET_TABLE_CONTENT_LOADING';
export const TABLE_CONTENT_CLEANUP = 'TABLE_CONTENT_CLEANUP'

export const onGetTableContent = orders => {
    return dispatch => {
        dispatch({
            type: GET_TABLE_CONTENT,
            orders: orders
        })
    }
}

export const onGetTableContentLoading = () => {
    return dispatch => {
        dispatch({
            type: GET_TABLE_CONTENT_LOADING,
        })
    }
}

export const onTableContentCleanup = () => {
    return dispatch => {
        dispatch({
            type: TABLE_CONTENT_CLEANUP
        })
    }
}