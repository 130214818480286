import axios from 'axios';
import config from '../config';
import store from '../store/store';
import { onSetAxiosInternalError } from '../store/actions/axiosErrorActions';
// import setBodyWidth from '../functions/setBodyWidth';

axios.defaults.baseURL = `${config.apiGateway.apiBase}`;

// set header token
axios.interceptors.request.use(
    (request) => {
        // Do something before request is sent

        let modifiedRequest = { ...request };
        const token = localStorage.getItem('token');
        //don't add token to header if AWS upload!
        if (
            !request.url.includes('amazonaws') &&
            !request.url.includes('forgotPassword')
        ) {
            // if (token) {
            let xUserId = localStorage.getItem('X-Track-Id');

            modifiedRequest.headers = {
                ...modifiedRequest.headers,
                authorization: 'Bearer ' + token,
            };

            //if single sign on user id add x-track-id and noTrack param
            if (xUserId) {
                modifiedRequest.headers = {
                    ...modifiedRequest.headers,
                    'X-Track-Id': xUserId,
                };
                if (request.url.includes('customer/userInfo')) {
                    if (modifiedRequest.params) {
                        modifiedRequest.params?.append('noTrack', true);
                    } else {
                        modifiedRequest.params = { noTrack: true };
                    }
                }
            }
            // }
        }

        return modifiedRequest;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// remove token when session is expired
axios.interceptors.response.use(
    (response) => {
        // set token for every reposnse
        // setTimeout(() => {
        //     setBodyWidth();
        // }, 1);
        return response;
    },
    (err) => {
        const originalRequest = err.config;
        if (
            err.response?.status === 401 &&
            !originalRequest._retry &&
            err.config.url !== '/customer/account/verifyEmailCode' &&
            err.config.url !==
                '/customer/account/requestEmailVerificationCode' &&
            err.config.url !== 'https://login.cnc24.com/dbconnections/signup' &&
            err.config.url !== 'https://login.cnc24.com/oauth/token'
        ) {
            localStorage.setItem('redirectErrorMessage', 'sessionExpired');
            localStorage.removeItem('token');
            localStorage.removeItem('X-Track-Id');
            window.location.assign(`${window.location.origin}/login`);
        } else if (err.response?.status === 404 && !originalRequest._retry) {
            window.location.href = '/404';
        } else if (
            err.response?.status >= 400 &&
            err.response?.status <= 499 &&
            err.response?.status !== 404 &&
            err.config.url !== '/customer/account/verifyEmailCode' &&
            err.config.url !==
                '/customer/account/requestEmailVerificationCode' &&
            err.config.url !== 'https://login.cnc24.com/dbconnections/signup' &&
            err.config.url !== 'https://login.cnc24.com/oauth/token'
        ) {
            localStorage.removeItem('token');
            localStorage.removeItem('X-Track-Id');
            window.location.assign(`${window.location.origin}/login`);
        } else if (err.response?.status === 500) {
            if (err.config?.url !== 'customer/userInfo') {
                store.dispatch(onSetAxiosInternalError());
            }
        }
        return Promise.reject(err);
    }
);

/**
 * ApiService is used for rest api integration.
 * Function should get data or params.
 * Response is handling in component where ApiService is called.
 */
class ApiService {
    static getExampleData() {
        return axios.get('entries');
    }

    static logIn(data) {
        return axios.post(
            `https://${config.apiGateway.domain}/oauth/token`,
            data
        );
    }

    static magicLogin(data) {
        return axios.post(
            `https://${config.apiGateway.domain}/passwordless/start`,
            data
        );
    }

    static getUser(params) {
        return axios.get(`customer/userInfo`, { params: params });
    }

    static resetPassword(data) {
        return axios.patch(`customer/account/forgotPassword`, data);
    }

    static changeLanguage(data) {
        return axios.patch(`customer/updateLanguage`, data);
    }

    static getTableData(dataType, params) {
        return axios.get(`customer/${dataType}`, { params: params });
    }

    static getDetails(type, id, params) {
        return axios.get(`customer/${type}/${id}`, { params: params });
    }

    static uploadUrl(data) {
        return axios.patch('customer/uploadUrl', data);
    }

    static uploadFile(url, file) {
        return axios.put(
            url,
            {
                headers: {
                    'Content-Disposition': `attachment; filename=${file.name};`,
                },
                contentType: 'binary/octet-stream',
            },
            file
        );
    }

    static acceptOffer(data) {
        return axios.patch('customer/acceptOfferProcess', data);
    }

    static acceptFa(data) {
        return axios.post('customer/createFaBatchOrder', data);
    }

    static getTax(params) {
        return axios.get('/customer/tax', { params: params });
    }

    static userSignUp(data) {
        return axios.post(
            `https://${config.apiGateway.domain}/dbconnections/signup`,
            data
        );
    }

    static requestEmailVerificationCode(data) {
        return axios.post(
            '/customer/account/requestEmailVerificationCode',
            data
        );
    }

    static sendVerificationCode(data) {
        return axios.post('/customer/account/verifyEmailCode', data);
    }

    static getLeadInProgress(recordId) {
        return axios.get('customer/leadInProgress', { params: { recordId } });
    }

    static updateContactPortalRole(data) {
        return axios.patch('customer/updateContactPortalRole', data);
    }

    static updateDefaultContacts(data) {
        return axios.patch('customer/updateDefaultContacts', data);
    }

    static inviteContact(data) {
        return axios.post('customer/inviteContact', data);
    }

    static checkContact(params) {
        return axios.get('customer/checkContact', { params: params });
    }
}

export default ApiService;
