import * as actionTypes from '../actions/axiosErrorActions';

const initialState = {
    axiosInternalError: false,
};

const acceptOffer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AXIOS_INTERNAL_ERROR:
            return {
                ...state,
                axiosInternalError: true,
            };
        case actionTypes.REMOVE_AXIOS_INTERNAL_ERROR:
            return {
                ...state,
                axiosInternalError: false,
            };
        default:
            return state;
    }
};

export default acceptOffer;
