import { useRef } from 'react';
import useIsOverflow from '../../../../hooks/useIsOverflow';
import classes from '../DetailsSideScreen.module.scss';

function DisplayedFile({ index, data, file, details, pdf, fileType }) {
    const ref = useRef();
    const hasOverflow = useIsOverflow(ref, (isOverflowFromCallback) => {
        // true
    });

    const downloadPdf = (index) => {
        let link = document.createElement('a');
        link.href = data.files[index]?.fileDownloadUrl;
        link.download = data.files[index]?.fileName;
        link.click();
    };

    return (
        <div
            key={`pdf-${index}`}
            className={
                classes['c-details-sidescreen__download-pdf-container-wrapper']
            }
        >
            <div
                className={`${classes['c-details-sidescreen__download-pdf-container']} ${classes['c-details-sidescreen__download-pdf-container--uploaded']}`}
            >
                <div
                    className={
                        classes['c-details-sidescreen__uploaded-file-wrapper']
                    }
                >
                    {hasOverflow && (
                        <div
                            className={
                                classes[
                                    'c-details-sidescreen__uploaded-file-full-name-wrapper'
                                ]
                            }
                        >
                            <div
                                className={
                                    classes[
                                        'c-details-sidescreen__uploaded-file-full-name'
                                    ]
                                }
                            >
                                {file.fileName}
                            </div>
                        </div>
                    )}
                    <span
                        ref={ref}
                        key={index}
                        onClick={
                            details.files?.length
                                ? () => {
                                      downloadPdf(index);
                                  }
                                : null
                        }
                        className={
                            classes['c-details-sidescreen__download-pdf']
                        }
                    >
                        {file.fileName}
                    </span>
                    <i className={`icon-${fileType}`}></i>
                </div>
            </div>
        </div>
    );
}

export default DisplayedFile;
