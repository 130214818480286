import Button from '../../Button/Button';
import WizzardHeaderItem from './WizzardHeaderItem.js/WizzardHeaderItem';
import { onToggleWizzard } from '../../../store/actions/offersWizzardActions';
import { onToggleFrameworkWizzard } from '../../../store/actions/frameworkWizzardActions';
import { useDispatch, useSelector } from 'react-redux';
import classes from './WizzardHeader.module.scss';
import cncLogo from '../../../images/cncLogoBlack.svg';
import { useTranslation } from 'react-i18next';
import useBreakpoint from '../../../hooks/useBreakpoint';
import { useNavigate } from 'react-router-dom';

function WizzardHeader({ items, active, totalStep, isValid, type }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const navigate = useNavigate();
    const offersWizzard = useSelector((state) => state.offersWizzardReducer);
    const frameworkWizzard = useSelector(
        (state) => state.frameworkWizzardReducer
    );

    const handleCancelWizzard = () => {
        if (type === 'offers') {
            dispatch(onToggleWizzard());
            navigate(`/offers/${offersWizzard.details.offerId}/components`);
        } else if (type === 'framework-agreements') {
            dispatch(onToggleFrameworkWizzard());
            navigate(
                `/framework-agreements/${frameworkWizzard.details.faId}/components?type=Overview`
            );
        }
        localStorage.removeItem('wizardType');
    };

    const offerSuccesPage = window.location.href.includes('offer-success');

    return (
        <div className={classes['wizzard-header']}>
            <div className={classes['wizzard-header__content']}>
                <img
                    src={cncLogo}
                    className={classes['wizzard-header__logo']}
                    alt="CNC Logo"
                />
                <div className={classes['wizzard-header__item-wrapper']}>
                    {items?.map((item, index) => {
                        return (
                            <WizzardHeaderItem
                                key={`wizzard-header-item-${index}`}
                                item={item}
                                active={active}
                                index={index + 1}
                                totalStep={totalStep}
                            />
                        );
                    })}
                </div>
                {!offerSuccesPage && (
                    <div className={classes['wizzard-header__actions']}>
                        <a
                            href="https://www.cnc24.com/contact/"
                            aria-label="Go to help page"
                            className={classes['wizzard-header__link']}
                            external="true"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <i className="icon-question-mark"></i>
                            {breakpoint > 768 ? (
                                <span>{t('wizard-header_help')}</span>
                            ) : null}
                        </a>
                        {breakpoint > 768 ? (
                            <Button
                                className={`btn btn--small btn--transparent btn--transparent-grey ${classes['wizzard-header__cancel-btn']}`}
                                onClick={() => handleCancelWizzard()}
                            >
                                {t('wizard-header_cancel-btn')}
                            </Button>
                        ) : null}
                        {breakpoint < 769 ? (
                            <button
                                className={
                                    classes['wizzard-header__cancel-btn-x']
                                }
                            >
                                <i
                                    className="icon-x-sign"
                                    onClick={() => handleCancelWizzard()}
                                ></i>
                            </button>
                        ) : null}
                    </div>
                )}
            </div>
            <span
                className={`${classes['wizzard-header__orange-line']} ${
                    active
                        ? classes[`wizzard-header__orange-line--${active}`]
                        : ''
                }`}
            ></span>
        </div>
    );
}

export default WizzardHeader;
