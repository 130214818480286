export const ACCEPT_OFFER = 'ACCEPT_OFFER';

export const onOfferSuccess = (offer, reference) => {
    return (dispatch) => {
        dispatch({
            type: ACCEPT_OFFER,
            offer: offer,
            reference: reference,
        });
    };
};
