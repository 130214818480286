import SidebarDataContent from '../../../SkeletonLoader/SidebarDataContent';
import SidebarDataTitleLabel from '../../../SkeletonLoader/SidebarDataTitleLabel';
import classes from '../DetailsSideScreen.module.scss';

function Inquiry({ details, loading, t }) {
    return (
        <div className={classes['c-details-sidescreen__detail']}>
            <SidebarDataTitleLabel
                loading={loading}
                labelName={t('order-details-sidebar_offer')}
            />

            <SidebarDataContent
                loading={loading}
                data={
                    details.offerId && details.offerNr ? (
                        <a
                            href={`${window.location.origin}/offers/${details.offerId}/components`}
                            target="_blank"
                            rel="noreferrer"
                            className={classes['c-details-sidescreen__link']}
                        >
                            <i className="icon-contract"></i> {details.offerNr}
                        </a>
                    ) : (
                        '-'
                    )
                }
            />
        </div>
    );
}

export default Inquiry;
