import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { onCloseDetails } from '../../../store/actions/detailsPopupActions';
import { useNavigate } from 'react-router-dom';

import classes from './DetailsPopup.module.scss';
import OffersPopup from './OffersPopup/OffersPopup';
import OrdersPopup from './OrdersPopup/OrdersPopup';

function DetailsPopup({ type, open }) {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const popupType = useSelector((state) => state.detailsPopup.popupType);

    const { order, id } = useParams();

    const handlePopupClose = () => {
        if (window.location.href.includes('framework-agreement')) {
            if (window.location.href.includes('Overview')) {
                navigate(
                    `/framework-agreements/${order}/components?type=Overview`
                );
            } else {
                navigate(
                    `/framework-agreements/${order}/components?type=Contract_Details`
                );
            }
        } else if (window.location.href.includes('orders')) {
            if (window.location.href.includes('deliveries')) {
                navigate(`/orders/${id}/deliveries`);
            } else {
                navigate(`/orders/${id}/components`);
            }
        } else if (window.location.href.includes('offers')) {
            navigate(`/offers/${id}/components`);
        }
        dispatch(onCloseDetails());
    };

    //cleanup
    useEffect(
        () => () => {
            dispatch(onCloseDetails());
        },
        //eslint-disable-next-line
        []
    );

    return (
        <>
            {open && (
                <div
                    onClick={handlePopupClose}
                    className={`${classes['details-popup__backdrop']}`}
                ></div>
            )}
            <div
                className={`${classes['details-popup']} ${
                    open ? classes['details-popup--show'] : ''
                }`}
            >
                {popupType === 'offersSingleOrder' ||
                popupType === 'orderComponents' ||
                popupType === 'offerFrameworkOrder' ||
                popupType === 'frameworkComponents' ||
                popupType === 'orderDeliveryPopup' ||
                popupType === 'frameworkAgreementsOrders' ||
                popupType === 'frameworkComponentsDetails' ? (
                    <OffersPopup open={open} />
                ) : null}
                {popupType === 'order-deliveries' ? (
                    <OrdersPopup open={open} type={type} />
                ) : null}
            </div>
        </>
    );
}

export default DetailsPopup;
