import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import config from '../../../config';
import Input from '../../../components/Input/Input/Input';
import { resetPasswod } from '../../../store/actions/userActions';
import { useDispatch } from 'react-redux';
import Button from '../../../components/Button/Button';
import { useTranslation } from 'react-i18next';
import classes from '../Login/Login.module.scss';
import { useNavigate } from 'react-router-dom';
import FormError from '../../../shared/FormError/FormError';
// import config from '../../../config';
import PageMeta from '../../../shared/PageMeta/PageMeta';
import AuthWrapper from '../../../components/AuthWrapper/AuthWrapper';

function ResetPassword() {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { t } = useTranslation();

	const validationSchema = Yup.object().shape({
		email: Yup.string()
			.email()
			.required(`${t('error-field-required')}`),
	});

	const initialValues = {
		email: '',
	};

	const submitRegister = (values) => {
		setLoading(true);
		setError('');
		dispatch(resetPasswod(values))
			.then((r) => {
				setLoading(false);
				navigate('/reset-success');
			})
			.catch((e) => {
				setLoading(false);
				// setError(t('invalid-email-error-message'));
				navigate('/reset-success');
			});
	};

	const onGoBack = (e) => {
		e.preventDefault();
		navigate('/login');
	};

	return (
		<>
			<PageMeta>
				<title>{t('login_header')} | CNC24</title>
			</PageMeta>
			<AuthWrapper>
				<div className={classes['c-login']}>
					<div className={classes['c-login__container-wrapper']}>
						<div className={classes['c-login__container']}>
							<h1 className={classes['c-login__title']}>
								{t('reset-password_header')}
							</h1>
							<p className={classes['c-login__description']}>
								{t('reset-password_paragraph')}
							</p>
							<Formik
								initialValues={initialValues}
								validationSchema={validationSchema}
								onSubmit={submitRegister}
							>
								{(props) => (
									<Form>
										{error ? (
											<FormError>{error}</FormError>
										) : null}
										<Input
											type='email'
											label={t(
												'reset-password_email-label'
											)}
											name='email'
											errors={props.errors.email}
											touched={props.touched.email}
										/>
										<button
											type='button'
											onClick={onGoBack}
											className={
												classes['c-login__button-link']
											}
											aria-label={t(
												'reset-password_back-to-login'
											)}
										>
											{t(
												'reset-password_back-to-login-page-link'
											)}
										</button>
										<Button
											disabled={
												!(
													props.isValid && props.dirty
												) || loading
											}
											className={`btn btn--purple btn--big btn--full-width ${
												loading ? 'btn--loading' : ''
											}`}
											ariaLabel={t(
												'reset-password_button'
											)}
											type='submit'
										>
											{t('reset-password_button')}
											{loading ? (
												<span className='loader'></span>
											) : null}
										</Button>
									</Form>
								)}
							</Formik>
							<p className={classes['c-login__not-member']}>
								{t(
									'reset-password_go-to-register-page-paragraph-text'
								)}
								<br />
								<a
									href={config.apiGateway.wizardUrl}
									target='_blank'
									rel='noreferrer'
									aria-label='Hier unverbindliches Angebot einholen.'
								>
									{t(
										'reset-password_go-to-register-page-link'
									)}
								</a>
							</p>
						</div>
					</div>
				</div>
			</AuthWrapper>
		</>
	);
}

export default ResetPassword;
