import React from 'react';
import PageTitle from './PageTitle/PageTitle';
import Tabs from './Tabs/Tabs';
import { useTranslation } from 'react-i18next';

function PageHeader() {
    const { t } = useTranslation();

    return (
        <div>
            <PageTitle title={`${t('account_heading')}`} />
            <Tabs
                tabs={[
                    {
                        name: t('account-tab_contacts'),
                        link: 'contacts',
                    },
                    {
                        name: t('account-tab_language'),
                        link: 'language',
                    },
                ]}
            />
        </div>
    );
}

export default PageHeader;
