import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguage } from '../../../store/actions/userActions';
import Button from '../../../components/Button/Button';
import FormError from '../../../shared/FormError/FormError';
import PageMeta from '../../../shared/PageMeta/PageMeta';
import classes from './Language.module.scss';
import setGoogleAnalytics from '../../../hooks/setGoogleAnalytics';

function Language() {
    const { t } = useTranslation();
    //eslint-disable-next-line
    const [cookie, setCookie] = useCookies();
    const [language, setLanguage] = useState('');
    const [buttonLoading, setButtonLoading] = useState(false);
    const [error, setError] = useState('');
    const [hasChanges, setHasChanges] = useState(false);

    const dispatch = useDispatch();
    const userReducer = useSelector((state) => state.user);

    useEffect(() => {
        if (userReducer.user.lang === 'English') {
            setLanguage({ label: t('lang_english'), value: 'English' });
        } else {
            setLanguage({ label: t('lang_deutsch'), value: 'Deutsch' });
        }
    }, [userReducer, t]);

    const onLanguageChange = (e) => {
        setLanguage(e);
        setHasChanges(e.value !== userReducer.user.lang);
    };

    const onSaveLanguage = () => {
        setButtonLoading(true);
        setError('');
        let langCookie = language.value === 'English' ? 'en' : 'de';
        const data = {
            language: language.value,
        };
        dispatch(changeLanguage(data))
            .then((r) => {
                setCookie('lang', langCookie);
                setButtonLoading(false);
                setHasChanges(false);
                window.location.reload();
            })
            .catch((e) => {
                setButtonLoading(false);
                setError(t('error_form-general'));
            });
    };

    // reactga4 for settings language tab
    useEffect(() => {
        setGoogleAnalytics(
            'page_view',
            'page_view_setting_language',
            'view setting page, language section'
        );
    }, []);

    const languageOptions = [
        {
            value: 'English',
            label: t('lang_english'),
        },
        {
            value: 'Deutsch',
            label: t('lang_deutsch'),
        },
    ];

    return (
        <>
            <PageMeta>
                <title>Language | CNC24</title>
            </PageMeta>
            <div className={classes['c-language']}>
                {/* <h2>{t('data.SETTINGS_LANGUAGELABEL')}</h2> */}
                {error ? <FormError>{error}</FormError> : null}
                <div className={classes['c-language__actions']}>
                    <Select
                        className="c-react-select c-react-select--max-492"
                        name="language-selector"
                        options={languageOptions}
                        onChange={onLanguageChange}
                        value={language}
                        classNamePrefix="c-react-select"
                    />
                    <Button
                        onClick={onSaveLanguage}
                        disabled={!hasChanges || buttonLoading}
                        className={`btn btn--purple btn--small btn--117  ${
                            classes['c-language__save-btn']
                        } ${buttonLoading ? 'btn--loading' : ''}`}
                        ariaLabel={t('lang_save-btn')}
                    >
                        {t('lang_save-btn')}
                        {buttonLoading ? (
                            <span className="loader"></span>
                        ) : null}
                    </Button>
                </div>
            </div>
        </>
    );
}

export default Language;
