import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button/Button';
import AditionalInfoIcon from '../../../../../components/Icons/AditionalInfoIcon';
import SidebarDataContent from '../../../../../components/SkeletonLoader/SidebarDataContent';
import Tooltip from '../../../../../components/Tooltip/Tooltip';
import classes from './ContactInfo.module.scss';

function ContactInfo({
    label,
    labelIcon,
    hasBackground,
    contact,
    smallLabelMargin,
    noLoading,
}) {
    const { t } = useTranslation();

    return (
        <>
            <div
                className={`${classes['c-contact-info__label']} ${
                    label === 'Invoicing' || label === 'Order Confirmation'
                        ? classes['c-contact-info__label--invoicing-confirm']
                        : ''
                } ${
                    smallLabelMargin
                        ? classes['c-contact-info__label--small-margin']
                        : ''
                }`}
            >
                {label}{' '}
                {labelIcon ? (
                    labelIcon === 'AditionalInfoIcon' ? (
                        <span
                            className={
                                classes['c-contact-info__additional-info']
                            }
                        >
                            <Button ariaLabel="Aditional info">
                                <AditionalInfoIcon />
                            </Button>
                            <Tooltip
                                text={t('contacts_main-contact-popup')}
                                extraClass="c-tooltip--small"
                            />
                        </span>
                    ) : (
                        labelIcon
                    )
                ) : null}
            </div>
            <div
                className={[
                    classes['c-contact-info__contact'],
                    hasBackground
                        ? classes['c-contact-info__contact--with-background']
                        : '',
                ].join(' ')}
            >
                <div>
                    <div className={classes['c-contact-info__contact-name']}>
                        <SidebarDataContent
                            data={contact?.name ? contact?.name : '-'}
                            noLoading={noLoading}
                        />
                    </div>
                    {contact?.email ? (
                        <div
                            className={classes['c-contact-info__contact-email']}
                        >
                            {contact?.email}
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
}

ContactInfo.defaultProps = {
    label: '',
    labelIcon: '',
    name: '',
    email: '',
    hasBackground: false,
    smallLabelMargin: false,
    noLoading: false,
};

export default ContactInfo;
