import classes from './Sidebar.module.scss';
import { Link } from 'react-router-dom';
import SidebarFooter from './SidebarFooter';
import SidebarHeader from './SidebarHeader';
import cncLogo from '../../images/cncLogo.svg';

function Sidebar() {
    return (
        <div className={classes.sidebar}>
            <div className={classes['sidebar__header-logo']}>
                <Link to="/">
                    <img src={cncLogo} alt="Cnc logo" width="135" height="32" />
                </Link>
            </div>
            <div className={classes['sidebar__menu']}>
                <SidebarHeader />
                <SidebarFooter />
            </div>
        </div>
    );
}

export default Sidebar;
