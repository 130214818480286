import { useState } from 'react';
import classes from './UploadedFile.module.scss';

function UploadedFile({ fileName, fileId, extraClass, onDelete }) {
    const [deleteAnim, setDeleteAnim] = useState(false);

    const onHandleDelete = () => {
        setDeleteAnim(true);

        setTimeout(() => {
            onDelete(fileId);
            setDeleteAnim(false);
        }, 300);
    };

    return (
        <li
            className={`${[
                classes['c-uploaded-file'],
                extraClass ? classes[`c-uploaded-file__${extraClass}`] : null,
            ].join(' ')} ${
                deleteAnim ? classes['c-uploaded-file__delete-anim'] : ''
            }`}
        >
            <div className={classes['c-uploaded-file__icon-name']}>
                <i className="icon-pdf"></i>
                <span className={classes['c-uploaded-file__file-name']}>
                    {fileName}
                </span>
            </div>
            {onDelete ? (
                <button onClick={() => onHandleDelete()} type="button">
                    <i className="icon-x-sign"></i>
                </button>
            ) : null}
        </li>
    );
}

export default UploadedFile;
