import { ErrorMessage, Field } from 'formik';

function InputWithCustomEvent(props) {
    const hasError = props.errors && props.touched;
    const formatedProps = {
        ...props,
        //hackz
        touched: 'not-needed',
    };

    return (
        <div
            className={`c-input ${
                props.hascustomerror === 'true' ? 'c-input__error' : ''
            } ${props.disabled ? 'c-input--readonly' : ''}`}
        >
            <label>
                {props.label}{' '}
                {props.inputisrequired === 'true' && (
                    <span
                        className={`c-input__required-error ${
                            hasError ? 'c-input__required-error--red' : ''
                        }`}
                    >
                        *
                    </span>
                )}
                <Field {...formatedProps} disabled={props.disabled}></Field>
            </label>
            {hasError && props.hideerror !== 'true' && (
                <div
                    className={`c-input__error-message ${
                        hasError ? 'active' : ''
                    }`}
                >
                    <ErrorMessage name={props.name} />
                </div>
            )}
        </div>
    );
}

export default InputWithCustomEvent;
