import NavbarLink from '../NavbarLink/NavbarLink';
import classes from './Sidebar.module.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import File2Icon from '../../components/Icons/File2Icon';
import NoteWithCheckmark from '../../components/Icons/NoteWithCheckmark';
import TwoPapersIcon from '../../components/Icons/TwoPapersIcon';
// import DashboardIcon from '../../components/Icons/DashboardIcon';

const SidebarHeader = () => {
    const { t } = useTranslation();
    const tableContentLoading = useSelector(
        (state) => state.tableContent.tableContentLoading
    );

    return (
        <div className={classes.sidebar__header}>
            <div className={classes['sidebar__header-links']}>
                {/* <NavbarLink
                    to="/dashboard"
                    icon={<DashboardIcon />}
                    linkName={t('dashboard_dashboard')}
                    ariaLabel="go to dashboard"
                    disabled={tableContentLoading}
                /> */}
                <NavbarLink
                    to="/offers?type=All"
                    icon={<File2Icon />}
                    linkName={t('main-navigation_offers')}
                    ariaLabel="go to offers page"
                    disabled={tableContentLoading}
                />
                <NavbarLink
                    to="/orders?type=Open"
                    icon={<NoteWithCheckmark />}
                    linkName={t('main-navigation_orders')}
                    ariaLabel={'go to orders page'}
                    disabled={tableContentLoading}
                />
                <NavbarLink
                    to="/framework-agreements?type=Active"
                    icon={<TwoPapersIcon />}
                    linkName={t('main-navigation_framework-agreements')}
                    ariaLabel={'go to framework contracts page'}
                    disabled={tableContentLoading}
                />
            </div>
        </div>
    );
};

export default SidebarHeader;
