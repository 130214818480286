import * as actionTypes from '../actions/frameworkWizzardActions';

const initialState = {
    open: false,
    details: {},
    currentStep: 1,
    totalStep: 1,
    frameworkLines: [],
    frameworkLinesQuantityArr: [],
    sameAddress: true,
    data: {
        faId: '',
        deliveryDate: '',
        faReference: '',
        faLines: [],
        totalPrice: '',
        addresses: {
            billingAddress: {
                city: '',
                companyName: '',
                country: '',
                postalCode: '',
                street: '',
                additionalInfo: '',
                taxNumber: '',
                contactInvoicing: '',
            },
            shippingAddress: {
                city: '',
                companyName: '',
                country: '',
                postalCode: '',
                street: '',
                additionalInfo: '',
            },
        },
        defaultContacts: {
            contactPurchasing: '',
            contactInvoicing: '',
            contactOrderConfirmation: '',
        },
    },
    tax: null,
};

const frameworkWizzardReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.TOGGLE_FRAMEWORK_WIZZARD:
            return {
                ...state,
                open: !state.open,
                currentStep: 1,
                totalStep: 1,
                data: {
                    faId: '',
                    deliveryDate: '',
                    faReference: '',
                    faLines: [],
                    sameAddress: true,
                    addresses: {
                        billingAddress: {
                            city: '',
                            companyName: '',
                            country: '',
                            postalCode: '',
                            street: '',
                            additionalInfo: '',
                            taxNumber: '',
                            contactInvoicing: '',
                        },
                        shippingAddress: {
                            city: '',
                            companyName: '',
                            country: '',
                            postalCode: '',
                            street: '',
                            additionalInfo: '',
                        },
                    },
                    defaultContacts: {
                        contactPurchasing: '',
                        contactInvoicing: '',
                        contactOrderConfirmation: '',
                    },
                },
            };
        case actionTypes.GET_FRAMEWORK_WIZZARD_DETAILS:
            let frameworkLinesQuantityArr = [];
            action.details.frameworkLines.forEach((item) => {
                frameworkLinesQuantityArr.push(item.quantityPerBatches);
            });
            return {
                ...state,
                details: action.details,
                frameworkLines: action.details.frameworkLines,
                frameworkLinesQuantityArr: frameworkLinesQuantityArr,
            };
        case actionTypes.STEP_UP:
            return {
                ...state,
                currentStep: state.currentStep + 1,
                totalStep:
                    state.currentStep === state.totalStep
                        ? state.totalStep + 1
                        : state.totalStep,
            };
        case actionTypes.STEP_DOWN:
            return {
                ...state,
                currentStep: state.currentStep - 1,
            };
        case actionTypes.SET_API_DATA:
            return {
                ...state,
                data: action.data,
                currentStep: state.currentStep + 1,
                totalStep:
                    state.currentStep === state.totalStep
                        ? state.totalStep + 1
                        : state.totalStep,
            };
        case actionTypes.SET_TAX:
            return {
                ...state,
                tax: action.tax,
            };
        case actionTypes.INPUT_CHANGE:
            let changedFrameworkQuantity = state.frameworkLinesQuantityArr;
            changedFrameworkQuantity[action.index] = action.value;
            return {
                ...state,
                frameworkLinesQuantityArr: changedFrameworkQuantity,
            };
        case actionTypes.RESTORE_ITEMS:
            let offerLinesIndexArr1 = [];
            state.details.frameworkLines?.forEach((item) => {
                offerLinesIndexArr1.push(item.quantityPerBatches);
            });
            return {
                ...state,
                frameworkLines: state.details.frameworkLines,
                frameworkLinesQuantityArr: offerLinesIndexArr1,
            };
        case actionTypes.REMOVE_FRAMEWORK_ITEM:
            let frameworkLines;
            let frameworkQuantity;
            frameworkQuantity = state.frameworkLinesQuantityArr.filter(
                (item, index) => {
                    return index !== action.index;
                }
            );
            frameworkLines = state.frameworkLines.filter((offerLine) => {
                return offerLine.frameworkLineId !== action.lineId;
            });

            return {
                ...state,
                frameworkLines: frameworkLines,
                frameworkLinesQuantityArr: frameworkQuantity,
            };
        case actionTypes.CLEAR_WIZZARD:
            return {
                ...state,
                open: false,
                details: {},
                currentStep: 1,
                totalStep: 1,
                frameworkLines: [],
                frameworkLinesQuantityArr: [],
                data: {
                    faId: '',
                    deliveryDate: '',
                    faReference: '',
                    sameAddress: true,
                    faLines: [],
                    addresses: {
                        billingAddress: {
                            city: '',
                            companyName: '',
                            country: '',
                            postalCode: '',
                            street: '',
                            additionalInfo: '',
                            taxNumber: '',
                            contactInvoicing: '',
                        },
                        shippingAddress: {
                            city: '',
                            companyName: '',
                            country: '',
                            postalCode: '',
                            street: '',
                            additionalInfo: '',
                        },
                    },
                    defaultContacts: {
                        contactPurchasing: '',
                        contactInvoicing: '',
                        contactOrderConfirmation: '',
                    },
                },
                tax: null,
            };
        default:
            return state;
    }
};

export default frameworkWizzardReducer;
