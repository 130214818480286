import React, { useState } from 'react';
import Table from '../../../components/Table/Table';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import PageSearch from '../../../components/PageHeader/PageSearch/PageSearch';
import PageMeta from '../../../shared/PageMeta/PageMeta';
import { useParams, useNavigate } from 'react-router-dom';
import { onOpenDetails } from '../../../store/actions/detailsPopupActions';
import setGoogleAnalytics from '../../../hooks/setGoogleAnalytics';

function OrderDeliveries() {
    const [tableData, setTableData] = useState(['']);
    const [showInitialMessage, setShowInitialMessage] = useState(true);
    const [loading, setLoading] = useState(true);

    const { componentId, id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const details = useSelector((state) => state.details.details);

    useEffect(() => {
        if (componentId && details.orderId) {
            let detailsObject;
            if (details.shipments?.length) {
                detailsObject = details.shipments.find(
                    (orderLine) => orderLine.shipmentId === componentId
                );
                if (detailsObject) {
                    dispatch(onOpenDetails(detailsObject, 'order-deliveries'));
                }
            }

            //fallback if componentId is not valid
            if (!detailsObject) {
                navigate(`/orders/${id}/components`);
            }
        }
        //eslint-disable-next-line
    }, [details]);

    const deliveryNotes = useSelector(
        (state) => state.details.searchedOrderShipments
    );

    const orderDetailsLoading = useSelector(
        (state) => state.details.detailsLoading
    );

    const orderNm = useSelector((state) => state.details.details?.orderNr);
    const { t } = useTranslation();

    const tableTitles = [
        {
            id: 1,
            name: t('order-details-del_shipping-date'),
        },
        {
            id: 2,
            name: t('order-details-del_tracking-num'),
        },
        {
            id: 3,
            name: t('order-details-del_delivery-num'),
        },
        {
            id: 4,
            name: t('order-details-del_provider'),
        },
        {
            id: 5,
            name: t('order-details-del_kind'),
        },
        {
            id: 6,
            name: t('order-details-del_weight'),
        },
    ];

    useEffect(() => {
        if (deliveryNotes) {
            const filteredTableData = deliveryNotes.map((note) => {
                return {
                    id: note.shipmentNumber,
                    data: {
                        deliveryDate: note.deliveryDate
                            ? moment(note.deliveryDate).format('DD.MM.YYYY')
                            : '-',
                        tracking: {
                            trackingCode: note.trackingCode
                                ? note.trackingCode
                                : '-',
                            trackingUrl: note.trackingUrl
                                ? note.trackingUrl
                                : '',
                        },
                        deliveryNoteNumber: note.deliveryNoteNames
                            ? { deliveryNoteNames: note.deliveryNoteNames }
                            : '-',
                        provider: note.provider ? note.provider : '-',
                        type: note.type ? note.type : '-',
                        weight: note.weight ? note.weight : '-',
                    },
                };
            });
            setTableData(filteredTableData);
        }
    }, [deliveryNotes]);

    const initialSearchValueHandler = (searchStarted) => {
        setShowInitialMessage(searchStarted);
    };

    // TODO REWORK WHEN DITTO WORKS AGAIN
    const getEmptyStateMessage = (whoseState, stateType, statePlace) => {
        const emptyStatesObject = t('emptyState');
        const emptyStateJson = JSON.parse(emptyStatesObject);
        return emptyStateJson[whoseState][stateType][statePlace];
    };

    // reactga4 for order deliveries
    useEffect(() => {
        setGoogleAnalytics(
            'page_view',
            'page_view_order_detail_delivery',
            'view order detail page, delivery section'
        );
    }, []);

    // fix for table empty state showing briefly after loading
    useEffect(() => {
        setLoading(orderDetailsLoading);
    }, [orderDetailsLoading]);

    return (
        <>
            <PageMeta>
                <title>
                    {t('global_order-btn')} {orderNm ? orderNm : ''} -
                    {t('order-details-inv_deliveries')} | CNC24
                </title>
            </PageMeta>
            <PageSearch
                componentSearch={true}
                tableType="orderDeliveries"
                onInitialSearchValue={initialSearchValueHandler}
                javascriptSearch={true}
            />

            <Table
                fullClickableRow={true}
                tableTitles={tableTitles}
                tableData={tableData}
                details={deliveryNotes}
                tableType="order-deliveries"
                emptyStHeading={
                    showInitialMessage
                        ? getEmptyStateMessage(
                              'orderDeliveriesEmptyState',
                              'InitialEmptyState',
                              'heading'
                          )
                        : t('order-deliveries-empty_heading')
                }
                emptyStText={
                    showInitialMessage
                        ? getEmptyStateMessage(
                              'orderDeliveriesEmptyState',
                              'InitialEmptyState',
                              'text'
                          )
                        : t('order-deliveries-empty_paragraph')
                }
                loading={loading}
            />
        </>
    );
}

export default OrderDeliveries;
