import classes from '../Details/DetailsSideScreen/DetailsSideScreen.module.scss';
import { useSelector } from 'react-redux';

const SidebarPrice = (props) => {
    const loading = useSelector((state) => state.details.detailsLoading);

    return loading ? (
        <span
            className={classes['c-details-sidescreen__price-skeleton']}
        ></span>
    ) : (
        <h3 className={classes['c-details-sidescreen__detail-data']}>
            {props.data}
        </h3>
    );
};
export default SidebarPrice;
