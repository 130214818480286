import classes from './SkeletonWithImageTable.module.scss';

const SkeletonWithImageTableLabels = () => {
    const numOfItems = [1, 2, 3];

    return (
        <>
            {numOfItems.map((item) => {
                return (
                    <div
                        key={item}
                        className={
                            classes[
                                'skeleton-with-image-table__label-value-wrapper'
                            ]
                        }
                    >
                        <div
                            className={`${classes['skeleton-with-image-table__label-value']} ${classes['skeleton-with-image-table__label-value--single-item']}`}
                        >
                            <span
                                className={
                                    classes['skeleton-with-image-table__label']
                                }
                            ></span>
                        </div>
                    </div>
                );
            })}

            {numOfItems.map((item) => {
                return (
                    <div
                        key={item}
                        className={
                            classes[
                                'skeleton-with-image-table__label-value-wrapper'
                            ]
                        }
                    >
                        <div
                            className={`${classes['skeleton-with-image-table__label-value']} ${classes['skeleton-with-image-table__label-value--three-items']}`}
                        >
                            <span
                                className={
                                    classes['skeleton-with-image-table__label']
                                }
                            ></span>
                            <span
                                className={
                                    classes['skeleton-with-image-table__label']
                                }
                            ></span>
                            <span
                                className={
                                    classes['skeleton-with-image-table__label']
                                }
                            ></span>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default SkeletonWithImageTableLabels;
