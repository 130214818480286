import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import ApiService from '../../services/api.service';
import classes from './Dropzone.module.scss';
import { useTranslation } from 'react-i18next';

function MyDropzone({ recordId, uploadSuccessfull, acceptFiles, type }) {
    const [files, setFiles] = useState([]);
    const [acceptedFiles, setAcceptedFiles] = useState([]);
    const { t } = useTranslation();

    const onDrop = useCallback(
        (acceptedFiles) => {
            setAcceptedFiles(acceptedFiles);
            acceptedFiles.forEach((file) => {
                const data = {
                    fileName: file.name,
                    recordId: recordId,
                    type: type,
                };
                ApiService.uploadUrl(data).then((response) => {
                    ApiService.uploadFile(response.data.data.url, file).then(
                        (r) => {
                            const fileObj = {
                                fileId: response.data.data.uuid,
                                fileName: file.name,
                                type: type,
                            };
                            setFiles((oldFiles) => [...oldFiles, fileObj]);
                        }
                    );
                });
            });
        },
        //eslint-disable-next-line
        [recordId, uploadSuccessfull]
    );

    useEffect(() => {
        //send files after all of them are uploaded
        if (
            acceptedFiles.length === files.length &&
            acceptedFiles.length !== 0
        ) {
            uploadSuccessfull(files);
            setFiles([]);
            setAcceptedFiles([]);
        }
    }, [acceptedFiles, files, uploadSuccessfull]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: acceptFiles,
    });

    return (
        <div {...getRootProps()} className={classes['c-dropzone']}>
            <input {...getInputProps()} accept=".pdf" />
            {isDragActive ? (
                <p>Drop the files here ...</p>
            ) : (
                <p>{t('wizard-ord-details_attach-order-doc')}</p>
            )}
            <i className="icon-upload"></i>
        </div>
    );
}

export default MyDropzone;
