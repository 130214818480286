import IframeWizard from '../../components/IframeWizard/IframeWizard';
import classes from './Wizard.module.scss';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

function Wizard() {
    const navigate = useNavigate();
    const [noTrack, setNoTrack] = useState();

    useEffect(
        () => {
            let token = localStorage.getItem('token');
            let decodedToken = jwt_decode(token);
            let xUserId = localStorage.getItem('X-Track-Id');
            setNoTrack(xUserId);
            const sessionExpiredCheck = setInterval(() => {
                let currentDate = new Date();
                if (decodedToken.exp * 1000 < currentDate.getTime()) {
                    navigate('/login');
                    localStorage.setItem(
                        'redirectErrorMessage',
                        'sessionExpired'
                    );
                    clearInterval(sessionExpiredCheck);
                }
            }, 60000);
        },
        //eslint-disable-next-line
        []
    );

    return (
        <div className={classes['c-wizard']}>
            <IframeWizard open={true} noTrack={noTrack} />
        </div>
    );
}

export default Wizard;
