import React from 'react';
import classes from '../WizzardHeader.module.scss';
import completedIcon from '../../../../images/completedIcon.svg';

function WizzardHeaderItem({ item, index, active, totalStep }) {
    const activeStep = index === active;
    const completedStep =
        totalStep > index && index !== active && active > index;

    return (
        <div
            className={`${classes['wizzard-header__item']} ${
                activeStep ? classes['wizzard-header__item--active'] : ''
            } ${
                completedStep ? classes['wizzard-header__item--completed'] : ''
            }`}
        >
            {!completedStep ? (
                <span className={`${classes['wizzard-header__step-number']}`}>
                    {index}
                </span>
            ) : (
                <img
                    src={completedIcon}
                    className={classes['wizzard-header__completed-icon']}
                    alt="completed icon"
                />
            )}

            <span className={classes['wizzard-header__step-name']}>{item}</span>

            <span className={classes['wizzard-header__chevron-right-icon']}>
                <i className={`icon-chevron-right-thin`}></i>
            </span>
        </div>
    );
}

export default WizzardHeaderItem;
