import React from 'react';
import classes from '../DetailsPopup.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { onCloseDetails } from '../../../../store/actions/detailsPopupActions';
import DownloadPdf from '../../../Download/DownloadPdf/DownloadPdf';
import { useTranslation } from 'react-i18next';
import TranslatedComponent from '../../../TranslatedComponent/TranslatedComponent';
import fallbackImg from '../../../../images/fallbackImg.svg';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function OffersPopup({ open }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const detailsPopup = useSelector(
        (state) => state.detailsPopup.detailsPopup
    );

    const { order, id } = useParams();

    const handlePopupClose = () => {
        if (window.location.href.includes('framework-agreement')) {
            navigate(`/framework-agreements/${order}/components?type=Overview`);
        } else if (window.location.href.includes('orders')) {
            if (window.location.href.includes('deliveries')) {
                navigate(`/orders/${id}/deliveries`);
            } else {
                navigate(`/orders/${id}/components`);
            }
        } else if (window.location.href.includes('offers')) {
            navigate(`/offers/${id}/components`);
        }
        dispatch(onCloseDetails());
    };

    const [errImg, setErrImg] = useState(false);

    const imgErr = (e) => {
        e.target.onError = null;
        e.target.src = fallbackImg;
        setErrImg(true);
    };

    return (
        <>
            {open ? (
                <>
                    <button
                        className={classes['details-popup__close-btn']}
                        onClick={handlePopupClose}
                    >
                        <i className="icon-x-sign"></i>
                    </button>
                    <div className={classes['details-popup__basic-info']}>
                        <h2 className={classes['details-popup__part-name']}>
                            {detailsPopup?.partName
                                ? detailsPopup.partName
                                : '-'}
                        </h2>
                        <span className={classes['details-popup__part-number']}>
                            {detailsPopup?.articleNumber
                                ? detailsPopup.articleNumber
                                : '-'}
                        </span>
                    </div>

                    <div
                        className={`${classes['details-popup__image']} ${
                            detailsPopup.files && detailsPopup.files[0]
                                ? ''
                                : classes['details-popup__image--hide-link']
                        }`}
                    >
                        <a
                            href={
                                detailsPopup.files && detailsPopup.files[0]
                                    ? detailsPopup?.files[0]?.fileUrl
                                    : ''
                            }
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src={
                                    detailsPopup.files && detailsPopup.files[0]
                                        ? detailsPopup?.files[0]
                                              ?.fileThumbnailUrl
                                        : fallbackImg
                                }
                                alt="img"
                                onError={imgErr}
                                className={
                                    detailsPopup.files &&
                                    detailsPopup.files[0] &&
                                    !errImg
                                        ? classes['details-popup__normal-img']
                                        : errImg
                                        ? classes['details-popup__fallback-img']
                                        : classes['details-popup__fallback-img']
                                }
                            />

                            <i className="icon-expand"></i>
                        </a>
                    </div>

                    {detailsPopup.files?.map((file, index) => {
                        return (
                            <DownloadPdf
                                key={`${file.fileName}-${index}`}
                                name={file.fileName}
                                url={file.fileDownloadUrl}
                            />
                        );
                    })}
                    <div className={classes['details-popup__specifications']}>
                        <div
                            className={
                                classes['details-popup__specifications-group']
                            }
                        >
                            <div
                                className={
                                    classes['details-popup__specification']
                                }
                            >
                                <span>{t('details_article-number')}</span>
                                <span>
                                    {detailsPopup.articleNumber
                                        ? detailsPopup.articleNumber
                                        : '-'}
                                </span>
                            </div>
                            <div
                                className={
                                    classes['details-popup__specification']
                                }
                            >
                                <span>{t('details_drawing-number')}</span>
                                <span>
                                    {detailsPopup.nameTechnicalDrawing
                                        ? detailsPopup.nameTechnicalDrawing
                                        : '-'}
                                </span>
                            </div>
                            <div
                                className={
                                    classes['details-popup__specification']
                                }
                            >
                                <span>{t('details_revision-number')}</span>
                                <span>
                                    {detailsPopup.revision
                                        ? detailsPopup.revision
                                        : '-'}
                                </span>
                            </div>
                        </div>
                        <div
                            className={
                                classes['details-popup__specifications-group']
                            }
                        >
                            <div
                                className={
                                    classes['details-popup__specification']
                                }
                            >
                                <span>{t('details_certificates')}</span>
                                <span>
                                    {detailsPopup.materialCertificate ? (
                                        <TranslatedComponent
                                            translationFileName="certificates"
                                            translatedComponent={
                                                detailsPopup.materialCertificate
                                            }
                                        />
                                    ) : (
                                        '-'
                                    )}
                                </span>
                            </div>
                            <div
                                className={
                                    classes['details-popup__specification']
                                }
                            >
                                <span>{t('details_labeling')}</span>
                                <span>
                                    {detailsPopup.labeling
                                        ? detailsPopup.labeling
                                        : '-'}
                                </span>
                                <span>
                                    {detailsPopup.labelingComments
                                        ? detailsPopup.labelingComments
                                        : ''}
                                </span>
                            </div>
                            <div
                                className={
                                    classes['details-popup__specification']
                                }
                            >
                                <span>{t('details_general-tolerances')}</span>
                                <span>
                                    {detailsPopup.tolerance
                                        ? detailsPopup.tolerance
                                        : '-'}
                                </span>
                            </div>
                        </div>
                        <div
                            className={
                                classes['details-popup__specifications-group']
                            }
                        >
                            <div
                                className={
                                    classes['details-popup__specification']
                                }
                            >
                                <span>{t('details_material')}</span>
                                <span>
                                    {detailsPopup.materialGroup ? (
                                        <TranslatedComponent
                                            translationFileName="materials"
                                            translatedComponent={
                                                detailsPopup.materialGroup
                                            }
                                        />
                                    ) : (
                                        '-'
                                    )}
                                    {detailsPopup.material && (
                                        <span> ({detailsPopup.material})</span>
                                    )}
                                </span>
                                <span>
                                    {detailsPopup.materialComments
                                        ? detailsPopup.materialComments
                                        : ''}
                                </span>
                            </div>
                            <div
                                className={
                                    classes['details-popup__specification']
                                }
                            >
                                <span>{t('details_production-method')}</span>
                                <span>
                                    {detailsPopup.productionMethod
                                        ? detailsPopup.productionMethod.map(
                                              (productionMethod, index) => {
                                                  return (
                                                      <span
                                                          key={`production-method-${index}`}
                                                      >
                                                          <TranslatedComponent
                                                              translationFileName="production-method"
                                                              translatedComponent={
                                                                  productionMethod
                                                              }
                                                              hasComma={
                                                                  index + 1 ===
                                                                  detailsPopup
                                                                      ?.productionMethod
                                                                      ?.length
                                                                      ? false
                                                                      : true
                                                              }
                                                          />
                                                      </span>
                                                  );
                                              }
                                          )
                                        : '-'}
                                </span>
                            </div>
                            <div
                                className={
                                    classes['details-popup__specification']
                                }
                            >
                                <span>{t('details_surface')}</span>
                                <ul
                                    className={
                                        classes['details-popup__surface']
                                    }
                                >
                                    {detailsPopup.surface?.length ? (
                                        detailsPopup?.surface.map(
                                            (surface, index) => {
                                                return (
                                                    <li
                                                        key={`surface-${index}`}
                                                    >
                                                        {surface}{' '}
                                                        {detailsPopup?.surfaceDetailsMap &&
                                                        detailsPopup
                                                            ?.surfaceDetailsMap[
                                                            surface
                                                        ]
                                                            ? `(${detailsPopup?.surfaceDetailsMap[surface]})`
                                                            : null}
                                                    </li>
                                                );
                                            }
                                        )
                                    ) : (
                                        <span>-</span>
                                    )}
                                </ul>
                                <span>
                                    {detailsPopup.surfaceDetails
                                        ? detailsPopup.surfaceDetails
                                        : ''}
                                </span>
                            </div>
                        </div>
                        <div
                            className={`${classes['details-popup__specification']} ${classes['details-popup__specification--comment']}`}
                        >
                            <span>{t('details_comments')}</span>
                            <span>
                                {detailsPopup.comments
                                    ? detailsPopup.comments
                                    : '-'}
                            </span>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
}

export default OffersPopup;
