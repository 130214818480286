import { useState } from 'react';
import SectionHeaderWrapper from '../../../../components/SectionHeaderWrapper/SectionHeaderWrapper';
import InviteContactModal from './InviteContactModal/InviteContactModal';
import classes from './ManageContacts.module.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PlusIcon from '../../../../components/Icons/PlusIcon';
import Button from '../../../../components/Button/Button';

function ManageContacts() {
    const [openModal, setOpenModal] = useState(false);
    const { t } = useTranslation();

    const userReducer = useSelector((state) => state.user);

    const inviteContactModalHandler = () => {
        setOpenModal(!openModal);
    };

    const modalClickHandler = () => {
        setOpenModal(!openModal);
    };

    return (
        <div className={classes['manage-contacts']}>
            <div className={classes['manage-contacts__header']}>
                <SectionHeaderWrapper
                    title={t('contacts_manage-contacts')}
                    description={t('contacts_add-contact-text')}
                />
                <Button
                    onClick={
                        userReducer.user.portalRole === 'Owner' ||
                        userReducer.user.portalRole === 'Admin'
                            ? inviteContactModalHandler
                            : null
                    }
                    className="btn btn--purple btn--small btn--small-only-icon"
                    disabled={
                        userReducer.user.portalRole !== 'Owner' &&
                        userReducer.user.portalRole !== 'Admin'
                    }
                >
                    <span>{t('contacts_invite')}</span> <PlusIcon />
                </Button>
            </div>
            {(userReducer.user.portalRole === 'Owner' ||
                userReducer.user.portalRole === 'Admin') &&
            openModal ? (
                <InviteContactModal onClickHandler={modalClickHandler} />
            ) : null}
        </div>
    );
}

export default ManageContacts;
