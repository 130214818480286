import React, { useEffect, useState } from 'react';
import PageMeta from '../../shared/PageMeta/PageMeta';
import { getTableData } from '../../store/thunks/tableDataThunks';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Table from '../../components/Table/Table';
import PageTitle from '../../components/PageHeader/PageTitle/PageTitle';
import { onTableContentCleanup } from '../../store/actions/tableContentActions';
import { onCloseDetails } from '../../store/actions/detailsPopupActions';
import PageSearch from '../../components/PageHeader/PageSearch/PageSearch';
import DatePicker from '../../components/DatePicker/DatePicker';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import setGoogleAnalytics from '../../hooks/setGoogleAnalytics';
import Select from 'react-select';
import Button from '../../components/Button/Button';

function Offers() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [filtersUsed, setfiltersUsed] = useState(false);
    const [loading, setLoading] = useState(true);
    const [statusType, setStatusType] = useState({});
    const [statusTypesList, setStatusTypesList] = useState([]);

    const tableContent = useSelector(
        (state) => state.tableContent.tableContent
    );
    const tableContentLoading = useSelector(
        (state) => state.tableContent.tableContentLoading
    );

    const [tableTitles, setTableTitles] = useState([]);
    const [tableData, setTableData] = useState('');
    const [finalDates, setFinalDates] = useState({});

    //eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();

    const [type, setType] = useState('');

    const offersPage = window.location.href.includes('offers');

    const { t } = useTranslation();

    // ga4
    useEffect(() => {
        setGoogleAnalytics(
            'page_view',
            'page_view_offer_overview',
            'view offer overview page'
        );

        const params = new URLSearchParams(window.location.search);
        const status = params.get('status');
        if (status) {
            let statusColor;
            // set status color
            switch (status) {
                case 'Draft':
                    statusColor = 'grey';
                    break;
                case 'In examination':
                    statusColor = 'yellow';
                    break;
                case 'Calculation running':
                    statusColor = 'yellow';
                    break;
                case 'Feedback required':
                    statusColor = 'red';
                    break;
                case 'Offer created':
                    statusColor = 'purple';
                    break;
                case 'Offer updated':
                    statusColor = 'orange';
                    break;
                case 'Price negotiation':
                    statusColor = 'light-blue';
                    break;
                case 'Canceled':
                    statusColor = 'grey';
                    break;
                case 'Ordered':
                    statusColor = 'green';
                    break;
                default:
                    statusColor = '';
                    break;
            }
            setStatusType({
                label: getStatusTranslation(status),
                value: status,
                color: statusColor,
            });
            filterStatusTypeList(status);
        } else {
            setStatusType({
                label: t('global_filter-by-status'),
            });
            filterStatusTypeList('All');
        }
        //eslint-disable-next-line
    }, [t]);

    useEffect(() => {
        //filter logic
        //on every search param change this gets triggered
        //dispatches thunk to get all orders
        //thunk takes search params from URL and voila
        const searchParams = new URLSearchParams(window.location.search);
        const type = searchParams.get('type');
        const startDate = searchParams.get('startDate');
        const endDate = searchParams.get('endDate');
        if (startDate && endDate) {
            setFinalDates({
                startDate: moment(startDate, 'YYYY-MM-DD'),
                endDate: moment(endDate, 'YYYY-MM-DD'),
            });
        } else {
            const currentDate = new Date();

            setFinalDates({
                startDate: moment(
                    currentDate.setMonth(currentDate.getMonth() - 3)
                ),
                endDate: moment(),
            });
        }
        const searchFilter = searchParams.get('searchFilter');

        // check if there is any param
        const hasFilters = searchFilter || startDate || endDate;
        setfiltersUsed(hasFilters);

        if (!tableContentLoading) {
            setType(type);
            dispatch(getTableData('offer'));
        }
        //eslint-disable-next-line
    }, [window.location.search]);

    useEffect(() => {
        // filtering and taking only neccessery data for table
        const filteredTableData = tableContent.map((item) => {
            return {
                id: item.offerId,
                data: {
                    deliveryDate: {
                        date: item.createdDate ? item.createdDate : '-',
                        iconType: item.iconType ? item.iconType : null,
                        iconHoverText: item.iconHoverText
                            ? item.iconHoverText
                            : null,
                    },
                    offerNr: item.offerNr ? item.offerNr : '-',
                    reference: item.reference ? item.reference : '-',
                    typeOfOrder: {
                        needTranslationItem: item.typeOfOrder
                            ? item.typeOfOrder
                            : '-',
                        translationObject: 'typeOfOrder',
                    },
                    // validTo: item.validTo ? item.validTo : '-',
                    nrOfParts: item.nrOfParts ? item.nrOfParts : '-',
                    simpleFormattedPrice: {
                        minFormattedPrice: item.totalPriceNetMin
                            ? item.totalPriceNetMin
                            : '-',
                        maxFormattedPrice: item.totalPriceNetMax
                            ? item.totalPriceNetMax
                            : '-',
                    },
                    status: {
                        status: item.status ? item.status : '-',
                        color: item.status ? item.status : null,
                        type: 'offersStatus',
                    },
                },
            };
        });
        //splitting array into object of arrays with year key
        //if there's no year NaN is key
        setTableData(filteredTableData);
    }, [tableContent]);

    //sortFilter - sort filter that is going to appear in search params
    //sortFilter is not needed if sortable is false
    //sortable - whether field is sortable or not
    useEffect(() => {
        setTableTitles([
            {
                id: 1,
                name: t('offers-table-columns_created-at'),
                sortFilter: 'createdDate',
                sortable: true,
            },
            {
                id: 2,
                name: t('offers-table-columns_offer-number'),
                sortFilter: 'offerNr',
                sortable: true,
            },
            {
                id: 3,
                name: t('offers-table-columns_reference'),
                sortFilter: 'reference',
                sortable: true,
            },
            {
                id: 4,
                name: t('offers-table-columns_kind-of-offer'),
                sortFilter: 'typeOfOrder',
                sortable: true,
            },
            {
                id: 5,
                name: t('offers-table-columns_components'),
                sortFilter: 'nrOfParts',
                sortable: true,
            },
            {
                id: 6,
                name: t('offers-table-columns_price'),
                sortFilter: 'totalPriceNetMax',
                sortable: true,
            },
            {
                id: 7,
                name: t('offers-table-columns_status'),
                sortFilter: 'status',
                sortable: false,
            },
        ]);
    }, [t, type]);

    const dateChangeHandler = (dates) => {
        setFinalDates({
            startDate: dates.startDate,
            endDate: dates.endDate,
        });
        if (
            (dates.endDate?._d !== finalDates.endDate?._d ||
                dates.startDate?._d !== finalDates.startDate?._d) &&
            finalDates.endDate?._d !== undefined &&
            dates.endDate?._d !== undefined
        ) {
            const searchParams = new URLSearchParams(window.location.search);
            const startDateParam = searchParams.get('startDate');
            const endDateParam = searchParams.get('endDate');

            if (startDateParam && endDateParam) {
                searchParams.set(
                    'startDate',
                    moment(dates.startDate).format('YYYY-MM-DD')
                );
                searchParams.set(
                    'endDate',
                    moment(dates.endDate).format('YYYY-MM-DD')
                );
            } else {
                searchParams.append(
                    'startDate',
                    moment(dates.startDate).format('YYYY-MM-DD')
                );
                searchParams.append(
                    'endDate',
                    moment(dates.endDate).format('YYYY-MM-DD')
                );
            }
            setSearchParams(searchParams);
        }
    };

    //cleanup
    //onTableContentCleanup sets loading to true and clears table array
    useEffect(
        () => () => {
            if (!window.location.href.includes('offers')) {
                dispatch(onTableContentCleanup());
                dispatch(onCloseDetails());
            }
        },
        //eslint-disable-next-line
        []
    );

    // TODO REWORK WHEN DITTO WORKS AGAIN
    const getEmptyStateMessage = (whoseState, stateType, statePlace) => {
        const emptyStatesObject = t('emptyState');
        const emptyStateJson = JSON.parse(emptyStatesObject);
        return emptyStateJson[whoseState][stateType][statePlace];
    };

    const checkSession = (event) => {
        let token = localStorage.getItem('token');
        let decodedToken = jwt_decode(token);
        let currentDate = new Date();

        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            navigate('/login');
            localStorage.setItem('redirectErrorMessage', 'sessionExpired');
        } else {
            navigate('/wizard');
        }
    };

    const onTypeChange = (selectedType) => {
        const params = new URLSearchParams();
        const currentParams = new URLSearchParams(window.location.search);
        const type = currentParams.get('status');

        if (type !== selectedType.value && selectedType.value !== 'All') {
            params.set('status', selectedType.value);
            setStatusType({
                value: selectedType.value,
                label: getStatusTranslation(selectedType.value),
                color: selectedType.color,
            });
            filterStatusTypeList(selectedType.value);
        } else {
            setStatusType({
                label: t('global_filter-by-status'),
            });
            filterStatusTypeList('All');
        }
        setSearchParams(params);
    };

    const filterStatusTypeList = (status) => {
        const filteredStatusTypeList = initialOrderTabs.filter((statusType) => {
            return statusType.value !== status;
        });
        setStatusTypesList(filteredStatusTypeList);
    };

    // get offer status translation
    const getStatusTranslation = (status) => {
        const translatedGroup = JSON.parse(t('offersStatus'));
        let statusLabel;

        switch (status) {
            case 'Draft':
                statusLabel = translatedGroup['Wizardnotfinished'];
                break;
            case 'In examination':
                statusLabel = translatedGroup['ProfiCheck'];
                break;
            case 'Calculation running':
                statusLabel = translatedGroup['Partnerofferreceived'];
                break;
            case 'Feedback required':
                statusLabel = translatedGroup['Waitingforanswer'];
                break;
            case 'Offer created':
                statusLabel = translatedGroup['Offersent'];
                break;
            case 'Offer updated':
                statusLabel = translatedGroup['UpdateSent'];
                break;
            case 'Price negotiation':
                statusLabel = translatedGroup['InNegotiation'];
                break;
            case 'Canceled':
                statusLabel = translatedGroup['Lost'];
                break;
            case 'Ordered':
                statusLabel = translatedGroup['Offeraccepted'];
                break;
            default:
                statusLabel = '';
                break;
        }
        return statusLabel;
    };

    const initialOrderTabs = [
        {
            id: '1',
            label: t('orders-tab-nav_all'),
            value: 'All',
            color: 'none',
        },
        {
            id: '2',
            label: getStatusTranslation('Draft'),
            value: 'Draft',
            color: 'grey',
        },
        {
            id: '3',
            label: getStatusTranslation('In examination'),
            value: 'In examination',
            color: 'yellow',
        },
        {
            id: '4',
            label: getStatusTranslation('Calculation running'),
            value: 'Calculation running',
            color: 'yellow',
        },
        {
            id: '5',
            label: getStatusTranslation('Feedback required'),
            value: 'Feedback required',
            color: 'red',
        },
        {
            id: '6',
            label: getStatusTranslation('Offer created'),
            value: 'Offer created',
            color: 'purple',
        },
        {
            id: '7',
            label: getStatusTranslation('Offer updated'),
            value: 'Offer updated',
            color: 'orange',
        },
        {
            id: '8',
            label: getStatusTranslation('Price negotiation'),
            value: 'Price negotiation',
            color: 'light-blue',
        },
        {
            id: '9',
            label: getStatusTranslation('Canceled'),
            value: 'Canceled',
            color: 'grey',
        },
        {
            id: '10',
            label: getStatusTranslation('Ordered'),
            value: 'Ordered',
            color: 'green',
        },
    ];

    // fix for table empty state showing briefly after loading
    useEffect(() => {
        setLoading(tableContentLoading);
    }, [tableContentLoading]);

    return (
        <>
            <PageMeta>
                <title>{t('offers_heading')} | CNC24</title>
            </PageMeta>

            <div className="l-container">
                <div className="l-container__header">
                    <PageTitle title={t('offers_heading')} />
                    <Button
                        onClick={checkSession}
                        className="btn btn--purple btn--small btn--small-only-icon"
                        ariaLabel={t('offers_new-offer-button')}
                    >
                        <span>{t('offers_new-offer-button')}</span>
                        {''}
                        <i className="icon-plus"></i>
                    </Button>
                </div>
                <div className="l-container__actions">
                    <PageSearch type="offers" />
                    <div
                        className={`l-container__tabs-picker ${
                            offersPage
                                ? 'l-container__tabs-picker--with-select'
                                : ''
                        }`}
                    >
                        <DatePicker
                            onDateChange={dateChangeHandler}
                            initialValue={finalDates}
                            type="offers"
                            datepickerType="rangeDate"
                        />
                        <Select
                            className="c-react-select c-react-select--full-height c-react-select--212 c-react-select--transaprent-grey-border c-react-select--status-select"
                            name="language-selector"
                            options={statusTypesList}
                            onChange={onTypeChange}
                            value={statusType}
                            classNamePrefix="c-react-select"
                            aria-label="Select offer type"
                            // menuIsOpen
                            formatOptionLabel={(data) => {
                                return (
                                    <div className="c-react-select__same-row">
                                        <span
                                            className={`c-react-select__color c-react-select__color--${data.color}`}
                                        ></span>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: data.label,
                                            }}
                                        />
                                    </div>
                                );
                            }}
                        />
                    </div>
                </div>
                <>
                    <Table
                        tableTitles={tableTitles}
                        tableData={tableData}
                        loading={loading}
                        tableType="offers"
                        emptyStHeading={
                            filtersUsed
                                ? t('offers-empty-state_heading')
                                : getEmptyStateMessage(
                                      'offersEmptyState',
                                      'InitialEmptyState',
                                      'heading'
                                  )
                        }
                        emptyStText={
                            filtersUsed
                                ? t('offers-empty-state_paragraph')
                                : getEmptyStateMessage(
                                      'offersEmptyState',
                                      'InitialEmptyState',
                                      'text'
                                  )
                        }
                        emptyStBtnText=""
                        linkableRows={true}
                    />
                </>
            </div>
        </>
    );
}

export default Offers;
