import SkeletonWithImageRow from './SkeletonWithImageRow';
import classes from './SkeletonWithImage.module.scss';

const SkeletonWithImage = () => {
    const numOfRows = [1, 2, 3, 4, 5];

    return (
        <div className={classes['skeleton-with-image__wrapper']}>
            {numOfRows.map((item) => {
                return <SkeletonWithImageRow key={item} />;
            })}
        </div>
    );
};

export default SkeletonWithImage;
