import React from 'react';
import Button from '../../../../../../components/Button/Button';
import { useDispatch } from 'react-redux';
import { onRestoreItems } from '../../../../../../store/actions/frameworkWizzardActions';
import classes from './RestoreItems.module.scss';
import { useTranslation } from 'react-i18next';

function RestoreItems() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleRestoreItems = () => {
        dispatch(onRestoreItems());
    };

    return (
        <div className={classes['restore-items']}>
            <p>{t('wizard-message_item-removed-from-table')}</p>
            <Button
                onClick={() => handleRestoreItems()}
                className={`btn btn--small btn--transparent btn--transparent-grey ${classes['restore-items__btn']}`}
            >
                {t('wizzard-message_add-comp-btn')}{' '}
                <i className="icon-plus"></i>
            </Button>
        </div>
    );
}

export default RestoreItems;
