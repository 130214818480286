import ApiService from '../../services/api.service';
import { onGetDetails, onDetailsLoading } from '../actions/detailsActions.js';
import { onGetWizzardDetails } from '../actions/offersWizzardActions';
import { onGetFrameworkWizzardDetails } from '../actions/frameworkWizzardActions';

export function getDetails(type, id, params, dontLoad) {
    return function (dispatch) {
        if (!dontLoad) {
            dispatch(onDetailsLoading());
        }
        ApiService.getDetails(type, id, params, dontLoad).then((details) => {
            dispatch(onGetDetails(details.data.data));
            if (type === 'offer') {
                dispatch(onGetWizzardDetails(details.data.data));
            } else if (type === 'frameworkAgreement') {
                dispatch(onGetFrameworkWizzardDetails(details.data.data));
            }
        });
    };
}
