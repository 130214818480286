function CircledCheckmarkIcon({ height, width }) {
    return (
        <svg
            width={height}
            height={width}
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="32" cy="32" r="26.6667" fill="#87CC9B" />
            <path
                d="M29.3902 41.3333L45.4959 25.2276L42.8781 22.6666L29.3902 36.1544L22.561 29.3252L20 31.8861L29.3902 41.3333Z"
                fill="white"
            />
        </svg>
    );
}

CircledCheckmarkIcon.defaultProps = {
    height: '79',
    width: '79',
};

export default CircledCheckmarkIcon;
