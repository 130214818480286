import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PageMeta from '../../../shared/PageMeta/PageMeta';
import ContactPersons from '../../Account/Contacts/ContactPersons/ContactPersons';
import classes from './OrderContacts.module.scss';

function OrderContacts() {
    const details = useSelector((state) => state.details.details);
    const { t } = useTranslation();

    return (
        <div className={classes['c-order-contacts']}>
            <PageMeta>
                <title>
                    {t('global_order-btn')}{' '}
                    {details.orderNr ? details.orderNr : ''} -{' '}
                    {t('account-tab_contacts')} | CNC24
                </title>
            </PageMeta>
            <ContactPersons
                type="order"
                invoiceContactId={details.contactInvoicing}
                purchasingContactId={details.contactPurchasing}
                confirmationContactId={details.contactOrderConfirmation}
                orderId={details.orderId}
            />
        </div>
    );
}

export default OrderContacts;
