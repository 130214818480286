import classNames from './ContactSkeletonLoader.module.scss';
import classes from '../Contact/Contact.module.scss';

function ContactSkeletonLoader() {
    return (
        <div className={classes['c-contact']}>
            <div className={classes['c-contact__basic-info']}>
                <div
                    className={[
                        classes['c-contact__name'],
                        classNames['c-contact-skeleton-loader__item'],
                        classNames['c-contact-skeleton-loader__medium'],
                        classNames[
                            'c-contact-skeleton-loader__medium--has-bottom'
                        ],
                    ].join(' ')}
                ></div>
                <div
                    className={[
                        classes['c-contact__email'],
                        classNames['c-contact-skeleton-loader__item'],
                        classNames['c-contact-skeleton-loader__medium'],
                    ].join(' ')}
                ></div>
            </div>
            <div className={classes['c-contact__role-status']}>
                <div
                    className={[
                        classes['c-contact__role'],
                        classNames['c-contact-skeleton-loader__item'],
                        classNames['c-contact-skeleton-loader__small'],
                    ].join(' ')}
                ></div>
                <div
                    className={[
                        classes['c-contact__portal-status'],
                        classNames['c-contact-skeleton-loader__item'],
                        classNames['c-contact-skeleton-loader__small'],
                    ].join(' ')}
                ></div>
            </div>
            <div className={classes['c-contact__portal-role']}>
                <div
                    className={[
                        classNames['c-contact-skeleton-loader__item'],
                        classNames['c-contact-skeleton-loader__small'],
                    ].join(' ')}
                ></div>
            </div>
        </div>
    );
}

export default ContactSkeletonLoader;
