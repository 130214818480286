import { Form, Formik } from 'formik';
import Input from '../../../../components/Input/Input/Input';
import classes from './Register.module.scss';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button/Button';
import { useState } from 'react';
import config from '../../../../config';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginRedirection from '../../../../components/LoginRedirection/LoginRedirection';
import ApiService from '../../../../services/api.service';
import ErrorMessage from '../../../../components/ErrorMessage/ErrorMessage';

function Register() {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const pageParams = useLocation();
    const params = new URLSearchParams(pageParams.search);
    const nu = params.get('nu');
    const server_token = params.get('s');
    const redirectUrl = params.get('retUrl');
    const [registerError, setRegisterError] = useState('');

    const [errors, setErrors] = useState({
        hasLowerCase: false,
        hasUpperCase: false,
        hasNumber: false,
        hasSpecialCharacteer: false,
    });

    const initialValues = {
        email: nu ? nu : '',
        password: '',
    };

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .test(function (value) {
                let hasLowerCase = false;
                let hasUpperCase = false;
                let hasNumber = false;
                let hasSpecialCharacter = false;
                let minimumChars = false;
                if (/[a-z]/.test(value?.trim()) && value?.length > 0)
                    hasLowerCase = true;
                if (/[A-Z]/.test(value?.trim())) hasUpperCase = true;
                if (/[0-9]/.test(value?.trim())) hasNumber = true;
                if (/[^a-zA-Z0-9]/.test(value?.trim()))
                    hasSpecialCharacter = true;
                if (value?.trim()?.length > 7) minimumChars = true;
                setErrors({
                    minimumChars: minimumChars,
                    hasLowerCase: hasLowerCase,
                    hasUpperCase: hasUpperCase,
                    hasNumber: hasNumber,
                    hasSpecialCharacter: hasSpecialCharacter,
                    atLeastThreeValid:
                        hasLowerCase +
                            hasUpperCase +
                            hasNumber +
                            hasSpecialCharacter >=
                        3,
                });
                return (
                    hasLowerCase +
                        hasUpperCase +
                        hasNumber +
                        hasSpecialCharacter >=
                    3
                );
            })
            .min(8),
        email: Yup.string()
            .email(`${t('error_field-email-invalid')}`)
            .required(`${t('error_field-required')}`),
    });

    const emailVerificationHandler = async (data) => {
        const token = localStorage.getItem('token');

        const verificationCodeData = {
            email: data?.email,
            s: server_token,
        };

        await ApiService.requestEmailVerificationCode(verificationCodeData);
        const formatedEmail = encodeURIComponent(data?.email);
        localStorage.removeItem('token');
        navigate(
            `/sign-up/confirm?nu=${formatedEmail}&s=${server_token}&t=${token}${
                redirectUrl ? '&retUrl=' + redirectUrl : ''
            }`
        );
    };

    const loginHandler = async (data) => {
        const loginData = {
            grant_type: 'http://auth0.com/oauth/grant-type/password-realm',
            client_id: config.apiGateway.clientId,
            realm: config.apiGateway.realm,
            audience: config.apiGateway.audience,
            username: data.email,
            password: data.password,
        };

        const loginResponse = await ApiService.logIn(loginData);
        if (loginResponse) {
            localStorage.setItem('token', loginResponse.data.access_token);

            const getUserResponse = await ApiService.getUser();
            if (getUserResponse.data.data.email_verified) {
                navigate('/login');
            } else {
                await emailVerificationHandler(data);
            }
        }
    };

    const checkRegisteredUser = async (values) => {
        setLoading(true);
        setRegisterError('');

        const data = {
            client_id: config.apiGateway.clientId,
            connection: config.apiGateway.realm,
            email: values.email,
            password: values.password,
        };

        try {
            const signUpResponse = await ApiService.userSignUp(
                data,
                server_token
            );
            if (signUpResponse?.data) {
                await loginHandler(data);
            } else {
                setRegisterError('AlreadyExists');
                setLoading(false);
            }
        } catch (error) {
            try {
                await loginHandler(data);
            } catch (err) {
                localStorage.removeItem('token');
                setRegisterError(err?.response?.data?.code);
                setLoading(false);
            }
        }
    };

    const submitRegisterHandler = (values) => {
        checkRegisteredUser(values);
    };

    return (
        <div className={classes['c-register']}>
            <div>
                <h1>{t('registration_create-account-label')}</h1>
                <Formik
                    onSubmit={submitRegisterHandler}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    {(props) => {
                        return (
                            <Form>
                                {registerError !== '' && (
                                    <ErrorMessage
                                        name={registerError}
                                        type="errorMessage"
                                        errorText={
                                            registerError === 'invalid_signup'
                                                ? t(
                                                      'registration_invalid-signup'
                                                  )
                                                : t(
                                                      'registration_already-registered'
                                                  )
                                        }
                                        hasErrorText={true}
                                    />
                                )}

                                <Input
                                    type="email"
                                    label={t('login_email-label')}
                                    name="email"
                                    errors={props.errors.email}
                                    touched={props.touched.email}
                                    autoComplete="email"
                                    disabled={true}
                                    hideerror="true"
                                />
                                <Input
                                    type="password"
                                    label={t('login_password-label')}
                                    name="password"
                                    errors={props.errors.password}
                                    touched={props.touched.password}
                                    autoComplete="password"
                                    hideerror="true"
                                />
                                <ul
                                    className={
                                        classes['c-register__requirements']
                                    }
                                >
                                    <li
                                        className={[
                                            classes['c-register__requirement'],
                                            props.dirty ||
                                            props.touched.password
                                                ? !errors.minimumChars
                                                    ? classes['invalid']
                                                    : classes['valid']
                                                : '',
                                        ].join(' ')}
                                    >
                                        <span></span>
                                        {t('registration_min-char-length')}
                                    </li>
                                    <ul>
                                        <li
                                            className={[
                                                classes[
                                                    'c-register__requirement'
                                                ],
                                                props.dirty ||
                                                props.touched.password
                                                    ? !errors.atLeastThreeValid
                                                        ? classes['invalid']
                                                        : classes['valid']
                                                    : '',
                                            ].join(' ')}
                                        >
                                            {t('registration_contain-chars')}
                                        </li>
                                        <ul
                                            className={
                                                classes[
                                                    'c-register__requirement-container'
                                                ]
                                            }
                                        >
                                            <li
                                                className={[
                                                    classes[
                                                        'c-register__requirement'
                                                    ],
                                                    props.dirty ||
                                                    props.touched.password
                                                        ? !errors.hasLowerCase
                                                            ? ''
                                                            : classes['valid']
                                                        : '',
                                                ].join(' ')}
                                            >
                                                {t(
                                                    'registration_lowercase-letters'
                                                )}
                                            </li>
                                            <li
                                                className={[
                                                    classes[
                                                        'c-register__requirement'
                                                    ],
                                                    props.dirty ||
                                                    props.touched.password
                                                        ? !errors.hasUpperCase
                                                            ? ''
                                                            : classes['valid']
                                                        : '',
                                                ].join(' ')}
                                            >
                                                {t(
                                                    'registration_uppercase-letters'
                                                )}
                                            </li>
                                            <li
                                                className={[
                                                    classes[
                                                        'c-register__requirement'
                                                    ],
                                                    props.dirty ||
                                                    props.touched.password
                                                        ? !errors.hasNumber
                                                            ? ''
                                                            : classes['valid']
                                                        : '',
                                                ].join(' ')}
                                            >
                                                {t('registration_numbers')}
                                            </li>
                                            <li
                                                className={[
                                                    classes[
                                                        'c-register__requirement'
                                                    ],
                                                    props.dirty ||
                                                    props.touched.password
                                                        ? !errors.hasSpecialCharacter
                                                            ? ''
                                                            : classes['valid']
                                                        : '',
                                                ].join(' ')}
                                            >
                                                {t(
                                                    'registration_special-chars'
                                                )}
                                            </li>
                                        </ul>
                                    </ul>
                                </ul>
                                <Button
                                    disabled={
                                        !(props.isValid && props.dirty) ||
                                        loading
                                    }
                                    className={`btn btn--purple btn--big btn--mobile-login btn--full-width ${
                                        loading ? 'btn--loading' : ''
                                    }`}
                                    ariaLabel={''}
                                    type="submit"
                                >
                                    {t('registration_create-acc-btn')}
                                    {loading ? (
                                        <span className="loader"></span>
                                    ) : null}
                                </Button>
                            </Form>
                        );
                    }}
                </Formik>
                <p className={classes['c-register__not-member']}>
                    {t('login_not-customer')}
                    <br />
                    <a
                        href={config.apiGateway.wizardUrl}
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Hier unverbindliches Angebot einholen."
                    >
                        {t('login_get-offer-link-text')}
                    </a>
                </p>
            </div>
            <LoginRedirection />
        </div>
    );
}

export default Register;
