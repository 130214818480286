import classes from '../DetailsPopup.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { onCloseDetails } from '../../../../store/actions/detailsPopupActions';
import { useEffect, useState } from 'react';
import moment from 'moment';
import TableList from '../../../TableList/TableList';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { useTranslation } from 'react-i18next';

function OrdersPopup({ open, type }) {
    const [parts, setParts] = useState([]);
    const dispatch = useDispatch();
    const detailsPopup = useSelector(
        (state) => state.detailsPopup.detailsPopup
    );
    const { t } = useTranslation();

    const handlePopupClose = () => {
        dispatch(onCloseDetails());
    };

    useEffect(() => {
        let filteredParts = [];

        filteredParts = detailsPopup.deliveryNotes.map((item) => {
            return {
                id: item.uuid,
                files: item.files,
                dropdown: item.parts?.map((deliveryNote) => {
                    return {
                        index: (
                            item.parts.indexOf(deliveryNote) + 1
                        ).toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                        }),
                        designation:
                            deliveryNote.partName +
                            ' ' +
                            deliveryNote.nameTechnicalDrawing,
                        articleNumber: deliveryNote.articleNumber,
                        deliveryNote: deliveryNote.orderedQuantity
                            ? deliveryNote.orderedQuantity +
                              ` ${t('global_pieces-short')}`
                            : '-',
                        shippedQuantity: deliveryNote.shippedQuantity
                            ? deliveryNote.shippedQuantity +
                              ` ${t('global_pieces-short')}`
                            : '-',
                    };
                }),
                data: {
                    deliveryNumber: item.deliveryNoteNumber,
                    orderNumber: item.orderNumber,
                    numberOfParts: item.parts?.length,
                    deliveryDownloadPdf: {
                        downloadUrl: item.files[0]?.fileDownloadUrl,
                        downloadFileName: item.files[0]?.fileName,
                    },
                },
            };
        });
        setParts(filteredParts);
    }, [detailsPopup, t]);

    const dropdownTitles = [
        {
            id: 1,
            name: t('delivery-detail-popup_pos'),
        },
        {
            id: 2,
            name: t('delivery-detail-popup_mark'),
        },
        {
            id: 3,
            name: t('delivery-detail-popup_article-num'),
        },
        {
            id: 4,
            name: t('delivery-detail-popup_ordered-amount'),
        },
        {
            id: 5,
            name: t('delivery-detail-popup_sent'),
        },
    ];

    const orderDeliveryPopupTitles = [
        {
            id: 1,
            name: t('delivery-detail-popup_delivery-num'),
        },
        {
            id: 2,
            name: t('delivery-detail-popup_order'),
        },
        {
            id: 3,
            name: t('delivery-detail-popup_num-of-parts'),
        },
        {
            id: 4,
            name: t('delivery-detail-popup_delivery-note'),
        },
    ];

    return (
        <>
            {open ? (
                <>
                    <button
                        className={classes['details-popup__close-btn']}
                        onClick={handlePopupClose}
                    >
                        <i className="icon-x-sign"></i>
                    </button>
                    <div className={classes['details-popup__basic-info']}>
                        <h2 className={classes['details-popup__part-name']}>
                            {t('delivery-detail-popup_heading')}
                        </h2>
                    </div>
                    <div className={classes['details-popup__specifications']}>
                        <div
                            className={
                                classes['details-popup__specifications-group']
                            }
                        >
                            <div
                                className={
                                    classes['details-popup__specification']
                                }
                            >
                                <span>
                                    {t('delivery-detail-popup_shipping-date')}
                                </span>
                                <span>
                                    {detailsPopup.deliveryDate
                                        ? moment(
                                              detailsPopup.deliveryDate
                                          ).format('DD.MM.YYYY')
                                        : '-'}
                                </span>
                            </div>
                            <div
                                className={
                                    classes['details-popup__specification']
                                }
                            >
                                <span>
                                    {t('delivery-detail-popup_provider')}
                                </span>
                                <span>
                                    {detailsPopup.provider
                                        ? detailsPopup.provider
                                        : '-'}
                                </span>
                            </div>
                            <div
                                className={
                                    classes['details-popup__specification']
                                }
                            >
                                <span>
                                    {t('delivery-detail-popup_tracking-num')}
                                </span>
                                <span>
                                    {detailsPopup.trackingUrl ? (
                                        <a
                                            className={
                                                classes[
                                                    'details-popup__link-with-icon'
                                                ]
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                            href={detailsPopup.trackingUrl}
                                        >
                                            <i className="icon-expand"></i>
                                            <span>
                                                {detailsPopup.trackingCode}
                                            </span>
                                        </a>
                                    ) : (
                                        detailsPopup.trackingCode
                                    )}
                                </span>
                            </div>
                        </div>
                        <div
                            className={
                                classes['details-popup__specifications-group']
                            }
                        >
                            <div
                                className={
                                    classes['details-popup__specification']
                                }
                            >
                                <span>{t('delivery-detail-popup_kind')}</span>
                                <span>
                                    {detailsPopup.type
                                        ? detailsPopup.type
                                        : '-'}
                                </span>
                            </div>
                            <div
                                className={
                                    classes['details-popup__specification']
                                }
                            >
                                <span>{t('delivery-detail-popup_weight')}</span>
                                <span>
                                    {detailsPopup.weight
                                        ? detailsPopup.weight
                                        : '-'}
                                </span>
                            </div>
                            <div
                                className={
                                    classes['details-popup__specification']
                                }
                            ></div>
                        </div>
                        <TableList
                            tableTitles={orderDeliveryPopupTitles}
                            tableData={parts}
                            dropdownTitles={dropdownTitles}
                            tableType="orderDeliveryPopup"
                            emptyStHeading="Noch keine Rahmenverträge vorhanden"
                            emptyStText="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua."
                            emptyStBtnText=""
                            details={parts}
                        />
                        {detailsPopup.otherOders?.length ? (
                            <ErrorMessage
                                hasIcon={true}
                                type="orderDeliveryPopup"
                                relatedOrders={detailsPopup.otherOders}
                                bgColor="yellow"
                            />
                        ) : null}
                    </div>
                </>
            ) : null}
        </>
    );
}

export default OrdersPopup;
