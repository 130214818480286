import React from 'react';
import classes from './FormError.module.scss';

function FormError(props) {
    return (
        <div className={classes['c-error']}>
            <p className={classes['c-error__message']}>
                {props.children}
            </p>
        </div>
    )
}

export default FormError