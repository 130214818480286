import * as actionTypes from '../actions/detailsActions';
import { orderBy } from 'lodash';

const initialState = {
    details: {},
    detailsLoading: true,
    singleOfferLines: [],
    searchedSingleOfferLines: [],
    frameworkOfferLines: [],
    searchedFrameworkOfferLines: [],
    orderLines: [],
    searchedOrderLines: [],
    searchedFrameworkLines: [],
    orderShipments: [],
    searchedOrderShipments: [],
    orderInvoices: [],
    searchedOrderInvoices: [],
    frameworkLines: [],
    frameworkOrders: [],
    searchedFrameworkOrders: [],
    frameworkInvoices: [],
    searchedFrameworkInvoices: [],
};

const tableContentReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_DETAILS:
            return {
                ...state,
                details: action.details,
                detailsLoading: false,
                singleOfferLines: action.details.singleOfferLines?.length
                    ? action.details.singleOfferLines
                    : [],
                searchedSingleOfferLines: action.details.singleOfferLines
                    ?.length
                    ? action.details.singleOfferLines
                    : [],
                frameworkOfferLines: action.details.frameworkOfferLines?.length
                    ? action.details.frameworkOfferLines
                    : [],
                searchedFrameworkOfferLines: action.details.frameworkOfferLines
                    ?.length
                    ? action.details.frameworkOfferLines
                    : [],
                orderLines: action.details.orderLines?.length
                    ? action.details.orderLines
                    : [],
                searchedOrderLines: action.details.orderLines?.length
                    ? action.details.orderLines
                    : [],
                frameworkLines: action.details.frameworkLines?.length
                    ? action.details.frameworkLines
                    : [],
                searchedFrameworkLines: action.details.frameworkLines?.length
                    ? action.details.frameworkLines
                    : [],
                orderShipments: action.details.shipments?.length
                    ? action.details.shipments
                    : [],
                searchedOrderShipments: action.details.shipments?.length
                    ? action.details.shipments
                    : [],
                orderInvoices: action.details.invoices?.length
                    ? action.details.invoices
                    : [],
                searchedOrderInvoices: action.details.invoices?.length
                    ? action.details.invoices
                    : [],
                frameworkOrders: action.details.orders?.length
                    ? action.details.orders
                    : [],
                searchedFrameworkOrders: action.details.orders?.length
                    ? action.details.orders
                    : [],
                frameworkInvoices: action.details.invoices?.length
                    ? action.details.invoices
                    : [],
                searchedFrameworkInvoices: action.details.invoices?.length
                    ? action.details.invoices
                    : [],
            };
        case actionTypes.SEARCH_DETAILS:
            let searchedSingleOfferLines = [];
            let searchedFrameworkOfferLines = [];
            let searchedOrderLines = [];
            let searchedFrameworkLines = [];
            let searchedOrderShipments = [];
            let searchedOrderInvoices = [];
            let searchedFrameworkOrders = [];
            let searchedFrameworkInvoices = [];

            if (action.tableType === 'offerComponents') {
                if (state.singleOfferLines.length) {
                    searchedSingleOfferLines = state.singleOfferLines.filter(
                        (item) =>
                            JSON.stringify(item)
                                .toLowerCase()
                                .includes(action.searchQuery.toLowerCase())
                    );
                }
                if (state.frameworkOfferLines.length) {
                    searchedFrameworkOfferLines =
                        state.frameworkOfferLines.filter((item) =>
                            JSON.stringify(item)
                                .toLowerCase()
                                .includes(action.searchQuery.toLowerCase())
                        );
                }
            } else if (action.tableType === 'orderComponents') {
                if (state.orderLines.length) {
                    searchedOrderLines = state.orderLines.filter((item) =>
                        JSON.stringify(item)
                            .toLowerCase()
                            .includes(action.searchQuery.toLowerCase())
                    );
                }
                searchedOrderShipments = state.searchedOrderShipments;
                searchedOrderInvoices = state.searchedOrderInvoices;
            } else if (action.tableType === 'frameworkComponents') {
                if (state.frameworkLines.length) {
                    searchedFrameworkLines = state.frameworkLines.filter(
                        (item) =>
                            JSON.stringify(item)
                                .toLowerCase()
                                .includes(action.searchQuery.toLowerCase())
                    );
                }
                searchedFrameworkInvoices = state.frameworkInvoices;
                searchedFrameworkOrders = state.frameworkOrders;
            } else if (action.tableType === 'orderDeliveries') {
                if (state.orderShipments.length) {
                    searchedOrderShipments = state.orderShipments.filter(
                        (item) =>
                            JSON.stringify(item)
                                .toLowerCase()
                                .includes(action.searchQuery.toLowerCase())
                    );
                }
                searchedOrderLines = state.searchedOrderLines;
                searchedOrderInvoices = state.searchedOrderInvoices;
            } else if (action.tableType === 'orderInvoices') {
                if (state.orderInvoices.length) {
                    searchedOrderInvoices = state.orderInvoices.filter((item) =>
                        JSON.stringify(item)
                            .toLowerCase()
                            .includes(action.searchQuery.toLowerCase())
                    );
                }
                searchedOrderShipments = state.searchedOrderShipments;
                searchedOrderLines = state.searchedOrderLines;
            } else if (action.tableType === 'frameworkDeliveries') {
                if (state.frameworkOrders.length) {
                    searchedFrameworkOrders = state.frameworkOrders.filter(
                        (item) =>
                            JSON.stringify(item)
                                .toLowerCase()
                                .includes(action.searchQuery.toLowerCase())
                    );
                }
                searchedFrameworkInvoices = state.frameworkInvoices;
                searchedFrameworkLines = state.frameworkLines;
            } else if (action.tableType === 'frameworkInvoices') {
                if (state.frameworkInvoices.length) {
                    searchedFrameworkInvoices = state.frameworkInvoices.filter(
                        (item) =>
                            JSON.stringify(item)
                                .toLowerCase()
                                .includes(action.searchQuery.toLowerCase())
                    );
                }
                searchedFrameworkOrders = state.frameworkOrders;
                searchedFrameworkLines = state.frameworkLines;
            }
            return {
                ...state,
                searchedSingleOfferLines: searchedSingleOfferLines,
                searchedFrameworkOfferLines: searchedFrameworkOfferLines,
                searchedOrderLines: searchedOrderLines,
                searchedFrameworkLines: searchedFrameworkLines,
                searchedOrderShipments: searchedOrderShipments,
                searchedOrderInvoices: searchedOrderInvoices,
                searchedFrameworkOrders: searchedFrameworkOrders,
                searchedFrameworkInvoices: searchedFrameworkInvoices,
            };
        case actionTypes.DETAILS_LOADING:
            return {
                ...state,
                detailsLoading: true,
            };
        case actionTypes.SORT_SINGLE_OFFERS:
            let sortedSingleOfferLines = state.searchedSingleOfferLines;
            var sortFilter = action.sortFilter;

            if (action.sortDirection === 'ASC') {
                if (action.sortFilter === 'material') {
                    sortedSingleOfferLines = orderBy(
                        sortedSingleOfferLines,
                        [`materialGroup`],
                        ['asc']
                    );
                } else {
                    sortedSingleOfferLines = orderBy(
                        sortedSingleOfferLines,
                        [`quantities[0].${sortFilter}`],
                        ['asc']
                    );
                }
            } else {
                if (action.sortFilter === 'material') {
                    sortedSingleOfferLines = orderBy(
                        sortedSingleOfferLines,
                        [`materialGroup`],
                        ['desc']
                    );
                } else {
                    sortedSingleOfferLines = orderBy(
                        sortedSingleOfferLines,
                        [`quantities[0].${sortFilter}`],
                        ['desc']
                    );
                }
            }

            return {
                ...state,
                searchedSingleOfferLines: sortedSingleOfferLines,
            };
        case actionTypes.SORT_FRAMEWORK_OFFERS:
            let sortedFrameworkOfferLines = state.searchedFrameworkOfferLines;

            if (action.sortDirection === 'ASC') {
                if (action.sortFilter === 'material') {
                    sortedFrameworkOfferLines = orderBy(
                        sortedFrameworkOfferLines,
                        [`materialGroup`],
                        ['asc']
                    );
                } else {
                    sortedFrameworkOfferLines = orderBy(
                        sortedFrameworkOfferLines,
                        [`quantities[0].${action.sortFilter}`],
                        ['asc']
                    );
                }
            } else {
                if (action.sortFilter === 'material') {
                    sortedFrameworkOfferLines = orderBy(
                        sortedFrameworkOfferLines,
                        [`materialGroup`],
                        ['desc']
                    );
                } else {
                    sortedFrameworkOfferLines = orderBy(
                        sortedFrameworkOfferLines,
                        [`quantities[0].${action.sortFilter}`],
                        ['desc']
                    );
                }
            }

            return {
                ...state,
                searchedFrameworkOfferLines: sortedFrameworkOfferLines,
            };

        case actionTypes.DETAILS_CLEANUP:
            return {
                ...state,
                details: {},
                detailsLoading: false,
                singleOfferLines: [],
                searchedSingleOfferLines: [],
                frameworkOfferLines: [],
                searchedFrameworkOfferLines: [],
                orderLines: [],
                searchedOrderLines: [],
                searchedFrameworkLines: [],
                orderShipments: [],
                searchedOrderShipments: [],
                orderInvoices: [],
                searchedOrderInvoices: [],
                frameworkLines: [],
                frameworkOrders: [],
                searchedFrameworkOrders: [],
                frameworkInvoices: [],
                searchedFrameworkInvoices: [],
            };
        default:
            return state;
    }
};

export default tableContentReducer;
