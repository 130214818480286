import { Field } from 'formik';
import React from 'react';
import classes from './ContactRadioInput.module.scss';

function ContactRadioInput(props) {
    const formatedProps = {
        ...props,
        //hackz
        touched: 'not-needed',
    };

    return (
        <label
            className={[
                classes['c-contact-radio-input'],
                props.selectedvalue === props.value && !props.disabled
                    ? classes['c-contact-radio-input--checked']
                    : '',
                props.disabled
                    ? classes['c-contact-radio-input--disabled']
                    : '',
            ].join(' ')}
        >
            <div className={classes['c-contact-radio-input__title-container']}>
                <div className={classes['c-contact-radio-input__title']}>
                    {props.title}
                </div>
                <div className={classes['c-contact-radio-input__circle']}></div>
            </div>
            <div className={classes['c-contact-radio-input__description']}>
                {props.description}
            </div>
            <Field
                {...formatedProps}
                disabled={props.disabled}
                type={props.type}
                value={props.value}
            />
        </label>
    );
}

export default ContactRadioInput;
