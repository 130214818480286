function FormatedPrice({ price, decimal, number }) {
    return (
        <>
            {price || price === 0
                ? decimal
                    ? new Intl.NumberFormat('de-DE').format(price) + ' €'
                    : new Intl.NumberFormat('de-DE', {
                          style: 'currency',
                          currency: 'EUR',
                      }).format(price)
                : new Intl.NumberFormat('de-DE').format(number)}
        </>
    );
}

export default FormatedPrice;
