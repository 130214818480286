import TableItemWithImage from '../../../Details/TableItemWithImage/TableItemWithImage';
import FormatedPrice from '../../../FormatedPrice/FormatedPrice';
import classes from './TableListItemHeader.module.scss';
import { useTranslation } from 'react-i18next';

function TableListItemHeader({ id, data, title, tableType, details }) {
    let formatedData;
    const { t } = useTranslation();

    if (data?.partName) {
        formatedData = (
            <div
                className={[
                    classes['c-table-list-item-header'],
                    `c-table-item__${tableType}-list`,
                    classes[`${tableType}`],
                ].join(' ')}
            >
                <TableItemWithImage
                    partImage={data.partImage}
                    title={title}
                    partName={data.partName}
                    partNumber={data.partNumber}
                    details={details}
                    tableType={tableType}
                />
            </div>
        );
    } else if (data?.downloadFileName) {
        const downloadPdf = () => {
            let link = document.createElement('a');
            link.href = data.downloadUrl;
            link.download = data.downloadFileName;
            link.click();
        };

        formatedData = (
            <div
                className={[
                    classes['c-table-list-item-header'],
                    classes[`${tableType}`],
                ].join(' ')}
            >
                <div
                    className={classes['c-table-list-item-header__data-title']}
                >
                    {title}
                </div>
                <div
                    className={
                        classes[
                            'c-table-list-item-header__data-container c-table-list-item-header__data-container--download'
                        ]
                    }
                >
                    {data?.downloadUrl && data?.downloadFileName ? (
                        <span
                            onClick={downloadPdf}
                            className={[
                                classes['c-table-list-item-header__data'],
                                classes[
                                    'c-table-list-item-header__data--download'
                                ],
                            ].join(' ')}
                        >
                            <i className="icon-pdf"></i>
                            {data?.downloadFileName}
                        </span>
                    ) : (
                        <span
                            className={
                                classes['c-table-list-item-header__data']
                            }
                        >
                            {data?.downloadFileName}
                        </span>
                    )}
                </div>
            </div>
        );
    } else {
        formatedData = (
            <div
                className={[
                    classes['c-table-list-item-header'],
                    classes[`${tableType}`],
                ].join(' ')}
            >
                <div
                    className={classes['c-table-list-item-header__data-title']}
                >
                    {title}
                </div>
                <div
                    className={
                        classes['c-table-list-item-header__data-container']
                    }
                >
                    <span className={classes['c-table-list-item-header__data']}>
                        {(tableType === 'orderComponents' ||
                            tableType === 'frameworkComponents') &&
                        id === 6 ? (
                            <div>
                                <FormatedPrice price={data} decimal={true} />
                            </div>
                        ) : (tableType === 'orderComponents' ||
                              tableType === 'frameworkComponents') &&
                          id === 5 ? (
                            <div>
                                <FormatedPrice number={data} />{' '}
                                {t('global_pieces')}
                            </div>
                        ) : (tableType === 'orderComponents' ||
                              tableType === 'frameworkComponents') &&
                          (id === 1 || id === 2 || id === 3 || id === 4) ? (
                            data !== '-' ? (
                                <FormatedPrice number={data} />
                            ) : (
                                '-'
                            )
                        ) : (
                            data
                        )}
                    </span>
                    {(tableType === 'orderComponents' ||
                        tableType === 'frameworkComponents') &&
                    (id === 1 || id === 2 || id === 3) ? (
                        <span
                            className={
                                classes['c-table-list-item-header__in-progress']
                            }
                        ></span>
                    ) : null}
                </div>
            </div>
        );
    }

    return formatedData;
}

export default TableListItemHeader;
