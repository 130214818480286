import React, { useEffect, useState } from 'react';
import PageMeta from '../../shared/PageMeta/PageMeta';
import { getTableData } from '../../store/thunks/tableDataThunks';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Table from '../../components/Table/Table';
import PageTitle from '../../components/PageHeader/PageTitle/PageTitle';
import { onTableContentCleanup } from '../../store/actions/tableContentActions';
import PageSearch from '../../components/PageHeader/PageSearch/PageSearch';
import DatePicker from '../../components/DatePicker/DatePicker';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import PageTabs from '../../components/PageHeader/PageTabs/PageTabs';
import Select from 'react-select';
import useBreakpoint from '../../hooks/useBreakpoint';
import classes from './FrameworkAgreements.module.scss';
import setGoogleAnalytics from '../../hooks/setGoogleAnalytics';

function FrameworkAgreements() {
    const dispatch = useDispatch();
    const [windowType, setWindowType] = useState('Active');
    const [filtersUsed, setfiltersUsed] = useState(false);
    const [loading, setLoading] = useState(true);

    const tableContent = useSelector(
        (state) => state.tableContent.tableContent
    );
    const tableContentLoading = useSelector(
        (state) => state.tableContent.tableContentLoading
    );

    const [tableTitles, setTableTitles] = useState([]);
    const [tableData, setTableData] = useState('');
    const [finalDates, setFinalDates] = useState({
        startDate: moment().startOf('year'),
        endDate: moment().endOf('year'),
    });

    //eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();

    const [type, setType] = useState('');

    const { t } = useTranslation();

    const breakpoint = useBreakpoint();

    useEffect(() => {
        //filter logic
        //on every search param change this gets triggered
        //dispatches thunk to get all orders
        //thunk takes search params from URL and voila
        const searchParams = new URLSearchParams(window.location.search);
        const type = searchParams.get('type');
        const startDate = searchParams.get('startDate');
        const endDate = searchParams.get('endDate');
        if (startDate && endDate) {
            setFinalDates({
                startDate: moment(startDate, 'YYYY-MM-DD'),
                endDate: moment(endDate, 'YYYY-MM-DD'),
            });
        }
        const searchFilter = searchParams.get('searchFilter');

        // check if there is any param
        const hasFilters = searchFilter || startDate || endDate;
        setfiltersUsed(hasFilters);

        if (!tableContentLoading) {
            if (type === 'Active') {
                setType({
                    label: t('fa-tab-header_active'),
                    value: 'Active',
                });
            } else {
                setType({ label: t('fa-tab-header_completed'), value: type });
            }
            dispatch(getTableData('frameworkAgreement'));
        }

        setWindowType(type);
        //eslint-disable-next-line
    }, [window.location.search]);

    useEffect(() => {
        //filtering and taking only neccessery data for table
        const filteredTableData = tableContent.map((item) => {
            return {
                id: item.faId,
                data: {
                    faNr: item.faNr ? item.faNr : '-',
                    reference: item.reference ? item.reference : '-',
                    startDate: item.startDate
                        ? moment(item.startDate).format('DD.MM.YYYY')
                        : '-',
                    endDate: item.endDate
                        ? moment(item.endDate).format('DD.MM.YYYY')
                        : '-',
                    numberOfParts: item.numberOfParts
                        ? //partsOrderedPercentage , partsShippedPercentage, partsPaidPercentage
                          item.numberOfParts
                        : '-',
                    partsOrderedPercentage:
                        item.partsOrderedPercentage >= 0 &&
                        item.partsOrderedPercentage !== null
                            ? `${item.partsOrderedPercentage} %`
                            : '-',
                    partsShippedPercentage:
                        item.partsShippedPercentage >= 0 &&
                        item.partsShippedPercentage !== null
                            ? `${item.partsShippedPercentage} %`
                            : '-',
                    partsPaidPercentage:
                        item.partsPaidPercentage >= 0 &&
                        item.partsPaidPercentage !== null
                            ? `${item.partsPaidPercentage} %`
                            : '-',
                },
            };
        });
        //splitting array into object of arrays with year key
        //if there's no year NaN is key
        setTableData(filteredTableData);
    }, [tableContent]);

    //sortFilter - sort filter that is going to appear in search params
    //sortFilter is not needed if sortable is false
    //sortable - whether field is sortable or not
    useEffect(() => {
        setTableTitles([
            {
                id: 1,
                name: t('fa-table-columns_contract-number'),
                sortFilter: 'faNr',
                sortable: true,
            },
            {
                id: 2,
                name: t('fa-table-columns_reference'),
                sortFilter: 'reference',
                sortable: true,
            },
            {
                id: 3,
                name: t('fa-table-columns_start-of-contract'),
                sortFilter: 'startDate',
                sortable: true,
            },
            {
                id: 4,
                name: t('fa-table-columns_end-of-contract'),
                sortFilter: 'endDate',
                sortable: true,
            },
            {
                id: 5,
                name: t('fa-table-columns_components'),
                sortFilter: 'numberOfParts',
                sortable: true,
            },
            {
                id: 6,
                name: t('fa-table-columns_ordered'),
                sortFilter: 'totalPartsOrdered',
                sortable: true,
            },
            {
                id: 7,
                name: t('fa-table-columns_delivered'),
                sortFilter: 'totalPartsDelivered',
                sortable: true,
            },
            {
                id: 8,
                name: t('fa-table-columns_billed'),
                sortFilter: 'totalPartsInvoiced',
                sortable: true,
            },
        ]);
    }, [t, type]);

    const dateChangeHandler = (dates) => {
        setFinalDates({
            startDate: dates.startDate,
            endDate: dates.endDate,
        });
        if (
            (dates.endDate?._d !== finalDates.endDate?._d ||
                dates.startDate?._d !== finalDates.startDate?._d) &&
            finalDates.endDate?._d !== undefined &&
            dates.endDate?._d !== undefined
        ) {
            const searchParams = new URLSearchParams(window.location.search);

            const startDateParam = searchParams.get('startDate');
            const endDateParam = searchParams.get('endDate');

            if (startDateParam && endDateParam) {
                searchParams.set(
                    'startDate',
                    moment(dates.startDate).format('YYYY-MM-DD')
                );
                searchParams.set(
                    'endDate',
                    moment(dates.endDate).format('YYYY-MM-DD')
                );
            } else {
                searchParams.append(
                    'startDate',
                    moment(dates.startDate).format('YYYY-MM-DD')
                );
                searchParams.append(
                    'endDate',
                    moment(dates.endDate).format('YYYY-MM-DD')
                );
            }
            setSearchParams(searchParams);
        }
    };

    //cleanup
    //onTableContentCleanup sets loading to true and clears table array
    useEffect(
        () => () => {
            if (!window.location.href.includes('framework-agreements')) {
                dispatch(onTableContentCleanup());
            }
        },
        //eslint-disable-next-line
        []
    );

    // reactga4 for framework agreements overview
    useEffect(() => {
        setGoogleAnalytics(
            'page_view',
            'page_view_fa_overview',
            'view fa page, regardless of tabs'
        );
    }, []);

    const frameworkTabs = [
        {
            id: '1',
            label: t('fa-tab-header_active'),
            value: 'Active',
        },
        {
            id: '2',
            label: t('fa-tab-header_completed'),
            value: 'Closed',
        },
    ];

    const onTypeChange = (selectedType) => {
        const params = new URLSearchParams();
        // const currentParams = new URLSearchParams(window.location.search);
        // const type = currentParams.get('type');
        params.set('type', selectedType.value);
        setSearchParams(params);
    };

    // TODO REWORK WHEN DITTO WORKS AGAIN
    const getEmptyStateMessage = (whoseState, stateType, statePlace) => {
        const emptyStatesObject = t('emptyState');
        const emptyStateJson = JSON.parse(emptyStatesObject);
        return emptyStateJson[whoseState][stateType][statePlace];
    };

    // fix for table empty state showing briefly after loading
    useEffect(() => {
        setLoading(tableContentLoading);
    }, [tableContentLoading]);

    return (
        <>
            <PageMeta>
                <title>{t('fa_header')} | CNC24</title>
            </PageMeta>

            <div className={[classes['c-framework'], 'l-container'].join(' ')}>
                <PageTitle title={t('fa_header')} />
                <div className="l-container__actions">
                    <PageSearch type="framework-agreements" />
                    <div className="l-container__tabs-picker">
                        {windowType === 'Closed' ? (
                            <DatePicker
                                onDateChange={dateChangeHandler}
                                initialValue={finalDates}
                                type="frameworkAgreements"
                                datepickerType="rangeDate"
                            />
                        ) : null}
                        {breakpoint > 575 ? (
                            <PageTabs
                                tabs={frameworkTabs}
                                pageName="framework"
                            />
                        ) : (
                            <Select
                                className="c-react-select c-react-select--max-492"
                                name="language-selector"
                                options={frameworkTabs}
                                onChange={onTypeChange}
                                value={type}
                                classNamePrefix="c-react-select"
                            />
                        )}
                    </div>
                </div>
                <>
                    <Table
                        tableTitles={tableTitles}
                        tableData={tableData}
                        loading={loading}
                        tableType="framework"
                        emptyStHeading={
                            filtersUsed
                                ? t('fa-empty-state_heading')
                                : getEmptyStateMessage(
                                      'faAgreementsEmptyState',
                                      'InitialEmptyState',
                                      'heading'
                                  )
                        }
                        emptyStText={
                            filtersUsed
                                ? t('fa-empty-state_paragraph')
                                : getEmptyStateMessage(
                                      'faAgreementsEmptyState',
                                      'InitialEmptyState',
                                      'text'
                                  )
                        }
                        emptyStBtnText={
                            filtersUsed
                                ? t('fa-empty-state_btn-text')
                                : getEmptyStateMessage(
                                      'faAgreementsEmptyState',
                                      'InitialEmptyState',
                                      'button'
                                  )
                        }
                        linkableRows={true}
                    />
                </>
            </div>
        </>
    );
}

export default FrameworkAgreements;
