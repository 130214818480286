import classes from '../DetailsSideScreen.module.scss';
import { useTranslation } from 'react-i18next';
import useBreakpoint from '../../../../hooks/useBreakpoint';
import { useRef, useEffect, useState } from 'react';
import SidebarDataTitleLabel from '../../../SkeletonLoader/SidebarDataTitleLabel';
import SidebarDataContent from '../../../SkeletonLoader/SidebarDataContent';
import SidebarDocsAndFaLabel from '../../../SkeletonLoader/SidebarDocsAndFaLabel';
import Inquiry from '../Inquiry/Inquiry';
import TotalPrice from '../TotalPrice/TotalPrice';
import { useSelector } from 'react-redux';
import DisplayedFile from '../DisplayedFile/DisplayedFile';
import PriceDropdown from '../PriceDropdown/PriceDropdown';

function DetailsSideScreenExpandContainer({
    data,
    pageType,
    onExpandedClickHandler,
    loading,
    priceDropdownExpanded,
}) {
    const [expanded, setExpanded] = useState(false);
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const [height, setHeight] = useState(null);
    const expandableContainer = useRef(null);
    const details = useSelector((state) => state.details.details);

    useEffect(() => {
        setHeight(expandableContainer.current.scrollHeight);
    }, [breakpoint, loading]);

    const downloadPdf = (index) => {
        let link = document.createElement('a');
        link.href = data.files[index]?.fileDownloadUrl;
        link.download = data.files[index]?.fileName;
        link.click();
    };

    const expandContainerHandler = () => {
        onExpandedClickHandler(!expanded);
        setExpanded(!expanded);
    };

    useEffect(() => {
        onExpandedClickHandler(true);
        setExpanded(true);
        //eslint-disable-next-line
    }, [priceDropdownExpanded]);

    const getFileType = (file) => {
        const fileName = file.fileName;
        const fileLastThreeLetters = fileName?.slice(-3);

        if (fileLastThreeLetters === 'pdf') {
            return 'pdf';
        } else if (fileLastThreeLetters === 'zip') {
            return 'zip';
        } else if (fileLastThreeLetters === 'xls') {
            return 'xls';
        } else if (fileLastThreeLetters === 'csv') {
            return 'csv';
        } else {
            return '';
        }
    };

    return (
        <div
            ref={expandableContainer}
            style={{
                maxHeight: expanded
                    ? pageType === 'orders'
                        ? height + 229
                        : height
                    : null,
            }}
            className={[
                classes['c-details-sidescreen__expand-container'],
                expanded ? classes['expanded'] : null,
            ].join(' ')}
        >
            {breakpoint < 1281 ? (
                <div
                    className={
                        classes['c-details-sidescreen__expand-action-box']
                    }
                >
                    <div
                        onClick={expandContainerHandler}
                        className={
                            classes['c-details-sidescreen__expand-action']
                        }
                    >
                        {expanded
                            ? t('global_close-details')
                            : t('global_view-details')}
                    </div>
                </div>
            ) : null}

            <div className={classes['c-details-sidescreen__expand-content']}>
                {pageType === 'offers' && details.status === 'New' ? (
                    <>
                        <div
                            className={
                                classes['c-details-sidescreen__parts-comments']
                            }
                        >
                            <div
                                className={
                                    classes['c-details-sidescreen__detail']
                                }
                            >
                                <SidebarDataTitleLabel
                                    labelName={t('global_number-of-parts')}
                                />
                                <SidebarDataContent
                                    data={
                                        details.numberOfParts
                                            ? details.numberOfParts
                                            : '-'
                                    }
                                />
                            </div>
                            <div
                                className={`${classes['c-details-sidescreen__detail']} ${classes['c-details-sidescreen__detail--comments']}`}
                            >
                                <SidebarDataTitleLabel
                                    labelName={t('details_comments')}
                                />
                                <SidebarDataContent
                                    data={
                                        details.comments
                                            ? details.comments
                                            : '-'
                                    }
                                />
                            </div>
                        </div>
                        <div
                            className={`${classes['c-details-sidescreen__detail']} ${classes['c-details-sidescreen__detail--uploaded-files']}`}
                        >
                            <SidebarDataTitleLabel
                                labelName={t('global_uploaded-files')}
                            />
                            <div
                                className={
                                    classes[
                                        'c-details-sidescreen__num-of-uploaded-files'
                                    ]
                                }
                            >
                                <SidebarDataContent
                                    data={
                                        details.files?.length
                                            ? details.files?.length
                                            : null
                                    }
                                />
                            </div>
                            <SidebarDataContent
                                loading={loading}
                                className={
                                    classes[
                                        'c-details-sidescreen__status-data-skeleton--mt-24'
                                    ]
                                }
                                additionalClass={`${
                                    classes[
                                        'c-details-sidescreen__document-download'
                                    ]
                                } ${
                                    !details.files?.length
                                        ? classes[
                                              'c-details-sidescreen__document-download--no-cursor'
                                          ]
                                        : ''
                                }
                                ${
                                    classes[
                                        'c-details-sidescreen__files-uploaded'
                                    ]
                                }
                                `}
                                data={
                                    details.files?.length
                                        ? details.files.map((file, index) => {
                                              return (
                                                  <DisplayedFile
                                                      key={`file-${index}`}
                                                      index={index}
                                                      file={file}
                                                      details={details}
                                                      data={data}
                                                      fileType={getFileType(
                                                          file
                                                      )}
                                                  />
                                              );
                                          })
                                        : '-'
                                }
                            />
                        </div>
                    </>
                ) : null}
                {pageType !== 'frameworkAgreement' && pageType !== 'offers' ? (
                    <div className={classes['c-details-sidescreen__detail']}>
                        <SidebarDataTitleLabel
                            loading={loading}
                            labelName={t(
                                'offers-single-order-sidebar_delivery-address'
                            )}
                        />

                        <SidebarDataContent
                            loading={loading}
                            data={
                                <div>
                                    {data.shippingCompany && (
                                        <div>{data.shippingCompany}</div>
                                    )}
                                    {data.shippingStreet && (
                                        <div>{data.shippingStreet}</div>
                                    )}

                                    {(data.shippingPostalCode ||
                                        data.shippingCity ||
                                        data.shippingCountryName) && (
                                        <div
                                            className={
                                                classes[
                                                    'c-details-sidescreen__one-line'
                                                ]
                                            }
                                        >
                                            {data.shippingPostalCode && (
                                                <span>
                                                    {data.shippingPostalCode}
                                                    {data.shippingCity
                                                        ? ' '
                                                        : !data.shippingCity
                                                        ? ', '
                                                        : null}
                                                </span>
                                            )}
                                            {data.shippingCity && (
                                                <span>
                                                    {data.shippingCity}
                                                    {data.shippingCountryName
                                                        ? ', '
                                                        : null}
                                                </span>
                                            )}
                                            {data.shippingCountryName && (
                                                <span>
                                                    {data.shippingCountryName}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                </div>
                            }
                        />
                    </div>
                ) : null}
                {pageType === 'orders' ? (
                    <div className={classes['c-details-sidescreen__detail']}>
                        <SidebarDataTitleLabel
                            loading={loading}
                            labelName={t(
                                'order-details-sidebar_billing-address'
                            )}
                        />

                        <SidebarDataContent
                            loading={loading}
                            data={
                                <div>
                                    {data.billingCompany && (
                                        <div>{data.billingCompany}</div>
                                    )}
                                    {data.billingStreet && (
                                        <div>{data.billingStreet}</div>
                                    )}

                                    {(data.billingPostalCode ||
                                        data.billingCity ||
                                        data.billingCountryName) && (
                                        <div
                                            className={
                                                classes[
                                                    'c-details-sidescreen__one-line'
                                                ]
                                            }
                                        >
                                            {data.billingPostalCode && (
                                                <span>
                                                    {data.billingPostalCode}
                                                    {data.billingCity
                                                        ? ' '
                                                        : data.billingCountryName
                                                        ? ', '
                                                        : null}
                                                </span>
                                            )}
                                            {data.billingCity && (
                                                <span>
                                                    {data.billingCity}
                                                    {data.billingCountryName
                                                        ? ', '
                                                        : null}
                                                </span>
                                            )}
                                            {data.billingCountryName && (
                                                <span>
                                                    {data.billingCountryName}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                    {!data.billingCompany &&
                                        !data.billingStreet &&
                                        !data.billingPostalCode &&
                                        !data.billingCity &&
                                        !data.billingCountryName &&
                                        '-'}
                                </div>
                            }
                        />
                    </div>
                ) : null}
                {(pageType === 'offers' && !data.productionCertificates) ||
                (pageType === 'offers' && !data.productionCountry) ||
                (pageType === 'offers' &&
                    !data.productionCountry &&
                    !data.productionCertificates &&
                    (breakpoint > 1280 ||
                        (breakpoint < 577 && breakpoint > 374))) ? (
                    <div
                        className={classes['c-details-sidescreen__detail']}
                    ></div>
                ) : null}
                {pageType === 'offers' && data.productionCertificates ? (
                    <div className={classes['c-details-sidescreen__detail']}>
                        <SidebarDataTitleLabel
                            loading={loading}
                            labelName={t(
                                'offers-single-order-sidebar_certificates'
                            )}
                        />

                        <SidebarDataContent
                            loading={loading}
                            data={
                                data.productionCertificates
                                    ? data.productionCertificates.join(', ')
                                    : '-'
                            }
                        />
                    </div>
                ) : null}
                {pageType === 'offers' && data.productionCountry ? (
                    <div className={classes['c-details-sidescreen__detail']}>
                        <SidebarDataTitleLabel
                            loading={loading}
                            labelName={t('offers-single-order-sidebar_origin')}
                        />

                        <SidebarDataContent
                            loading={loading}
                            data={
                                data.productionCountry
                                    ? data.productionCountry
                                    : '-'
                            }
                        />
                    </div>
                ) : null}
                {pageType === 'offers' &&
                ((data.productionCertificates && !data.productionCountry) ||
                    (!data.productionCertificates &&
                        data.productionCountry)) ? (
                    <div
                        className={classes['c-details-sidescreen__detail']}
                    ></div>
                ) : null}
                {pageType === 'orders' &&
                breakpoint < 1281 &&
                breakpoint > 576 ? (
                    <div
                        className={classes['c-details-sidescreen__detail']}
                    ></div>
                ) : null}
                {pageType === 'orders' ||
                (pageType === 'frameworkAgreement' &&
                    (breakpoint > 1280 || breakpoint < 577)) ? (
                    <Inquiry loading={loading} details={data} t={t} />
                ) : null}
                {(data.status === 'Offer created' ||
                    data.status === 'Offersent' ||
                    data.status === 'UpdateSent' ||
                    data.status === 'InNegotiation' ||
                    data.status === 'Offeraccepted' ||
                    data.status === 'Lost') &&
                breakpoint > 1280 ? (
                    <TotalPrice
                        details={data}
                        t={t}
                        loading={loading}
                        pageType={pageType}
                    />
                ) : null}
                {/* TODO need to replace hardocded values */}
                {(pageType === 'orders' || pageType === 'frameworkAgreement') &&
                breakpoint > 1280 ? (
                    <PriceDropdown
                        data={data}
                        pageType={pageType}
                        expandedContainerStatus={expanded}
                    />
                ) : null}
                {pageType === 'orders' &&
                data.typeOfOrder === 'Framework Order' ? (
                    <div
                        className={classes['c-details-sidescreen__boxed-info']}
                    >
                        <h4>
                            {t('order-details-sidebar_framework-agreement')}
                        </h4>
                        <div
                            className={
                                classes[
                                    'c-details-sidescreen__document-download'
                                ]
                            }
                        >
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={`${window.location.origin}/framework-agreements/${data.faId}/components?type=Overview`}
                                className={
                                    classes['c-details-sidescreen__link']
                                }
                            >
                                <i className="icon-rectangle-layer"></i>
                                {data.faNr}
                            </a>
                        </div>
                    </div>
                ) : null}
                {pageType === 'orders' ||
                data.status === 'Offer created' ||
                data.status === 'Offersent' ||
                data.status === 'UpdateSent' ||
                data.status === 'InNegotiation' ||
                data.status === 'Offeraccepted' ||
                data.status === 'Lost' ||
                pageType === 'frameworkAgreement' ? (
                    <div
                        className={[
                            classes['c-details-sidescreen__boxed-info'],
                            pageType === 'orders' ||
                            pageType === 'frameworkAgreement'
                                ? classes[
                                      'c-details-sidescreen__boxed-info--limited'
                                  ]
                                : '',
                        ].join(' ')}
                    >
                        <div>
                            <SidebarDocsAndFaLabel
                                loading={loading}
                                labelName={t('order-details-sidebar_documents')}
                            />

                            <SidebarDataContent
                                loading={loading}
                                className={
                                    classes[
                                        'c-details-sidescreen__status-data-skeleton--mt-24'
                                    ]
                                }
                                additionalClass={`${
                                    classes[
                                        'c-details-sidescreen__document-download'
                                    ]
                                } ${
                                    !data.files?.length
                                        ? classes[
                                              'c-details-sidescreen__document-download--no-cursor'
                                          ]
                                        : ''
                                }`}
                                data={
                                    data.files?.length
                                        ? data.files.map((file, index) => {
                                              return (
                                                  <div
                                                      key={`pdf-${index}`}
                                                      className={
                                                          classes[
                                                              'c-details-sidescreen__download-pdf-container'
                                                          ]
                                                      }
                                                  >
                                                      <span
                                                          key={index}
                                                          onClick={
                                                              data.files?.length
                                                                  ? () => {
                                                                        downloadPdf(
                                                                            index
                                                                        );
                                                                    }
                                                                  : null
                                                          }
                                                          className={
                                                              classes[
                                                                  'c-details-sidescreen__download-pdf'
                                                              ]
                                                          }
                                                      >
                                                          <i
                                                              className={`icon-${getFileType(
                                                                  file
                                                              )}`}
                                                          ></i>
                                                          {file.fileName}
                                                      </span>
                                                  </div>
                                              );
                                          })
                                        : '-'
                                }
                            />
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

export default DetailsSideScreenExpandContainer;
