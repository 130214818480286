import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import Checkbox from '../../../../../components/Input/Checkbox/Checkbox';
import Table from '../../../../../components/Table/Table';
import WizzardFooter from '../../../../../components/Wizzard/WizzardFooter/WizzardFooter';
import wizardStyles from '../../../../../components/Wizzard/WizzardGeneral.module.scss';
import classes from '../../../../../components/Wizzard/WizzardSummary.module.scss';
import UploadedFile from '../../../../../components/UploadedFile/UploadedFile';
import { useTranslation } from 'react-i18next';
import { acceptFa } from '../../../../../store/thunks/acceptOfferThunk';
import { useNavigate } from 'react-router-dom';
import { onClearWizzard } from '../../../../../store/actions/offersWizzardActions';
import { getTax } from '../../../../../store/thunks/offersWizzardThunk';
import moment from 'moment';
import FormatedPrice from '../../../../../components/FormatedPrice/FormatedPrice';
import TranslatedComponent from '../../../../../components/TranslatedComponent/TranslatedComponent';

function Summary() {
    const [tableData, setTableData] = useState([]);
    // const [buttonDisabled, setButtonDisabled] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [taxPrice, setTaxPrice] = useState(0);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [countries, setCountries] = useState();
    const [contacts, setContacts] = useState();
    const frameworkWizzardReducer = useSelector(
        (state) => state.frameworkWizzardReducer
    );
    const userReducer = useSelector((state) => state.user);
    const { t } = useTranslation();

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const tableTitles = [
        {
            id: 1,
            name: t('wizard-table_designation'),
        },
        {
            id: 2,
            name: t('wizard-table_article-num'),
        },
        {
            id: 3,
            name: t('wizard-table_material'),
        },
        {
            id: 4,
            name: t('wizard-table_additional-prod-processes'),
        },
        {
            id: 5,
            name: t('wizard-table_delivery-time'),
        },
        {
            id: 6,
            name: t('label_order-quantity'),
        },
        {
            id: 7,
            name: t('offers-table-columns_price'),
        },
    ];

    // const handleCheckboxChange = (value) => {
    //     setButtonDisabled(value);
    // };

    useEffect(() => {
        setTotalPrice(frameworkWizzardReducer.data.totalPrice);
        setTaxPrice(
            (frameworkWizzardReducer.tax?.taxPercent / 100) * totalPrice
        );
    }, [frameworkWizzardReducer, totalPrice]);

    useEffect(() => {
        let formatedTableData = frameworkWizzardReducer?.frameworkLines?.map(
            (item, index) => {
                return {
                    id: item.uuid,
                    data: {
                        part: {
                            partName: item.partName ? item.partName : '-',
                            partNumber: item.partNumber ? item.partNumber : '-',
                        },
                        articleNumber: item.articleNumber
                            ? item.articleNumber
                            : '-',
                        material: {
                            material: item.material ? item.material : '-',
                            materialGroup: item.materialGroup
                                ? item.materialGroup
                                : '-',
                            materialComments: item.materialComments
                                ? item.materialComments
                                : '-',
                        },
                        producationMethod: item.producationMethod
                            ? item.productionMethod.join(', ')
                            : '-',
                        deliveryTime: item.durationAgreement
                            ? item.durationAgreement
                            : item.deliveryTimePerBatch
                            ? item.deliveryTimePerBatch
                            : '-',
                        quantity: {
                            selectQuantity: frameworkWizzardReducer.data
                                ?.faLines[index]?.quantity
                                ? frameworkWizzardReducer.data.faLines[index]
                                      .quantity
                                : '-',
                            alignRight: true,
                        },
                        totalPriceNet: {
                            totalPrice: item.totalPrice ? item.totalPrice : '-',
                        },
                    },
                };
            }
        );
        setTableData(formatedTableData);
    }, [frameworkWizzardReducer, t]);

    useEffect(() => {
        const countriesList = JSON.parse(t('countries'));
        setCountries(countriesList);

        // contacts list
        const contactsList = userReducer?.user.contacts;
        setContacts(contactsList);

        const params = new URLSearchParams();
        if (
            frameworkWizzardReducer.data.addresses.billingAddress.country.length
        ) {
            params.append(
                'country',
                frameworkWizzardReducer.data.addresses.billingAddress.country
            );
            params.append(
                'taxId',
                frameworkWizzardReducer.data.addresses.billingAddress.taxNumber
            );
        } else {
            params.append(
                'country',
                frameworkWizzardReducer.data.addresses.shippingAddress.country
            );
        }
        dispatch(getTax(params));

        //eslint-disable-next-line
    }, []);

    const handleOfferSubmit = () => {
        setButtonLoading(true);

        const data = {
            faId: frameworkWizzardReducer.data.faId,
            files: frameworkWizzardReducer.data.files,
            deliveryDate: frameworkWizzardReducer.data.deliveryDate,
            faReference: frameworkWizzardReducer.data.faReference,
            faLines: frameworkWizzardReducer.data.faLines,
            totalPrice: frameworkWizzardReducer.data.totalPrice,
            addresses: {
                billingAddress:
                    frameworkWizzardReducer.data.addresses.billingAddress,
                shippingAddress:
                    frameworkWizzardReducer.data.addresses.shippingAddress,
            },
            defaultContacts: {
                contactInvoicing:
                    frameworkWizzardReducer.data.defaultContacts
                        .contactInvoicing,
                contactPurchasing:
                    frameworkWizzardReducer.data.defaultContacts
                        .contactPurchasing,
                contactOrderConfirmation:
                    frameworkWizzardReducer.data.defaultContacts
                        .contactOrderConfirmation,
            },
        };
        const reference = frameworkWizzardReducer.data.faReference;
        dispatch(acceptFa(data, reference)).then((r) => {
            dispatch(onClearWizzard());
            setButtonLoading(false);
            navigate('/offer-success-fa');
        });
    };

    // get contact info
    const getContactInfo = (returnType, contactId) => {
        const chosenContact = userReducer?.user.contacts.find(
            (contact) => contact.id === contactId
        );

        if (returnType === 'email') {
            return chosenContact?.email;
        }

        return (
            chosenContact?.firstname +
            (chosenContact?.firstname ? ' ' : null) +
            chosenContact?.lastname
        );
    };

    return (
        <>
            <div className={classes.summary}>
                <span className={wizardStyles['wizzard__offer-num']}>
                    {frameworkWizzardReducer.details.offerNr}
                </span>
                <h3 className={classes['summary__intro-heading']}>
                    {t('summary_intro-heading')}
                </h3>
                <div className={classes['summary__wrapper']}>
                    <div className={classes['summary__left-side']}>
                        <h4 className={classes['summary__componets-heading']}>
                            {t('summary-table_heading')}
                        </h4>
                        <Table
                            tableTitles={tableTitles}
                            tableData={tableData}
                            tableType="faWizardSummary"
                        />
                        <div>
                            <div
                                className={
                                    classes[
                                        'summary__ordering-instructions-sum'
                                    ]
                                }
                            >
                                <div
                                    className={
                                        classes[
                                            'summary__ordering-instructions'
                                        ]
                                    }
                                >
                                    {frameworkWizzardReducer?.details
                                        .orderConditions?.length ? (
                                        <>
                                            <h6>
                                                {t('summary-instr_heading')}
                                            </h6>
                                            <ul>
                                                {frameworkWizzardReducer?.details.orderConditions?.map(
                                                    (orderCondition, index) => {
                                                        const textBullets =
                                                            JSON.parse(
                                                                t(
                                                                    'summary-text-bullets'
                                                                )
                                                            );
                                                        let value = textBullets[
                                                            orderCondition
                                                        ]
                                                            ? textBullets[
                                                                  orderCondition
                                                              ]
                                                            : '';
                                                        if (
                                                            value.includes(
                                                                '{shippingCosts}'
                                                            )
                                                        ) {
                                                            value = `${value.replace(
                                                                '{shippingCosts}',
                                                                frameworkWizzardReducer
                                                                    .details
                                                                    .shippingCosts
                                                            )} €`;
                                                        } else if (
                                                            value.includes(
                                                                '{capacityReservedUntil}'
                                                            )
                                                        ) {
                                                            value =
                                                                value.replace(
                                                                    '{capacityReservedUntil}',
                                                                    frameworkWizzardReducer
                                                                        .details
                                                                        .capacityReservedUntil
                                                                );
                                                        } else if (
                                                            value.includes(
                                                                '{paymentTerms}'
                                                            )
                                                        ) {
                                                            value =
                                                                value.replace(
                                                                    '{paymentTerms}',
                                                                    frameworkWizzardReducer
                                                                        .details
                                                                        .paymentTerms
                                                                );
                                                        } else if (
                                                            value.includes(
                                                                '{improvedPaymentTerms}'
                                                            )
                                                        ) {
                                                            value =
                                                                value.replace(
                                                                    '{improvedPaymentTerms}',
                                                                    frameworkWizzardReducer
                                                                        .details
                                                                        .improvedPaymentTerms
                                                                );
                                                        }
                                                        return (
                                                            <li
                                                                key={`${index}=${value}`}
                                                            >
                                                                {value}
                                                            </li>
                                                        );
                                                    }
                                                )}
                                            </ul>
                                        </>
                                    ) : null}
                                </div>
                                <div className={classes['summary__sum']}>
                                    <div
                                        className={classes['summary__sum-row']}
                                    >
                                        <div
                                            className={
                                                classes[
                                                    'summary__sum-row-label'
                                                ]
                                            }
                                        >
                                            {t('summary-table_sum')}
                                        </div>
                                        <span
                                            className={
                                                classes[
                                                    'summary__sum-row-value'
                                                ]
                                            }
                                        >
                                            <FormatedPrice price={totalPrice} />
                                        </span>
                                    </div>
                                    <div
                                        className={classes['summary__sum-row']}
                                    >
                                        <span
                                            className={
                                                classes[
                                                    'summary__sum-row-label'
                                                ]
                                            }
                                        >
                                            {t('summary-table_tax')}
                                            {
                                                frameworkWizzardReducer?.tax
                                                    ?.taxPercent
                                            }
                                            %
                                        </span>
                                        <span
                                            className={
                                                classes[
                                                    'summary__sum-row-value'
                                                ]
                                            }
                                        >
                                            <FormatedPrice price={taxPrice} />
                                        </span>
                                    </div>
                                    <div
                                        className={`${classes['summary__sum-row']} ${classes['summary__sum-row--brutto']}`}
                                    >
                                        <span
                                            className={`${classes['summary__sum-row-label']} ${classes['summary__sum-row-label--brutto']}`}
                                        >
                                            {t('summary-table_total-brutto')}
                                        </span>
                                        <span
                                            className={`${classes['summary__sum-row-value']} ${classes['summary__sum-row-value--brutto']}`}
                                        >
                                            <FormatedPrice
                                                price={totalPrice + taxPrice}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes['summary__right-side']}>
                        <div
                            className={
                                classes['summary__right-side-grey-section']
                            }
                        >
                            <h3
                                className={
                                    classes[
                                        'summary__right-side-grey-section-heading'
                                    ]
                                }
                            >
                                {t('summary_your-order-details')}
                            </h3>
                            <div
                                className={
                                    classes[
                                        'summary__order-details-boxes-wrapper'
                                    ]
                                }
                            >
                                {frameworkWizzardReducer.data.addresses
                                    .shippingAddress?.companyName.length ? (
                                    <div
                                        className={
                                            classes[
                                                'summary__order-details-box'
                                            ]
                                        }
                                    >
                                        <h6>
                                            {t(
                                                'wizard-ord-details_shipping-address'
                                            )}
                                        </h6>
                                        <div
                                            className={
                                                classes[
                                                    'summary__address-content'
                                                ]
                                            }
                                        >
                                            {frameworkWizzardReducer.data
                                                .addresses.shippingAddress
                                                ?.companyName && (
                                                <div>
                                                    {
                                                        frameworkWizzardReducer
                                                            .data.addresses
                                                            .shippingAddress
                                                            ?.companyName
                                                    }
                                                </div>
                                            )}
                                            {frameworkWizzardReducer.data
                                                .addresses.shippingAddress
                                                .street && (
                                                <div>
                                                    {
                                                        frameworkWizzardReducer
                                                            .data.addresses
                                                            .shippingAddress
                                                            .street
                                                    }
                                                </div>
                                            )}

                                            {(frameworkWizzardReducer.data
                                                .addresses.shippingAddress
                                                .postalCode ||
                                                frameworkWizzardReducer.data
                                                    .addresses.shippingAddress
                                                    .city ||
                                                frameworkWizzardReducer.data
                                                    .addresses.shippingAddress
                                                    .country) && (
                                                <div
                                                    className={
                                                        classes[
                                                            'summary__one-line'
                                                        ]
                                                    }
                                                >
                                                    {frameworkWizzardReducer
                                                        .data.addresses
                                                        .shippingAddress
                                                        .postalCode && (
                                                        <div>
                                                            {
                                                                frameworkWizzardReducer
                                                                    .data
                                                                    .addresses
                                                                    .shippingAddress
                                                                    .postalCode
                                                            }
                                                            {frameworkWizzardReducer
                                                                .data.addresses
                                                                .shippingAddress
                                                                .city
                                                                ? ' '
                                                                : frameworkWizzardReducer
                                                                      .data
                                                                      .addresses
                                                                      .shippingAddress
                                                                      .country
                                                                ? ', '
                                                                : null}
                                                        </div>
                                                    )}
                                                    {frameworkWizzardReducer
                                                        .data.addresses
                                                        .shippingAddress
                                                        .city && (
                                                        <div>
                                                            {
                                                                frameworkWizzardReducer
                                                                    .data
                                                                    .addresses
                                                                    .shippingAddress
                                                                    .city
                                                            }
                                                            {frameworkWizzardReducer
                                                                .data.addresses
                                                                .shippingAddress
                                                                .country
                                                                ? ', '
                                                                : null}
                                                        </div>
                                                    )}
                                                    {countries &&
                                                        frameworkWizzardReducer
                                                            .data.addresses
                                                            .shippingAddress
                                                            .country && (
                                                            <div>
                                                                {
                                                                    countries[
                                                                        frameworkWizzardReducer
                                                                            .data
                                                                            .addresses
                                                                            .shippingAddress
                                                                            .country
                                                                    ]
                                                                }
                                                            </div>
                                                        )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    '-'
                                )}
                                <div
                                    className={
                                        classes['summary__order-details-box']
                                    }
                                >
                                    <h6>
                                        {t(
                                            'wizard-ord-details_billing-address'
                                        )}
                                    </h6>
                                    {frameworkWizzardReducer.data.addresses
                                        .billingAddress?.companyName.length ? (
                                        <div
                                            className={[
                                                classes[
                                                    'summary__order-details-box'
                                                ],
                                                classes[
                                                    'summary__address-content'
                                                ],
                                            ].join(' ')}
                                        >
                                            {frameworkWizzardReducer.data
                                                .addresses.billingAddress
                                                .companyName && (
                                                <div>
                                                    {
                                                        frameworkWizzardReducer
                                                            .data.addresses
                                                            .billingAddress
                                                            .companyName
                                                    }
                                                </div>
                                            )}
                                            {frameworkWizzardReducer.data
                                                .addresses.billingAddress
                                                .street && (
                                                <div>
                                                    {
                                                        frameworkWizzardReducer
                                                            .data.addresses
                                                            .billingAddress
                                                            .street
                                                    }
                                                </div>
                                            )}

                                            {(frameworkWizzardReducer.data
                                                .addresses.billingAddress
                                                .postalCode ||
                                                frameworkWizzardReducer.data
                                                    .addresses.billingAddress
                                                    .city ||
                                                frameworkWizzardReducer.data
                                                    .addresses.billingAddress
                                                    .country) && (
                                                <div
                                                    className={
                                                        classes[
                                                            'summary__one-line'
                                                        ]
                                                    }
                                                >
                                                    {frameworkWizzardReducer
                                                        .data.addresses
                                                        .billingAddress
                                                        .postalCode && (
                                                        <div>
                                                            {
                                                                frameworkWizzardReducer
                                                                    .data
                                                                    .addresses
                                                                    .billingAddress
                                                                    .postalCode
                                                            }
                                                            {frameworkWizzardReducer
                                                                .data.addresses
                                                                .billingAddress
                                                                .city
                                                                ? ' '
                                                                : frameworkWizzardReducer
                                                                      .data
                                                                      .addresses
                                                                      .billingAddress
                                                                      .country
                                                                ? ', '
                                                                : null}
                                                        </div>
                                                    )}
                                                    {frameworkWizzardReducer
                                                        .data.addresses
                                                        .billingAddress
                                                        .city && (
                                                        <div>
                                                            {
                                                                frameworkWizzardReducer
                                                                    .data
                                                                    .addresses
                                                                    .billingAddress
                                                                    .city
                                                            }
                                                            {frameworkWizzardReducer
                                                                .data.addresses
                                                                .billingAddress
                                                                .country
                                                                ? ', '
                                                                : null}
                                                        </div>
                                                    )}
                                                    {countries &&
                                                        frameworkWizzardReducer
                                                            .data.addresses
                                                            .billingAddress
                                                            .country && (
                                                            <div>
                                                                {
                                                                    countries[
                                                                        frameworkWizzardReducer
                                                                            .data
                                                                            .addresses
                                                                            .billingAddress
                                                                            .country
                                                                    ]
                                                                }
                                                            </div>
                                                        )}
                                                </div>
                                            )}
                                            {frameworkWizzardReducer.data
                                                .addresses.billingAddress
                                                .taxNumber && (
                                                <div>
                                                    {
                                                        frameworkWizzardReducer
                                                            .data.addresses
                                                            .billingAddress
                                                            .taxNumber
                                                    }
                                                </div>
                                            )}
                                        </div>
                                    ) : frameworkWizzardReducer.data.addresses
                                          .shippingAddress?.companyName
                                          .length ? (
                                        <div
                                            className={[
                                                classes[
                                                    'summary__order-details-box'
                                                ],
                                                classes[
                                                    'summary__address-content'
                                                ],
                                            ].join(' ')}
                                        >
                                            {frameworkWizzardReducer.data
                                                .addresses.shippingAddress
                                                ?.companyName && (
                                                <div>
                                                    {
                                                        frameworkWizzardReducer
                                                            .data.addresses
                                                            .shippingAddress
                                                            ?.companyName
                                                    }
                                                </div>
                                            )}
                                            {frameworkWizzardReducer.data
                                                .addresses.shippingAddress
                                                .street && (
                                                <div>
                                                    {
                                                        frameworkWizzardReducer
                                                            .data.addresses
                                                            .shippingAddress
                                                            .street
                                                    }
                                                </div>
                                            )}

                                            {(frameworkWizzardReducer.data
                                                .addresses.shippingAddress
                                                .postalCode ||
                                                frameworkWizzardReducer.data
                                                    .addresses.shippingAddress
                                                    .city ||
                                                frameworkWizzardReducer.data
                                                    .addresses.shippingAddress
                                                    .country) && (
                                                <div
                                                    className={
                                                        classes[
                                                            'summary__one-line'
                                                        ]
                                                    }
                                                >
                                                    {frameworkWizzardReducer
                                                        .data.addresses
                                                        .shippingAddress
                                                        .postalCode && (
                                                        <div>
                                                            {
                                                                frameworkWizzardReducer
                                                                    .data
                                                                    .addresses
                                                                    .shippingAddress
                                                                    .postalCode
                                                            }
                                                            {frameworkWizzardReducer
                                                                .data.addresses
                                                                .shippingAddress
                                                                .city
                                                                ? ' '
                                                                : frameworkWizzardReducer
                                                                      .data
                                                                      .addresses
                                                                      .shippingAddress
                                                                      .country
                                                                ? ', '
                                                                : null}
                                                        </div>
                                                    )}
                                                    {frameworkWizzardReducer
                                                        .data.addresses
                                                        .shippingAddress
                                                        .city && (
                                                        <div>
                                                            {
                                                                frameworkWizzardReducer
                                                                    .data
                                                                    .addresses
                                                                    .shippingAddress
                                                                    .city
                                                            }
                                                            {frameworkWizzardReducer
                                                                .data.addresses
                                                                .shippingAddress
                                                                .country
                                                                ? ', '
                                                                : null}
                                                        </div>
                                                    )}
                                                    {countries &&
                                                        frameworkWizzardReducer
                                                            .data.addresses
                                                            .shippingAddress
                                                            .country && (
                                                            <div>
                                                                {
                                                                    countries[
                                                                        frameworkWizzardReducer
                                                                            .data
                                                                            .addresses
                                                                            .shippingAddress
                                                                            .country
                                                                    ]
                                                                }
                                                            </div>
                                                        )}
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        '-'
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    classes[
                                        'summary__order-details-boxes-wrapper'
                                    ]
                                }
                            >
                                <div
                                    className={
                                        classes['summary__order-details-box']
                                    }
                                >
                                    <h6>
                                        {t('wizard-ord-details_shipping-date')}
                                    </h6>
                                    <span>
                                        {frameworkWizzardReducer.data
                                            ?.deliveryDate
                                            ? moment(
                                                  frameworkWizzardReducer.data
                                                      .deliveryDate
                                              ).format('DD.MM.YYYY')
                                            : '-'}
                                    </span>
                                </div>
                                <div
                                    className={
                                        classes['summary__order-details-box']
                                    }
                                >
                                    <h6>
                                        {t(
                                            'wizard-ord-details_internal-ord-num'
                                        )}
                                    </h6>
                                    <span>
                                        {frameworkWizzardReducer.data
                                            ?.faReference
                                            ? frameworkWizzardReducer.data
                                                  .faReference
                                            : '-'}
                                    </span>
                                </div>
                            </div>
                            <div
                                className={
                                    classes['summary__order-details-box']
                                }
                            >
                                <h6>{t('summary_order-document')}</h6>
                                <ul>
                                    {frameworkWizzardReducer.data?.files &&
                                    frameworkWizzardReducer.data?.files
                                        .length ? (
                                        <ul>
                                            {frameworkWizzardReducer.data.files.map(
                                                (file, index) => (
                                                    <UploadedFile
                                                        key={`file-${index}`}
                                                        fileName={file.fileName}
                                                        extraClass="summary"
                                                    />
                                                )
                                            )}
                                        </ul>
                                    ) : (
                                        '-'
                                    )}
                                </ul>
                            </div>
                            <div
                                className={`${classes['summary__order-details-box']} ${classes['summary__order-details-box--comment']}`}
                            >
                                <h6>{t('wizard-ord-details_comment')}</h6>
                                <p>
                                    {frameworkWizzardReducer.data.comments
                                        ? frameworkWizzardReducer.data.comments
                                        : '-'}
                                </p>
                            </div>
                            <div
                                className={[
                                    classes['summary__multiple-items'],
                                    classes['summary__contacts'],
                                ].join(' ')}
                            >
                                <div
                                    className={`${classes['summary__order-details-box']} ${classes['summary__order-details-box--contact']}`}
                                >
                                    <h6>
                                        <TranslatedComponent
                                            translationFileName="contact-roles"
                                            translatedComponent={'purchasing'}
                                        />
                                    </h6>
                                    <div
                                        className={classes['summary__contact']}
                                    >
                                        <div
                                            className={
                                                classes['summary__contact-name']
                                            }
                                        >
                                            {contacts &&
                                            frameworkWizzardReducer?.data
                                                .defaultContacts
                                                .contactPurchasing
                                                ? getContactInfo(
                                                      'name',
                                                      frameworkWizzardReducer
                                                          ?.data.defaultContacts
                                                          .contactPurchasing
                                                  )
                                                : '-'}
                                        </div>
                                        {contacts &&
                                        frameworkWizzardReducer?.data
                                            .defaultContacts
                                            .contactPurchasing ? (
                                            <p
                                                className={
                                                    classes[
                                                        'summary__contact-email'
                                                    ]
                                                }
                                            >
                                                {getContactInfo(
                                                    'email',
                                                    frameworkWizzardReducer
                                                        ?.data.defaultContacts
                                                        .contactPurchasing
                                                )}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                                <div
                                    className={`${classes['summary__order-details-box']} ${classes['summary__order-details-box--contact']}`}
                                >
                                    <h6>
                                        <TranslatedComponent
                                            translationFileName="contact-roles"
                                            translatedComponent={'invoicing'}
                                        />
                                    </h6>
                                    <div
                                        className={classes['summary__contact']}
                                    >
                                        <div
                                            className={
                                                classes['summary__contact-name']
                                            }
                                        >
                                            {contacts &&
                                            frameworkWizzardReducer?.data
                                                .defaultContacts
                                                .contactInvoicing
                                                ? getContactInfo(
                                                      'name',
                                                      frameworkWizzardReducer
                                                          ?.data.defaultContacts
                                                          .contactInvoicing
                                                  )
                                                : '-'}
                                        </div>
                                        {contacts &&
                                        frameworkWizzardReducer?.data
                                            .defaultContacts
                                            .contactInvoicing ? (
                                            <p
                                                className={
                                                    classes[
                                                        'summary__contact-email'
                                                    ]
                                                }
                                            >
                                                {getContactInfo(
                                                    'email',
                                                    frameworkWizzardReducer
                                                        ?.data.defaultContacts
                                                        .contactInvoicing
                                                )}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                                <div
                                    className={`${classes['summary__order-details-box']} ${classes['summary__order-details-box--contact']}`}
                                >
                                    <h6>
                                        <TranslatedComponent
                                            translationFileName="contact-roles"
                                            translatedComponent={
                                                'orderConfirmation'
                                            }
                                        />
                                    </h6>
                                    <div
                                        className={classes['summary__contact']}
                                    >
                                        <div
                                            className={
                                                classes['summary__contact-name']
                                            }
                                        >
                                            {contacts &&
                                            frameworkWizzardReducer?.data
                                                .defaultContacts
                                                .contactOrderConfirmation
                                                ? getContactInfo(
                                                      'name',
                                                      frameworkWizzardReducer
                                                          ?.data.defaultContacts
                                                          .contactOrderConfirmation
                                                  )
                                                : '-'}
                                        </div>
                                        {contacts &&
                                        frameworkWizzardReducer?.data
                                            .defaultContacts
                                            .contactOrderConfirmation ? (
                                            <p
                                                className={
                                                    classes[
                                                        'summary__contact-email'
                                                    ]
                                                }
                                            >
                                                {getContactInfo(
                                                    'email',
                                                    frameworkWizzardReducer
                                                        ?.data.defaultContacts
                                                        .contactOrderConfirmation
                                                )}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <WizzardFooter
                    prevButtonText={t('global_go-back-btn')}
                    nextButtonText={t('summary-table_fa-wizard-s2-next-btn')}
                    handleSubmitApi={handleOfferSubmit}
                    nextSubmit={true}
                    nextButtonLoading={buttonLoading}
                    backUrl={`/framework-agreements/${frameworkWizzardReducer.details.faId}/wizard/order-details`}
                />
            </div>
        </>
    );
}

export default Summary;
