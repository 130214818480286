import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

function SimpleSelect({
    initialValue,
    onChange,
    errors,
    touched,
    onBlur,
    extraClass,
    translationGroup,
    prefillValue,
    ariaLabel,
    type,
    customList,
    disabled,
    placeholder,
    isSearchable,
}) {
    const hasError = errors && touched;
    const [selectItem, setSelectItem] = useState();
    const [selectList, setSelectList] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        const translatedGroup = JSON.parse(t(translationGroup));

        let selectItemsList;
        if (translationGroup === 'contacts-roles') {
            selectItemsList = [
                {
                    key: {
                        value: 'QA / QM',
                        label: translatedGroup['QA/QM'],
                    },
                },
                {
                    key: {
                        value: 'Order Confirmation',
                        label: translatedGroup['OrderConfirmation'],
                    },
                },
                {
                    key: {
                        value: 'Complaints',
                        label: translatedGroup['Complaints'],
                    },
                },
                {
                    key: {
                        value: 'Invoicing',
                        label: translatedGroup['Invoicing'],
                    },
                },
                {
                    key: {
                        value: 'Logistics',
                        label: translatedGroup['Logistics'],
                    },
                },
                {
                    key: {
                        value: 'Construction Department',
                        label: translatedGroup['ConstructionDepartment'],
                    },
                },
                {
                    key: {
                        value: 'Purchasing',
                        label: translatedGroup['Purchasing'],
                    },
                },
                {
                    key: {
                        value: 'Production',
                        label: translatedGroup['Production'],
                    },
                },
                {
                    key: {
                        value: 'Inquiry',
                        label: translatedGroup['Inquiry'],
                    },
                },
            ];
        } else {
            selectItemsList = translatedGroup;
        }

        if (initialValue) {
            let label = selectItemsList[initialValue];
            setSelectItem({
                label: label,
                value: initialValue,
            });
        }

        let itemList;
        if (customList) {
            itemList = selectItemsList.map((item) => {
                return {
                    label: item.key.label,
                    value: item.key.value,
                };
            });
        } else {
            itemList = Object.entries(selectItemsList).map((item) => {
                return {
                    label: item[1],
                    value: item[0],
                };
            });
        }

        setSelectList(itemList);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (prefillValue) {
            const selectItemsList = JSON.parse(t(translationGroup));
            let label = selectItemsList[prefillValue];
            setSelectItem({
                label: label,
                value: prefillValue,
            });
        }
        //eslint-disable-next-line
    }, [prefillValue]);

    const onSelectChange = (e) => {
        setSelectItem(e);
        onChange(e);
    };

    return (
        <>
            <Select
                className={`c-react-select c-react-select--transaprent-grey-border ${
                    extraClass ? 'c-react-select--normal' : ''
                }`}
                name="quantity-selector"
                options={selectList}
                onChange={onSelectChange}
                value={selectItem}
                classNamePrefix="c-react-select"
                onBlur={onBlur}
                // menuIsOpen={true}
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
                aria-label={ariaLabel}
                isDisabled={disabled}
                placeholder={placeholder}
                isSearchable={isSearchable}
            />
            {hasError ? <div className="c-error__message">{errors}</div> : null}
        </>
    );
}

SimpleSelect.defaultProps = {
    ariaLabel: '',
    customList: false,
    placeholder: null,
};

export default SimpleSelect;
