import { useEffect } from 'react';
import WizzardHeader from '../../../components/Wizzard/WizzardHeader/WizzardHeader';
import { useSelector } from 'react-redux';
import classes from '../../../components/Wizzard/WizzardGeneral.module.scss';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../shared/Loader/Loader';
import { onToggleFrameworkWizzard } from '../../../store/actions/frameworkWizzardActions';
import { useDispatch } from 'react-redux';

function FrameworkAgreementsWizzard() {
    const { t } = useTranslation();
    //eslint-disable-next-line
    const [isFormValid, setIsFormValid] = useState(false);

    const headerItems = [
        'Bauteile',
        t('wizard-header_step-order-details'),
        t('wizard-header_step-summary'),
    ];

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const frameworkWizzard = useSelector(
        (state) => state.frameworkWizzardReducer
    );

    useEffect(() => {
        if (frameworkWizzard.details.faId && !frameworkWizzard.open) {
            dispatch(onToggleFrameworkWizzard());
            if (
                frameworkWizzard.currentStep === 1 &&
                !window.location.pathname.includes('wizard/components')
            ) {
                navigate(
                    `/framework-agreements/${frameworkWizzard.details.faId}/wizard/components`
                );
            }
        }
        //eslint-disable-next-line
    }, [frameworkWizzard]);

    return (
        <div className={classes['wizzard']}>
            <div>
                <WizzardHeader
                    items={headerItems}
                    active={frameworkWizzard.currentStep}
                    totalStep={frameworkWizzard.totalStep}
                    isValid={isFormValid}
                    type="framework-agreements"
                />
                {frameworkWizzard.open ? (
                    <div
                        className={`${classes['wizzard__body']} ${
                            frameworkWizzard.currentStep === 1
                                ? classes['wizzard__body--components']
                                : ''
                        } ${
                            frameworkWizzard.currentStep === 2
                                ? classes['wizzard__body--order-details']
                                : ''
                        } ${
                            frameworkWizzard.currentStep === 3
                                ? classes['wizzard__body--summary']
                                : ''
                        }`}
                    >
                        <Outlet />
                    </div>
                ) : (
                    <Loader />
                )}
            </div>
        </div>
    );
}

export default FrameworkAgreementsWizzard;
