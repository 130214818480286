import React, { useRef, useState } from 'react';
import useIsOverflow from '../../../../hooks/useIsOverflow';
// import classes from './TableItemContent.module.scss';
import { useDispatch } from 'react-redux';
import { onOpenDetails } from '../../../../store/actions/detailsPopupActions';
import {
    onRemoveFaItem,
    onRemoveItem,
} from '../../../../store/actions/offersWizzardActions';
import FormatedPrice from '../../../FormatedPrice/FormatedPrice';
import LabelWithoutBackground from '../../../Label/LabelWithoutBackground/LabelWithoutBackground';
import { useTranslation } from 'react-i18next';
import QuantitySelect from './QuantitySelect/QuantitySelect';
import TableItemContentMaterial from './TableItemContentMaterial/TableItemContentMaterial';
import TranslatedComponent from '../../../TranslatedComponent/TranslatedComponent';
import TableInput from './TableInput/TableInput';
import {
    onRemoveFrameworkItem,
    onFrameworkInputChange,
} from '../../../../store/actions/frameworkWizzardActions';
import fallbackImg from '../../../../images/fallbackImg.svg';
import InvoiceFiles from './InvoiceFiles/InvoiceFiles';
import { useNavigate } from 'react-router-dom';

function TableItemContent({
    data,
    tableType,
    tableDataLength,
    type,
    details,
    hasDiscount,
    certificate,
    hasOrigin,
}) {
    const ref = useRef();
    const hasOverflow = useIsOverflow(ref, (isOverflowFromCallback) => {
        // true
    });
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [showPopup, setShowPopup] = useState(false);
    const navigate = useNavigate();

    const handleDetailsPopupOpen = () => {
        if (details) {
            if (details.offerLineId) {
                navigate(details.offerLineId);
            }
            if (details.shipmentId) {
                navigate(details.shipmentId);
            }
            if (details.frameworkLineId) {
                if (window.location.href.includes('Contract_Details')) {
                    navigate(
                        `${details.frameworkLineId}?type=Contract_Details`
                    );
                }
            }
            dispatch(onOpenDetails(details, tableType));
        }
    };

    const [errImg, setErrImg] = useState(false);

    const imgErr = (e) => {
        e.target.onError = null;
        e.target.src = fallbackImg;
        setErrImg(true);
    };

    const linkClickHandler = (e) => {
        e.stopPropagation();
    };

    const handleDeleteTableRow = () => {
        if (tableType === 'wizardFaOffers') {
            dispatch(onRemoveFaItem(data.lineId, data.index));
        } else if (data.wizard === 'framework-agreement') {
            dispatch(onRemoveFrameworkItem(data.lineId, data.index));
        } else {
            dispatch(onRemoveItem(data.lineId, data.index));
        }
    };

    const supportsHover = window.matchMedia('(hover: hover)').matches;

    const hoverIconClickHandler = () => {
        if (!supportsHover) {
            setShowPopup(!showPopup);
        }
    };

    const handleInputChange = (value) => {
        if (data.wizard === 'framework-agreement') {
            dispatch(onFrameworkInputChange(value, data.index));
        }
    };

    let formatedData;

    if (data?.minFormattedPrice) {
        formatedData = (
            <>
                {data?.minFormattedPrice !== '-' && (
                    <FormatedPrice price={data.minFormattedPrice} />
                )}
                {data.maxFormattedPrice &&
                data.maxFormattedPrice &&
                data?.minFormattedPrice !== data?.maxFormattedPrice
                    ? ' - '
                    : data.minFormattedPrice || data.maxFormattedPrice
                    ? ''
                    : '-'}
                {data?.maxFormattedPrice !== '-' &&
                    data?.minFormattedPrice !== data?.maxFormattedPrice && (
                        <FormatedPrice price={data.maxFormattedPrice} />
                    )}
            </>
        );
    } else if (data?.partName) {
        formatedData = (
            <div className="c-table-item__container">
                <div
                    className={`c-table-item__image ${
                        !data.partImage || errImg
                            ? 'c-table-item__image--scale-1'
                            : ''
                    } ${tableType === 'faWizardSummary' ? 'normalCursor' : ''}`}
                >
                    <img
                        src={data.partImage ? data.partImage : fallbackImg}
                        alt="Part design"
                        onError={imgErr}
                    />
                    <div className="c-table-item__image-overlay">
                        <i className="icon-search-plus"></i>
                    </div>
                </div>
                <div>
                    <div className="c-table-item__part-name">
                        {data.partName}
                    </div>
                    <div className="c-table-item--grey">{data.partNumber}</div>
                </div>
            </div>
        );
    } else if (data?.originCountry) {
        formatedData = (
            <div>
                {data?.originCountry !== '-' ||
                (data.hsCode === '-' && data?.originCountry === '-') ? (
                    <div className="c-table-item__origin-country">
                        {data?.originCountry}
                    </div>
                ) : null}
                {data.hsCode !== '-' ? <div>{data.hsCode}</div> : null}
            </div>
        );
    } else if (data?.material) {
        formatedData = (
            <div
                className={`${
                    data.requestedMaterial
                        ? 'c-table-item__has-requested-material'
                        : ''
                }`}
            >
                <TableItemContentMaterial
                    requestedMaterial={data.requestedMaterial}
                    material={data.material}
                    materialGroup={data.materialGroup}
                />
                {data.requestedMaterial ? (
                    <TableItemContentMaterial
                        requestedMaterial={data.requestedMaterial}
                        material={data.material}
                        materialGroup={data.materialGroup}
                        orangeText={true}
                    />
                ) : null}
                {data.materialComments && (
                    <div className="c-table-item__material-comment">
                        {data.materialComments}
                    </div>
                )}
            </div>
        );
    } else if (data?.quantitySample) {
        formatedData = (
            <div className="c-table-item__container c-table-item__container--multiple c-table-item__container--quantity">
                {data.quantitySample && data.quantitySample !== '-' ? (
                    <span>
                        <FormatedPrice number={data.quantitySample} />
                        <span>{t('global_pieces-short')}</span>
                    </span>
                ) : null}
                {data.quantityFirstBatch && data.quantityFirstBatch !== '-' ? (
                    <span>
                        <FormatedPrice number={data.quantityFirstBatch} />
                        <span>{t('global_pieces-short')}</span>
                    </span>
                ) : null}
                {data.quantityPerBatches && data.quantityPerBatches !== '-' ? (
                    <span>
                        <FormatedPrice number={data.quantityPerBatches} />
                        <span>{t('global_pieces-short')}</span>
                    </span>
                ) : null}
                {data.quantitySample === '-' &&
                    data.quantityFirstBatch === '-' &&
                    data.quantityPerBatches === '-' && <span>-</span>}
            </div>
        );
    } else if (data?.deliveryTimeSample) {
        formatedData = (
            <div className="c-table-item__container c-table-item__container--multiple c-table-item__container--deliveryTime">
                {data.deliveryTimeSample.quantitySample ? (
                    <span>
                        {data.deliveryTimeSample.deliveryTimeSample}{' '}
                        {data.deliveryTimeSample.deliveryTimeSample === '-'
                            ? ''
                            : data.deliveryTimeSample.deliveryTimeSample === 1
                            ? t('global_day')
                            : t('global_days')}
                    </span>
                ) : null}
                {data.deliveryTimeFirstBatch.quantityFirstBatch ? (
                    <span>
                        {data.deliveryTimeFirstBatch.deliveryTimeFirstBatch}{' '}
                        {data.deliveryTimeFirstBatch.deliveryTimeFirstBatch ===
                        '-'
                            ? ''
                            : data.deliveryTimeFirstBatch
                                  .deliveryTimeFirstBatch === 1
                            ? t('global_week')
                            : t('global_weeks')}
                    </span>
                ) : null}
                {data.deliveryTimePerBatch.quantityPerBatches ? (
                    <span>
                        {data.deliveryTimePerBatch.deliveryTimePerBatch}{' '}
                        {data.deliveryTimePerBatch.deliveryTimePerBatch === '-'
                            ? ''
                            : data.deliveryTimePerBatch.deliveryTimePerBatch ===
                              1
                            ? t('global_week')
                            : t('global_weeks')}
                    </span>
                ) : null}
                {!data.deliveryTimeSample.quantitySample &&
                    !data.deliveryTimeFirstBatch.quantityFirstBatch &&
                    !data.deliveryTimePerBatch.quantityPerBatches && (
                        <span>-</span>
                    )}
            </div>
        );
    } else if (data?.sample) {
        formatedData = (
            <div className="c-table-item__container c-table-item__container--multiple c-table-item__container--batch">
                {data.sample.quantitySample ? (
                    <span>{data.sample.sample}</span>
                ) : null}
                {data.firstBatch.quantityFirstBatch ? (
                    <span>{data.firstBatch.firstBatch}</span>
                ) : null}
                {data.perBatch.quantityPerBatches ? (
                    <span>{data.perBatch.perBatch}</span>
                ) : null}
                {!data.sample.quantitySample &&
                    !data.firstBatch.quantityFirstBatch &&
                    !data.perBatch.quantityPerBatches && <span>-</span>}
            </div>
        );
    } else if ((data?.price || data?.price === 0) && !data?.discount) {
        formatedData = (
            <div
                className={`c-table-item__container c-table-item__container--multiple c-table-item__price ${
                    data.priceStatus === 'Calculation running' ||
                    data.priceStatus === 'CalculationRunning'
                        ? 'calculationRuning'
                        : data.priceStatus === 'Data Validation' ||
                          data.priceStatus === 'DataValidation'
                        ? 'DataValidation'
                        : data.priceStatus === 'Incomplete data' ||
                          data.priceStatus === 'IncompleteData'
                        ? 'IncompleteData'
                        : data.status
                }`}
            >
                {data.priceStatus === 'Data Validation' ||
                data.priceStatus === 'DataValidation' ||
                data.priceStatus === 'Calculation running' ||
                data.priceStatus === 'CalculationRunning' ||
                data.priceStatus === 'Incomplete data' ||
                data.priceStatus === 'IncompleteData' ? (
                    <span className="c-table-item__price-note">
                        <TranslatedComponent
                            translationFileName="partsStatus"
                            translatedComponent={data.priceStatus}
                        />
                    </span>
                ) : null}
                {data.status !== 'Offer created' &&
                data.status !== 'Lost' &&
                data.status !== 'Offersent' &&
                data.status !== 'UpdateSent' &&
                data.status !== 'InNegotiation' &&
                data.status !== 'Offeraccepted' &&
                data.status !== 'OfferCreated' &&
                tableType !== 'frameworkComponentsDetails' &&
                tableType !== 'faWizardStepOne' &&
                tableType !== 'offersSingleOrderWizardSummary' &&
                tableType !== 'offersFaWizardSummary' &&
                !data.isOnlyComment ? (
                    <span
                        className={`c-table-item__price-status ${
                            data.targetPrice ? 'hasTargetPrice' : ''
                        }`}
                    ></span>
                ) : null}
                {data.isOnlyComment ? (
                    <span className="c-table-item__price-comment">
                        <span className="c-table-item__price-icon">
                            <i
                                className="icon-info"
                                onClick={hoverIconClickHandler}
                            ></i>
                            <div
                                className={`c-table-item__price-hover-container ${
                                    showPopup ? 'active' : ''
                                }`}
                            >
                                <div className="c-table-item__price-hover-text">
                                    {data.noPriceAvailableComment}
                                </div>
                            </div>
                        </span>
                        <span className="c-table-item__price-comment-text">
                            <div>{t('global_no-price-available')}</div>
                        </span>
                    </span>
                ) : data.status === 'Offer created' ||
                  data.status === 'Offersent' ||
                  data.status === 'UpdateSent' ||
                  data.status === 'InNegotiation' ||
                  data.status === 'Offeraccepted' ||
                  data.status === 'Lost' ||
                  data.status === 'OfferCreated' ||
                  tableType === 'frameworkComponentsDetails' ||
                  tableType === 'faWizardStepOne' ||
                  tableType === 'offersSingleOrderWizardSummary' ||
                  tableType === 'offersFaWizardSummary' ? (
                    <div className="c-table-item__total-price">
                        <FormatedPrice price={data.price} />
                    </div>
                ) : null}
                {!data.noTargetPrice &&
                data.targetPrice &&
                data.targetPrice !== '-' ? (
                    <span className="c-table-item__price-display">
                        {t('global_target-price')}:{' '}
                        {data.targetPrice && data.targetPrice !== '-' ? (
                            <FormatedPrice price={data.targetPrice} />
                        ) : (
                            '-'
                        )}
                    </span>
                ) : null}
            </div>
        );
    } else if (data?.price || data?.price === 0) {
        formatedData = (
            <div
                className={`c-table-item__container c-table-item__container--multiple c-table-item__price c-table-item__price--${tableType} ${
                    data.status === 'Calculation running' ||
                    data.status === 'CalculationRunning'
                        ? 'calculationRuning'
                        : data.status
                }`}
            >
                {data.status !== 'OfferCreated' &&
                data.status !== 'Offersent' &&
                data.status !== 'UpdateSent' &&
                data.status !== 'InNegotiation' &&
                data.status !== 'Offeraccepted' &&
                data.status !== 'Offer created' &&
                data.status !== 'Lost' &&
                tableType !== 'frameworkComponentsDetails' &&
                tableType !== 'offersSingleOrderCertificates' &&
                tableType !== 'offersSingleOrderWizardSummary' &&
                (!data.isOnlyComment || data.isOnlyComment === false) ? (
                    <span className="c-table-item__price-status"></span>
                ) : null}
                {data.status === 'Offer created' ||
                data.status === 'OfferCreated' ||
                data.status === 'Offersent' ||
                data.status === 'UpdateSent' ||
                data.status === 'InNegotiation' ||
                data.status === 'Offeraccepted' ||
                data.status === 'Lost' ||
                tableType === 'frameworkComponentsDetails' ||
                tableType === 'offersSingleOrderWizardSummary' ? (
                    <div
                        className={`c-table-item__total-price c-table-item__total-price--${tableType} ${
                            (data.status === 'Offer created' ||
                                data.status === 'Offersent' ||
                                data.status === 'UpdateSent' ||
                                data.status === 'InNegotiation' ||
                                data.status === 'Offeraccepted' ||
                                data.status === 'Lost' ||
                                data.status === 'OfferCreated') &&
                            (((data.priceBeforeDiscount ||
                                data.priceBeforeDiscount === 0) &&
                                data.discount &&
                                data.discount !== '-') ||
                                data.isOnlyComment)
                                ? 'c-table-item__total-price--has-discount'
                                : ''
                        }`}
                    >
                        {(data.priceBeforeDiscount ||
                            data.priceBeforeDiscount === 0) &&
                        !data.isOnlyComment &&
                        tableType !== 'offersSingleOrderWizardSummary' &&
                        data.discount !== '-' ? (
                            <span className="c-table-item__total-price--price-before-discount">
                                <FormatedPrice
                                    price={data.priceBeforeDiscount}
                                />
                            </span>
                        ) : null}
                        <span
                            className={`c-table-item__total-net-price
                                ${
                                    ((data.status === 'Offer created' ||
                                        data.status === 'Offersent' ||
                                        data.status === 'InNegotiation' ||
                                        data.status === 'Offeraccepted' ||
                                        data.status === 'Lost' ||
                                        data.status === 'UpdateSent' ||
                                        data.status === 'OfferCreated') &&
                                        data.priceBeforeDiscount &&
                                        data.isOnlyComment) ||
                                    tableType ===
                                        'offersSingleOrderWizardSummary'
                                        ? ''
                                        : data.discount !== '-'
                                        ? 'c-table-item__total-net-price--red'
                                        : ''
                                }
                            `}
                        >
                            {data.isOnlyComment ? (
                                <span className="c-table-item__price-comment">
                                    <span className="c-table-item__price-icon">
                                        <i
                                            className="icon-info"
                                            onClick={hoverIconClickHandler}
                                        ></i>
                                        <div
                                            className={`c-table-item__price-hover-container ${
                                                showPopup ? 'active' : ''
                                            }`}
                                        >
                                            <div className="c-table-item__price-hover-text">
                                                {data.noPriceAvailableComment}
                                            </div>
                                        </div>
                                    </span>
                                    <span className="c-table-item__price-comment-text">
                                        <div>
                                            {t('global_no-price-available')}
                                        </div>
                                    </span>
                                </span>
                            ) : (
                                <FormatedPrice price={data.price} />
                            )}
                        </span>
                    </div>
                ) : null}
                {tableType !== 'offersSingleOrderCertificates' &&
                data.targetPrice ? (
                    <span
                        className={`c-table-item__price-display c-table-item__price-display--${tableType}`}
                    >
                        {t('global_target-price')}:{' '}
                        {data.targetPrice && data.targetPrice !== '-' ? (
                            <FormatedPrice price={data.targetPrice} />
                        ) : (
                            '-'
                        )}
                    </span>
                ) : null}
            </div>
        );
    } else if (typeof data?.deletableRow !== 'undefined') {
        if (data.deletableRow) {
            formatedData = (
                <div className="c-table-item__container c-table-item__delete">
                    <button
                        onClick={() => handleDeleteTableRow()}
                        className="c-table-item__delete-btn-wizard"
                    >
                        <i className="icon-x-sign"></i>
                    </button>
                </div>
            );
        }
    } else if (data?.trackingCode) {
        formatedData = (
            <div className="c-table-item__container c-table-item__container--multiple c-table-item__container--link-with-icon">
                {data.trackingCode !== '-' && data.trackingUrl ? (
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={data.trackingUrl}
                        onClick={linkClickHandler}
                    >
                        <i className="icon-expand"></i>
                        <span>{data.trackingCode}</span>
                    </a>
                ) : (
                    <span>{data.trackingCode}</span>
                )}
            </div>
        );
    } else if (data?.totalPrice) {
        formatedData = (
            <div className="c-table-item__container">
                {data.totalPrice !== '-' ? (
                    <span>
                        <FormatedPrice price={data.totalPrice} />
                    </span>
                ) : (
                    <span>{data.totalPrice}</span>
                )}
            </div>
        );
    } else if (data?.number) {
        formatedData = (
            <div className="c-table-item__container">
                {data.number !== '-' ? (
                    <span>
                        <FormatedPrice number={data.number} />
                    </span>
                ) : (
                    <span>{data.number}</span>
                )}
            </div>
        );
    } else if (data?.invoiceFiles) {
        formatedData = data.invoiceFiles.map((invoice, index) => {
            return <InvoiceFiles invoice={invoice} key={index} />;
        });
    } else if (data?.deliveryNoteNames) {
        formatedData = (
            <div
                className={`c-table-item__container c-table-item__container--multiple`}
            >
                {data.deliveryNoteNames.map((noteName) => (
                    <div key={`noteName-${noteName}`}>{noteName}</div>
                ))}
            </div>
        );
    } else if (data?.orderId) {
        formatedData = (
            <div
                className={`c-table-item__container c-table-item__container--link-with-icon c-table-item__container--can-overflow`}
            >
                <a
                    rel="noreferrer"
                    target="_blank"
                    href={`/orders/${data.orderId}/components`}
                >
                    <i className="icon-file-2"></i>
                    {data?.orderNr}
                </a>
            </div>
        );
    } else if (data?.status) {
        if (data.status === '-') {
            formatedData = data.status;
        } else {
            formatedData = (
                <LabelWithoutBackground
                    statusText={data.status}
                    classText={data.color}
                    type={data.type}
                />
            );
        }
    } else if (data?.dateStatus) {
        formatedData = (
            <div
                className={`c-table-item__container c-table-item__date-status`}
            >
                <i className="icon-checkmark-with-green-bg path1 path2"></i>
            </div>
        );
    } else if (data?.linkWithIcon) {
        formatedData = (
            <div className={`c-table-item__container c-table-item__link`}>
                {data.linkUrl ? (
                    <a rel="noreferrer" target="_blank" href={data.linkUrl}>
                        <i className={`icon-${data.icon}`}></i>
                        {data?.linkText}
                    </a>
                ) : (
                    <span>{data?.linkText}</span>
                )}
            </div>
        );
    } else if (data?.selectQuantity) {
        if (data.selectQuantity.length > 1) {
            formatedData = (
                <div className={`c-table-item__container c-table-item__select`}>
                    <QuantitySelect
                        data={data.selectQuantity}
                        rowIndex={data.index}
                        index={data}
                    />
                </div>
            );
        } else {
            formatedData = (
                <div
                    className={`c-table-item__container ${
                        data.alignRight ? 'c-table-item__container--right' : ''
                    }`}
                >
                    {data.selectQuantity !== '-' ? (
                        <span>
                            <FormatedPrice number={data.selectQuantity} />{' '}
                            {t('global_pieces-short')}
                        </span>
                    ) : (
                        '-'
                    )}
                </div>
            );
        }
    } else if (data?.targetTotalPriceNet) {
        formatedData = <div className={`c-table-item__container`}></div>;
    } else if (data?.certificate || data?.needTranslationItem) {
        formatedData = (
            <div className={`c-table-item__container`}>
                {(data?.certificate && data?.certificate !== '-') ||
                (data.needTranslationItem &&
                    data.needTranslationItem !== '-') ? (
                    <TranslatedComponent
                        translationFileName={
                            data.translationObject
                                ? data.translationObject
                                : 'certificates'
                        }
                        translatedComponent={
                            data.certificate
                                ? data.certificate
                                : data.needTranslationItem
                        }
                    />
                ) : (
                    '-'
                )}
            </div>
        );
    } else if (data?.maxQuantity) {
        formatedData = (
            <div className={`c-table-item__container`}>
                <TableInput
                    inputChange={handleInputChange}
                    placeholder={data.placeholder}
                    maxQuantity={parseInt(data.maxQuantity)}
                    index={data.index}
                />
            </div>
        );
    } else {
        formatedData = data;
    }

    return (
        <div
            onClick={
                tableType === 'offerFrameworkOrder' && data.material
                    ? null
                    : handleDetailsPopupOpen
            }
            className={`c-table-item__row-item ${
                tableDataLength ? `c-table-item__${tableDataLength}` : ''
            } ${type ? `c-table-item__${type}` : ''} ${
                tableType ? `c-table-item__${tableType}` : ''
            } ${
                hasDiscount
                    ? 'c-table-item--has-discount'
                    : 'c-table-item--no-discount'
            } ${certificate ? 'certificate' : ''} ${
                hasOrigin
                    ? 'c-table-item--has-origin'
                    : 'c-table-item--no-origin'
            }`}
        >
            <div
                className={`c-table-item__content ${
                    tableType === 'contacts' && hasOverflow ? 'hasOverflow' : ''
                }`}
                ref={ref}
            >
                <span>{formatedData}</span>
                {tableType === 'contacts' && hasOverflow && (
                    <div className="c-table-item__overflow-content">
                        <div className="c-table-item__overflow-data">
                            {formatedData}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default TableItemContent;
