import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import ContactSelect from '../../../../../components/Input/ContactSelect/ContactSelect';
import ModalWrapper from '../../../../../components/ModalWrapper/ModalWrapper';
import ResultModal from '../../../../../components/ResultModal/ResultModal';
import TranslatedComponent from '../../../../../components/TranslatedComponent/TranslatedComponent';
import ApiService from '../../../../../services/api.service';
import { getUser } from '../../../../../store/actions/userActions';
import ContactInfo from '../ContactInfo/ContactInfo';
import classes from './UpdateContactsModal.module.scss';
import CircledCheckmarkIcon from '../../../../../components/Icons/CircledCheckmarkIcon';
import Button from '../../../../../components/Button/Button';
import { useTranslation } from 'react-i18next';
import { getDetails } from '../../../../../store/thunks/detailsThunk';

function UpdateContactsModal({
    ownerContact,
    invoicingContact,
    orderConfirmationContact,
    purchasingContact,
    onClickHandler,
    type,
    orderId,
}) {
    const [step2, setStep2] = useState(false);
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    const invoicingContactChange = (contact, props) => {
        props.setFieldValue('invoicingContact', contact.value);
    };
    const orderConfirmationContactChange = (contact, props) => {
        props.setFieldValue('orderConfirmationContact', contact.value);
    };
    const purchasingContactChange = (contact, props) => {
        props.setFieldValue('purchasingContact', contact.value);
    };
    const dispatch = useDispatch();

    const initialValues = {
        invoicingContact: invoicingContact?.id,
        orderConfirmationContact: orderConfirmationContact?.id,
        purchasingContact: purchasingContact?.id,
    };

    const formSubmitHandler = (values) => {
        setLoading(true);
        let data;
        if (type === 'global') {
            data = {
                contactInvoicing: values.invoicingContact,
                contactOrderConfirmation: values.orderConfirmationContact,
                recordId: null,
            };
        } else if (type === 'order') {
            data = {
                contactInvoicing: values.invoicingContact,
                contactOrderConfirmation: values.orderConfirmationContact,
                contactPurchasing: values.purchasingContact,
                recordId: orderId,
            };
        }

        ApiService.updateDefaultContacts(data)
            .then((r) => {
                setStep2(true);
                setLoading(false);
                if (type === 'global') {
                    return dispatch(getUser());
                } else if (type === 'order') {
                    const params = new URLSearchParams();
                    dispatch(getDetails('order', orderId, params, true));
                }
            })
            .catch((e) => {
                setLoading(false);
            });
    };

    const modalClickHandler = () => {
        onClickHandler(false);
    };

    return (
        <ModalWrapper onModalClose={modalClickHandler}>
            <div className={classes['c-manage-contacts-modal']}>
                {!step2 ? (
                    <div className={classes['c-manage-contacts-modal--step-1']}>
                        <h1>{t('contacts_update-contacts')}</h1>
                        <div
                            className={
                                classes['c-manage-contacts-modal__content']
                            }
                        >
                            {type === 'global' ? (
                                <ContactInfo
                                    hasBackground={true}
                                    label={t('contacts_main-contact')}
                                    contact={ownerContact}
                                    smallLabelMargin={true}
                                    noLoading={true}
                                />
                            ) : null}
                            <Formik
                                onSubmit={formSubmitHandler}
                                initialValues={initialValues}
                            >
                                {(props) => (
                                    <Form>
                                        {type === 'order' ? (
                                            <div
                                                className={
                                                    classes[
                                                        'c-manage-contacts-modal__select-wrapper'
                                                    ]
                                                }
                                            >
                                                <TranslatedComponent
                                                    translationFileName="contact-roles"
                                                    translatedComponent={
                                                        'purchasing'
                                                    }
                                                />
                                                <ContactSelect
                                                    onChange={(contact) =>
                                                        purchasingContactChange(
                                                            contact,
                                                            props
                                                        )
                                                    }
                                                    initialValue={
                                                        purchasingContact?.id
                                                    }
                                                    name="purchasingContact"
                                                    extraClass={
                                                        'c-react-select--mt-8'
                                                    }
                                                />
                                            </div>
                                        ) : null}
                                        <div
                                            className={
                                                classes[
                                                    'c-manage-contacts-modal__select-wrapper'
                                                ]
                                            }
                                        >
                                            <TranslatedComponent
                                                translationFileName="contact-roles"
                                                translatedComponent={
                                                    'invoicing'
                                                }
                                            />
                                            <ContactSelect
                                                onChange={(contact) =>
                                                    invoicingContactChange(
                                                        contact,
                                                        props
                                                    )
                                                }
                                                initialValue={
                                                    invoicingContact.id
                                                }
                                                name="invoicingContact"
                                                extraClass={
                                                    'c-react-select--mt-8'
                                                }
                                            />
                                        </div>
                                        <div
                                            className={
                                                classes[
                                                    'c-manage-contacts-modal__select-wrapper'
                                                ]
                                            }
                                        >
                                            <TranslatedComponent
                                                translationFileName="contact-roles"
                                                translatedComponent={
                                                    'orderConfirmation'
                                                }
                                            />
                                            <ContactSelect
                                                onChange={(contact) =>
                                                    orderConfirmationContactChange(
                                                        contact,
                                                        props
                                                    )
                                                }
                                                initialValue={
                                                    orderConfirmationContact.id
                                                }
                                                name="orderConfirmationContact"
                                                extraClass={
                                                    'c-react-select--mt-8'
                                                }
                                            />
                                        </div>
                                        <Button
                                            className={`btn btn--purple btn--small btn--mobile-login  ${
                                                loading ? 'btn--loading' : ''
                                            }`}
                                            ariaLabel={''}
                                            type="submit"
                                        >
                                            {t('lang_save-btn')}
                                            {loading ? (
                                                <span className="loader"></span>
                                            ) : null}
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                ) : null}
                {step2 ? (
                    <ResultModal
                        title={t('contacts_success-update')}
                        onConfirmHandler={modalClickHandler}
                        icon={<CircledCheckmarkIcon />}
                        additionButtonText={t('contacts_ok')}
                    />
                ) : null}
            </div>
        </ModalWrapper>
    );
}

UpdateContactsModal.defaultProps = {
    ownerContact: '',
    invoicingContact: '',
    orderConfirmationContact: '',
    purchasingContact: '',
    showModal: false,
    type: 'global',
};

export default UpdateContactsModal;
