import SidebarFooter from '../Sidebar/SidebarFooter';
import SidebarHeader from '../Sidebar/SidebarHeader';
import { Link, useLocation } from 'react-router-dom';
import classes from './MobileNavigation.module.scss';
import cncLogo from '../../images/cncLogo.svg';
import { useState } from 'react';
import { useEffect } from 'react';

const MobileNavigation = () => {
    const [showMenu, setShowMenu] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setShowMenu(false);
    }, [location.pathname]);

    const toggleMenuHandler = () => {
        setShowMenu(!showMenu);
        const scrY = window.scrollY;
        const html = document.querySelector('html');

        if (!showMenu) {
            // this.scrY = this.window.nativeWindow.scrollY;
            // disable scroll  when menu opened
            html.classList.add('scrollblock');
            html.style.top = `-${scrY}px`;
        } else {
            html.classList.remove('scrollblock');
            html.style.top = null;
            window.scrollTo(0, scrY);
        }
    };

    return (
        <div className={classes['mobile-nav']}>
            <div className={classes['mobile-nav__header']}>
                <Link to="/">
                    <img src={cncLogo} alt="Cnc logo" />
                </Link>
                <div className={classes['mobile-nav__icons']}>
                    {/* <button className={classes['mobile-nav__icon-bell']}>
                        <i className="icon-bell"></i>
                    </button> */}

                    <button
                        className={classes['mobile-nav__icon-hamburger']}
                        onClick={toggleMenuHandler}
                        aria-label="menu"
                    >
                        <i className="icon-hamburger-icon-full"></i>
                    </button>
                </div>
            </div>
            <div
                className={
                    showMenu
                        ? classes['mobile-nav__overlay--show']
                        : classes['mobile-nav__overlay']
                }
                onClick={toggleMenuHandler}
            ></div>
            <div
                className={
                    showMenu
                        ? classes['mobile-nav__menu--show']
                        : classes['mobile-nav__menu']
                }
            >
                <SidebarHeader />
                <SidebarFooter />
            </div>
        </div>
    );
};

export default MobileNavigation;
