import { useRef } from 'react';
import useIsOverflow from '../../../../../hooks/useIsOverflow';
function InvoiceFiles({ invoice }) {
    const downloadPdf = () => {
        let link = document.createElement('a');
        link.href = invoice.fileDownloadUrl;
        link.download = invoice.fileName;
        link.click();
    };

    const ref = useRef();
    const hasOverflow = useIsOverflow(ref, (isOverflowFromCallback) => {
        // true
    });

    return (
        <div className="c-table-item__pdf-file">
            <div
                className="c-table-item__container c-table-item__pdf-download"
                onClick={downloadPdf}
                ref={ref}
            >
                <i className="icon-pdf"></i>
                {invoice.fileName}
            </div>
            {hasOverflow && (
                <span className="c-table-item__pdf-file-full-name">
                    {invoice.fileName}
                </span>
            )}
        </div>
    );
}

export default InvoiceFiles;
