import classes from './SkeletonWithImageTable.module.scss';

const SkeletonWithImageTableHeader = () => {
    const numOfHeaderItems = [1, 2, 3, 4, 5, 6, 7, 8];

    return (
        <div className={classes['skeleton-with-image-table__header']}>
            {numOfHeaderItems.map((item) => {
                return (
                    <div
                        key={item}
                        className={
                            classes[
                                'skeleton-with-image-table__header-element-wrapper'
                            ]
                        }
                    >
                        <span></span>
                    </div>
                );
            })}
        </div>
    );
};

export default SkeletonWithImageTableHeader;
