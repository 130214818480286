import React, { useEffect } from 'react';
import PageMeta from '../../shared/PageMeta/PageMeta';
import { useLocation, useNavigate } from 'react-router';
import classes from './Homepage.module.scss';
import { useSelector } from 'react-redux';

function Homepage() {
    const navigate = useNavigate();
    const location = useLocation();
    const userReducer = useSelector((state) => state.user);

    useEffect(() => {
        let token = localStorage.getItem('token');

        if (!token || (userReducer && !userReducer.user.email_verified)) {
            navigate('/login', {
                state: { redirectTo: location.state },
            });
        } else {
            // navigate('/dashboard');
            navigate('/offers?type=All');
        }

        //eslint-disable-next-line
    }, [navigate]);

    return (
        <div className={classes['c-homepage']}>
            <PageMeta>
                <title>Homepage | CNC24</title>
            </PageMeta>
        </div>
    );
}

export default Homepage;
