import { useEffect } from 'react';
import PageMeta from '../../../shared/PageMeta/PageMeta';
import setGoogleAnalytics from '../../../hooks/setGoogleAnalytics';
import classes from './Contacts.module.scss';
import ContactHeader from './ContactHeader/ContactHeader';
import ContactPersons from './ContactPersons/ContactPersons';
import ManageContacts from './ManageContacts/ManageContacts';
import ContactList from './ContactList/ContactList';
import { useTranslation } from 'react-i18next';

function Contacts() {
    const { t } = useTranslation();

    // reactga4 for settings contacts tab
    useEffect(() => {
        setGoogleAnalytics(
            'page_view',
            'page_view_setting_contact',
            'view setting page, contact section'
        );
    }, []);

    return (
        <>
            <PageMeta>
                <title>{t('account-tab_contacts')} | CNC24</title>
            </PageMeta>
            <div className={classes['c-contacts']}>
                <div className={classes['c-contacts__section']}>
                    <ContactHeader />
                </div>
                <div className={classes['c-contacts__section']}>
                    <ContactPersons type="global" noLoading={true} />
                </div>
                <div className={classes['c-contacts__section']}>
                    <ManageContacts />
                </div>

                <ContactList />
            </div>
        </>
    );
}

export default Contacts;
