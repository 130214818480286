import { useTranslation } from 'react-i18next';

function ContactHeader() {
    const { t } = useTranslation();

    return (
        <>
            <h3>{t('contacts-header')}</h3>
            <p>{t('contacts_header-paragraph-txt')}</p>
        </>
    );
}

export default ContactHeader;
