import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LabelWithoutBackground from '../../Label/LabelWithoutBackground/LabelWithoutBackground';
import classes from './DetailsSideScreen.module.scss';
import useBreakpoint from '../../../hooks/useBreakpoint';
import { useTranslation } from 'react-i18next';
import DetailsSideScreenExpandContainer from './DetailsSideScreenExpandContainer/DetailsSideScreenExpandContainer';
import { useState } from 'react';
import moment from 'moment';
import SidebarDataTitleLabel from '../../SkeletonLoader/SidebarDataTitleLabel';
import SidebarDataContent from '../../SkeletonLoader/SidebarDataContent';
import Inquiry from './Inquiry/Inquiry';
import TotalPrice from './TotalPrice/TotalPrice';
import PriceDropdown from './PriceDropdown/PriceDropdown';
import TranslatedComponent from '../../TranslatedComponent/TranslatedComponent';
// import Translateddaponent from '../../TranslatedComponent/TranslatedComponent';

function DetailsSideScreen({ pageType, goBackLink, loading }) {
    const details = useSelector((state) => state.details.details);
    const breakpoint = useBreakpoint();
    const { t } = useTranslation();
    const [expandedStatus, setExpandedStatus] = useState(false);
    const [priceExpandedStatus, setpriceExpandedStatus] = useState(false);

    const expandedClickHandler = (expandStatus) => {
        setExpandedStatus(expandStatus);
    };

    const priceDropdownExpandedHandler = () => {
        setExpandedStatus(true);
        setpriceExpandedStatus(!priceExpandedStatus);
    };

    return (
        <div className={classes['c-details-sidescreen']}>
            {loading ? (
                <span
                    className={`btn btn--link ${classes['c-details-sidescreen__back-btn-skeleton']}`}
                ></span>
            ) : (
                <Link to={goBackLink} className="btn btn--link">
                    <i className="icon-chevron-left-thin"></i>{' '}
                    {t('offers-single-order-sidebar_back-button')}
                </Link>
            )}
            <div className={classes['c-details-sidescreen__main-info']}>
                <div>
                    {loading ? (
                        <span
                            className={`${classes['c-details-sidescreen__heading']} ${classes['c-details-sidescreen__heading--skeleton']}`}
                        ></span>
                    ) : (
                        <h1
                            className={classes['c-details-sidescreen__heading']}
                        >
                            {pageType === 'offers'
                                ? details.offerNr
                                    ? details.offerNr
                                    : '-'
                                : pageType === 'orders'
                                ? details.orderNr
                                : pageType === 'frameworkAgreement'
                                ? details.faNr
                                : '-'}
                        </h1>
                    )}
                    {loading ? (
                        <span
                            className={`${classes['c-details-sidescreen__id']} ${classes['c-details-sidescreen__id--skeleton']}`}
                        ></span>
                    ) : (
                        <div className={classes['c-details-sidescreen__id']}>
                            {details.reference ? details.reference : '-'}
                        </div>
                    )}
                </div>
                {/* {pageType === 'orders' || pageType === 'frameworkAgreement' ? (
                    loading ? (
                        <span
                            className={`${classes['c-details-sidescreen__bg-label-skeleton']} ${labelWithBackgroundStyles['c-label-with-background']}`}
                        ></span>
                    ) : (
                        <LabelWithBackground
                            color={
                                details?.labels?.length
                                    ? details?.labels[0]?.type
                                    : null
                            }
                            name={
                                details?.labels?.length
                                    ? details?.labels[0]?.name
                                    : null
                            }
                        />
                    )
                ) : null} */}
            </div>
            <div
                className={[
                    classes['c-details-sidescreen__details'],
                    classes[`${pageType}`],
                    expandedStatus ? classes['expandedStatus'] : null,
                ].join(' ')}
            >
                <div className={classes['c-details-sidescreen__detail']}>
                    <SidebarDataTitleLabel
                        labelName={t('offers-single-order-sidebar_status')}
                    />

                    <SidebarDataContent
                        data={
                            details.status ? (
                                <LabelWithoutBackground
                                    statusText={details.status}
                                    icon={true}
                                    classText={details.status}
                                    type={
                                        pageType === 'offers'
                                            ? 'offersStatus'
                                            : pageType === 'orders'
                                            ? 'ordersStatus'
                                            : 'frameworkAgreementStatus'
                                    }
                                />
                            ) : (
                                '-'
                            )
                        }
                    />
                </div>
                {pageType === 'frameworkAgreement' &&
                    (breakpoint > 1280 || breakpoint < 577) && (
                        <div
                            className={classes['c-details-sidescreen__detail']}
                        ></div>
                    )}
                {pageType === 'offers' ? (
                    <>
                        <div
                            className={classes['c-details-sidescreen__detail']}
                        >
                            <SidebarDataTitleLabel
                                labelName={t(
                                    'offers-single-order-sidebar_kind-of-offer'
                                )}
                            />

                            <SidebarDataContent
                                data={
                                    details.typeOfOrder ? (
                                        <TranslatedComponent
                                            translationFileName="typeOfOrder"
                                            translatedComponent={
                                                details.typeOfOrder
                                            }
                                        />
                                    ) : (
                                        '-'
                                    )
                                }
                            />
                        </div>

                        <div
                            className={classes['c-details-sidescreen__detail']}
                        >
                            <SidebarDataTitleLabel
                                loading={loading}
                                labelName={t(
                                    'offers-single-order-sidebar_delivery-address'
                                )}
                            />

                            <SidebarDataContent
                                loading={loading}
                                data={
                                    details.shippingCompany ||
                                    details.shippingStreet ||
                                    details.shippingCity ||
                                    details.shippingCountryName ||
                                    details.shippingPostalCode ? (
                                        <div>
                                            {details.shippingCompany && (
                                                <div>
                                                    {details.shippingCompany}
                                                </div>
                                            )}
                                            {details.shippingStreet && (
                                                <div>
                                                    {details.shippingStreet}
                                                </div>
                                            )}

                                            {(details.shippingPostalCode ||
                                                details.shippingCity ||
                                                details.shippingCountryName) && (
                                                <div
                                                    className={
                                                        classes[
                                                            'c-details-sidescreen__one-line'
                                                        ]
                                                    }
                                                >
                                                    {details.shippingPostalCode && (
                                                        <span>
                                                            {
                                                                details.shippingPostalCode
                                                            }
                                                            {details.shippingCity
                                                                ? ' '
                                                                : !details.shippingCity
                                                                ? ', '
                                                                : null}
                                                        </span>
                                                    )}
                                                    {details.shippingCity && (
                                                        <span>
                                                            {
                                                                details.shippingCity
                                                            }
                                                            {details.shippingCountryName
                                                                ? ', '
                                                                : null}
                                                        </span>
                                                    )}
                                                    {details.shippingCountryName && (
                                                        <span>
                                                            {
                                                                details.shippingCountryName
                                                            }
                                                        </span>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        '-'
                                    )
                                }
                            />
                        </div>

                        {details.status === 'New' ? (
                            <div
                                className={`${classes['c-details-sidescreen__detail']} ${classes['c-details-sidescreen__detail--hidden']}`}
                            ></div>
                        ) : null}

                        {details.status === 'New' ? (
                            <div
                                className={
                                    classes['c-details-sidescreen__detail']
                                }
                            >
                                <SidebarDataTitleLabel
                                    labelName={t('global_latest-offer-date')}
                                />
                                <SidebarDataContent
                                    data={
                                        details.offerDate
                                            ? moment(details.offerDate).format(
                                                  'DD.MM.YYYY'
                                              )
                                            : '-'
                                    }
                                />
                            </div>
                        ) : null}

                        {details.status === 'New' ? (
                            <div
                                className={
                                    classes['c-details-sidescreen__detail']
                                }
                            >
                                <SidebarDataTitleLabel
                                    labelName={t(
                                        'order-details-sidebar_delivery-date'
                                    )}
                                />
                                <SidebarDataContent
                                    data={
                                        details.deliveryDate
                                            ? moment(
                                                  details.deliveryDate
                                              ).format('DD.MM.YYYY')
                                            : '-'
                                    }
                                />
                            </div>
                        ) : null}

                        <div
                            className={
                                classes[
                                    'c-details-sidescreen__detail--for-offers-mob'
                                ]
                            }
                        ></div>
                    </>
                ) : null}
                {pageType === 'orders' ? (
                    <div className={classes['c-details-sidescreen__detail']}>
                        <SidebarDataTitleLabel
                            labelName={t('order-details-sidebar_delivery-date')}
                        />
                        <SidebarDataContent
                            data={
                                details.deliveryDate
                                    ? moment(details.deliveryDate).format(
                                          'DD.MM.YYYY'
                                      )
                                    : '-'
                            }
                        />
                    </div>
                ) : null}
                {pageType === 'frameworkAgreement' &&
                breakpoint < 1280 &&
                breakpoint > 577 ? (
                    <Inquiry loading={loading} details={details} t={t} />
                ) : null}
                {pageType === 'frameworkAgreement' &&
                breakpoint > 577 &&
                breakpoint < 1281 ? (
                    <div
                        className={[
                            classes['c-details-sidescreen__detail'],
                            classes['c-details-sidescreen__detail--relative'],
                        ].join(' ')}
                    >
                        <PriceDropdown
                            data={details}
                            pageType={pageType}
                            onPriceDropdownAction={priceDropdownExpandedHandler}
                            expandedContainerStatus={expandedStatus}
                        />
                    </div>
                ) : null}
                {pageType === 'frameworkAgreement' ? (
                    <div className={classes['c-details-sidescreen__detail']}>
                        <SidebarDataTitleLabel
                            labelName={t('fa-details-sidebar_term-start')}
                        />
                        <SidebarDataContent
                            data={
                                details.startDate
                                    ? moment(details.startDate).format(
                                          'DD.MM.YYYY'
                                      )
                                    : '-'
                            }
                        />
                    </div>
                ) : null}
                {pageType === 'frameworkAgreement' ? (
                    <div className={classes['c-details-sidescreen__detail']}>
                        <SidebarDataTitleLabel
                            labelName={t('fa-details-sidebar_term-end')}
                        />
                        <SidebarDataContent
                            data={
                                details.endDate
                                    ? moment(details.endDate).format(
                                          'DD.MM.YYYY'
                                      )
                                    : '-'
                            }
                        />
                    </div>
                ) : null}
                {((details.status === 'Offersent' ||
                    details.status === 'UpdateSent' ||
                    details.status === 'InNegotiation' ||
                    details.status === 'Offeraccepted' ||
                    details.status === 'Lost') &&
                    breakpoint < 1281) ||
                ((pageType === 'orders' || pageType === 'frameworkAgreement') &&
                    breakpoint < 577) ? (
                    <TotalPrice
                        details={details}
                        t={t}
                        loading={loading}
                        pageType={pageType}
                    />
                ) : null}
                {pageType === 'orders' &&
                breakpoint < 1281 &&
                breakpoint > 576 ? (
                    <div
                        className={[
                            classes['c-details-sidescreen__detail'],
                            classes['c-details-sidescreen__detail--relative'],
                        ].join(' ')}
                    >
                        <PriceDropdown
                            data={details}
                            pageType={pageType}
                            onPriceDropdownAction={priceDropdownExpandedHandler}
                            expandedContainerStatus={expandedStatus}
                        />
                    </div>
                ) : null}
                <DetailsSideScreenExpandContainer
                    loading={loading}
                    data={details}
                    pageType={pageType}
                    onExpandedClickHandler={expandedClickHandler}
                    priceDropdownExpanded={priceExpandedStatus}
                />
            </div>
        </div>
    );
}

export default DetailsSideScreen;
