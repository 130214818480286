import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';

function TableInput({ inputChange, placeholder, index }) {
    const [inputValue, setInputValue] = useState('');

    const frameworkWizzardQuantity = useSelector(
        (state) => state.frameworkWizzardReducer.frameworkLinesQuantityArr
    );

    useEffect(() => {
        setInputValue(frameworkWizzardQuantity[index]);
        //eslint-disable-next-line
    }, []);

    const handleInputChange = (e) => {
        inputChange(e.target.value.replace(/[^0-9]/g, ''));
        setInputValue(e.target.value.replace(/[^0-9]/g, ''));
    };
    return (
        <div>
            <input
                type="text"
                onChange={handleInputChange}
                value={inputValue}
            ></input>
            <span>{placeholder}</span>
        </div>
    );
}

export default TableInput;
