import classes from './ErrorMessage.module.scss';
import { useTranslation } from 'react-i18next';
import AdditionInfoFilledIcon from '../Icons/AdditionInfoFilledIcon';

function ErrorMessage({
    type,
    relatedOrders,
    errorText,
    bgColor,
    hasIcon,
    hasErrorText,
    additionalClass,
    customIcon,
}) {
    const { t } = useTranslation();

    return (
        <div
            className={`${classes['c-error-message']} ${
                classes[`c-error-message--${type}`]
            } ${hasIcon ? classes['c-error-message--hasIcon'] : ''}  ${
                classes[`c-error-message--${bgColor}`]
            } ${classes[`c-error-message--${additionalClass}`]}`}
        >
            {hasIcon ? (
                !customIcon ? (
                    <i
                        className={
                            type === 'successfullyLoggedOut'
                                ? 'icon-checkmark-circle'
                                : 'icon-warning'
                        }
                    ></i>
                ) : (
                    <AdditionInfoFilledIcon fill="#02AAFE" />
                )
            ) : null}
            <span className={classes['c-error-message__content']}>
                {type === 'successfullyLoggedOut' ? (
                    t('login_success-logout-msg')
                ) : type === 'sessionExpired' ? (
                    t('login_session-expired-msg')
                ) : hasErrorText ? (
                    errorText
                ) : (
                    <div>
                        <span
                            className={
                                classes['c-error-message__related-order-text']
                            }
                        >
                            {t('delivery-detail-popup_note')}
                        </span>
                        {relatedOrders?.map((order, index) => {
                            return (
                                <a
                                    key={`related-order-${index}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    href={
                                        window.location.origin +
                                        '/orders/' +
                                        order.orderId +
                                        '/components'
                                    }
                                    className={
                                        classes[
                                            'c-error-message__related-order'
                                        ]
                                    }
                                >
                                    <i className="icon-contract"></i>
                                    {order.orderNr}
                                </a>
                            );
                        })}
                    </div>
                )}
            </span>
        </div>
    );
}

ErrorMessage.defaultProps = {
    type: '',
    errorText: '',
    bgColor: '',
    hasIcon: '',
    hasErrorText: '',
    additionClass: '',
    customIcon: '',
};

export default ErrorMessage;
