import classes from './SkeletonWithImageTable.module.scss';
import SkeletonWithImageTableLabels from './SkeletonWithImageTableLabels';
const SkeletonWithImageTableRow = () => {
    return (
        <div className={classes['skeleton-with-image-table']}>
            <div className={classes['skeleton-with-image-table__content']}>
                <div
                    className={
                        classes[
                            'skeleton-with-image-table__image-part-name-box'
                        ]
                    }
                >
                    <span
                        className={classes['skeleton-with-image-table__image']}
                    ></span>

                    <div
                        className={`${classes['skeleton-with-image-table__label-value']} ${classes['skeleton-with-image-table__label-value--two-items']}`}
                    >
                        <span
                            className={
                                classes['skeleton-with-image-table__label']
                            }
                        ></span>
                        <span
                            className={
                                classes['skeleton-with-image-table__value']
                            }
                        ></span>
                    </div>
                </div>

                <SkeletonWithImageTableLabels />

                <div
                    className={
                        classes[
                            'skeleton-with-image-table__label-value-wrapper'
                        ]
                    }
                >
                    <div
                        className={`${classes['skeleton-with-image-table__label-value']} ${classes['skeleton-with-image-table__label-value--two-items']}`}
                    >
                        <span
                            className={
                                classes['skeleton-with-image-table__label']
                            }
                        ></span>
                        <span
                            className={
                                classes['skeleton-with-image-table__value']
                            }
                        ></span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SkeletonWithImageTableRow;
