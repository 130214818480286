import ApiService from '../../services/api.service';
import { onSetTax } from '../actions/offersWizzardActions';

export function getTax(params) {
    return function (dispatch) {
        return ApiService.getTax(params).then((r) => {
            dispatch(onSetTax(r.data.data));
        });
    };
}
