import React from 'react';
import classes from './SideScreen.module.scss';
import cncLogo from '../../images/cncLogo.svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function SideScreen() {
    const { t } = useTranslation();

    return (
        <div className={classes['c-login-left']}>
            <div className={classes['c-login-left__container']}>
                <Link to="/">
                    <img src={cncLogo} alt="Cnc logo" width="169" height="40" />
                </Link>
                <h1>{t('login_sidebar-header')}</h1>
            </div>
        </div>
    );
}

export default SideScreen;
