import ApiService from '../../services/api.service';
import { onOfferSuccess } from '../actions/acceptOfferActions';

export function acceptOffer(data, reference) {
    return function (dispatch) {
        return ApiService.acceptOffer(data).then((offer) => {
            dispatch(onOfferSuccess(offer.data.data, reference));
        });
    };
}

export function acceptFa(data, reference) {
    return function (dispatch) {
        return ApiService.acceptFa(data).then((offer) => {
            dispatch(onOfferSuccess(offer.data.data, reference));
        });
    };
}
