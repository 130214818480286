import { useEffect } from 'react';
import Button from '../Button/Button';
import CloseIcon from '../Icons/CloseIcon';
import classes from './ModalWrapper.module.scss';

function ModalWrapper({ children, onModalClose }) {
    // add scrollblock when modal opens
    useEffect(() => {
        document.getElementsByTagName('body')[0].classList.add('noScroll');
    }, []);

    // remove scrollblock when modal closes
    useEffect(() => {
        return () => {
            document
                .getElementsByTagName('body')[0]
                .classList.remove('noScroll');
        };
    }, []);

    return (
        <div className={classes['c-modal-wrapper']}>
            <div className={classes['c-modal-wrapper__modal']}>
                {children}
                <Button
                    className={classes['c-modal-wrapper__close']}
                    onClick={() => {
                        onModalClose();
                    }}
                    ariaLabel="Close modal"
                >
                    <CloseIcon />
                </Button>
            </div>
        </div>
    );
}

ModalWrapper.defaultProps = {
    onModalClose: false,
    showModal: false,
};

export default ModalWrapper;
