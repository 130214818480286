function Tooltip({ text, extraClass }) {
    return (
        <div className={`c-tooltip ${extraClass}`}>
            <div className="c-tooltip__content">{text}</div>
        </div>
    );
}

Tooltip.defaultProps = {
    text: '',
    extraClass: '',
};

export default Tooltip;
