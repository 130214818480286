import { useTranslation } from 'react-i18next';

function TranslatedComponent({
    translationFileName,
    translatedComponent,
    hasComma,
}) {
    const { t } = useTranslation();
    const trimedTranslatedComponent = translatedComponent?.replace(/ /g, '');
    const translationObject = JSON.parse(t(translationFileName));

    return (
        <>
            {translationObject[trimedTranslatedComponent]}
            {hasComma ? <span>, </span> : null}
        </>
    );
}

export default TranslatedComponent;
