import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import classes from '../../Tabs/Tab/Tab.module.scss';

function Tab(props) {
    //eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();

    const [active, setActive] = useState(false);

    const handleTabSwitch = () => {
        const currentParams = new URLSearchParams(window.location.search);
        const type = currentParams.get('type');
        const search = currentParams.get('searchFilter');
        let params;
        if (search) {
            params = new URLSearchParams('searchFilter=' + search);
        } else {
            params = new URLSearchParams();
        }

        if (props.pageName === 'framework') {
            params.set('type', type === props.type ? 'Active' : props.type);
        } else if (props.pageName === 'orders') {
            params.set('type', type === props.type ? 'Open' : props.type);
            if (props.type === 'Completed' || props.type === 'All') {
                params.set('startDate', '2023-01-01');
                params.set('endDate', '2023-12-31');
            }
        } else if (props.pageName === 'frameworkComponents') {
            params.set('type', type === props.type ? 'Overview' : props.type);
        } else {
            params.set('type', type === props.type ? 'All' : props.type);
        }

        setSearchParams(params);
    };

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const type = params.get('type');
        if (props.type === type) {
            setActive(true);
        } else {
            setActive(false);
        }
        //eslint-disable-next-line
    }, [window.location.search]);

    return (
        <button
            className={`${classes['c-tab__btn']} ${active ? 'active' : ''} ${
                props.type === 'frameworkComponents'
                    ? classes['c-tab--details']
                    : ''
            }`}
            onClick={handleTabSwitch}
        >
            {props.title}
        </button>
    );
}

export default Tab;
