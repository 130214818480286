import * as actionTypes from '../actions/userActions';

const initialState = {
    user: '',
    userLoading: true,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOG_IN:
            return {
                ...state,
                user: action.user,
            };
        case actionTypes.GET_USER:
            return {
                ...state,
                user: action.user,
                userLoading: false,
            };
        case actionTypes.CHANGE_LANGUAGE:
            // let changeLangugeUser = state.user
            // changeLangugeUser = {
            //     ...changeLangugeUser,
            //     lang: action.language
            // }
            // return {
            //     ...state,
            //     user: changeLangugeUser
            // }
            return {
                ...state,
            };
        case actionTypes.LOG_OUT:
            return {
                ...state,
                user: '',
            };
        case actionTypes.DISABLE_LOADING:
            return {
                ...state,
                userLoading: false,
            };
        default:
            return state;
    }
};

export default userReducer;
