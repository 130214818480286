import WizzardHeader from '../../../components/Wizzard/WizzardHeader/WizzardHeader';
import { useDispatch, useSelector } from 'react-redux';
import classes from '../../../components/Wizzard/WizzardGeneral.module.scss';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { onToggleWizzard } from '../../../store/actions/offersWizzardActions';
import Loader from '../../../shared/Loader/Loader';

function OffersWizzard() {
    const { t } = useTranslation();
    //eslint-disable-next-line
    const [isFormValid, setIsFormValid] = useState(false);

    const headerItems = [
        t('wizard-header_step-parts'),
        t('wizard-header_step-order-details'),
        t('wizard-header_step-summary'),
    ];

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type');

    const offersWizzard = useSelector((state) => state.offersWizzardReducer);

    useEffect(() => {
        if (
            !offersWizzard.wizzardType.length &&
            offersWizzard.details?.offerId
        ) {
            dispatch(onToggleWizzard(type ? type : 'single-offer'));
            navigate(
                `/offers/${offersWizzard.details.offerId}/wizard/components?type=${type}`
            );
        }
        //eslint-disable-next-line
    }, [offersWizzard.wizzardType, offersWizzard.details, type]);

    return (
        <div className={classes['wizzard']}>
            <div>
                <WizzardHeader
                    items={headerItems}
                    active={offersWizzard.currentStep}
                    totalStep={offersWizzard.totalStep}
                    isValid={isFormValid}
                    type="offers"
                />
                {offersWizzard.open ? (
                    <div
                        className={`${classes['wizzard__body']} ${
                            offersWizzard.currentStep === 1
                                ? classes['wizzard__body--components']
                                : ''
                        } ${
                            offersWizzard.currentStep === 2
                                ? classes['wizzard__body--order-details']
                                : ''
                        } ${
                            offersWizzard.currentStep === 3
                                ? classes['wizzard__body--summary']
                                : ''
                        }`}
                    >
                        <Outlet />
                    </div>
                ) : (
                    <Loader />
                )}
            </div>
        </div>
    );
}

export default OffersWizzard;
