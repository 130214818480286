import { useState } from 'react';
import { useSelector } from 'react-redux';
import SectionHeaderWrapper from '../../../../components/SectionHeaderWrapper/SectionHeaderWrapper';
import ContactInfo from './ContactInfo/ContactInfo';
import classes from './ContactPersons.module.scss';
import UpdateContactsModal from './UpdateContactsModal/UpdateContactsModal';
import { useTranslation } from 'react-i18next';

function ContactPersons({
    type,
    invoiceContactId,
    purchasingContactId,
    confirmationContactId,
    orderId,
    noLoading,
}) {
    const userReducer = useSelector((state) => state.user);
    const [openModal, setOpenModal] = useState(false);
    const { t } = useTranslation();

    const contactsList = userReducer?.user.contacts;

    let invoicingContactUser;
    let orderConfirmationContactUser;
    let portalOwnerContactUser;
    let purchasingContactUser;

    if (type === 'global') {
        invoicingContactUser = contactsList.find(
            (contact) => contact.id === userReducer?.user.contactInvoicing
        );
        orderConfirmationContactUser = contactsList.find(
            (contact) =>
                contact.id === userReducer?.user.contactOrderConfirmation
        );
        portalOwnerContactUser = contactsList.find(
            (contact) => contact.portalRole === 'Owner'
        );
    } else if (type === 'order') {
        purchasingContactUser = contactsList.find(
            (contact) => contact.id === purchasingContactId
        );
        invoicingContactUser = contactsList.find(
            (contact) => contact.id === invoiceContactId
        );
        orderConfirmationContactUser = contactsList.find(
            (contact) => contact.id === confirmationContactId
        );
    }

    const modalClickHandler = () => {
        setOpenModal(!openModal);
    };

    return (
        <>
            <SectionHeaderWrapper
                title={t('contacts_persons')}
                description={t('contacts_manage-txt')}
                edit={true}
                type="contactPersons"
                onClickHandler={modalClickHandler}
                userPortalRole={userReducer.user.portalRole}
            />
            {(userReducer.user.portalRole === 'Owner' ||
                userReducer.user.portalRole === 'Admin') &&
            openModal ? (
                <UpdateContactsModal
                    ownerContact={portalOwnerContactUser}
                    invoicingContact={invoicingContactUser}
                    orderConfirmationContact={orderConfirmationContactUser}
                    purchasingContact={purchasingContactUser}
                    onClickHandler={modalClickHandler}
                    type={type}
                    orderId={orderId}
                />
            ) : null}
            <div className={classes['c-contact-persons__contacts']}>
                {type === 'global' ? (
                    <div className={classes['c-contact-persons__contact']}>
                        <ContactInfo
                            hasBackground={true}
                            label={t('contacts_account-owner')}
                            labelIcon="AditionalInfoIcon"
                            contact={portalOwnerContactUser}
                            smallLabelMargin={true}
                            noLoading={noLoading}
                        />
                    </div>
                ) : null}
                <div className={classes['c-contact-persons__one-row']}>
                    {type === 'order' ? (
                        <div className={classes['c-contact-persons__contact']}>
                            <ContactInfo
                                label={t('contacts_purchasing')}
                                contact={purchasingContactUser}
                                noLoading={noLoading}
                            />
                        </div>
                    ) : null}
                    <div className={classes['c-contact-persons__contact']}>
                        <ContactInfo
                            label={t('contacts_invoicing')}
                            contact={invoicingContactUser}
                            noLoading={noLoading}
                        />
                    </div>
                    <div className={classes['c-contact-persons__contact']}>
                        <ContactInfo
                            label={t('contacts_order-confirm')}
                            contact={orderConfirmationContactUser}
                            noLoading={noLoading}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

ContactPersons.defaultProps = {
    type: 'global',
    invoiceContactId: '',
    purchasingContactId: '',
    confirmationContactId: '',
    noLoading: false,
};

export default ContactPersons;
