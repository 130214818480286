import React from 'react';
import classes from './TableSkeletonLoader.module.scss';
import TableLines from '../../Table/TableEmptyState/TableLines/TableLines';

function TableSkeletonLoader(props) {
    let linesArr = [];
    const numOfRows = [1, 2, 3, 4, 5];

    for (let i = 0; i < props.columns; i++) {
        linesArr.push(i);
    }

    return (
        <div
            className={
                props.showSkeletonHeader
                    ? classes['table-skeleton-loader__with-header']
                    : classes['table-skeleton-loader']
            }
        >
            {props.showSkeletonHeader ? (
                <div
                    className={`${classes['table-skeleton-loader__header']} ${
                        classes['table-skeleton-loader__lines']
                    } ${
                        classes[`table-empty-state__lines--${props.tableType}`]
                    }`}
                >
                    {linesArr.map((item) => {
                        return (
                            <TableLines
                                key={`skeleton-loader-${item}`}
                                width={`table-empty-state__lines--${props.tableType}`}
                            />
                        );
                    })}
                </div>
            ) : null}
            <div className={classes['table-skeleton-loader__lines-wrapper']}>
                <>
                    {numOfRows.map((item) => {
                        return (
                            <div
                                key={item}
                                className={`${
                                    classes['table-skeleton-loader__lines']
                                } ${
                                    classes[
                                        `table-empty-state__lines--${props.tableType}`
                                    ]
                                }`}
                            >
                                {linesArr.map((item) => {
                                    return (
                                        <TableLines
                                            key={`skeleton-loader-${item}`}
                                            width={`table-empty-state__lines--${props.tableType}`}
                                        />
                                    );
                                })}
                            </div>
                        );
                    })}
                </>
            </div>
        </div>
    );
}

export default TableSkeletonLoader;
