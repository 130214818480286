import classes from './Loader.module.scss';
import React from 'react';

export default function Loader() {
    return (
        <div className={classes.Loader}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
}
