import * as actionTypes from '../actions/tableContentActions';

const initialState = {
    tableContent: [],
    tableContentLoading: false,
}

const tableContentReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_TABLE_CONTENT:
            return {
                ...state,
                tableContent: action.orders,
                tableContentLoading: false
            };
        case actionTypes.GET_TABLE_CONTENT_LOADING:
            return {
                ...state,
                tableContentLoading: true,
            }
        case actionTypes.TABLE_CONTENT_CLEANUP:
            return {
                ...state,
                tableContentLoading: false,
                tableContent: [],
            }
        default:
            return state;
    }
}

export default tableContentReducer;