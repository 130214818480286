export const TOGGLE_FRAMEWORK_WIZZARD = 'TOGGLE_FRAMEWORK_WIZZARD';
export const GET_FRAMEWORK_WIZZARD_DETAILS = 'GET_FRAMEWORK_WIZZARD_DETAILS';
export const SET_QUANTITY_INDEX = 'SET_QUANTITY_INDEX';
export const STEP_UP = 'STEP_UP';
export const STEP_DOWN = 'STEP_DOWN';
export const REMOVE_FRAMEWORK_ITEM = 'REMOVE_FRAMEWORK_ITEM';
export const RESTORE_ITEMS = 'RESTORE_ITEMS';
export const SET_API_DATA = 'SET_API_DATA';
export const SET_TAX = 'SET_TAX';
export const CLEAR_WIZZARD = 'CLEAR_WIZZARD';
export const INPUT_CHANGE = 'INPUT_CHANGE';

export const onFrameworkInputChange = (value, index) => {
    return (dispatch) => {
        dispatch({
            type: INPUT_CHANGE,
            index: index,
            value: value,
        });
    };
};

export const onSetTax = (tax) => {
    return (dispatch) => {
        dispatch({
            type: SET_TAX,
            tax: tax,
        });
    };
};

export const onSetFrameworkApiData = (payload) => {
    return (dispatch) => {
        dispatch({
            type: SET_API_DATA,
            data: payload,
        });
    };
};

export const onRestoreItems = () => {
    return (dispatch) => {
        dispatch({
            type: RESTORE_ITEMS,
        });
    };
};

export const onRemoveFrameworkItem = (lineId, index) => {
    return (dispatch) => {
        dispatch({
            type: REMOVE_FRAMEWORK_ITEM,
            lineId: lineId,
            index: index,
        });
    };
};

export const onStepUp = () => {
    return (dispatch) => {
        dispatch({
            type: STEP_UP,
        });
    };
};

export const onStepDown = () => {
    return (dispatch) => {
        dispatch({
            type: STEP_DOWN,
        });
    };
};

export const onSetQuantityIndex = (rowIndex, quantityIndex) => {
    return (dispatch) => {
        dispatch({
            type: SET_QUANTITY_INDEX,
            rowIndex: rowIndex,
            quantityIndex: quantityIndex,
        });
    };
};

export const onGetFrameworkWizzardDetails = (details) => {
    return (dispatch) => {
        dispatch({
            type: GET_FRAMEWORK_WIZZARD_DETAILS,
            details: details,
        });
    };
};

export const onToggleFrameworkWizzard = () => {
    return (dispatch) => {
        dispatch({
            type: TOGGLE_FRAMEWORK_WIZZARD,
        });
    };
};

export const onClearWizzard = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_WIZZARD,
        });
    };
};
