import SideScreen from '../SideScreen/SideScreen';
import classes from './AuthWrapper.module.scss';

function AuthWrapper({ children }) {
    return (
        <div className={classes['c-auth-wrapper']}>
            <SideScreen />
            <div className={classes['c-auth-wrapper__content-container']}>
                <div className={classes['c-auth-wrapper__content']}>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default AuthWrapper;
