import React from 'react';
import { Link } from 'react-router-dom';
import classes from './EmailConfirm.module.scss';
import cncLogo from '../../../images/cncLogo.svg';
import letterImg from '../../../images/mailImgOrange.svg';
import { useTranslation } from 'react-i18next';

export default function EmailConfirm() {
    const { t } = useTranslation();

    return (
        <div className={classes['c-email-confirm']}>
            <div className={classes['c-email-confirm__header']}>
                <Link to="/">
                    <img src={cncLogo} alt="Cnc logo" width="118" height="40" />
                </Link>
            </div>
            <div className={classes['c-email-confirm__container']}>
                <img
                    src={letterImg}
                    alt="Letter icon with orange outline"
                    width="80"
                    height="64"
                />
                <h1>{t('reset-password_confirmation-heading-step-1')}</h1>
                <p>
                    {/* Wir haben Ihnen einen temporären Login-Link zugesendet.
                    <br />
                    Bitte schauen Sie in Ihr E-Mail-Postfach von
                    <strong> max.mustermann@firma.de</strong>. */}
                    {t('reset-password_confirmation-text-step-1')}
                </p>
                <Link
                    to="/login"
                    className={
                        'btn btn--transparent btn--big btn--sqaure btn--transparent-grey'
                    }
                >
                    {t('reset-password_confirmation-btn-text-step-1')}
                </Link>
            </div>
        </div>
    );
}
