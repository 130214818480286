import FormatedPrice from '../../../../FormatedPrice/FormatedPrice';
import SidebarDataContent from '../../../../SkeletonLoader/SidebarDataContent';
import SidebarDataTitleLabel from '../../../../SkeletonLoader/SidebarDataTitleLabel';
import classes from './PriceLabelAndPrice.module.scss';

function PriceLabelAndPrice({
	price,
	translation,
	additionalClass,
	additionPriceClass,
	additionPriceContainerClass,
}) {
	return (
		<div className={classes['c-price-label-and-price']}>
			<SidebarDataTitleLabel
				labelName={translation}
				additionalClass={additionalClass}
			/>
			<div
				className={[
					classes['c-price-label-and-price__formated-price'],
					classes[additionPriceContainerClass],
				].join(' ')}
			>
				<SidebarDataContent
					additionalClass={`${additionPriceClass}`}
					data={<FormatedPrice price={price} />}
				/>
			</div>
		</div>
	);
}

PriceLabelAndPrice.defaultProps = {
	price: '',
	translation: '',
	additionalClass: '',
	additionPriceContainerClass: '',
};

export default PriceLabelAndPrice;
