import React from 'react';
import classes from './TableEmptyState.module.scss';
import { Link } from 'react-router-dom';
import TableLines from './TableLines/TableLines';

function TableEmptyState(props) {
    let linesArr = [];
    const numOfEmpStRows = [1, 2, 3];

    for (let i = 0; i < props.columns; i++) {
        linesArr.push(i);
    }

    return (
        <div
            className={`${classes['table-empty-state']} ${
                props.overflowAuto
                    ? classes['table-empty-state--overflow-auto']
                    : ''
            }`}
        >
            <div className={classes['table-empty-state__lines-wrapper']}>
                {numOfEmpStRows.map((row) => {
                    return (
                        <div
                            key={row}
                            className={`${
                                classes['table-empty-state__lines']
                            } ${
                                classes[
                                    `table-empty-state__lines--${props.tableType}`
                                ]
                            }`}
                        >
                            {linesArr.map((item) => {
                                return (
                                    <TableLines
                                        key={item}
                                        width={`table-empty-state__lines--${props.tableType}`}
                                    />
                                );
                            })}
                        </div>
                    );
                })}
            </div>

            <div className={classes['table-empty-state__content']}>
                <h3 className={classes['table-empty-state__heading']}>
                    {props.heading}
                </h3>
                <p className={classes['table-empty-state__description']}>
                    {props.description}{' '}
                </p>
                {props.descriptionSecond ? (
                    <p className={classes['table-empty-state__description']}>
                        {props.descriptionSecond}{' '}
                    </p>
                ) : null}
                {props.btnText ? (
                    <Link
                        to="/"
                        className="btn btn--small btn--purple btn--square"
                    >
                        {props.btnText}
                    </Link>
                ) : null}
            </div>
        </div>
    );
}

export default TableEmptyState;
