import { Formik, Form } from 'formik';
import React from 'react';
import Input from '../../../../../components/Input/Input/Input';
import WizzardFooter from '../../../../../components/Wizzard/WizzardFooter/WizzardFooter';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { onSetFrameworkApiData } from '../../../../../store/actions/frameworkWizzardActions';
import Dropzone from '../../../../../components/Dropzone/Dropzone';
import classes from '../../../../../components/Wizzard/WizzardOrderDetails.module.scss';
import UploadedFile from '../../../../../components/UploadedFile/UploadedFile';
import { useEffect, useState } from 'react';
import DatePicker from '../../../../../components/DatePicker/DatePicker';
import CountrySelect from '../../../../../components/Input/CountrySelect/CountrySelect';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import ContactSelect from '../../../../../components/Input/ContactSelect/ContactSelect';
import TranslatedComponent from '../../../../../components/TranslatedComponent/TranslatedComponent';

function OrderDetails({ extraClassRightSide, firstStep }) {
    const [files, setFiles] = useState([]);

    const { t } = useTranslation();

    const frameworkWizzardReducer = useSelector(
        (state) => state.frameworkWizzardReducer
    );

    const userReducer = useSelector((state) => state.user);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    useEffect(() => {
        if (frameworkWizzardReducer.data.files) {
            setFiles(frameworkWizzardReducer.data.files);
        }
    }, [frameworkWizzardReducer]);

    const validationSchema = Yup.object().shape({
        shippingAddressCompanyName: Yup.string().required(
            `${t('error_field-required')}`
        ),
        shippingAddressAdditionalInfo: Yup.string(),
        shippingAddressStreet: Yup.string().required(
            `${t('error_field-required')}`
        ),
        shippingAddressPostalCode: Yup.string().required(
            `${t('error_field-required')}`
        ),
        shippingAddressCity: Yup.string().required(
            `${t('error_field-required')}`
        ),
        shippingAddressCountry: Yup.string().required(
            `${t('error_field-required')}`
        ),
        billingAddressCompanyName: Yup.string().required(
            `${t('error_field-required')}`
        ),
        billingAddressAdditionalInfo: Yup.string(),
        billingAddressStreet: Yup.string().required(
            `${t('error_field-required')}`
        ),
        billingAddressTaxNumber: Yup.string(),
        billingAddressPostalCode: Yup.string().required(
            `${t('error_field-required')}`
        ),
        billingAddressCity: Yup.string().required(
            `${t('error_field-required')}`
        ),
        billingAddressCountry: Yup.string().required(
            `${t('error_field-required')}`
        ),
        reference: Yup.string().required(`${t('error_field-required')}`),
        comments: Yup.string(),
        deliveryDate: Yup.string().required(`${t('error_field-required')}`),
        contactPurchasing: Yup.string(),
        contactInvoicing: Yup.string(),
        contactOrderConfirmation: Yup.string(),
    });

    const initialValues = {
        shippingAddressCompanyName: frameworkWizzardReducer.data.addresses
            .shippingAddress.companyName
            ? frameworkWizzardReducer.data.addresses.shippingAddress.companyName
            : frameworkWizzardReducer.details.shippingCompany
            ? frameworkWizzardReducer.details.shippingCompany
            : '',
        shippingAddressAdditionalInfo: frameworkWizzardReducer.data.addresses
            .shippingAddress.additionalInfo
            ? frameworkWizzardReducer.data.addresses.shippingAddress
                  .additionalInfo
            : frameworkWizzardReducer.details.shippingAdditionalInfo
            ? frameworkWizzardReducer.details.shippingAdditionalInfo
            : '',
        shippingAddressStreet: frameworkWizzardReducer.data.addresses
            .shippingAddress.street
            ? frameworkWizzardReducer.data.addresses.shippingAddress.street
            : frameworkWizzardReducer.details.shippingStreet
            ? frameworkWizzardReducer.details.shippingStreet
            : '',
        shippingAddressPostalCode: frameworkWizzardReducer.data.addresses
            .shippingAddress.postalCode
            ? frameworkWizzardReducer.data.addresses.shippingAddress.postalCode
            : frameworkWizzardReducer.details.shippingPostalCode
            ? frameworkWizzardReducer.details.shippingPostalCode
            : '',
        shippingAddressCity: frameworkWizzardReducer.data.addresses
            .shippingAddress.city
            ? frameworkWizzardReducer.data.addresses.shippingAddress.city
            : frameworkWizzardReducer.details.shippingCity
            ? frameworkWizzardReducer.details.shippingCity
            : '',
        shippingAddressCountry: frameworkWizzardReducer.data.addresses
            .shippingAddress.country
            ? frameworkWizzardReducer.data.addresses.shippingAddress.country
            : frameworkWizzardReducer.details.shippingCountry
            ? frameworkWizzardReducer.details.shippingCountry
            : 'DE',
        billingAddressCompanyName: frameworkWizzardReducer.data.addresses
            .billingAddress.companyName
            ? frameworkWizzardReducer.data.addresses.billingAddress.companyName
            : frameworkWizzardReducer.details.billingCompany
            ? frameworkWizzardReducer.details.billingCompany
            : '',
        billingAddressAdditionalInfo: frameworkWizzardReducer.data.addresses
            .billingAddress.additionalInfo
            ? frameworkWizzardReducer.data.addresses.billingAddress
                  .additionalInfo
            : frameworkWizzardReducer.details.billingAdditionalInfo
            ? frameworkWizzardReducer.details.billingAdditionalInfo
            : '',
        billingAddressStreet: frameworkWizzardReducer.data.addresses
            .billingAddress.street
            ? frameworkWizzardReducer.data.addresses.billingAddress.street
            : frameworkWizzardReducer.details.billingStreet
            ? frameworkWizzardReducer.details.billingStreet
            : '',
        billingAddressTaxNumber: frameworkWizzardReducer.data.addresses
            .billingAddress.taxNumber
            ? frameworkWizzardReducer.data.addresses.billingAddress.taxNumber
            : frameworkWizzardReducer.details.taxNumber
            ? frameworkWizzardReducer.details.taxNumber
            : '',
        billingAddressPostalCode: frameworkWizzardReducer.data.addresses
            .billingAddress.postalCode
            ? frameworkWizzardReducer.data.addresses.billingAddress.postalCode
            : frameworkWizzardReducer.details.billingPostalCode
            ? frameworkWizzardReducer.details.billingPostalCode
            : '',
        billingAddressCity: frameworkWizzardReducer.data.addresses
            .billingAddress.city
            ? frameworkWizzardReducer.data.addresses.billingAddress.city
            : frameworkWizzardReducer.details.billingCity
            ? frameworkWizzardReducer.details.billingCity
            : '',
        billingAddressCountry: frameworkWizzardReducer.data.addresses
            .billingAddress.country
            ? frameworkWizzardReducer.data.addresses.billingAddress.country
            : frameworkWizzardReducer.details.billingCountry
            ? frameworkWizzardReducer.details.billingCountry
            : 'DE',
        reference: frameworkWizzardReducer.data.faReference,
        comments: frameworkWizzardReducer.data.comments,
        deliveryDate: frameworkWizzardReducer.data.deliveryDate
            ? frameworkWizzardReducer.data.deliveryDate
            : new Date().toJSON()?.slice(0, 10),
        contactPurchasing: frameworkWizzardReducer.data.defaultContacts
            .contactPurchasing
            ? frameworkWizzardReducer.data.defaultContacts.contactPurchasing
            : userReducer.user.contactPurchasing
            ? userReducer.user.contactPurchasing
            : '',
        contactInvoicing: frameworkWizzardReducer.data.defaultContacts
            .contactInvoicing
            ? frameworkWizzardReducer.data.defaultContacts.contactInvoicing
            : userReducer.user.contactInvoicing
            ? userReducer.user.contactInvoicing
            : '',
        contactOrderConfirmation: frameworkWizzardReducer.data.defaultContacts
            .contactOrderConfirmation
            ? frameworkWizzardReducer.data.defaultContacts
                  .contactOrderConfirmation
            : userReducer.user.contactOrderConfirmation
            ? userReducer.user.contactOrderConfirmation
            : '',
    };

    const acceptFiles = { 'application/pdf': [] };

    const handleSubmit = (values) => {
        let frameworkLines = frameworkWizzardReducer.frameworkLines.map(
            (frameworkLine, index) => {
                return {
                    faLineId: frameworkLine.frameworkLineId,
                    quantity: parseInt(
                        frameworkWizzardReducer.frameworkLinesQuantityArr[index]
                    ),
                };
            }
        );
        let totalPrice = 0;
        frameworkWizzardReducer.details.frameworkLines.forEach(
            (frameworkLine) => {
                totalPrice = totalPrice + frameworkLine.totalPrice;
            }
        );
        let data = {
            faId: frameworkWizzardReducer.details.faId,
            files: files,
            deliveryDate: values.deliveryDate,
            faReference: values.reference,
            faLines: frameworkLines,
            totalPrice: totalPrice,
            comments: values.comments,
            addresses: {
                billingAddress: {
                    city: values.billingAddressCity,
                    companyName: values.billingAddressCompanyName,
                    country: values.billingAddressCountry,
                    postalCode: values.billingAddressPostalCode,
                    street: values.billingAddressStreet,
                    additionalInfo: values.billingAddressAdditionalInfo,
                    taxNumber: values.billingAddressTaxNumber,
                },
                shippingAddress: {
                    city: values.shippingAddressCity,
                    companyName: values.shippingAddressCompanyName,
                    country: values.shippingAddressCountry,
                    postalCode: values.shippingAddressPostalCode,
                    street: values.shippingAddressStreet,
                    additionalInfo: values.shippingAddressAdditionalInfo,
                },
            },
            defaultContacts: {
                contactPurchasing: values.contactPurchasing,
                contactInvoicing: values.contactInvoicing,
                contactOrderConfirmation: values.contactOrderConfirmation,
            },
        };
        dispatch(onSetFrameworkApiData(data));

        navigate(
            `/framework-agreements/${frameworkWizzardReducer.details.faId}/wizard/summary`
        );
    };

    const handleUploadSuccessfull = (uploadedFiles) => {
        let filesClone = [...files];
        let mergedArrays = [...filesClone, ...uploadedFiles];
        setFiles(mergedArrays);
    };

    const dateChangeHandler = (date, props) => {
        let selectedDate;
        if (typeof date === 'object') {
            selectedDate = moment(date).format('YYYY-MM-DD');
        } else {
            selectedDate = date;
        }
        props.setFieldValue('deliveryDate', selectedDate?.slice(0, 10));
    };

    const handleDatePickerBlur = (props) => {
        props.setFieldTouched('deliveryDate', true);
    };

    const handleCountryShippingChange = (country, props) => {
        props.setFieldValue('shippingAddressCountry', country.value);
    };

    const handleCountryBillingChange = (country, props) => {
        props.setFieldValue('billingAddressCountry', country.value);
    };

    const handleCountryBillingBlur = (props) => {
        props.setFieldTouched('billingAddressCountry', true);
    };

    const handleCountryShippingBlur = (props) => {
        props.setFieldTouched('shippingAddressCountry', true);
    };

    // contactPurchasing
    const handleContactPurchasingChange = (contact, props) => {
        props.setFieldValue('contactPurchasing', contact.value);
    };

    const handleContactPurchasingBlur = (props) => {
        props.setFieldTouched('contactPurchasing', true);
    };

    // contactInvoicing
    const handleContactInvoicingChange = (contact, props) => {
        props.setFieldValue('contactInvoicing', contact.value);
    };

    const handleContactInvoicingBlur = (props) => {
        props.setFieldTouched('contactInvoicing', true);
    };

    // contactOrderConfirmation
    const handleContactOrderConfirmationChange = (contact, props) => {
        props.setFieldValue('contactOrderConfirmation', contact.value);
    };

    const handleContactOrderConfirmationBlur = (props) => {
        props.setFieldTouched('contactOrderConfirmation', true);
    };

    const handleDelete = (fileId) => {
        let deletedFiles = files;
        deletedFiles = deletedFiles.filter((file) => file.fileId !== fileId);
        setFiles(deletedFiles);
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => handleSubmit(values)}
                validateOnMount={true}
            >
                {(props) => {
                    return (
                        <Form>
                            <div className={classes['c-wizzard-order-details']}>
                                <div
                                    className={
                                        classes[
                                            'c-wizzard-order-details__left-side'
                                        ]
                                    }
                                >
                                    <h3>
                                        {t(
                                            'wizard-ord-details_shipping-address'
                                        )}
                                    </h3>
                                    <Input
                                        type="text"
                                        label={t(
                                            'wizard-order-details_company-name'
                                        )}
                                        name="shippingAddressCompanyName"
                                        errors={
                                            props.errors
                                                .shippingAddressCompanyName
                                        }
                                        touched={
                                            props.touched
                                                .shippingAddressCompanyName
                                        }
                                        autoComplete="shipping-address-company-name"
                                        inputisrequired="true"
                                    />
                                    <Input
                                        type="text"
                                        label={t(
                                            'wizard-ord-details_additional-info'
                                        )}
                                        name="shippingAddressAdditionalInfo"
                                        autoComplete="shipping-address-additional-info"
                                    />
                                    <div
                                        className={`${classes['c-wizzard-order-details__input-layout']} ${classes['c-wizzard-order-details__input-layout--added-margin']}`}
                                    >
                                        <div
                                            className={[
                                                classes[
                                                    'c-wizzard-order-details__input'
                                                ],
                                                classes[
                                                    'c-wizzard-order-details__input--large'
                                                ],
                                            ].join(' ')}
                                        >
                                            <Input
                                                type="text"
                                                label={t(
                                                    'wizard-ord-details_street'
                                                )}
                                                name="shippingAddressStreet"
                                                errors={
                                                    props.errors
                                                        .shippingAddressStreet
                                                }
                                                touched={
                                                    props.touched
                                                        .shippingAddressStreet
                                                }
                                                autoComplete="shipping-address-street"
                                                inputisrequired="true"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            classes[
                                                'c-wizzard-order-details__input-layout'
                                            ]
                                        }
                                    >
                                        <div
                                            className={[
                                                classes[
                                                    'c-wizzard-order-details__input'
                                                ],
                                                classes[
                                                    'c-wizzard-order-details__input--small'
                                                ],
                                            ].join(' ')}
                                        >
                                            <Input
                                                type="text"
                                                label={t(
                                                    'wizard-ord-details_plz'
                                                )}
                                                name="shippingAddressPostalCode"
                                                errors={
                                                    props.errors
                                                        .shippingAddressPostalCode
                                                }
                                                touched={
                                                    props.touched
                                                        .shippingAddressPostalCode
                                                }
                                                autoComplete="shipping-address-postal-code"
                                                inputisrequired="true"
                                            />
                                        </div>
                                        <div
                                            className={[
                                                classes[
                                                    'c-wizzard-order-details__input'
                                                ],
                                                classes[
                                                    'c-wizzard-order-details__input--large'
                                                ],
                                            ].join(' ')}
                                        >
                                            <Input
                                                type="text"
                                                label={t(
                                                    'wizard-ord-details_city'
                                                )}
                                                name="shippingAddressCity"
                                                errors={
                                                    props.errors
                                                        .shippingAddressCity
                                                }
                                                touched={
                                                    props.touched
                                                        .shippingAddressCity
                                                }
                                                autoComplete="shipping-address-city"
                                                inputisrequired="true"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            classes[
                                                'c-wizzard-order-details__item-container'
                                            ]
                                        }
                                    >
                                        <div
                                            className={
                                                classes[
                                                    'c-wizzard-order-details__item-label'
                                                ]
                                            }
                                        >
                                            {t('wizard-ord-details_country')}{' '}
                                            <span
                                                className={`c-input__required-error ${
                                                    props.errors
                                                        .shippingAddressCountry
                                                        ? 'c-input__required-error--red'
                                                        : ''
                                                }`}
                                            >
                                                *
                                            </span>
                                        </div>
                                        <CountrySelect
                                            initialValue={
                                                props.values
                                                    .shippingAddressCountry
                                            }
                                            onChange={(country) =>
                                                handleCountryShippingChange(
                                                    country,
                                                    props
                                                )
                                            }
                                            errors={
                                                props.errors
                                                    .shippingAddressCountry
                                            }
                                            touched={
                                                props.touched
                                                    .shippingAddressCountry
                                            }
                                            onBlur={() =>
                                                handleCountryShippingBlur(props)
                                            }
                                            extraClass="offersWizzard"
                                        />
                                    </div>
                                    <div
                                        className={
                                            classes[
                                                'c-wizzard-order-details__billing-address'
                                            ]
                                        }
                                    >
                                        <h3>
                                            {t(
                                                'wizard-ord-details_billing-address'
                                            )}
                                        </h3>
                                        <Input
                                            type="text"
                                            label={t(
                                                'wizard-order-details_company-name'
                                            )}
                                            name="billingAddressCompanyName"
                                            errors={
                                                props.errors
                                                    .billingAddressCompanyName
                                            }
                                            touched={
                                                props.touched
                                                    .billingAddressCompanyName
                                            }
                                            autoComplete="billing-address-company-name"
                                            inputisrequired="true"
                                        />
                                        <Input
                                            type="text"
                                            label={t(
                                                'wizard-ord-details_additional-info'
                                            )}
                                            name="billingAddressAdditionalInfo"
                                            autoComplete="billing-address-additional-info"
                                        />
                                        <Input
                                            type="text"
                                            label={t(
                                                'wizard-ord-details_tax-num'
                                            )}
                                            name="billingAddressTaxNumber"
                                            errors={
                                                props.errors
                                                    .billingAddressTaxNumber
                                            }
                                            touched={
                                                props.touched
                                                    .billingAddressTaxNumber
                                            }
                                            autoComplete="billing-address-street"
                                        />
                                        <div
                                            className={
                                                classes[
                                                    'c-wizzard-order-details__input-layout'
                                                ]
                                            }
                                        >
                                            <div
                                                className={[
                                                    classes[
                                                        'c-wizzard-order-details__input'
                                                    ],
                                                    classes[
                                                        'c-wizzard-order-details__input--large'
                                                    ],
                                                ].join(' ')}
                                            >
                                                <Input
                                                    type="text"
                                                    label={t(
                                                        'wizard-ord-details_street'
                                                    )}
                                                    name="billingAddressStreet"
                                                    errors={
                                                        props.errors
                                                            .billingAddressStreet
                                                    }
                                                    touched={
                                                        props.touched
                                                            .billingAddressStreet
                                                    }
                                                    autoComplete="billing-address-postal-code"
                                                    inputisrequired="true"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                classes[
                                                    'c-wizzard-order-details__input-layout'
                                                ]
                                            }
                                        >
                                            <div
                                                className={[
                                                    classes[
                                                        'c-wizzard-order-details__input'
                                                    ],
                                                    classes[
                                                        'c-wizzard-order-details__input--small'
                                                    ],
                                                ].join(' ')}
                                            >
                                                <Input
                                                    type="text"
                                                    label={t(
                                                        'wizard-ord-details_plz'
                                                    )}
                                                    name="billingAddressPostalCode"
                                                    errors={
                                                        props.errors
                                                            .billingAddressPostalCode
                                                    }
                                                    touched={
                                                        props.touched
                                                            .billingAddressPostalCode
                                                    }
                                                    autoComplete="billing-address-postal-code"
                                                    inputisrequired="true"
                                                />
                                            </div>
                                            <div
                                                className={[
                                                    classes[
                                                        'c-wizzard-order-details__input'
                                                    ],
                                                    classes[
                                                        'c-wizzard-order-details__input--large'
                                                    ],
                                                ].join(' ')}
                                            >
                                                <Input
                                                    type="text"
                                                    label={t(
                                                        'wizard-ord-details_city'
                                                    )}
                                                    name="billingAddressCity"
                                                    errors={
                                                        props.errors
                                                            .billingAddressCity
                                                    }
                                                    touched={
                                                        props.touched
                                                            .billingAddressCity
                                                    }
                                                    autoComplete="billing-address-city"
                                                    inputisrequired="true"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                classes[
                                                    'c-wizzard-order-details__item-container'
                                                ]
                                            }
                                        >
                                            <div
                                                className={
                                                    classes[
                                                        'c-wizzard-order-details__item-label'
                                                    ]
                                                }
                                            >
                                                {t(
                                                    'wizard-ord-details_country'
                                                )}{' '}
                                                <span
                                                    className={`c-input__required-error ${
                                                        props.touched
                                                            .billingAddressCountry &&
                                                        props.errors
                                                            .billingAddressCountry
                                                            ? 'c-input__required-error--red'
                                                            : ''
                                                    }`}
                                                >
                                                    *
                                                </span>
                                            </div>
                                            <CountrySelect
                                                initialValue={
                                                    props.values
                                                        .billingAddressCountry
                                                }
                                                onChange={(country) =>
                                                    handleCountryBillingChange(
                                                        country,
                                                        props
                                                    )
                                                }
                                                errors={
                                                    props.errors
                                                        .billingAddressCountry
                                                }
                                                touched={
                                                    props.touched
                                                        .billingAddressCountry
                                                }
                                                onBlur={() =>
                                                    handleCountryBillingBlur(
                                                        props
                                                    )
                                                }
                                                extraClass="offersWizzard"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`${classes['c-wizzard-order-details__right-side']} ${classes[extraClassRightSide]}`}
                                >
                                    <h3>
                                        {t('wizard-ord-details_shipping-date')}
                                    </h3>
                                    <div
                                        className={
                                            classes[
                                                'c-wizzard-order-details__item-container'
                                            ]
                                        }
                                    >
                                        <div
                                            className={
                                                classes[
                                                    'c-wizzard-order-details__item-label'
                                                ]
                                            }
                                        >
                                            {t(
                                                'wizard-ord-details_desired-delivery-date'
                                            )}
                                        </div>
                                        <DatePicker
                                            onDateChange={(date) =>
                                                dateChangeHandler(date, props)
                                            }
                                            initialValue={
                                                props.values.deliveryDate
                                            }
                                            type="singleDate"
                                            singleDate="true"
                                            errors={props.errors.deliveryDate}
                                            touched={props.touched.deliveryDate}
                                            onBlur={() =>
                                                handleDatePickerBlur(props)
                                            }
                                        />
                                    </div>
                                    <div
                                        className={
                                            classes[
                                                'c-wizzard-order-details__order-information-container'
                                            ]
                                        }
                                    >
                                        <h2>
                                            {t(
                                                'wizard-ord-details_your-order-info'
                                            )}
                                        </h2>
                                        <div
                                            className={
                                                classes[
                                                    'c-wizzard-order-details__order-information'
                                                ]
                                            }
                                        >
                                            <Input
                                                type="text"
                                                label={t(
                                                    'wizard-ord-details_internal-ord-num'
                                                )}
                                                name="reference"
                                                errors={props.errors.reference}
                                                touched={
                                                    props.touched.reference
                                                }
                                                autoComplete="contact-invoicing"
                                                inputisrequired="true"
                                            />
                                            <div
                                                className={
                                                    classes[
                                                        'c-wizzard-order-details__dropzone'
                                                    ]
                                                }
                                            >
                                                <Dropzone
                                                    recordId={
                                                        frameworkWizzardReducer
                                                            .details.offerId
                                                    }
                                                    uploadSuccessfull={
                                                        handleUploadSuccessfull
                                                    }
                                                    acceptFiles={acceptFiles}
                                                    type="Order_Uploads"
                                                />
                                                {files.length ? (
                                                    <ul>
                                                        {files.map(
                                                            (file, index) => (
                                                                <UploadedFile
                                                                    key={`file-${index}`}
                                                                    fileName={
                                                                        file.fileName
                                                                    }
                                                                    fileId={
                                                                        file.fileId
                                                                    }
                                                                    onDelete={
                                                                        handleDelete
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                    </ul>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <Input
                                        component="textarea"
                                        type="textarea"
                                        label={t('wizard-ord-details_comment')}
                                        name="comments"
                                        className={
                                            classes[
                                                'c-wizzard-order-details__comment'
                                            ]
                                        }
                                    />
                                    <div
                                        className={
                                            classes[
                                                'c-wizzard-order-details__contacts'
                                            ]
                                        }
                                    >
                                        <h2>Contacts</h2>
                                        <div
                                            className={
                                                classes[
                                                    'c-wizzard-order-details__contacts-dropdowns'
                                                ]
                                            }
                                        >
                                            <div
                                                className={
                                                    classes[
                                                        'c-wizzard-order-details__contacts-dropdown'
                                                    ]
                                                }
                                            >
                                                <div
                                                    className={
                                                        classes[
                                                            'c-wizzard-order-details__item-label'
                                                        ]
                                                    }
                                                >
                                                    <TranslatedComponent
                                                        translationFileName="contact-roles"
                                                        translatedComponent={
                                                            'purchasing'
                                                        }
                                                    />
                                                </div>
                                                <ContactSelect
                                                    onChange={(contact) =>
                                                        handleContactPurchasingChange(
                                                            contact,
                                                            props
                                                        )
                                                    }
                                                    initialValue={
                                                        props.values
                                                            .contactPurchasing
                                                    }
                                                    errors={
                                                        props.errors
                                                            .contactPurchasing
                                                    }
                                                    touched={
                                                        props.touched
                                                            .contactPurchasing
                                                    }
                                                    onBlur={() =>
                                                        handleContactPurchasingBlur(
                                                            props
                                                        )
                                                    }
                                                    // extraClass='offersWizzard'
                                                />
                                            </div>
                                            <div
                                                className={
                                                    classes[
                                                        'c-wizzard-order-details__contacts-dropdown'
                                                    ]
                                                }
                                            >
                                                <div
                                                    className={
                                                        classes[
                                                            'c-wizzard-order-details__item-label'
                                                        ]
                                                    }
                                                >
                                                    <TranslatedComponent
                                                        translationFileName="contact-roles"
                                                        translatedComponent={
                                                            'invoicing'
                                                        }
                                                    />
                                                </div>
                                                <ContactSelect
                                                    onChange={(contact) =>
                                                        handleContactInvoicingChange(
                                                            contact,
                                                            props
                                                        )
                                                    }
                                                    initialValue={
                                                        props.values
                                                            .contactInvoicing
                                                    }
                                                    errors={
                                                        props.errors
                                                            .contactInvoicing
                                                    }
                                                    touched={
                                                        props.touched
                                                            .contactInvoicing
                                                    }
                                                    onBlur={() =>
                                                        handleContactInvoicingBlur(
                                                            props
                                                        )
                                                    }
                                                    // extraClass='offersWizzard'
                                                />
                                            </div>
                                            <div
                                                className={
                                                    classes[
                                                        'c-wizzard-order-details__contacts-dropdown'
                                                    ]
                                                }
                                            >
                                                <div
                                                    className={
                                                        classes[
                                                            'c-wizzard-order-details__item-label'
                                                        ]
                                                    }
                                                >
                                                    <TranslatedComponent
                                                        translationFileName="contact-roles"
                                                        translatedComponent={
                                                            'orderConfirmation'
                                                        }
                                                    />
                                                </div>
                                                <ContactSelect
                                                    onChange={(contact) =>
                                                        handleContactOrderConfirmationChange(
                                                            contact,
                                                            props
                                                        )
                                                    }
                                                    initialValue={
                                                        props.values
                                                            .contactOrderConfirmation
                                                    }
                                                    errors={
                                                        props.errors
                                                            .contactOrderConfirmation
                                                    }
                                                    touched={
                                                        props.touched
                                                            .contactOrderConfirmation
                                                    }
                                                    onBlur={() =>
                                                        handleContactOrderConfirmationBlur(
                                                            props
                                                        )
                                                    }
                                                    // extraClass='offersWizzard'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <WizzardFooter
                                prevButtonText={
                                    firstStep ? '' : t('global_go-back-btn')
                                }
                                nextButtonText={t('global_continue-btn')}
                                nextSubmit={true}
                                backUrl={`/framework-agreements/${frameworkWizzardReducer.details.faId}/wizard/components`}
                            />
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
}

export default OrderDetails;
