import classes from './SkeletonWithImageTable.module.scss';
import SkeletonWithImageTableHeader from './SkeletonWithImageTableHeader';
import SkeletonWithImageTableRow from './SkeletonWithImageTableRow';

const SkeletonWithImageTable = () => {
    const numOfSkeletonRows = [1, 2, 3, 4];

    return (
        <div className={classes['skeleton-with-image-table__wrapper']}>
            <SkeletonWithImageTableHeader />
            <div className={classes['skeleton-with-image-table__rows-wrapper']}>
                {numOfSkeletonRows.map((element) => {
                    return <SkeletonWithImageTableRow key={element} />;
                })}
            </div>
        </div>
    );
};

export default SkeletonWithImageTable;
