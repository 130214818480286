import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import ApiService from '../../../../../services/api.service';
import classes from './Contact.module.scss';
import useIsOverflow from '../../../../../hooks/useIsOverflow';
import { useTranslation } from 'react-i18next';
import TranslatedComponent from '../../../../../components/TranslatedComponent/TranslatedComponent';

function Contact({ contact, userPortalRole }) {
    const [options, setOptions] = useState();
    const [contactRole, setContactRole] = useState();
    const userReducer = useSelector((state) => state.user);
    const { t } = useTranslation();

    const initialOptionsList = [
        {
            label: t('contacts_admin'),
            value: 'Admin',
        },
        {
            label: t('contacts_member'),
            value: 'Member',
        },
    ];

    useEffect(() => {
        if (contact.portalRole) {
            setContactRole({
                label: t(
                    contact.portalRole === 'Member'
                        ? 'contacts_member'
                        : 'contacts_admin'
                ),
                value: contact.portalRole,
            });
            setOptions(setOptionsListHandler(contact.portalRole));
        } else {
            setOptions(initialOptionsList);
        }

        //eslint-disable-next-line
    }, [userReducer?.user.contacts]);

    const setOptionsListHandler = (portalRole, test) => {
        const optionsListItems = initialOptionsList.filter((obj) => {
            return obj.value !== portalRole;
        });

        return optionsListItems;
    };

    const onContactChange = (e) => {
        const data = [
            {
                contactId: contact.id,
                portalRole: e.value,
            },
        ];

        ApiService.updateContactPortalRole(data)
            .then((r) => {
                setContactRole(e);
                setOptions(setOptionsListHandler(e.value));
            })
            .catch((e) => {
                // console.log('error', e);
            });
    };

    const ref = useRef();
    const hasOverflow = useIsOverflow(ref, (isOverflowFromCallback) => {
        // true
    });

    //Portal statuses
    const activeStatus = contact.portalStatus === 'Active';
    const invited = contact.portalStatus === 'Invited';
    const notInvited = contact.portalStatus === 'Not invited';

    return (
        <div className={classes['c-contact']}>
            <div className={classes['c-contact__basic-info']}>
                <div className={classes['c-contact__name']}>{contact.name}</div>
                <div
                    className={`${classes['c-contact__email']} ${
                        hasOverflow && classes['c-contact__email--with-cursor']
                    }`}
                    ref={ref}
                >
                    {contact.email}

                    {hasOverflow && (
                        <div className={classes['c-contact__email-tooltip']}>
                            {contact.email}
                        </div>
                    )}
                </div>
            </div>
            <div className={classes['c-contact__role-status']}>
                <div className={classes['c-contact__role']}>
                    {contact.role ? (
                        <TranslatedComponent
                            translationFileName="contacts-roles"
                            translatedComponent={contact.role}
                        />
                    ) : (
                        '-'
                    )}
                </div>
                <div
                    className={`${classes['c-contact__portal-status']} ${
                        activeStatus
                            ? classes['c-contact__portal-status--active']
                            : notInvited
                            ? classes['c-contact__portal-status--not-invited']
                            : invited
                            ? classes['c-contact__portal-status--invited']
                            : ''
                    }`}
                >
                    {contact.portalStatus ? (
                        <TranslatedComponent
                            translationFileName="contactsStatus"
                            translatedComponent={contact.portalStatus}
                        />
                    ) : (
                        '-'
                    )}
                </div>
            </div>
            <div className={classes['c-contact__portal-role']}>
                {contact.portalRole === 'Owner' ||
                (userPortalRole !== 'Owner' &&
                    userReducer.user.portalRole !== 'Admin') ? (
                    <div
                        className={classes['c-contact__portal-role--disabled']}
                    >
                        {contact.portalRole === 'Owner'
                            ? t('contacts_owner')
                            : contact.portalRole === 'Member'
                            ? t('contacts_member')
                            : contact.portalRole === 'Admin'
                            ? t('contacts_admin')
                            : '-'}
                    </div>
                ) : (
                    <Select
                        className={`c-react-select c-react-select--105 c-react-select--transaprent-grey-border c-react-select__limited-width`}
                        name="quantity-selector"
                        options={options}
                        onChange={onContactChange}
                        value={contactRole}
                        classNamePrefix="c-react-select"
                        // onBlur={onBlur}
                        // TODO need to set translation placeholder
                        placeholder={t('contacts_select-placeholder')}
                        // set menuIsOpen={true} when working in dev to be able to inspect select
                        // menuIsOpen={true}
                        // getOptionLabel={(option) =>
                        //     `${option.label}: ${option.value}`
                        // }
                        aria-label="Select contact role"
                        isSearchable={false}
                        formatOptionLabel={(data) => {
                            return (
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: data.label,
                                    }}
                                />
                            );
                        }}
                    />
                )}
            </div>
        </div>
    );
}

Contact.defaultProps = {
    contact: {},
};

export default Contact;
