import { NavLink } from 'react-router-dom';
import classes from '../NavbarLink/NavbarLink.module.scss';
import setGoogleAnalytics from '../../hooks/setGoogleAnalytics';

const NavbarLink = ({
    external,
    className,
    ariaLabel,
    to,
    icon,
    linkName,
    disabled,
    trackGA,
}) => {
    const scrollBlockHandler = () => {
        const scrY = window.scrollY;
        const html = document.querySelector('html');
        html.classList.remove('scrollblock');
        html.style.top = null;
        window.scrollTo(0, scrY);
    };

    const clickHandler = () => {
        scrollBlockHandler();

        if (trackGA) {
            // category ->
            // action -> event name
            // label -> description
            // Send a custom event
            setGoogleAnalytics('click', 'click_help', 'click help button');
        }
    };

    return (
        <>
            {!external ? (
                <NavLink
                    onClick={scrollBlockHandler}
                    className={`${className ? className : ''} ${
                        classes['navbar-link']
                    }`}
                    to={to}
                    target={external}
                    aria-label={ariaLabel}
                    style={{ pointerEvents: disabled ? 'none' : 'auto' }}
                >
                    {icon}
                    <span className={className ? className : ''}>
                        {linkName}
                    </span>
                </NavLink>
            ) : (
                <a
                    onClick={clickHandler}
                    className={`${className ? className : ''} ${
                        classes['navbar-link']
                    }`}
                    href={to}
                    target={external}
                    aria-label={ariaLabel}
                    style={{ pointerEvents: disabled ? 'none' : 'auto' }}
                >
                    {icon}
                    <span className={className ? className : ''}>
                        {linkName}
                    </span>
                </a>
            )}
        </>
    );
};
export default NavbarLink;
