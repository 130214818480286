import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

function CountrySelect({
    initialValue,
    onChange,
    errors,
    touched,
    onBlur,
    extraClass,
}) {
    const hasError = errors && touched;
    const [country, setCountry] = useState('');
    const [countryList, setCountryList] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        const countries = JSON.parse(t('countries'));

        if (initialValue) {
            let label = countries[initialValue];
            setCountry({
                label: label,
                value: initialValue,
            });
        }
        setCountryList(
            Object.entries(countries).map((country) => {
                return {
                    label: country[1],
                    value: country[0],
                };
            })
        );
        //eslint-disable-next-line
    }, []);

    const onCountryChange = (e) => {
        setCountry(e);
        onChange(e);
    };

    return (
        <>
            <Select
                className={`c-react-select c-react-select--transaprent-grey-border ${
                    extraClass !== 'offersWizzard'
                        ? 'c-react-select--max-492'
                        : ''
                } ${
                    extraClass === 'offersWizzard'
                        ? 'c-react-select__country'
                        : ''
                }`}
                name="quantity-selector"
                options={countryList}
                onChange={onCountryChange}
                value={country}
                classNamePrefix="c-react-select"
                onBlur={onBlur}
                aria-label="Select country"
            />
            {hasError ? <div className="c-error__message">{errors}</div> : null}
        </>
    );
}

export default CountrySelect;
