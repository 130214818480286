import classes from './SvelteLoader.module.scss';

function SvelteLoader() {
    return (
        <div className={classes['c-svelte-loader']}>
            <span
                className={classes['spinner-border']}
                role="status"
                aria-hidden="true"
            ></span>
        </div>
    );
}

export default SvelteLoader;
