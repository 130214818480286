import classes from './Icons.module.scss';

function TwoPapersIcon() {
    return (
        <svg
            className={classes['c-icons__icon']}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className="icon-path"
                d="M15.9844 0.984375H3.98438C2.90625 0.984375 2.01562 1.92188 2.01562 3V17.0156H3.98438V3H15.9844V0.984375ZM18.9844 5.01562H8.01562C6.89062 5.01562 6 5.90625 6 6.98438V21C6 22.0781 6.89062 23.0156 8.01562 23.0156H18.9844C20.1094 23.0156 21 22.0781 21 21V6.98438C21 5.90625 20.1094 5.01562 18.9844 5.01562ZM18.9844 21H8.01562V6.98438H18.9844V21Z"
                fill="#838A9C"
            />
        </svg>
    );
}

export default TwoPapersIcon;
