import classes from './Sidebar.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logOut } from '../../store/actions/userActions';
import { useState, useRef } from 'react';
import NavbarLink from '../NavbarLink/NavbarLink';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import useOnClickOutside from '../../hooks/useOnClickOutside';
import { useEffect } from 'react';
import QuestionMarkWithCircleIcon from '../../components/Icons/QuestionMarkWithCircleIcon';

const SidebarFooter = () => {
    const userReducer = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);
    const ref = useRef();
    const location = useLocation();

    const { t } = useTranslation();

    const handleLogout = () => {
        dispatch(logOut());
        localStorage.setItem('redirectErrorMessage', 'successfullyLoggedOut');
        navigate('/login');
    };

    const sidebarPopupHandler = () => {
        setShowPopup(!showPopup);
    };

    useOnClickOutside(ref, () => setShowPopup(false));

    useEffect(() => {
        setShowPopup(false);
    }, [location.pathname]);

    return (
        <div className={classes.sidebar__footer}>
            <div className={classes['sidebar__footer-links']}>
                {/* <div className={classes['sidebar__footer-notification']}>
                    <NavbarLink
                        to="/notifications"
                        icon="icon-bell"
                        linkName="Notifications"
                        className={navLinkStyles['navbar-link--notification']}
                    />
                    <p>4</p>
                </div> */}
                <NavbarLink
                    to="https://www.cnc24.com/contact/"
                    ariaLabel={'go to help page'}
                    icon={<QuestionMarkWithCircleIcon />}
                    external="true"
                    linkName={`${t('secondary-navigation_help')}`}
                    className={classes['sidebar__link--help']}
                    trackGA={true}
                />
            </div>

            <div className={classes['sidebar__footer-profile']}>
                <div className={classes['sidebar__footer-profile-info']}>
                    <h4 className={classes['sidebar__footer-profile-name']}>
                        {userReducer.user &&
                            userReducer.user.customerInfo?.firstName}{' '}
                        {userReducer.user &&
                            userReducer.user.customerInfo?.lastName}
                    </h4>
                    <h5 className={classes['sidebar__footer-profile-company']}>
                        {userReducer.user && userReducer.user.company}
                    </h5>
                </div>

                <div className={classes['sidebar__footer-profile-info-short']}>
                    <h4>
                        {userReducer.user &&
                            userReducer.user.customerInfo?.firstName?.slice(
                                0,
                                1
                            )}
                    </h4>
                </div>

                <button
                    className={classes['sidebar__footer-profile-menu-icon']}
                    onClick={sidebarPopupHandler}
                    aria-label="Account menu"
                >
                    <i className={`icon-vertical-three-dots `}></i>
                    {/* {!showPopup && (
                        <i
                            className={`icon-vertical-three-dots ${classes['sidebar__footer-profile-menu-icon--dots']}`}
                        ></i>
                    )}
                    {showPopup && (
                        <i
                            className={`icon-x-sign ${classes['sidebar__footer-profile-menu-icon--x-btn']}`}
                        ></i>
                    )} */}
                </button>
                <div
                    ref={ref}
                    className={
                        !showPopup
                            ? classes['sidebar__footer-profile-menu-links']
                            : classes[
                                  'sidebar__footer-profile-menu-links--show'
                              ]
                    }
                >
                    <Link to="/settings/contacts" aria-label="settings">
                        <i className="icon-settings"></i>{' '}
                        <span>{t('account-navigation_settings')}</span>
                    </Link>
                    <button onClick={handleLogout} aria-label="Logout">
                        <i className="icon-logout"></i>{' '}
                        <span>{t('account-navigation_logout')}</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SidebarFooter;
