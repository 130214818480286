import { useEffect, useState } from 'react';
import config from '../../../config';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Input from '../../../components/Input/Input/Input';
import { LOG_IN, magicLogin } from '../../../store/actions/userActions';
import { useDispatch } from 'react-redux';
import Button from '../../../components/Button/Button';

import classes from './Login.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import FormError from '../../../shared/FormError/FormError';
import { useTranslation } from 'react-i18next';
import PageMeta from '../../../shared/PageMeta/PageMeta';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import AuthWrapper from '../../../components/AuthWrapper/AuthWrapper';
import ReactGA from 'react-ga4';
import ApiService from '../../../services/api.service';
import UtilService from '../../../services/util.service';
import i18n from '../../../i18next';

function Login() {
    const [magicLink, setMagicLink] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [logoutError, setLogoutError] = useState();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [redirectUrl, setRedirectUrl] = useState();

    useEffect(() => {
        if (localStorage.getItem('redirectErrorMessage')) {
            const errorMessage = localStorage.getItem('redirectErrorMessage');
            setLogoutError(errorMessage);
            localStorage.removeItem('redirectErrorMessage');
        }
        if (!ReactGA.isInitialized && config.environment !== 'local') {
            ReactGA.initialize(config.apiGateway.googleAnalythicsId);
        }

        setRedirectUrl(location?.state?.redirectTo);
        //eslint-disable-next-line
    }, []);

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email(`${t('error_field-email-invalid')}`)
            .required(`${t('error_field-required')}`),
        password: !magicLink
            ? Yup.string().required(`${t('error_field-required')}`)
            : Yup.string(),
    });

    const initialValues = {
        email: '',
        password: '',
    };

    const emailVerificationHandler = async (data, response) => {
        const verificationCodeData = {
            email: response?.email,
            s: response.s,
        };
        const token = localStorage.getItem('token');
        await ApiService.requestEmailVerificationCode(verificationCodeData);
        const formatedEmail = encodeURIComponent(response?.email);
        localStorage.removeItem('token');
        navigate(
            `/sign-up/confirm?nu=${formatedEmail}&s=${response.s}&t=${token}${
                redirectUrl ? '&retUrl=' + redirectUrl : ''
            }`
        );
    };

    const submitLogin = async (values) => {
        setLoading(true);
        setError('');
        setLogoutError(null);
        if (magicLink) {
            const data = {
                client_id: config.apiGateway.clientId,
                connection: 'email',
                email: values.email,
                send: 'link',
            };
            return dispatch(magicLogin(data)).then((r) => {
                navigate('/confirm-login');
            });
        } else {
            const data = {
                grant_type: 'http://auth0.com/oauth/grant-type/password-realm',
                client_id: config.apiGateway.clientId,
                realm: config.apiGateway.realm,
                audience: config.apiGateway.audience,
                username: values.email,
                password: values.password,
            };
            try {
                const loginResponse = await ApiService.logIn(data);
                if (loginResponse) {
                    localStorage.setItem(
                        'token',
                        loginResponse.data.access_token
                    );

                    const getUserResponse = await ApiService.getUser();
                    if (getUserResponse.data.data.email_verified) {
                        const language = UtilService.setLanguageCookie(
                            getUserResponse.data.data.lang
                        );
                        i18n.changeLanguage(language);
                        dispatch({
                            type: LOG_IN,
                            user: getUserResponse.data?.data,
                        });
                        if (location.state?.redirectTo) {
                            navigate(redirectUrl);
                        } else {
                            navigate('/offers?type=All');
                        }
                    } else {
                        await emailVerificationHandler(
                            data,
                            getUserResponse.data.data
                        );
                    }
                }
            } catch (error) {
                setLoading(false);
                if (error?.response?.status === 500) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('X-Track-Id');
                    navigate('/500');
                } else if (error?.response?.data) {
                    setError(t('error_form-invalid-password'));
                } else {
                    setError(t('error_form-general'));
                }
            }
        }
    };

    const onChangeLoginTypeClick = (e) => {
        e.preventDefault();
        setMagicLink(magicLink);
        navigate('/reset-password');
    };

    return (
        <>
            <PageMeta>
                <title>{t('login_header')} | CNC24</title>
            </PageMeta>
            <AuthWrapper>
                <>
                    {logoutError ? (
                        <ErrorMessage
                            type={logoutError}
                            hasIcon={true}
                            bgColor={
                                logoutError === 'successfullyLoggedOut'
                                    ? 'green'
                                    : 'yellow'
                            }
                        />
                    ) : null}
                    <h1 className={classes['c-login__title']}>
                        {t('login_header')}
                    </h1>
                    <p className={classes['c-login__description']}>
                        {t('login_paragraph')}
                    </p>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={submitLogin}
                        autoComplete="some-random-name"
                    >
                        {(props) => (
                            <Form>
                                {error ? <FormError>{error}</FormError> : null}
                                <Input
                                    type="email"
                                    label={t('login_email-label')}
                                    name="email"
                                    errors={props.errors.email}
                                    touched={props.touched.email}
                                    autoComplete="email"
                                />
                                {!magicLink && (
                                    <Input
                                        type="password"
                                        label={t('login_password-label')}
                                        name="password"
                                        errors={props.errors.password}
                                        touched={props.touched.password}
                                        autoComplete="current-password"
                                    />
                                )}
                                <button
                                    type="button"
                                    onClick={onChangeLoginTypeClick}
                                    className={classes['c-login__button-link']}
                                    aria-label={t('login_forgot-password')}
                                >
                                    {/* {magicLink
                                            ? 'Mit Passwort anmelden?'
                                            : 'Möchten Sie lieber einen Link zur Anmeldung erhalten?'} */}
                                    {t('login_forgot-password')}
                                </button>
                                <Button
                                    disabled={
                                        !(props.isValid && props.dirty) ||
                                        loading
                                    }
                                    className={`btn btn--purple btn--big btn--mobile-login btn--full-width ${
                                        loading ? 'btn--loading' : ''
                                    }`}
                                    ariaLabel={
                                        magicLink
                                            ? 'Link zur Anmeldung senden'
                                            : t('login_button')
                                    }
                                    type="submit"
                                >
                                    {magicLink
                                        ? 'Link zur Anmeldung senden'
                                        : t('login_button')}
                                    {loading ? (
                                        <span className="loader"></span>
                                    ) : null}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                    <p className={classes['c-login__not-member']}>
                        {t('login_not-customer')}
                        <br />
                        <a
                            href={config.apiGateway.wizardUrl}
                            target="_blank"
                            rel="noreferrer"
                            aria-label="Hier unverbindliches Angebot einholen."
                        >
                            {t('login_get-offer-link-text')}
                        </a>
                    </p>
                </>
            </AuthWrapper>
            {/* </div> */}
        </>
    );
}

export default Login;
