/**
 * This file can be used for exporting some static data for e.g.
 *
 * export const blogs = [
 *      { id: 0, title: 'Lorem ispum' }
 * ]
 *
 */

export const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
