import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classes from './IframeWizard.module.scss';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import ApiService from '../../services/api.service';
import config from '../../config';
import SvelteLoader from '../../shared/SvelteLoader/SvelteLoader';

function IframeWizard({ open, noTrack }) {
    const user = useSelector((state) => state.user.user);
    const [wizardOpen, setWizardOpen] = useState(false);
    const navigate = useNavigate();
    const [cookies] = useCookies();
    const [showIframe, setShowIframe] = useState(false);

    useEffect(() => {
        setWizardOpen(open);
    }, [open]);

    const onMessage = (event) => {
        const { func, message } = event.data;

        if (func === 'getCustomerInfo') {
            const searchParams = new URLSearchParams(window.location.search);
            const id = searchParams.get('id');
            if (id) {
                ApiService.getLeadInProgress(id).then((r) => {
                    setCustomerInfo(
                        Object.assign({}, user.customerInfo, {
                            serverData: r.data.data,
                        })
                    );
                });
            } else {
                setCustomerInfo(user.customerInfo);
            }
        } else if (func === 'viewOpp') {
            if (message) {
                // redirect to single order
                navigate(`/offers/${message}/components`);
            } else {
                // redirect to all orders
                navigate('/offers?type=All');
            }
        } else if (func === 'triggerClose') {
            //redirect to all orders if closed
            navigate('/offers?type=All');
        } else if (func === 'startNew') {
            window.location.reload();
        }
    };

    const setCustomerInfo = (customerData) => {
        let iframe = document.getElementById('wizard-iframe');
        iframe =
            iframe?.contentWindow ||
            iframe?.contentDocument.document ||
            iframe?.contentDocument;
        iframe.postMessage(
            {
                func: 'setCustomerInfo',
                message: JSON.stringify(customerData),
            },
            '*'
        );
    };

    window.addEventListener('message', onMessage, false);

    const iframeLoadedHandler = () => {
        setShowIframe(true);
    };

    return (
        <>
            {wizardOpen && user ? (
                <div className={classes['c-iframe-wizard']}>
                    <iframe
                        src={`${config.apiGateway.wizardUrl}/?notrack=${
                            noTrack ? '1' : '0'
                        }&customer=1&account_id=${user.accountId}&contact_id=${
                            user.contactId
                        }&lang=${cookies.lang}&iframe=1`}
                        title="Wizard"
                        id="wizard-iframe"
                        scrolling="yes"
                        frameBorder="0"
                        onLoad={iframeLoadedHandler}
                        className={[
                            classes['c-iframe-wizard__content'],
                            showIframe
                                ? classes['c-iframe-wizard__content--show']
                                : '',
                        ].join(' ')}
                    ></iframe>
                    {!showIframe && (
                        <div className={classes['c-iframe-wizard__loading']}>
                            <SvelteLoader />
                        </div>
                    )}
                </div>
            ) : null}
        </>
    );
}

export default IframeWizard;
