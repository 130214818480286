import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PageMeta from '../../shared/PageMeta/PageMeta';
import classes from '../auth/Login/Login.module.scss';
import errStyles from './ErrorPage.module.scss';
import { useTranslation } from 'react-i18next';
import AuthWrapper from '../../components/AuthWrapper/AuthWrapper';

export default function ErrorPage() {
    const [page, setPage] = useState('');
    const location = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        if (location.pathname.includes('access-denied')) {
            setPage(403);
        } else if (location.pathname.includes('internal-error')) {
            setPage(500);
        } else {
            setPage(404);
        }
    }, [location]);

    return (
        <div className={classes['c-login']}>
            <PageMeta>
                <title>
                    {page === 403
                        ? t('403_heading')
                        : page === 404
                        ? t('404_heading')
                        : 'Internal Server Error'}{' '}
                    | CNC24
                </title>
            </PageMeta>
            <AuthWrapper>
                <div className={classes['c-login__container-wrapper']}>
                    <div className={classes['c-login__container']}>
                        <h1 className={classes['c-login__title']}>
                            {page === 403
                                ? t('403_heading')
                                : page === 500
                                ? t('err-500_heading')
                                : t('404_heading')}
                        </h1>
                        {page !== 500 ? (
                            <p className={classes['c-login__description']}>
                                {page === 403
                                    ? t('403_description')
                                    : t('404_description')}
                                <span
                                    className={classes['c-login__error-code']}
                                >
                                    {page === 403
                                        ? t('403_error-code')
                                        : t('404_error-code')}
                                </span>
                            </p>
                        ) : (
                            <p className={errStyles['error-page__description']}>
                                <span>{t('err-500_text-1')} </span>
                                <a
                                    href="https://www.cnc24.com/contact/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {t('err-500_report-link')}
                                </a>
                                <span> {t('err-500_text-2')}</span>
                            </p>
                        )}

                        {page !== 500 && (
                            <Link
                                className={`btn btn--purple btn--big btn--mobile-login btn--icon-left btn--full-width ${classes['c-login__error-page-btn']}`}
                                to="/"
                                state={{ redirectTo: location.state }}
                            >
                                <i className="icon-arrow-right"></i>
                                <span>
                                    {page === 403
                                        ? t('403_button')
                                        : t('404_button')}
                                </span>
                            </Link>
                        )}
                    </div>
                </div>
            </AuthWrapper>
        </div>
    );
}
