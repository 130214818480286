import { useState, useEffect } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { onSetQuantityIndex } from '../../../../../store/actions/offersWizzardActions';

function QuantitySelect({ data, rowIndex, index }) {
    const [selectData, setSelectData] = useState([]);
    const [selectedValue, setSelectedValue] = useState({
        label: data[0].label,
        value: data[0].value,
    });

    const offerLinesIndex = useSelector(
        (state) => state.offersWizzardReducer.offerLinesIndex
    );

    const dispatch = useDispatch();

    useEffect(() => {
        setSelectData(index.selectQuantity);
        setSelectedValue({
            label: index.selectQuantity[offerLinesIndex[rowIndex]].label,
            value: index.selectQuantity[offerLinesIndex[rowIndex]].value,
        });
        //eslint-disable-next-line
    }, [index]);

    const onQuantityChange = (e) => {
        let index = selectData.indexOf(e);
        dispatch(onSetQuantityIndex(rowIndex, index));
        setSelectedValue(e);
    };

    return (
        <Select
            className="c-react-select c-react-select--max-492"
            name="quantity-selector"
            options={selectData}
            onChange={onQuantityChange}
            value={selectedValue}
            classNamePrefix="c-react-select"
            menuPortalTarget={document.querySelector('.c-table--wizardOffers')}
            menuShouldBlockScroll={true}
            isSearchable={false}
        />
    );
}

export default QuantitySelect;
