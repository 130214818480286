/**
 * UtilService is used for making some global
 * functions that can be used throughout application
 * for e.g. date formating, string manipulation...
 */
class UtilService {
    static onPopupOpen() {
        document.getElementsByTagName('body')[0].classList.add('noScroll');
    }

    static onPopupClose() {
        setTimeout(() => {
            if (document.querySelectorAll('.popup-overlay').length === 0) {
                document.getElementsByTagName('body')[0].classList.remove('noScroll');
            }
        }, 250)
    }

    static setLanguageCookie(language) {
        let lang = 'en'
        if (language === 'Deutsch') {
            lang = 'de'
        }
        document.cookie = `lang=${lang}`

        return lang
    }
}

export default UtilService;