import classes from './Icons.module.scss';

function AditionalInfoIcon({ fill }) {
    return (
        <svg
            className={classes['c-icons__icon']}
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.34375 3.65625H7.65625V5H6.34375V3.65625ZM6.34375 6.34375H7.65625V10.3438H6.34375V6.34375ZM7 0.34375C3.3125 0.34375 0.34375 3.3125 0.34375 7C0.34375 10.6875 3.3125 13.6563 7 13.6563C10.6875 13.6563 13.6563 10.6875 13.6563 7C13.6563 3.3125 10.6875 0.34375 7 0.34375ZM7 12.3438C4.0625 12.3438 1.65625 9.9375 1.65625 7C1.65625 4.0625 4.0625 1.65625 7 1.65625C9.9375 1.65625 12.3438 4.0625 12.3438 7C12.3438 9.9375 9.9375 12.3438 7 12.3438Z"
                fill={fill}
            />
        </svg>
    );
}

AditionalInfoIcon.defaultProps = {
    fill: '#838A9C',
};

export default AditionalInfoIcon;
