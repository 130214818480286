import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Select from 'react-select';

function ContactSelect({
    initialValue,
    onChange,
    errors,
    touched,
    onBlur,
    name,
    extraClass,
}) {
    const hasError = errors && touched;
    const [contact, setContact] = useState();
    const [contactList, setContactList] = useState([]);
    const userReducer = useSelector((state) => state.user);
    const { t } = useTranslation();

    useEffect(() => {
        const contacts = userReducer?.user.contacts;

        if (initialValue) {
            let initialContact = contacts.find(
                (contact) => contact.id === initialValue
            );

            setContact({
                label: initialContact?.email,
                value: initialContact?.id,
                name: initialContact?.name,
            });
        }
        setContactList(
            contacts.map((contact) => {
                return {
                    label: contact.email,
                    value: contact.id,
                    name: contact.name,
                };
            })
        );

        //eslint-disable-next-line
    }, [userReducer]);

    const onContactChange = (e) => {
        setContact(e);
        onChange(e);
    };

    return (
        <>
            <Select
                className={`c-react-select c-react-select--transaprent-grey-border ${extraClass} c-react-select__contact-select`}
                name={name}
                options={contactList}
                onChange={onContactChange}
                value={contact}
                classNamePrefix="c-react-select"
                onBlur={onBlur}
                // TODO need to set translation placeholder
                placeholder={t('contacts_contacts-dropdown-placeholder')}
                // set menuIsOpen={true} when working in dev to be able to inspect select
                // menuIsOpen={true}
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
                aria-label="Select contact"
                formatOptionLabel={function (data) {
                    return (
                        <div className="c-react-select__option-container">
                            <span
                                className="c-react-select__name"
                                dangerouslySetInnerHTML={{ __html: data.name }}
                            ></span>
                            <span
                                className="c-react-select__email"
                                dangerouslySetInnerHTML={{ __html: data.label }}
                            />
                        </div>
                    );
                }}
            />
            {hasError ? <div className="c-error__message">{errors}</div> : null}
        </>
    );
}

ContactSelect.defaultProps = {
    name: 'contact-selector',
    extraClass: '',
};

export default ContactSelect;
