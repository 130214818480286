import classes from './DatePickerDropdown.module.scss';
import DatePickerCalendar from './DatePickerCalendar/DatePickerCalendar';
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Button from '../../Button/Button';

const formattedCurrentDate = moment();
function DatePickerDropdown(props) {
    const [dates, setDates] = useState({});
    const [chosenDateRange, setChosenDateRange] = useState(props.initialValue);
    const [dateSingle, setDateSingle] = useState();

    const { t } = useTranslation();

    useEffect(() => {
        setDates({
            startDate: props.initialValue?.startDate,
            endDate: props.initialValue?.endDate,
        });
        //eslint-disable-next-line
    }, []);

    const presetDate = useCallback(
        (type, number) => {
            setChosenDateRange(type + number);

            if (type === 'months') {
                let startDate = new Date();
                let finalStartDate = startDate.setMonth(
                    startDate.getMonth() - number
                );

                setDates({
                    startDate: moment(finalStartDate),
                    endDate: formattedCurrentDate,
                });
            } else {
                let year = new Date().getFullYear() + number;

                setDates({
                    startDate: moment([year]).startOf('year'),
                    endDate: moment([year]).endOf('year'),
                });
            }
        },
        [setDates]
    );

    useEffect(() => {
        props.onChange && props.onChange(dates);
        //eslint-disable-next-line
    }, [dates]);

    const isMatch = (type, number) => {
        if (dates) {
            const initialEndDate = moment(dates.endDate).format('YYYY-MM-DD');
            const initialStartDate = moment(dates.startDate).format(
                'YYYY-MM-DD'
            );

            if (type === 'year') {
                let year = new Date().getFullYear() + number;
                let matchedStartDate = moment([year])
                    .startOf('year')
                    .format('YYYY-MM-DD');

                var matchedEndDate;
                if (number === 0) {
                    matchedEndDate = moment([year])
                        .endOf('year')
                        .format('YYYY-MM-DD');
                } else {
                    matchedEndDate = moment([year])
                        .endOf('year')
                        .format('YYYY-MM-DD');
                }

                return (
                    matchedStartDate > initialStartDate ||
                    matchedStartDate < initialStartDate ||
                    matchedEndDate > initialEndDate ||
                    matchedEndDate < initialEndDate
                );
            } else {
                let startDate = new Date();
                let finalEndDate = moment(startDate).format('YYYY-MM-DD');
                let finalStartDate = moment(
                    startDate.setMonth(startDate.getMonth() - number)
                ).format('YYYY-MM-DD');

                return (
                    finalStartDate > initialStartDate ||
                    finalStartDate < initialStartDate ||
                    finalEndDate > initialEndDate ||
                    finalEndDate < initialEndDate
                );
            }
        }
        return false;
    };

    const saveDateHandler = () => {
        if (props.datepickerType === 'rangeDate') {
            props.onRangeDateChange(dates);
        } else {
            props.onSingleDateChange(dateSingle);
        }
    };

    const cancelDateHandler = () => {
        props.onCancelSingleDateHandler();
    };

    return (
        <div
            className={[
                classes['c-date-picker-dropdown'],
                classes[`c-date-picker-dropdown--${props.type}`],
            ].join(' ')}
        >
            {props.singleDate ? (
                <div
                    className={[
                        classes['c-date-picker-dropdown__header'],
                        classes['c-date-picker-dropdown__header--single-date'],
                    ].join(' ')}
                >
                    <h2>{t('global_choose-date')}</h2>
                    <button type="button" onClick={cancelDateHandler}>
                        <i className="icon-x-sign"></i>
                    </button>
                </div>
            ) : (
                <div className={classes['c-date-picker-dropdown__header']}>
                    <button
                        className={`btn btn--rounded ${
                            !isMatch('months', 3) ? 'active' : ''
                        }`}
                        onClick={() => presetDate('months', 3)}
                    >
                        {t('date-picker_last-3-months')}
                    </button>
                    <button
                        className={`btn btn--rounded ${
                            !isMatch('months', 6) ? 'active' : ''
                        }`}
                        onClick={() => presetDate('months', 6)}
                    >
                        {t('date-picker_last-6-months')}
                    </button>
                    <button
                        className={`btn btn--rounded ${
                            chosenDateRange === 'year0' || !isMatch('year', 0)
                                ? 'active'
                                : ''
                        }`}
                        onClick={() => presetDate('year', 0)}
                    >
                        {t('date-picker_this-year')}
                    </button>
                    <button
                        className={`btn btn--rounded ${
                            !isMatch('year', -1) ? 'active' : ''
                        }`}
                        onClick={() => presetDate('year', -1)}
                    >
                        {t('date-picker_last-year')}
                    </button>
                </div>
            )}
            <DatePickerCalendar
                {...{ dates, setDates }}
                {...{ dateSingle, setDateSingle }}
                singleDate={props.singleDate}
                initialValue={props.initialValue}
            />
            {/* {props.singleDate ? ( */}
            <div className={classes['c-date-picker-dropdown__footer']}>
                <Button
                    className={`btn btn--small btn--transparent btn--transparent-grey ${classes['wizzard-header__cancel-btn']}`}
                    onClick={cancelDateHandler}
                >
                    {t('global_cancel-btn')}
                </Button>
                <Button
                    type={'button'}
                    onClick={saveDateHandler}
                    className={`btn btn--small btn--purple ${classes['wizzard-footer__next-btn']}`}
                    disabled={
                        !props.singleDate &&
                        (!dates.startDate || !dates.endDate)
                    }
                >
                    {t('global_save-btn')}
                </Button>
            </div>
            {/* ) : null} */}
        </div>
    );
}

const NOOP = () => {};
DatePickerDropdown.defaultProps = {
    onChange: NOOP,
};

export default DatePickerDropdown;
