import React from 'react';
import classes from './Account.module.scss';

import { Outlet } from 'react-router-dom';
import PageHeader from '../../components/PageHeader/PageHeader';

function Account() {
    return (
        <div className={classes['c-account']}>
            <div className="l-container">
                <PageHeader />
                <Outlet />
            </div>
        </div>
    );
}

export default Account;
