import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import classes from '../../TableHeader/TableHeader.module.scss';
import {
    onSingleOfferLinesSort,
    onFrameworkOfferLinesSort,
} from '../../../../store/actions/detailsActions';
import { useDispatch } from 'react-redux';

function TableHeaderItem(props) {
    //eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();
    const [active, setActive] = useState(false);
    const [sortDirection, setSortDirection] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);

        const sortFilter = searchParams.get('sortFilter');
        const sortDirection = searchParams.get('sortDirection');
        if (sortFilter) {
            if (props.tableType === 'offersSingleOrder') {
                dispatch(onSingleOfferLinesSort(sortDirection, sortFilter));
            } else if (props.tableType === 'offerFrameworkOrder') {
                dispatch(onFrameworkOfferLinesSort(sortDirection, sortFilter));
            }
        }
        //eslint-disable-next-line
    }, []);

    const handleHeaderItemClick = (title) => {
        if (props.title.sortable) {
            const searchParams = new URLSearchParams(window.location.search);

            const sortFilter = searchParams.get('sortFilter');
            const sortDirection = searchParams.get('sortDirection');

            let direction = sortDirection === 'ASC' ? 'DESC' : 'ASC';
            if (sortFilter !== title.sortFilter) {
                direction = 'ASC';
            }
            if (sortFilter) {
                searchParams.set('sortFilter', title.sortFilter);
                searchParams.set('sortDirection', direction);
            } else {
                searchParams.append(`sortFilter`, title.sortFilter);
                searchParams.append(`sortDirection`, direction);
            }
            setSearchParams(searchParams);
            if (props.title.feSort) {
                if (props.tableType === 'offersSingleOrder') {
                    dispatch(
                        onSingleOfferLinesSort(direction, title.sortFilter)
                    );
                } else if (props.tableType === 'offerFrameworkOrder') {
                    dispatch(
                        onFrameworkOfferLinesSort(direction, title.sortFilter)
                    );
                }
            }
        }
    };

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const sortFilter = params.get('sortFilter');
        const direction = params.get('sortDirection');
        if (props.title.sortFilter === sortFilter) {
            setActive(true);
            setSortDirection(direction);
        } else {
            setActive(false);
            setSortDirection('DESC');
        }
        //eslint-disable-next-line
    }, [window.location.search]);

    return (
        <div
            onClick={() => handleHeaderItemClick(props.title)}
            className={`${props.title.sortable ? 'sortable' : ''} ${
                active ? 'sortedBy' : ''
            } ${sortDirection === 'ASC' ? 'ascending' : 'descending'} ${
                props.tableType
                    ? classes[`c-table-header__${props.tableType}`]
                    : ''
            } ${
                props.tableList ? classes['c-table-header--no-top-radius'] : ''
            } ${
                props.hasDiscount
                    ? classes['c-table-header--has-discount']
                    : classes['c-table-header--no-discount']
            } ${
                props.hasOrigin
                    ? classes['c-table-header--has-origin']
                    : classes['c-table-header--no-origin']
            }`}
            key={props.title.id}
        >
            <span className={classes[`c-table-header__${props.titles.length}`]}>
                {props.title.name}
                {props.title.sortable ? (
                    sortDirection === 'DESC' ? (
                        <i className="icon-chevron-down"></i>
                    ) : (
                        <i className="icon-chevron-up"></i>
                    )
                ) : null}
            </span>
        </div>
    );
}

export default TableHeaderItem;
