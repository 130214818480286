import classes from './ResultModal.module.scss';
function ResultModal({
    title,
    onConfirmHandler,
    icon,
    additionButtonText,
    additionButtonIcon,
}) {
    const confirmHandler = () => {
        onConfirmHandler();
    };

    return (
        <div className={classes['result-modal']}>
            <div className={classes['result-modal__icon-title']}>
                {icon}
                <h1>{title}</h1>
            </div>
            <button
                onClick={confirmHandler}
                className={`${classes['result-modal__btn']} btn btn--purple btn--small btn--mobile-login`}
            >
                {additionButtonText}
                {additionButtonIcon ? (
                    <span className={classes['result-modal__btn-icon']}>
                        {additionButtonIcon}
                    </span>
                ) : null}
            </button>
        </div>
    );
}

ResultModal.defaultProps = {
    title: '',
    onConfirmHandler: () => {},
    icon: '',
    additionButtonText: '',
    additionButtonIcon: '',
};

export default ResultModal;
