import classes from './SkeletonWithImage.module.scss';
import SkeletonWithImageLabelAndValue from './SkeletonWithImageLabelAndValue';

const SkeletonWithImageRow = () => {
    return (
        <div className={classes['skeleton-with-image']}>
            <div
                className={classes['skeleton-with-image__image-part-name-box']}
            >
                <span className={classes['skeleton-with-image__image']}></span>

                <div className={classes['skeleton-with-image__label-value']}>
                    <span
                        className={classes['skeleton-with-image__label']}
                    ></span>
                    <span
                        className={classes['skeleton-with-image__value']}
                    ></span>
                </div>
            </div>

            <SkeletonWithImageLabelAndValue />

            <div className={classes['skeleton-with-image__dropdown-wrapper']}>
                <span
                    className={classes['skeleton-with-image__dropdown']}
                ></span>
            </div>
        </div>
    );
};

export default SkeletonWithImageRow;
