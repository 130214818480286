import React, { useEffect } from 'react';
import { ssoUser } from '../../../store/actions/userActions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../shared/Loader/Loader';

function SSOLogin() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const magicToken = params.get('tmagic-token');
        const userId = params.get('x-userId');
        localStorage.setItem('token', magicToken);
        localStorage.setItem('X-Track-Id', userId);
        if (userId !== null) {
            dispatch(ssoUser()).then((r) => {
                // window?.clarity('stop');
                // navigate('/dashboard');
                navigate('/offers?type=All');
            });
        } else {
            localStorage.removeItem('token');
            localStorage.removeItem('X-Track-Id');
            navigate('/login');
        }
        //eslint-disable-next-line
    }, []);

    return <Loader />;
}

export default SSOLogin;
