import classes from './Icons.module.scss';

function QuestionMarkWithCircleIcon() {
    return (
        <svg
            className={classes['c-icons__icon']}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className="icon-path"
                d="M9.01562 16H10.9844V13.9844H9.01562V16ZM10 0.015625C4.46875 0.015625 0.015625 4.46875 0.015625 10C0.015625 15.5312 4.46875 19.9844 10 19.9844C15.5312 19.9844 19.9844 15.5312 19.9844 10C19.9844 4.46875 15.5312 0.015625 10 0.015625ZM10 18.0156C5.59375 18.0156 1.98438 14.4062 1.98438 10C1.98438 5.59375 5.59375 1.98438 10 1.98438C14.4062 1.98438 18.0156 5.59375 18.0156 10C18.0156 14.4062 14.4062 18.0156 10 18.0156ZM10 4C7.79688 4 6.01562 5.78125 6.01562 7.98438H7.98438C7.98438 6.90625 8.92188 6.01562 10 6.01562C11.0781 6.01562 12.0156 6.90625 12.0156 7.98438C12.0156 10 9.01562 9.76562 9.01562 13H10.9844C10.9844 10.75 13.9844 10.5156 13.9844 7.98438C13.9844 5.78125 12.2031 4 10 4Z"
                fill="#838A9C"
            />
        </svg>
    );
}

export default QuestionMarkWithCircleIcon;
