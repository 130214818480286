import { Outlet } from 'react-router-dom';
import AuthWrapper from '../../../components/AuthWrapper/AuthWrapper';
import PageMeta from '../../../shared/PageMeta/PageMeta';

function RegisterContainer() {
    return (
        <AuthWrapper>
            <PageMeta>
                <title>Signup | CNC24</title>
            </PageMeta>
            <Outlet />
        </AuthWrapper>
    );
}

export default RegisterContainer;
