import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Contact from './Contact/Contact';
import classes from './ContactList.module.scss';
import ContactSkeletonLoader from './ContactSkeletonLoader/ContactSkeletonLoader';

function ContactList() {
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(true);

    const userReducer = useSelector((state) => state.user);

    useEffect(() => {
        const userContacts = userReducer?.user.contacts;
        setContacts(userContacts);
        setLoading(false);
    }, [userReducer?.user.contacts]);

    return (
        <div className={classes['contact-list']}>
            {loading
                ? Array.from({ length: 4 }, (_, index) => (
                      <ContactSkeletonLoader key={index} />
                  ))
                : Object.entries(contacts).map((contact, index) => {
                      return (
                          <Contact
                              contact={contact[1]}
                              key={index}
                              userPortalRole={userReducer.user.portalRole}
                          />
                      );
                  })}
        </div>
    );
}

export default ContactList;
