import React from 'react';
import { NavLink } from 'react-router-dom';
import classes from './Tab.module.scss';

function Tab({ onlyOneTab, link, name }) {
    return (
        <li
            className={[
                classes['c-tab'],
                onlyOneTab ? classes['c-tab--one'] : '',
            ].join(' ')}
        >
            <NavLink
                className={[
                    classes['c-tab__link'],
                    onlyOneTab ? 'active' : '',
                ].join(' ')}
                to={link}
            >
                {name}
            </NavLink>
            <span></span>
        </li>
    );
}

export default Tab;
