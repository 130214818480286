import React, { useState, useEffect } from 'react';
import {
    DayPickerRangeController,
    DayPickerSingleDateController,
} from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import useBreakpoint from '../../../../hooks/useBreakpoint';
import moment from 'moment';
import 'moment/locale/de';
import { useSelector } from 'react-redux';

function DatePickerCalendar({
    dates,
    setDates,
    dateSingle,
    setDateSingle,
    singleDate,
    initialValue,
}) {
    const [focusedInput, setFocusedInput] = useState('startDate');
    const { startDate, endDate } = dates;
    const handleDatesChange = ({ startDate, endDate }) => {
        setDates({
            startDate,
            endDate,
        });
    };

    const userReducer = useSelector((state) => state.user);
    const breakpoint = useBreakpoint();
    if (userReducer.user.lang === 'Deutsch') {
        moment.locale('de');
    } else {
        moment.locale('en');
    }

    const renderDayContents = (day) => {
        return (
            <React.Fragment>
                {' '}
                <span className="CalendarDayWeekNumber">
                    {day.format('W')}
                </span>{' '}
                {day.format('D')}{' '}
            </React.Fragment>
        );
    };

    useEffect(() => {
        setDateSingle(initialValue ? moment(initialValue, 'YYYY-DD-MM') : null);
        //eslint-disable-next-line
    }, []);

    const onSingleDateChange = (date) => {
        setDateSingle(date);
    };

    const limitSelectedDaysHandler = (day) => {
        const currentDay = new Date();
        currentDay.setHours(0, 0, 0, 0);
        return day.isBefore(currentDay) || day.isSame(currentDay);
    };

    useEffect(() => {
        if (typeof initialValue === 'string') {
            setDateSingle(moment(initialValue, 'YYYY-MM-DD'));
        }
        //eslint-disable-next-line
    }, []);

    return (
        <div
            className={`c-date-picker-calendar c-date-picker-calendar--${userReducer.user.lang}`}
        >
            {singleDate ? (
                <DayPickerSingleDateController
                    renderDayContents={renderDayContents}
                    onDateChange={onSingleDateChange}
                    isOutsideRange={limitSelectedDaysHandler}
                    date={dateSingle}
                    numberOfMonths={breakpoint > 1024 ? 2 : 1}
                    firstDayOfWeek={1}
                    transitionDuration={0}
                />
            ) : (
                <DayPickerRangeController
                    renderDayContents={renderDayContents}
                    startDate={startDate}
                    endDate={endDate}
                    onDatesChange={handleDatesChange}
                    focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={(focusedInput) => {
                        setFocusedInput(focusedInput || 'startDate');
                    }}
                    numberOfMonths={breakpoint > 1024 ? 2 : 1}
                    firstDayOfWeek={1}
                    transitionDuration={0}
                />
            )}
        </div>
    );
}

export default DatePickerCalendar;
