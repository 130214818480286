import { applyMiddleware, compose, createStore } from 'redux';
import rootReducer from './rootReducer';
// import logger from 'redux-logger';
import thunk from 'redux-thunk';

// const store = createStore(rootReducer, applyMiddleware(logger));

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
// to add console logs from redux add logger in applyMiddleware. Example below
// const store = createStore(rootReducer, composeEnhancers(applyMiddleware(logger, thunk)));

export default store