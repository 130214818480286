import React, { useState } from 'react';
import { onOpenDetails } from '../../../store/actions/detailsPopupActions';
import { useDispatch } from 'react-redux';
import fallbackImg from '../../../../src/images/fallbackImg.svg';
import { useNavigate } from 'react-router-dom';

function TableItemWithImage({
    partImage,
    title,
    partName,
    details,
    tableType,
    partNumber,
}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errImg, setErrImg] = useState(false);

    const imgErr = (e) => {
        e.target.onError = null;
        e.target.src = fallbackImg;
        setErrImg(true);
    };

    const handleDetailsPopupOpen = () => {
        if (details) {
            if (details.orderLineId) {
                navigate(details.orderLineId);
            } else if (details.frameworkLineId) {
                const searchParams = new URLSearchParams(
                    window.location.search
                );
                const type = searchParams.get('type');
                navigate(`${details.frameworkLineId}?type=${type}`);
            }
            dispatch(onOpenDetails(details, tableType));
        }
    };

    return (
        <div
            className="c-table-item__container"
            onClick={handleDetailsPopupOpen}
        >
            <div
                className={`c-table-item__image ${
                    !partImage || errImg ? 'c-table-item__image--scale-1' : ''
                }`}
            >
                <img
                    src={partImage ? partImage : fallbackImg}
                    alt="Part design"
                    loading="lazy"
                    onError={imgErr}
                />
                {/* <div>{data.partImage}</div> */}
                <div className="c-table-item__image-overlay">
                    <i className="icon-search-plus"></i>
                </div>
            </div>
            <div>
                <div className="c-table-item__part-name c-table-item--bold">
                    {partName}
                </div>
                <div className="c-table-item--grey c-table-item--small">
                    {partNumber}
                </div>
            </div>
        </div>
    );
}

export default TableItemWithImage;
