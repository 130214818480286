import { Navigate } from 'react-router-dom';
import useBreakpoint from '../../hooks/useBreakpoint';
import Sidebar from '../../layout/Sidebar/Sidebar';
import MobileNavigation from '../MobileNavigation/MobileNavigation';
import classes from './MainLayout.module.scss';

function MainLayout({ component: Component, ...props }) {
    const breakpoint = useBreakpoint();
    const getCurrentPathWithParams = () => {
        const url = new URL(window.location.href);
        const urlWithoutOrigin = url.pathname + url.search + url.hash;
        return urlWithoutOrigin;
    };

    return (
        <div
            className={[
                classes['c-main-layout'],
                props.sidebar ? classes['c-main-layout--has-sidebar'] : '',
            ].join(' ')}
        >
            {props.sidebar ? (
                breakpoint > 768 ? (
                    <Sidebar />
                ) : (
                    <MobileNavigation />
                )
            ) : null}
            {!props.protected ? (
                <Component />
            ) : localStorage.getItem('token') ? (
                <Component />
            ) : (
                <Navigate
                    to="/login"
                    state={{ redirectTo: getCurrentPathWithParams() }}
                />
            )}
        </div>
    );
}

export default MainLayout;
