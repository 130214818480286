import PageMeta from '../../shared/PageMeta/PageMeta';
import classes from '../../pages/auth/Login/Login.module.scss';
import errStyles from '../../pages/ErrorPage/ErrorPage.module.scss';
import { useTranslation } from 'react-i18next';
import AuthWrapper from '../AuthWrapper/AuthWrapper';
import Button from '../Button/Button';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { onRemoveAxiosInternalError } from '../../store/actions/axiosErrorActions';
import { useEffect } from 'react';

export default function ErrorPage() {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const userReducer = useSelector((state) => state.user);

    useEffect(() => {
        if (!userReducer?.user?.email_verified) {
            navigate('/login');
            localStorage.removeItem('token');
            localStorage.removeItem('X-Track-Id');
        }
        //eslint-disable-next-line
    }, [userReducer]);

    const handleErrorClose = () => {
        dispatch(onRemoveAxiosInternalError());
        navigate('/offers?type=All');
        // navigate('/dashboard');
    };

    return (
        <div className={`${classes['c-login']} ${classes['c-login__popup']}`}>
            <PageMeta>
                <title>Internal Server Error | CNC24</title>
            </PageMeta>
            <AuthWrapper>
                <div className={classes['c-login__container-wrapper']}>
                    <div className={classes['c-login__container']}>
                        <h1 className={classes['c-login__title']}>
                            {t('err-500_heading')}
                        </h1>
                        <p className={errStyles['error-page__description']}>
                            {t('err-500_text')}
                        </p>
                        <Button
                            className="btn btn--big btn--purple btn--square btn--full-width"
                            onClick={handleErrorClose}
                        >
                            {t('err-500_go-back-btn')}
                        </Button>
                    </div>
                </div>
            </AuthWrapper>
        </div>
    );
}
