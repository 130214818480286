import React from 'react';
import TableListItem from './TableListItem/TableListItem';
import classes from './TableList.module.scss';
import TableEmptyState from '../Table/TableEmptyState/TableEmptyState';
import SkeletonWithImage from '../SkeletonLoader/SkeletonWithImage/SkeletonWithImage';
import TableSkeletonLoader from '../Table/TableSkeletonLoader/TableSkeletonLoader';

function TableList(props) {
    return (
        <div className={classes['c-table-list']}>
            {props.loading ? (
                <SkeletonWithImage />
            ) : props.tableData?.length ? (
                props.tableData.map((item, index) => {
                    return (
                        <TableListItem
                            key={`table-list-item-${index}`}
                            item={item}
                            index={index}
                            titles={props.tableTitles}
                            dropdown={item.dropdown}
                            dropdownTitles={props.dropdownTitles}
                            tableType={props.tableType}
                            details={props.details}
                        />
                    );
                })
            ) : props.tableData.length === 0 ? (
                <TableEmptyState
                    heading={props.emptyStHeading}
                    description={props.emptyStText}
                    btnText={props.emptyStBtnText}
                    columns={props.tableTitles?.length}
                    tableType={props.tableType}
                />
            ) : (
                <TableSkeletonLoader
                    columns={props.tableTitles?.length}
                    tableType={props.tableType}
                />
            )}
        </div>
    );
}

export default TableList;
