import React from 'react';
import Tab from './Tab/Tab';
import classes from './Tabs.module.scss';

function Tabs(props) {
    return (
        <div className={`${classes['c-tabs']} ${classes[props.extraClass]}`}>
            <ul>
                {props.tabs.map((tab, index) => (
                    <Tab
                        link={tab.link}
                        name={tab.name}
                        key={`tab-${index}`}
                        onlyOneTab={props.onlyOneTab}
                    />
                ))}
            </ul>
        </div>
    );
}

export default Tabs;
