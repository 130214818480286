import * as actionTypes from '../actions/offersWizzardActions';

const initialState = {
    open: false,
    details: {},
    offerLines: [],
    frameworkOfferLines: [],
    offerLinesIndex: [],
    currentStep: 1,
    totalStep: 1,
    wizzardType: '',
    data: {
        order: {
            reference: '',
            comments: '',
            deliveryDate: '',
            orderLines: [],
            files: [],
        },
        frameworkAgreement: {
            reference: '',
            comments: '',
            deliveryDate: '',
            frameworkAgreementLines: [],
            files: [],
        },
        addresses: {
            billingAddress: {
                city: '',
                companyName: '',
                country: '',
                postalCode: '',
                street: '',
                additionalInfo: '',
                taxNumber: '',
                contactInvoicing: '',
            },
            shippingAddress: {
                city: '',
                companyName: '',
                country: '',
                postalCode: '',
                street: '',
                additionalInfo: '',
            },
        },
        defaultContacts: {
            contactPurchasing: '',
            contactInvoicing: '',
            contactOrderConfirmation: '',
        },
    },
    tax: null,
};

const offersWizzardReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.TOGGLE_OFFERS_WIZZARD:
            let offerLinesIndexInitial = [];
            state.details?.singleOfferLines.forEach(() => {
                offerLinesIndexInitial.push(0);
            });
            return {
                ...state,
                open: !state.open,
                offerLinesIndex: offerLinesIndexInitial,
                offerLines: state.details.singleOfferLines,
                frameworkLines: state.details.frameworkOfferLines,
                currentStep: 1,
                totalStep: 1,
                wizzardType: action.wizzardType ? action.wizzardType : '',
                data: {
                    order: {
                        reference: '',
                        comments: '',
                        deliveryDate: '',
                        orderLines: [],
                        files: [],
                    },
                    frameworkAgreement: {
                        reference: '',
                        comments: '',
                        deliveryDate: '',
                        frameworkAgreementLines: [],
                        files: [],
                    },
                    addresses: {
                        billingAddress: {
                            city: '',
                            companyName: '',
                            country: '',
                            postalCode: '',
                            street: '',
                            additionalInfo: '',
                            taxNumber: '',
                            contactInvoicing: '',
                        },
                        shippingAddress: {
                            city: '',
                            companyName: '',
                            country: '',
                            postalCode: '',
                            street: '',
                            additionalInfo: '',
                        },
                    },
                    defaultContacts: {
                        contactPurchasing: '',
                        contactInvoicing: '',
                        contactOrderConfirmation: '',
                    },
                },
            };
        case actionTypes.GET_WIZZARD_DETAILS:
            let offerLinesIndexArr = [];
            action.details.singleOfferLines?.forEach(() => {
                offerLinesIndexArr.push(0);
            });
            return {
                ...state,
                details: action.details,
                offerLines: action.details.singleOfferLines,
                frameworkOfferLines: action.details.frameworkOfferLines,
                offerLinesIndex: offerLinesIndexArr,
            };
        case actionTypes.SET_QUANTITY_INDEX:
            let offerLinesIndex = state.offerLinesIndex;
            offerLinesIndex[action.rowIndex] = action.quantityIndex;
            return {
                ...state,
                offerLinesIndex: offerLinesIndex,
            };
        case actionTypes.STEP_UP:
            return {
                ...state,
                currentStep: state.currentStep + 1,
                totalStep:
                    state.currentStep === state.totalStep
                        ? state.totalStep + 1
                        : state.totalStep,
            };
        case actionTypes.STEP_DOWN:
            return {
                ...state,
                currentStep: state.currentStep - 1,
            };
        case actionTypes.REMOVE_ITEM:
            let offerLines;
            let offerLinesIndexes;
            offerLinesIndexes = state.offerLinesIndex.filter((item, index) => {
                return index !== action.index;
            });
            offerLines = state.offerLines.filter(
                (offerLine) => offerLine.lineId !== action.lineId
            );

            return {
                ...state,
                offerLines: offerLines,
                offerLinesIndex: offerLinesIndexes,
            };
        case actionTypes.REMOVE_FA_ITEM:
            let faLines;
            let offerFaLinesIndexes;
            offerFaLinesIndexes = state.offerLinesIndex.filter(
                (item, index) => {
                    return index !== action.index;
                }
            );
            faLines = state.frameworkOfferLines.filter(
                (offerLine) => offerLine.lineId !== action.lineId
            );

            return {
                ...state,
                frameworkOfferLines: faLines,
                offerLinesIndex: offerFaLinesIndexes,
            };
        case actionTypes.RESTORE_ITEMS:
            let offerLinesIndexArr1 = [];
            state.details.singleOfferLines?.forEach(() => {
                offerLinesIndexArr1.push(0);
            });
            return {
                ...state,
                offerLines: state.details.singleOfferLines,
                offerLinesIndex: offerLinesIndexArr1,
                frameworkOfferLines: state.details.frameworkOfferLines,
            };
        case actionTypes.SET_API_DATA:
            return {
                ...state,
                data: action.data,
                currentStep: state.currentStep + 1,
                totalStep:
                    state.currentStep === state.totalStep
                        ? state.totalStep + 1
                        : state.totalStep,
            };
        case actionTypes.SET_TAX:
            return {
                ...state,
                tax: action.tax,
            };
        case actionTypes.CLEAR_WIZZARD:
            return {
                ...state,
                open: false,
                details: {},
                offerLines: [],
                offerLinesIndex: [],
                frameworkOfferLines: [],
                currentStep: 1,
                totalStep: 1,
                wizzardType: '',
                data: {
                    order: {
                        reference: '',
                        comments: '',
                        deliveryDate: '',
                        orderLines: [],
                        files: [],
                    },
                    frameworkAgreement: {
                        reference: '',
                        comments: '',
                        deliveryDate: '',
                        frameworkAgreementLines: [],
                        files: [],
                    },
                    addresses: {
                        billingAddress: {
                            city: '',
                            companyName: '',
                            country: '',
                            postalCode: '',
                            street: '',
                            additionalInfo: '',
                            taxNumber: '',
                            contactInvoicing: '',
                        },
                        shippingAddress: {
                            city: '',
                            companyName: '',
                            country: '',
                            postalCode: '',
                            street: '',
                            additionalInfo: '',
                        },
                    },
                    defaultContacts: {
                        contactPurchasing: '',
                        contactInvoicing: '',
                        contactOrderConfirmation: '',
                    },
                },
                tax: null,
            };
        default:
            return state;
    }
};

export default offersWizzardReducer;
