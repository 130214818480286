import * as actionTypes from '../actions/acceptOfferActions';

const initialState = {
    offer: {},
    reference: '',
};

const acceptOffer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ACCEPT_OFFER:
            return {
                ...state,
                offer: action.offer,
                reference: action.reference,
            };
        default:
            return state;
    }
};

export default acceptOffer;
