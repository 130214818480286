import i18n from '../../i18next';
import ApiService from '../../services/api.service';
import UtilService from '../../services/util.service';

export const GET_USER = 'GET_USER';
export const LOG_OUT = 'LOG_OUT';
export const LOG_IN = 'LOG_IN';
export const MAGIC_LOGIN = 'MAGIC_LOGIN';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const DISABLE_LOADING = 'DISABLE_LOADING';

export const logIn = (data) => {
    return (dispatch) => {
        return ApiService.logIn(data).then((r) => {
            localStorage.setItem('token', r.data.access_token);
            return ApiService.getUser().then((user) => {
                if (user.data.data.email_verified) {
                    const language = UtilService.setLanguageCookie(
                        user.data.data.lang
                    );
                    i18n.changeLanguage(language);
                    dispatch({
                        type: LOG_IN,
                        user: user.data?.data,
                    });
                    return user;
                } else {
                    localStorage.removeItem('token');
                    return user;
                }
            });
        });
    };
};

export const ssoUser = () => {
    return (dispatch) => {
        return ApiService.getUser().then((user) => {
            const language = UtilService.setLanguageCookie(
                user.data?.data?.lang
            );
            i18n.changeLanguage(language);
            dispatch({
                type: GET_USER,
                user: user.data?.data,
            });
        });
    };
};

export const getUser = () => {
    return (dispatch) => {
        ApiService.getUser().then((user) => {
            const language = UtilService.setLanguageCookie(
                user?.data?.data?.lang
            );
            i18n.changeLanguage(language);
            dispatch({
                type: GET_USER,
                user: user?.data?.data,
            });
        });
    };
};

export const magicLogin = (data) => {
    return (dispatch) => {
        return ApiService.magicLogin(data).then((r) => {
            dispatch({
                type: MAGIC_LOGIN,
            });
        });
    };
};

export const logOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('X-Track-Id');

    return (dispatch) => {
        dispatch({
            type: LOG_OUT,
        });
    };
};

export const resetPasswod = (data) => {
    return (dispatch) => {
        return ApiService.resetPassword(data).then((r) => {
            dispatch({
                type: RESET_PASSWORD,
            });
        });
    };
};

export const changeLanguage = (data) => {
    return (dispatch) => {
        return ApiService.changeLanguage(data).then((r) => {
            let language = data.language;
            dispatch({
                type: CHANGE_LANGUAGE,
                language: language,
            });
        });
    };
};

export const disableLoading = () => {
    return (dispatch) => {
        dispatch({
            type: DISABLE_LOADING,
        });
    };
};
