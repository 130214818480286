import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import germanTranslation from './ditto/lang__base.json';
import englishTranslation from './ditto/lang__english.json';

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        resources: {
            en: { translation: englishTranslation },
            de: { translation: germanTranslation },
        },
        cookie: 'lang',
        debug: false,
        detection: {
            order: ['querystring', 'cookie'],
            cache: ['cookie'],
        },
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
