import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button/Button';
import Input from '../../../../components/Input/Input/Input';
import * as Yup from 'yup';
import classes from './ConfirmRegister.module.scss';
import LoginRedirection from '../../../../components/LoginRedirection/LoginRedirection';
import { useLocation, useNavigate } from 'react-router-dom';
import ApiService from '../../../../services/api.service';
import ErrorMessage from '../../../../components/ErrorMessage/ErrorMessage';
import { useDispatch } from 'react-redux';
import { getUser } from '../../../../store/actions/userActions';

function ConfirmRegister() {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const pageParams = useLocation();
    const params = new URLSearchParams(pageParams.search);
    const nu = params.get('nu');
    const redirectUrl = params.get('retUrl');
    const navigate = useNavigate();
    const [codeError, setCodeError] = useState();
    const dispatch = useDispatch();
    const server_token = params.get('s');
    const token2 = params.get('t');

    const initialValues = {
        password: '',
        email: nu,
    };

    const submitRegisterHandler = async (props) => {
        setCodeError('');
        localStorage.setItem('token', token2);
        setLoading(true);

        const data = {
            code: props.password,
            email: props.email,
        };

        ApiService.sendVerificationCode(data)
            .then((r) => {
                if (r === undefined) {
                    setCodeError(true);
                    setLoading(false);
                } else {
                    if (r.status === 200) {
                        dispatch(getUser());
                        if (redirectUrl) {
                            navigate(redirectUrl);
                        } else {
                            navigate('/offers?type=All');
                        }
                    }
                }
            })
            .catch((e) => {
                const error = e?.response?.data?.type;
                const blockedCode = error?.includes(
                    'blocked_verification_code'
                );
                setCodeError(blockedCode ? 'blocked_code' : 'invalid_code');
                setLoading(false);
                localStorage.removeItem('token');
            });
    };

    const validationSchema = Yup.object().shape({
        password: Yup.string().required(`${t('error_field-required')}`),
    });

    const resentCodeHandler = async () => {
        localStorage.setItem('token', token2);
        await sendCodeRequest();
        localStorage.removeItem('token');
    };

    const sendCodeRequest = async () => {
        const verificationCodeData = {
            email: nu,
            s: server_token,
        };
        ApiService.requestEmailVerificationCode(verificationCodeData);
    };

    return (
        <div className={classes['c-confirm-register']}>
            <div>
                <h1 className={classes['c-confirm-register__title']}>
                    {t('registration_apply-reg-code')}
                </h1>
                <p className={classes['c-confirm-register__description']}>
                    {t('registration_before-mail-txt')}{' '}
                    <a
                        className={classes['c-confirm-register__help-email']}
                        href="mailto:alen.makaric@etondigital.com"
                    >
                        {nu}
                    </a>{' '}
                    {t('registration_after-email-text')}
                </p>
                <Formik
                    onSubmit={submitRegisterHandler}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    {(props) => (
                        <Form>
                            {codeError ? (
                                <ErrorMessage
                                    hasIcon={true}
                                    type="errorMessage"
                                    errorText={
                                        codeError === 'blocked_code'
                                            ? t('registration_blocked-code')
                                            : t('registration_invalid-code')
                                    }
                                    hasErrorText={true}
                                />
                            ) : null}

                            <Input
                                type="password"
                                label="Code"
                                name="password"
                                errors={props.errors.password}
                                touched={props.touched.password}
                                autoComplete="current-password"
                            />
                            <Button
                                disabled={
                                    !(props.isValid && props.dirty) || loading
                                }
                                className={`btn btn--purple btn--big btn--mobile-login btn--full-width ${
                                    loading ? 'btn--loading' : ''
                                } ${
                                    classes['c-confirm-register__submit-button']
                                }`}
                                ariaLabel={''}
                                type="submit"
                            >
                                {t('registration_create-acc-btn')}
                                {loading ? (
                                    <span className="loader"></span>
                                ) : null}
                            </Button>
                        </Form>
                    )}
                </Formik>
                <Button
                    onClick={resentCodeHandler}
                    ariaLabel={t('registration_resend-confirmation-code')}
                    type="button"
                    className={classes['c-confirm-register__link-button']}
                >
                    {t('registration_resend-confirmation-code')}
                </Button>
            </div>
            <LoginRedirection />
        </div>
    );
}

export default ConfirmRegister;
