import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import WizzardHeader from '../../../components/Wizzard/WizzardHeader/WizzardHeader';
import classes from '../../Offers/OfferSuccess/OfferSuccess.module.scss';
import { useTranslation } from 'react-i18next';

function FrameworkAgreementsSuccessPage() {
    const offer = useSelector((state) => state.acceptOffer.offer);
    const reference = useSelector((state) => state.acceptOffer.reference);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if (!offer.orderName) {
            navigate('/offers?type=All');
        }
    }, [offer, navigate]);
    return (
        <div className={classes['offer-success']}>
            <WizzardHeader />
            <div className={classes['offer-success__content-wrapper']}>
                <div className={classes['offer-success__content']}>
                    <h1 className={classes['offer-success__heading']}>
                        {t('fa-wizard-success_heading')}
                    </h1>
                    <p className={classes['offer-success__description']}>
                        {t('fa-wizard-success_paragraph')}
                    </p>
                    <div className={classes['offer-success__order-details']}>
                        <div
                            className={
                                classes['offer-success__order-details-info']
                            }
                        >
                            <h2
                                className={
                                    classes['offer-success__order-details-name']
                                }
                            >
                                {offer.orderName}
                            </h2>
                            <p
                                className={
                                    classes['offer-success__order-details-id']
                                }
                            >
                                {reference ? reference : '-'}
                            </p>
                        </div>
                        <div
                            className={
                                classes[
                                    'offer-success__order-details-link-wrap'
                                ]
                            }
                        >
                            <Link to={`/orders/${offer.orderId}/components`}>
                                <i className="icon-arrow-right"></i>{' '}
                                {t('fa-wizard-success_view-order')}
                            </Link>
                        </div>
                    </div>
                    <Link
                        to={'/framework-agreements?type=Active'}
                        className={`btn btn--small btn--purple `}
                    >
                        {t('fa-wizard-success_all-fa')}
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default FrameworkAgreementsSuccessPage;
