import classes from '../Details/DetailsSideScreen/DetailsSideScreen.module.scss';
import { useSelector } from 'react-redux';

const SidebarDataContent = ({
    className,
    additionalClass,
    onClick,
    data,
    noLoading,
}) => {
    const loading = useSelector((state) => state.details.detailsLoading);

    return loading && !noLoading ? (
        <span
            className={`${classes['c-details-sidescreen__status-data-skeleton']} ${className}`}
        ></span>
    ) : (
        <div
            className={`${classes['c-details-sidescreen__detail-data']} ${additionalClass}`}
            onClick={onClick}
        >
            {data}
        </div>
    );
};

SidebarDataContent.defaultProps = {
    className: '',
    additionalClass: '',
    onClick: () => {},
    noLoading: false,
};

export default SidebarDataContent;
