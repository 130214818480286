import React from 'react';
import classes from '../TableEmptyState.module.scss';

function TableLines({ width }) {
    return (
        <div className={`${classes[`${width}`]}`}>
            <span></span>
        </div>
    );
}

export default TableLines;
