import ReactGA from 'react-ga4';

const setGoogleAnalytics = (category, event, label) => {
    ReactGA.event({
        category: category,
        action: event,
        label: label,
    });
};

export default setGoogleAnalytics;
