import React, { Suspense, useEffect, useState } from 'react';
import './App.scss';
import { Route, Routes, useLocation } from 'react-router-dom';
import Offers from './pages/Offers/Offers';
import Homepage from './pages/Homepage/Homepage';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import Loader from './shared/Loader/Loader';
import FrameworkAgreements from './pages/FrameworkAgreements/FrameworkAgreements';
import Orders from './pages/Orders/Orders';
import Login from './pages/auth/Login/Login';
import MainLayout from './layout/MainLayout/MainLayout';
import EmailConfirm from './pages/auth/EmailConfirm/EmailConfirm';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, disableLoading } from './store/actions/userActions';
import ResetPassword from './pages/auth/ResetPassword/ResetPassword';
import Account from './pages/Account/Account';
import Contacts from './pages/Account/Contacts/Contacts';
import Language from './pages/Account/Language/Language';
import { useCookies } from 'react-cookie';
import i18n from './i18next';
import OffersDetails from './pages/Offers/OffersDetails/OffersDetails';
import OfferComponents from './pages/Offers/OfferComponents/OfferComponents';
import OrderDetails from './pages/Orders/OrderDetails/OrderDetails';
import OrderComponents from './pages/Orders/OrderComponents/OrderComponents';
import OrderDeliveries from './pages/Orders/OrderDeliveries/OrderDeliveries';
import OrderInvoices from './pages/Orders/OrderInvoices/OrderInvoices';
import FrameworkAgreementsDetails from './pages/FrameworkAgreements/FrameworkAgreementsDetails/FrameworkAgreementsDetails';
import FrameworkAgreementsComponents from './pages/FrameworkAgreements/FrameworkAgreementsComponents/FrameworkAgreementsComponents';
import FrameworkAgreementsDeliveries from './pages/FrameworkAgreements/FrameworkAgreementsDeliveries/FrameworkAgreementsDeliveries';
import FrameworkAgreementsInvoices from './pages/FrameworkAgreements/FrameworkAgreementsInvoices/FrameworkAgreementsInvoices';
import SSOLogin from './pages/auth/SSOLogin/SSOLogin';
import OfferSuccess from './pages/Offers/OfferSuccess/OfferSuccess';
import FrameworkAgreementsSuccessPage from './pages/FrameworkAgreements/FrameworkAgreementsWizzard/FrameworkAgreementsSuccessPage';
import Wizard from './pages/Wizard/Wizard';
import Register from './pages/auth/RegisterContainer/Register/Register';
import ConfirmRegister from './pages/auth/RegisterContainer/ConfirmRegister/ConfirmRegister';
import RegisterContainer from './pages/auth/RegisterContainer/RegisterContainer';
import { clarity } from 'react-microsoft-clarity';
import config from './config';
import OffersWizzard from './pages/Offers/OffersWizzard/OffersWizzard';
import OffersWizardComponents from './pages/Offers/OffersWizzard/Steps/Components/Components';
import OffersWizardOrderDetails from './pages/Offers/OffersWizzard/Steps/OrderDetails/OrderDetails';
import OffersWizardSummary from './pages/Offers/OffersWizzard/Steps/Summary/Summary';
import FrameworkWizard from './pages/FrameworkAgreements/FrameworkAgreementsWizzard/FrameworkAgreementsWizzard';
import FrameworkWizardComponents from './pages/FrameworkAgreements/FrameworkAgreementsWizzard/Steps/Components/Components';
import FrameworkWizardOrderDetails from './pages/FrameworkAgreements/FrameworkAgreementsWizzard/Steps/OrderDetails/OrderDetails';
import FrameworkWizardSummary from './pages/FrameworkAgreements/FrameworkAgreementsWizzard/Steps/Summary/Summary';
import ErrorPopup from './components/ErrorPopup/ErrorPopup';
import ReactGA from 'react-ga4';
import OrderContacts from './pages/Orders/OrderContacts/OrderContacts';
// import Dashboard from './pages/Dashboard/Dashboard';

function App() {
    const location = useLocation();
    const [cookies] = useCookies();

    const dispatch = useDispatch();
    const userReducer = useSelector((state) => state.user);
    const axiosErrorReducer = useSelector((state) => state.axiosErrorReducer);
    const params = new URLSearchParams(location.search);
    const trackStatus = params.get('noTrack');
    const [noTrack, setNoTrack] = useState();

    useEffect(() => {
        let token = localStorage.getItem('token');
        if (token) {
            return dispatch(getUser());
        } else {
            dispatch(disableLoading());
        }
    }, [dispatch]);

    useEffect(() => {
        if (cookies.lang) {
            i18n.changeLanguage(cookies.lang);
        }
    }, [cookies.lang]);

    useEffect(() => {
        // Start seeing data on the Clarity dashboard with your id
        let xUserId = localStorage.getItem('X-Track-Id');
        const token = !localStorage.getItem('token');
        setNoTrack(trackStatus);

        if (trackStatus !== '1' && !xUserId && config.environment !== 'local') {
            window[
                `ga-disable-${config.apiGateway.googleAnalythicsId}`
            ] = false;
            ReactGA.initialize(config.apiGateway.googleAnalythicsId);
        } else if (window.clarity && !token) {
            window.clarity('stop');
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        const token = localStorage.getItem('token');
        let xUserId = localStorage.getItem('X-Track-Id');
        if (
            !trackStatus &&
            !xUserId &&
            token &&
            config.environment !== 'local' &&
            !noTrack &&
            userReducer.user !== ''
        ) {
            clarity.init(config.apiGateway.windowsClarityID);
            window?.clarity('start');

            // Check if Clarity has been initialized before calling its methods
            if (clarity.hasStarted() && !xUserId) {
                clarity.identify(userReducer.user?.accountId);
            }
        }
        //eslint-disable-next-line
    }, [userReducer, noTrack]);

    return (
        <main className="c-app">
            {axiosErrorReducer.axiosInternalError ? (
                <ErrorPopup type={500} />
            ) : null}
            {!userReducer.userLoading ? (
                <Suspense fallback={<Loader />}>
                    <Routes location={location}>
                        <React.Fragment>
                            <Route
                                path="*"
                                element={
                                    <MainLayout
                                        component={ErrorPage}
                                        sidebar={false}
                                    />
                                }
                            />
                            <Route
                                path="access-denied"
                                element={
                                    <MainLayout
                                        component={ErrorPage}
                                        sidebar={false}
                                    />
                                }
                            />
                            <Route
                                path="internal-error"
                                element={
                                    <MainLayout
                                        component={ErrorPage}
                                        sidebar={false}
                                    />
                                }
                            />
                            <Route
                                path="/"
                                element={
                                    <MainLayout
                                        component={Homepage}
                                        sidebar={true}
                                    />
                                }
                            />
                            {/* <Route
                                path="dashboard"
                                element={
                                    <MainLayout
                                        component={Dashboard}
                                        sidebar={true}
                                        protected={true}
                                    />
                                }
                            /> */}
                            <Route
                                path="offers"
                                element={
                                    <MainLayout
                                        component={Offers}
                                        sidebar={true}
                                        protected={true}
                                    />
                                }
                            />
                            <Route
                                path="/offers/:id"
                                element={
                                    <MainLayout
                                        component={OffersDetails}
                                        sidebar={true}
                                        protected={true}
                                    />
                                }
                            >
                                <Route
                                    path="components"
                                    element={<OfferComponents />}
                                />
                                <Route
                                    path="components/:componentId"
                                    element={<OfferComponents />}
                                />
                                <Route
                                    path="wizard"
                                    element={<OffersWizzard />}
                                >
                                    <Route
                                        path="components"
                                        element={<OffersWizardComponents />}
                                    />
                                    <Route
                                        path="order-details"
                                        element={<OffersWizardOrderDetails />}
                                    />
                                    <Route
                                        path="summary"
                                        element={<OffersWizardSummary />}
                                    />
                                </Route>
                            </Route>
                            <Route
                                path="offer-success"
                                element={<OfferSuccess />}
                            ></Route>
                            <Route
                                path="offer-success-fa"
                                element={<FrameworkAgreementsSuccessPage />}
                            ></Route>
                            <Route
                                path="/orders/:id"
                                element={
                                    <MainLayout
                                        component={OrderDetails}
                                        sidebar={true}
                                        protected={true}
                                    />
                                }
                            >
                                <Route
                                    path="components"
                                    element={<OrderComponents />}
                                />
                                <Route
                                    path="components/:componentId"
                                    element={<OrderComponents />}
                                />
                                <Route
                                    path="deliveries"
                                    element={<OrderDeliveries />}
                                />
                                <Route
                                    path="deliveries/:componentId"
                                    element={<OrderDeliveries />}
                                />
                                <Route
                                    path="invoices"
                                    element={<OrderInvoices />}
                                />
                                <Route
                                    path="contacts"
                                    element={<OrderContacts />}
                                />
                            </Route>
                            <Route
                                path="framework-agreements/:order"
                                element={
                                    <MainLayout
                                        component={FrameworkAgreementsDetails}
                                        sidebar={true}
                                        protected={true}
                                    />
                                }
                            >
                                <Route
                                    path="components"
                                    element={<FrameworkAgreementsComponents />}
                                />
                                <Route
                                    path="components/:componentId"
                                    element={<FrameworkAgreementsComponents />}
                                />
                                <Route
                                    path="orders"
                                    element={<FrameworkAgreementsDeliveries />}
                                />
                                <Route
                                    path="invoices"
                                    element={<FrameworkAgreementsInvoices />}
                                />
                                <Route
                                    path="wizard"
                                    element={<FrameworkWizard />}
                                >
                                    <Route
                                        path="components"
                                        element={<FrameworkWizardComponents />}
                                    />
                                    <Route
                                        path="order-details"
                                        element={
                                            <FrameworkWizardOrderDetails />
                                        }
                                    />
                                    <Route
                                        path="summary"
                                        element={<FrameworkWizardSummary />}
                                    />
                                </Route>
                            </Route>
                            <Route
                                path="orders"
                                element={
                                    <MainLayout
                                        component={Orders}
                                        sidebar={true}
                                        protected={true}
                                    />
                                }
                            />
                            <Route
                                path="framework-agreements"
                                element={
                                    <MainLayout
                                        component={FrameworkAgreements}
                                        sidebar={true}
                                        protected={true}
                                    />
                                }
                            />
                            <Route
                                path="settings"
                                element={
                                    <MainLayout
                                        component={Account}
                                        sidebar={true}
                                        protected={true}
                                    />
                                }
                            >
                                <Route path="contacts" element={<Contacts />} />
                                <Route path="language" element={<Language />} />
                            </Route>
                            {/* Auth pages */}
                            <Route
                                path="login"
                                element={
                                    <MainLayout
                                        component={Login}
                                        sidebar={false}
                                    />
                                }
                            />
                            <Route
                                path="sign-up"
                                element={
                                    <MainLayout
                                        component={RegisterContainer}
                                        sidebar={false}
                                    />
                                }
                            >
                                <Route path="" element={<Register />} />
                                <Route
                                    path="confirm"
                                    element={<ConfirmRegister />}
                                />
                            </Route>
                            <Route
                                path="reset-password"
                                element={
                                    <MainLayout
                                        component={ResetPassword}
                                        sidebar={false}
                                    />
                                }
                            />
                            <Route
                                path="confirm-login"
                                element={
                                    <MainLayout
                                        component={EmailConfirm}
                                        sidebar={false}
                                    />
                                }
                            />
                            <Route
                                path="reset-success"
                                element={
                                    <MainLayout
                                        component={EmailConfirm}
                                        sidebar={false}
                                    />
                                }
                            />
                            <Route
                                path="wizard"
                                element={
                                    <MainLayout
                                        component={Wizard}
                                        sidebar={false}
                                        protected={true}
                                    />
                                }
                            ></Route>
                            <Route path="magic-login" element={<SSOLogin />} />
                        </React.Fragment>
                    </Routes>
                </Suspense>
            ) : (
                <Loader />
            )}
        </main>
    );
}

export default App;
