import classes from './Icons.module.scss';

function File2Icon() {
    return (
        <svg
            className={classes['c-icons__icon']}
            width="18"
            height="21"
            viewBox="0 0 18 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className="icon-path"
                d="M3.98438 15H11.0156V17.0156H3.98438V15ZM3.98438 11.0156H14.0156V12.9844H3.98438V11.0156ZM3.98438 6.98438H14.0156V9H3.98438V6.98438ZM15.9844 3H11.8125C11.3906 1.82812 10.3125 0.984375 9 0.984375C7.6875 0.984375 6.60938 1.82812 6.1875 3H2.01562C1.875 3 1.73438 3 1.59375 3.04688C1.21875 3.14062 0.84375 3.32812 0.609375 3.60938C0.421875 3.75 0.28125 3.98438 0.140625 4.21875C0.046875 4.45312 0 4.73438 0 5.01562V18.9844C0 19.2656 0.046875 19.5469 0.140625 19.7812C0.28125 20.0156 0.421875 20.25 0.609375 20.4375C0.84375 20.6719 1.21875 20.9062 1.59375 20.9531C1.73438 21 1.875 21 2.01562 21H15.9844C17.1094 21 18 20.1094 18 18.9844V5.01562C18 3.89062 17.1094 3 15.9844 3ZM9 2.76562C9.42188 2.76562 9.75 3.09375 9.75 3.51562C9.75 3.89062 9.42188 4.26562 9 4.26562C8.57812 4.26562 8.25 3.89062 8.25 3.51562C8.25 3.09375 8.57812 2.76562 9 2.76562ZM15.9844 18.9844H2.01562V5.01562H15.9844V18.9844Z"
                fill="#838A9C"
            />
        </svg>
    );
}

export default File2Icon;
