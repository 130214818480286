import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classes from './PriceDropdown.module.scss';
import PriceLabelAndPrice from './PriceLabelAndPrice/PriceLabelAndPrice';
import test from '../DetailsSideScreen.module.scss';
import TranslatedComponent from '../../../TranslatedComponent/TranslatedComponent';

function PriceDropdown({
    data,
    pageType,
    onPriceDropdownAction,
    expandedContainerStatus,
}) {
    const [dropdownExpanded, setDropdownExpanded] = useState(false);
    const dropdownClickHandler = () => {
        setDropdownExpanded(!dropdownExpanded);
        onPriceDropdownAction();
    };
    const loading = useSelector((state) => state.details.detailsLoading);

    useEffect(() => {
        if (!expandedContainerStatus) {
            setDropdownExpanded(false);
        }
    }, [expandedContainerStatus]);

    return (
        <div className={classes['c-price-dropdown']}>
            <div className={classes['c-price-dropdown__header']}>
                <PriceLabelAndPrice
                    price={data.totalPrice}
                    translation={
                        <TranslatedComponent
                            translationFileName="prices"
                            translatedComponent="totalPrice"
                        />
                    }
                    additionalClass="c-details-sidescreen__detail-title--small"
                />
                {!loading ? (
                    <button
                        className={[
                            classes['c-price-dropdown__dropdown-button'],
                            dropdownExpanded
                                ? classes[
                                      'c-price-dropdown__dropdown-button--rotate'
                                  ]
                                : '',
                        ].join(' ')}
                        onClick={dropdownClickHandler}
                        aria-label="dropdown"
                    >
                        <i className="icon-chevron-down-thin"></i>
                    </button>
                ) : null}
            </div>
            {!loading ? (
                <div
                    className={[
                        classes['c-price-dropdown__expandable-container'],
                        classes[
                            `c-price-dropdown__expandable-container--${pageType}`
                        ],
                        !dropdownExpanded
                            ? classes[
                                  'c-price-dropdown__expandable-container--hidden'
                              ]
                            : '',
                    ].join(' ')}
                >
                    {pageType === 'orders' && data.discount ? (
                        <PriceLabelAndPrice
                            price={data.discount}
                            translation={
                                <TranslatedComponent
                                    translationFileName="prices"
                                    translatedComponent="discount"
                                />
                            }
                            additionalClass="c-details-sidescreen__detail-title--small-italic"
                        />
                    ) : null}
                    {pageType === 'orders' ? (
                        <PriceLabelAndPrice
                            price={data.shippingCosts}
                            translation={
                                <TranslatedComponent
                                    translationFileName="prices"
                                    translatedComponent="shippingCosts"
                                />
                            }
                            additionalClass="c-details-sidescreen__detail-title--small-italic"
                        />
                    ) : null}
                    <PriceLabelAndPrice
                        price={data.taxes}
                        translation={
                            <TranslatedComponent
                                translationFileName="prices"
                                translatedComponent="VAT"
                            />
                        }
                        additionalClass="c-details-sidescreen__detail-title--small-italic"
                    />
                    {/* TODO Need preis Gesamt data */}
                    <PriceLabelAndPrice
                        price={data.totalPriceGross}
                        translation={
                            <TranslatedComponent
                                translationFileName="prices"
                                translatedComponent="totalCost"
                            />
                        }
                        additionalClass="c-details-sidescreen__detail-title--medium"
                        additionPriceClass={`${test['c-details-sidescreen__detail-data--purple-price']}`}
                        additionPriceContainerClass="c-price-label-and-price__formated-price--large"
                    />
                </div>
            ) : null}
        </div>
    );
}

PriceDropdown.defaultProps = {
    pageType: '',
    onPriceDropdownAction: () => {},
};

export default PriceDropdown;
