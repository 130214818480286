import React from 'react';
import { useEffect } from 'react';
import DetailsSideScreen from '../../../components/Details/DetailsSideScreen/DetailsSideScreen';
import { useDispatch, useSelector } from 'react-redux';
import { getDetails } from '../../../store/thunks/detailsThunk';
import { Outlet, useParams } from 'react-router-dom';
import { onDetailsCleanup } from '../../../store/actions/detailsActions';
import DetailsWrapper from '../../../components/Details/DetailsWrapper/DetailsWrapper';
import DetailsRigthSideWrapper from '../../../components/Details/DetailsRigthSideWrapper/DetailsRigthSideWrapper';
import DetailsPopup from '../../../components/Details/DetailsPopup/DetailsPopup';
import { onTableContentCleanup } from '../../../store/actions/tableContentActions';
import Tabs from '../../../components/PageHeader/Tabs/Tabs';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button/Button';
import {
    onClearWizzard,
    onToggleFrameworkWizzard,
} from '../../../store/actions/frameworkWizzardActions';
import classes from './FrameworkAgreementDetails.module.scss';
import { useNavigate } from 'react-router-dom';

function FrameworkAgreementsDetails() {
    const dispatch = useDispatch();
    const { order } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const offerDetailsLoading = useSelector(
        (state) => state.details.detailsLoading
    );

    const detailsPopup = useSelector(
        (state) => state.detailsPopup.detailsPopup
    );

    const frameworkWizzard = useSelector(
        (state) => state.frameworkWizzardReducer
    );

    useEffect(() => {
        const params = new URLSearchParams();
        dispatch(getDetails('frameworkAgreement', order, params));
    }, [dispatch, order]);

    //cleanup
    useEffect(
        () => () => {
            dispatch(onTableContentCleanup());
            dispatch(onDetailsCleanup());
            dispatch(onClearWizzard());
        },
        //eslint-disable-next-line
        []
    );

    const handleOpenWizzard = () => {
        dispatch(onToggleFrameworkWizzard());
        navigate(
            `/framework-agreements/${frameworkWizzard.details.faId}/wizard/components`
        );
    };

    return (
        <>
            <>
                <DetailsWrapper>
                    <DetailsSideScreen
                        loading={offerDetailsLoading}
                        pageType="frameworkAgreement"
                        goBackLink="/framework-agreements?type=Active"
                    />
                    <DetailsRigthSideWrapper>
                        <div className={classes['c-fa-details-tabs-btn']}>
                            <div
                                className={
                                    classes[
                                        'c-fa-details-tabs-btn__tabs-wrapper'
                                    ]
                                }
                            >
                                <Tabs
                                    extraClass="c-tabs__fa-details"
                                    tabs={[
                                        {
                                            name: t(
                                                'fa-details-tab_components'
                                            ),
                                            link: 'components?type=Overview',
                                        },
                                        {
                                            name: t('fa-details-tab_orders'),
                                            link: 'orders',
                                        },
                                        {
                                            name: t('fa-details-tab_invocies'),
                                            link: 'invoices',
                                        },
                                    ]}
                                />
                            </div>
                            {frameworkWizzard?.details.displayOrderButton ? (
                                <div
                                    className={
                                        classes[
                                            'c-fa-details-tabs-btn__btn-wrapper'
                                        ]
                                    }
                                >
                                    <Button
                                        onClick={handleOpenWizzard}
                                        className={`btn btn--small btn--purple `}
                                    >
                                        {t('global_order-btn')}
                                    </Button>
                                </div>
                            ) : null}
                        </div>
                        <Outlet />
                    </DetailsRigthSideWrapper>
                </DetailsWrapper>
                <DetailsPopup
                    type="orders"
                    open={Object.values(detailsPopup).length ? true : false}
                />
            </>
        </>
    );
}

export default FrameworkAgreementsDetails;
