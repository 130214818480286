import React from 'react';
// import classes from './TableItem.module.scss';
import moment from 'moment';
import TableItemContent from './TableItemContent/TableItemContent';
import { Link } from 'react-router-dom';

function TableItem(props) {
    const tableData = Object.values(props.data);
    let certificates;

    if (
        props.tableType === 'offersSingleOrder' ||
        props.tableType === 'wizardOffers' ||
        props.tableType === 'offerFrameworkOrder' ||
        props.tableType === 'wizardFaOffers' ||
        props.tableType === 'offersSingleOrderWizardSummary' ||
        props.tableType === 'offersFaWizardSummary'
    ) {
        certificates = props.certificates?.length
            ? Object.values(props.certificates[0])
            : [];
    }

    if (props.linkableRows) {
        return (
            <Link
                to={
                    props.tableType === 'offers' &&
                    props.data.status.status === 'Wizardnotfinished'
                        ? `/wizard?id=${props.id}`
                        : props.tableType === 'offers'
                        ? `${props.id}/components`
                        : props.tableType === 'framework'
                        ? `${props.id}/components?type=Overview`
                        : `${props.id}/components`
                }
                className={`c-table-item c-table-item--link c-table-item__${props.tableType}-row`}
            >
                {tableData.length &&
                    tableData.map((data, index) => {
                        if (data?.date) {
                            return (
                                <div
                                    key={`table-data-link-${index}`}
                                    className={`${`c-table-item__${tableData.length}`} ${
                                        props.tableType
                                            ? `c-table-item__${props.tableType}`
                                            : ''
                                    }`}
                                >
                                    <div className={'c-table-item__content'}>
                                        <span>
                                            <span className="icon-type">
                                                {data.iconType}
                                            </span>
                                            {data.date !== '-'
                                                ? moment(data.date).format(
                                                      'DD.MM.YYYY'
                                                  )
                                                : data.date}
                                            <span className="iconHoverText">
                                                {data.iconHoverText}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            );
                        } else {
                            return (
                                <TableItemContent
                                    key={`table-item-content-${index}`}
                                    data={data}
                                    tableType={props.tableType}
                                    tableDataLength={tableData.length}
                                />
                            );
                        }
                    })}
            </Link>
        );
    } else {
        return (
            <>
                <div
                    key={'table-item'}
                    className={`c-table-item ${
                        props.itemIndex ? 'item-index-' + props.itemIndex : ''
                    }  ${
                        (props.tableType === 'offersSingleOrder' &&
                            tableData[1]?.length > 1) ||
                        props.tableType === 'offerFrameworkOrder'
                            ? 'c-table-item--with-multiple'
                            : ''
                    } ${
                        props.tableType === 'offerFrameworkOrder' &&
                        tableData[2]?.length > 1
                            ? 'c-table-item--multiple-rows'
                            : ''
                    } ${
                        props.tableType === 'order-deliveries'
                            ? 'c-table-item--deliveries-link'
                            : ''
                    } ${props.showCertificates ? 'hasCertificate' : ''} ${
                        props.missingDataInfo?.length ? 'hasErrorMessage' : ''
                    }`}
                >
                    {tableData.length &&
                        tableData.map((data, index) => {
                            if (data?.date) {
                                return (
                                    <div
                                        key={`table-data-${index}`}
                                        className={`${`c-table-item__${tableData.length}`} ${
                                            props.tableType
                                                ? `c-table-item__${props.tableType}`
                                                : ''
                                        }`}
                                    >
                                        <div
                                            className={'c-table-item__content'}
                                        >
                                            <span>
                                                <span className="icon-type">
                                                    {data.iconType}
                                                </span>
                                                {moment(data.date).format(
                                                    'DD.MM.YYYY'
                                                )}
                                                <span className="iconHoverText">
                                                    {data.iconHoverText}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                );
                            } else if (Array.isArray(data)) {
                                let mappedData = [];
                                let asd = data.map((item, index) => {
                                    const arr = Object.values(item);
                                    mappedData = arr.map((data, i) => {
                                        return (
                                            <TableItemContent
                                                key={`table-item-content-${index}-${i}`}
                                                data={data ? data : '-'}
                                                type="single-item"
                                                hasDiscount={props.hasDiscount}
                                                hasOrigin={props.hasOrigin}
                                            />
                                        );
                                    });
                                    return (
                                        <div
                                            key={`table-item-conetnt-${index}`}
                                            className="c-table-item__single-row-with-multiple"
                                        >
                                            {mappedData}
                                        </div>
                                    );
                                });
                                return (
                                    <div
                                        key={`key`}
                                        className={`c-table-item__multiple-items ${
                                            props.tableType
                                                ? `c-table-item__${props.tableType}`
                                                : ''
                                        } ${
                                            props.hasDiscount
                                                ? 'hasDiscount'
                                                : 'noDiscount'
                                        } ${
                                            props.hasOrigin
                                                ? 'hasOrigin'
                                                : 'noOrigin'
                                        }`}
                                    >
                                        {asd}
                                    </div>
                                );
                            } else {
                                return (
                                    <TableItemContent
                                        key={`table-item-content-${index}-01`}
                                        data={data}
                                        tableType={props.tableType}
                                        tableDataLength={tableData.length}
                                        details={props.details}
                                        hasDiscount={props.hasDiscount}
                                        hasOrigin={props.hasOrigin}
                                    />
                                );
                            }
                        })}
                </div>
                {props.missingDataInfo?.length ? (
                    <div
                        className={`c-table-item__error-message-container ${
                            props.tableType === 'offersSingleOrder' &&
                            props.showCertificates
                                ? 'hasCertificate'
                                : ''
                        } ${props.hasDiscount ? 'hasDiscount' : ''} ${
                            props.hasOrigin ? 'hasOrigin' : ''
                        }`}
                    >
                        <div className="c-table-item__error-message">
                            <i className="icon-warning"></i>
                            <div className="c-table-item__error-message-text">
                                {props.missingDataInfo.map(
                                    (errorMessage, index) => {
                                        return (
                                            <span
                                                key={`error-message-${index}`}
                                            >
                                                {index !== 0 ? ', ' : null}
                                                {errorMessage === 'Other'
                                                    ? props.customErrorMessage
                                                    : errorMessage}
                                            </span>
                                        );
                                    }
                                )}
                            </div>
                        </div>
                    </div>
                ) : null}
                {(props.tableType === 'offersSingleOrder' ||
                    props.tableType === 'wizardOffers' ||
                    props.tableType === 'offerFrameworkOrder' ||
                    props.tableType === 'wizardFaOffers' ||
                    props.tableType === 'offersSingleOrderWizardSummary' ||
                    props.tableType === 'offersFaWizardSummary') &&
                props.showCertificates ? (
                    <div className={`c-table-item`}>
                        {certificates?.length &&
                            certificates?.map((data, index) => {
                                return (
                                    <TableItemContent
                                        key={`table-item-content-${index}-01`}
                                        data={data}
                                        tableType={
                                            props.tableType ===
                                            'offersSingleOrder'
                                                ? 'offersSingleOrderCertificates'
                                                : props.tableType ===
                                                  'wizardOffers'
                                                ? 'wizardOfferSingleOrder'
                                                : props.tableType ===
                                                  'offerFrameworkOrder'
                                                ? 'offerFrameworkOrder'
                                                : props.tableType ===
                                                  'wizardFaOffers'
                                                ? 'wizardFaOffers'
                                                : props.tableType ===
                                                  'offersSingleOrderWizardSummary'
                                                ? 'offersSingleOrderWizardSummary'
                                                : props.tableType ===
                                                  'offersFaWizardSummary'
                                                ? 'offersFaWizardSummary'
                                                : 'offersWizzardComponents'
                                        }
                                        certificate={true}
                                        tableDataLength={tableData.length}
                                        hasDiscount={props.hasDiscount}
                                        hasOrigin={props.hasOrigin}
                                    />
                                );
                            })}
                    </div>
                ) : null}
            </>
        );
    }
}

export default TableItem;
