import React, { useState, useEffect } from 'react';
import Table from '../../../components/Table/Table';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import PageSearch from '../../../components/PageHeader/PageSearch/PageSearch';
import PageMeta from '../../../shared/PageMeta/PageMeta';
import setGoogleAnalytics from '../../../hooks/setGoogleAnalytics';

function FrameworkAgreementsInvoices() {
    const [tableData, setTableData] = useState(['']);
    const [showInitialMessage, setShowInitialMessage] = useState(true);
    const [loading, setLoading] = useState(true);

    const invoices = useSelector(
        (state) => state.details.searchedFrameworkInvoices
    );

    const detailsLoading = useSelector((state) => state.details.detailsLoading);

    const { t } = useTranslation();
    const faNumber = useSelector((state) => state.details.details.faNr);

    const tableTitles = [
        {
            id: 1,
            name: t('fa-inovices_date'),
        },
        {
            id: 2,
            name: t('fa-invoice_invoice-num'),
        },
        {
            id: 3,
            name: t('fa-invoice_invoice'),
        },
        {
            id: 4,
            name: t('fa-invoice_deliveries'),
        },
        {
            id: 5,
            name: t('fa-invoices_valid-untill'),
        },
        {
            id: 6,
            name: t('fa-invoices_amount'),
        },
        {
            id: 7,
            name: t('fa-invoices_status'),
        },
    ];

    useEffect(() => {
        if (invoices) {
            const filteredTableData = invoices.map((invoice) => {
                return {
                    id: invoice.invoiceId,
                    data: {
                        invoiceDate: invoice.invoiceDate
                            ? moment(invoice.invoiceDate).format('DD.MM.YYYY')
                            : '-',
                        invoiceNumber: invoice.invoiceNumber
                            ? invoice.invoiceNumber
                            : '-',
                        invoiceFiles: invoice.files
                            ? { invoiceFiles: invoice.files[0] }
                            : '-',
                        deliveries: invoice.deliveryNotes?.length
                            ? [invoice.deliveryNotes]
                            : '-',
                        dueDate: invoice.dueDate
                            ? moment(invoice.dueDate).format('DD.MM.YYYY')
                            : '-',
                        totalPrice: {
                            totalPrice: invoice.totalPrice
                                ? invoice.totalPrice
                                : '-',
                        },
                        status: {
                            status: invoice.status ? invoice.status : '-',
                            color: invoice.status ? invoice.status : null,
                            type: 'invoicesStatus',
                        },
                    },
                };
            });
            setTableData(filteredTableData);
        }
    }, [invoices]);

    const initialSearchValueHandler = (searchStarted) => {
        setShowInitialMessage(searchStarted);
    };

    // TODO REWORK WHEN DITTO WORKS AGAIN
    const getEmptyStateMessage = (whoseState, stateType, statePlace) => {
        const emptyStatesObject = t('emptyState');
        const emptyStateJson = JSON.parse(emptyStatesObject);
        return emptyStateJson[whoseState][stateType][statePlace];
    };

    // reactga4 for framework agreements details page invoices tab
    useEffect(() => {
        setGoogleAnalytics(
            'page_view',
            'page_view_fa_detail_invoice',
            'view fa detail page, invoice section'
        );
    }, []);

    // fix for table empty state showing briefly after loading
    useEffect(() => {
        setLoading(detailsLoading);
    }, [detailsLoading]);

    return (
        <>
            <PageMeta>
                <title>
                    Framework Agreement {faNumber ? faNumber : ''} - Invoices |
                    CNC24
                </title>
            </PageMeta>
            <PageSearch
                tableType="frameworkInvoices"
                componentSearch={true}
                onInitialSearchValue={initialSearchValueHandler}
                javascriptSearch={true}
            />
            <Table
                tableTitles={tableTitles}
                tableData={tableData}
                tableType="table-invoices"
                addedPadding={true}
                emptyStHeading={
                    showInitialMessage
                        ? getEmptyStateMessage(
                              'faAgreementsInvoicesEmptyState',
                              'InitialEmptyState',
                              'heading'
                          )
                        : t('fa-invoices-empty_heading')
                }
                emptyStText={
                    showInitialMessage
                        ? getEmptyStateMessage(
                              'faAgreementsInvoicesEmptyState',
                              'InitialEmptyState',
                              'heading'
                          )
                        : t('fa-invoices_paragraph')
                }
                loading={loading}
            />
        </>
    );
}

export default FrameworkAgreementsInvoices;
