import React from 'react';
import classes from './DownloadPdf.module.scss';

function DownloadPdf(props) {
    const downloadPdf = () => {
        let link = document.createElement('a');
        link.href = props.url;
        link.download = props.name;
        link.click();
    };

    return (
        <div onClick={downloadPdf} className={classes['download-pdf-btn']}>
            <span>{props.name}</span>
            <i className="icon-download"></i>
        </div>
    );
}

export default DownloadPdf;
