import ApiService from '../../services/api.service';
import {
    onGetTableContent,
    onGetTableContentLoading,
} from '../actions/tableContentActions';

export function getTableData(dataType) {
    return function (dispatch) {
        dispatch(onGetTableContentLoading());
        setTimeout(() => {
            const params = new URLSearchParams(window.location.search);
            ApiService.getTableData(dataType, params)
                .then((tableData) => {
                    dispatch(onGetTableContent(tableData.data.data));
                })
                .catch((e) => {
                    if (e.response?.status === 404) {
                        dispatch(onGetTableContent([]));
                    }
                });
        }, 100);
    };
}
