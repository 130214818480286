import { useRef, useState, useEffect } from 'react';
import Table from '../../../Table/Table';
import classes from './TableListItemContent.module.scss';

function TableListItemContent({ content, titles, expanded, tableType }) {
    const expandableContainer = useRef(null);
    const [height, setHeight] = useState(null);

    useEffect(() => {
        if (expandableContainer.current) {
            setHeight(expandableContainer.current.scrollHeight);
        }
    }, []);

    return content ? (
        <div
            ref={expandableContainer}
            style={{ maxHeight: expanded ? height : null }}
            className={
                !expanded
                    ? classes['c-table-list-item-content']
                    : classes['c-table-list-item-content--expanded']
            }
        >
            <Table
                tableList={true}
                tableTitles={titles}
                tableData={content}
                linkableRows={false}
                tableType={tableType}
            />
        </div>
    ) : null;
}

export default TableListItemContent;
