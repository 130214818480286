import TranslatedComponent from '../../../../TranslatedComponent/TranslatedComponent';

function TableItemContentMaterial({
    material,
    materialGroup,
    requestedMaterial,
    orangeText,
}) {
    return (
        <div className="c-table-item__container c-table-item__container--multiple c-table-item__container--material">
            <span>
                <TranslatedComponent
                    translationFileName="materials"
                    translatedComponent={materialGroup}
                />
            </span>
            {((orangeText && requestedMaterial) ||
                (!orangeText && material && material !== '-')) && (
                <span> ({orangeText ? requestedMaterial : material})</span>
            )}
        </div>
    );
}

export default TableItemContentMaterial;
