import React from 'react';
import { useEffect } from 'react';
import DetailsSideScreen from '../../../components/Details/DetailsSideScreen/DetailsSideScreen';
// import OfferComponents from '../OfferComponents/OfferComponents';
import { useDispatch, useSelector } from 'react-redux';
import { getDetails } from '../../../store/thunks/detailsThunk';
import { Outlet, useParams } from 'react-router-dom';
import { onDetailsCleanup } from '../../../store/actions/detailsActions';
import DetailsWrapper from '../../../components/Details/DetailsWrapper/DetailsWrapper';
import DetailsRigthSideWrapper from '../../../components/Details/DetailsRigthSideWrapper/DetailsRigthSideWrapper';
import DetailsPopup from '../../../components/Details/DetailsPopup/DetailsPopup';
import { onTableContentCleanup } from '../../../store/actions/tableContentActions';
// import OffersWizzard from '../OffersWizzard/OffersWizzard';
import { onClearWizzard } from '../../../store/actions/offersWizzardActions';
import setGoogleAnalytics from '../../../hooks/setGoogleAnalytics';

function OffersDetails() {
    const dispatch = useDispatch();
    const { id } = useParams();

    const offerDetailsLoading = useSelector(
        (state) => state.details.detailsLoading
    );

    const detailsPopup = useSelector(
        (state) => state.detailsPopup.detailsPopup
    );

    useEffect(() => {
        const params = new URLSearchParams();
        dispatch(getDetails('offer', id, params));
    }, [dispatch, id]);

    //cleanup
    useEffect(
        () => () => {
            dispatch(onTableContentCleanup());
            dispatch(onDetailsCleanup());
            dispatch(onClearWizzard());
        },
        //eslint-disable-next-line
        []
    );

    useEffect(() => {
        // react ga4 for offer details
        setGoogleAnalytics(
            'page_view',
            'page_view_offer_detail',
            'view offer detail page'
        );
    }, []);

    return (
        <>
            <>
                <DetailsWrapper>
                    <DetailsSideScreen
                        pageType="offers"
                        goBackLink="/offers?type=All"
                        loading={offerDetailsLoading}
                    />
                    <DetailsRigthSideWrapper>
                        {/* <OfferComponents  /> */}
                        <Outlet />
                    </DetailsRigthSideWrapper>
                </DetailsWrapper>
                <DetailsPopup
                    type="offers"
                    open={Object.values(detailsPopup).length ? true : false}
                />

                {/* {offersWizzard?.open ? <OffersWizzard /> : null} */}
            </>
        </>
    );
}

export default OffersDetails;
