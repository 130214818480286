import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../../../../components/Table/Table';
import RestoreItems from './RestoreItems/RestoreItems';
import { useTranslation } from 'react-i18next';
import WizzardFooter from '../../../../../components/Wizzard/WizzardFooter/WizzardFooter';
import {
    onRemoveFaItem,
    onRemoveItem,
} from '../../../../../store/actions/offersWizzardActions';

function Components() {
    const [tableData, setTableData] = useState([]);
    const [frameworkTableData, setFrameworkTableData] = useState([]);
    const [tableTitles, setTableTitles] = useState([]);
    const [frameworkTableTitles, setFrameworkTableTitles] = useState([]);
    const [offerHasDiscount, setOfferHasDiscount] = useState();
    const [frameworkOrderHasDiscount, setFrameworkOrderHasDiscount] =
        useState();
    const { t } = useTranslation();

    const offerLines = useSelector((state) => state.offersWizzardReducer);
    const details = useSelector((state) => state.details.details);
    const dispatch = useDispatch();
    const [initialOfferLinesLength, setInitialOfferLinesLength] = useState();
    const [initialFaOfferLinesLength, setInitialFaOfferLinesLength] =
        useState();
    const [singleOrderHasOrigin, setSingleOrderHasOrigin] = useState();
    const [frameworkOrderHasOrigin, setFrameworkOrderHasOrigin] = useState();

    const filterLineOffers = (lineOffers, removeLineFromRedux) => {
        return lineOffers?.filter((offerLine) => {
            // remove quantities whose totalPrice has 0 value
            offerLine.quantities = offerLine.quantities?.filter(
                (quantity) => quantity?.totalPriceNet !== 0
            );

            // remove offerlines if they have no quantities
            if (!offerLine?.quantities?.length && removeLineFromRedux) {
                if (offerLines.wizzardType === 'single-offer') {
                    dispatch(onRemoveItem(offerLine.lineId, offerLine.index));
                } else {
                    dispatch(onRemoveFaItem(offerLine.lineId, offerLine.index));
                }
            }
            return offerLine?.quantities?.length !== 0;
        });
    };

    useEffect(() => {
        // get initial offersline length
        // remove quantities that have no totalPrice andd offerlines that have no quantities from api state
        if (offerLines.wizzardType === 'single-offer') {
            const offerLinesWithQuantitiesFormated = filterLineOffers(
                details.singleOfferLines,
                false
            );

            // set length of formated offerlines
            setInitialOfferLinesLength(offerLinesWithQuantitiesFormated.length);
        } else {
            const offerFaLinesWithQuantitiesFormated = filterLineOffers(
                details.frameworkOfferLines,
                false
            );

            // set length of formated offerlines
            if (offerFaLinesWithQuantitiesFormated.length) {
                setInitialFaOfferLinesLength(
                    offerFaLinesWithQuantitiesFormated?.length
                );
            }
        }

        //eslint-disable-next-line
    }, [details.singleOfferLines]);

    // for offer single order
    useEffect(() => {
        let dataHasDiscount;
        let offerSingleOrderHasOrigin;
        let offerFrameworkOrderHasOrigin;

        const offerLineWithIndex = offerLines.offerLines?.map(
            (offerLine, index) => {
                offerLine.index = index;
                return offerLine;
            }
        );

        //  remove offers with no price
        const removedOffersWithNoPrice = filterLineOffers(
            offerLineWithIndex,
            true
        );

        const filteredOfferLines = removedOffersWithNoPrice?.map(
            (offerLine, index) => {
                let quantities = [];
                if (offerLine.quantities?.length > 1) {
                    offerLine.quantities.forEach((item) => {
                        let quantityObj = {
                            label: `${item.quantity} ${t(
                                'global_pieces-short'
                            )}`,
                            value: item.quantity,
                        };
                        quantities.push(quantityObj);
                    });
                } else {
                    quantities = offerLine.quantities[0]?.quantity;
                }

                return {
                    showCertificates: offerLine.certificateLines?.length,
                    certificates: offerLine.certificateLines?.map(
                        (certificate) => {
                            const object = {
                                index: '',
                                certificateName: {
                                    certificate: certificate?.partName
                                        ? certificate.partName
                                        : '-',
                                },
                                articleNumberColumn: '',
                                material: {
                                    material: certificate?.material
                                        ? certificate.material
                                        : '-',
                                    materialGroup: certificate?.materialGroup
                                        ? certificate.materialGroup
                                        : '-',
                                },
                                surfaceDetailsColumn: '-',
                                deliveryTimeColum: '',
                                origin: '',
                                quantity: certificate?.quantity
                                    ? certificate.quantity +
                                      ` ${t('global_pieces-short')}`
                                    : '-',
                                totalPrice: {
                                    totalPrice: certificate?.priceNet
                                        ? certificate.priceNet
                                        : '-',
                                },
                                discount: certificate?.discountPercent
                                    ? certificate?.discountPercent + '%'
                                    : '-',
                                totalPriceNet: {
                                    price:
                                        certificate?.totalPriceNet ||
                                        certificate?.totalPriceNet === 0
                                            ? certificate?.totalPriceNet
                                            : '-',
                                    priceBeforeDiscount:
                                        certificate.totalPriceNetBeforeDiscount,
                                    discount: certificate?.discountPercent
                                        ? certificate?.discountPercent + '%'
                                        : null,
                                    targetPrice: certificate?.targetPriceNet,
                                    isOnlyComment:
                                        offerLine.certificateLines
                                            ?.isOnlyComment,
                                    noPriceAvailableComment:
                                        offerLine.certificateLines
                                            ?.noPriceAvailableComment,
                                    status: details.status,
                                    priceStatus:
                                        offerLine.certificateLines?.status,
                                    noTargetPrice: true,
                                },
                                deleteRowSpace: '',
                            };
                            return object;
                        }
                    ),
                    data: {
                        index: `${offerLine.index + 1 < 10 ? 0 : ''}${
                            offerLine.index + 1
                        }`,
                        partName: offerLine.partName ? offerLine.partName : '-',
                        articleNumber: offerLine.articleNumber
                            ? offerLine.articleNumber
                            : '-',
                        material: {
                            material: offerLine.material
                                ? offerLine.material
                                : '-',
                            materialGroup: offerLine.materialGroup
                                ? offerLine.materialGroup
                                : '-',
                            materialComments: offerLine.materialComments,
                        },
                        surfaceDetails: offerLine.surfaceDetails
                            ? offerLine.surfaceDetails
                            : '-',
                        deliveryTime: offerLine.quantities[
                            offerLines.offerLinesIndex[offerLine.index]
                                ? offerLines.offerLinesIndex[offerLine.index]
                                : 0
                        ]?.deliveryTime
                            ? offerLine.quantities[
                                  offerLines.offerLinesIndex[offerLine.index]
                                      ? offerLines.offerLinesIndex[
                                            offerLine.index
                                        ]
                                      : 0
                              ].deliveryTime +
                              (offerLine.quantities[
                                  offerLines.offerLinesIndex[offerLine.index]
                                      ? offerLines.offerLinesIndex[
                                            offerLine.index
                                        ]
                                      : 0
                              ].showDays
                                  ? ` ${t('text_62e6e458b1f57b951f61dfaa')}`
                                  : '')
                            : '-',
                        origin: {
                            hsCode: offerLine.quantities[
                                offerLines.offerLinesIndex[offerLine.index]
                                    ? offerLines.offerLinesIndex[
                                          offerLine.index
                                      ]
                                    : 0
                            ]?.HSCode
                                ? offerLine.quantities[
                                      offerLines.offerLinesIndex[
                                          offerLine.index
                                      ]
                                          ? offerLines.offerLinesIndex[
                                                offerLine.index
                                            ]
                                          : 0
                                  ]?.HSCode
                                : '-',
                            originCountry: offerLine.quantities[
                                offerLines.offerLinesIndex[offerLine.index]
                                    ? offerLines.offerLinesIndex[
                                          offerLine.index
                                      ]
                                    : 0
                            ]?.originCountry
                                ? offerLine.quantities[
                                      offerLines.offerLinesIndex[
                                          offerLine.index
                                      ]
                                          ? offerLines.offerLinesIndex[
                                                offerLine.index
                                            ]
                                          : 0
                                  ]?.originCountry
                                : '-',
                        },
                        selectQuantity: {
                            index: offerLine.index,
                            selectQuantity: quantities ? quantities : '-',
                        },
                        totalPrice: {
                            totalPrice: offerLine.quantities[
                                offerLines.offerLinesIndex[offerLine.index]
                                    ? offerLines.offerLinesIndex[
                                          offerLine.index
                                      ]
                                    : 0
                            ]?.priceNet
                                ? offerLine.quantities[
                                      offerLines.offerLinesIndex[
                                          offerLine.index
                                      ]
                                          ? offerLines.offerLinesIndex[
                                                offerLine.index
                                            ]
                                          : 0
                                  ].priceNet
                                : '-',
                        },
                        discountPercent: offerLine.quantities[
                            offerLines.offerLinesIndex[offerLine.index]
                                ? offerLines.offerLinesIndex[offerLine.index]
                                : 0
                        ]?.discountPercent
                            ? offerLine.quantities[
                                  offerLines.offerLinesIndex[offerLine.index]
                                      ? offerLines.offerLinesIndex[
                                            offerLine.index
                                        ]
                                      : 0
                              ].discountPercent + '%'
                            : '-',
                        totalPriceNet: {
                            price: offerLine.quantities[
                                offerLines.offerLinesIndex[offerLine.index]
                                    ? offerLines.offerLinesIndex[
                                          offerLine.index
                                      ]
                                    : 0
                            ]?.totalPriceNet
                                ? offerLine.quantities[
                                      offerLines.offerLinesIndex[
                                          offerLine.index
                                      ]
                                          ? offerLines.offerLinesIndex[
                                                offerLine.index
                                            ]
                                          : 0
                                  ].totalPriceNet
                                : '-',
                            priceBeforeDiscount: offerLine.quantities[
                                offerLines.offerLinesIndex[offerLine.index]
                                    ? offerLines.offerLinesIndex[
                                          offerLine.index
                                      ]
                                    : 0
                            ]?.totalPriceNetBeforeDiscount
                                ? offerLine.quantities[
                                      offerLines.offerLinesIndex[
                                          offerLine.index
                                      ]
                                          ? offerLines.offerLinesIndex[
                                                offerLine.index
                                            ]
                                          : 0
                                  ].totalPriceNetBeforeDiscount
                                : '-',
                            targetPrice: offerLine.quantities[
                                offerLines.offerLinesIndex[offerLine.index]
                                    ? offerLines.offerLinesIndex[
                                          offerLine.index
                                      ]
                                    : 0
                            ]?.targetPriceNet
                                ? offerLine.quantities[
                                      offerLines.offerLinesIndex[
                                          offerLine.index
                                      ]
                                          ? offerLines.offerLinesIndex[
                                                offerLine.index
                                            ]
                                          : 0
                                  ].targetPriceNet
                                : '-',
                            discount: offerLine.quantities[
                                offerLines.offerLinesIndex[offerLine.index]
                                    ? offerLines.offerLinesIndex[
                                          offerLine.index
                                      ]
                                    : 0
                            ]?.discountPercent
                                ? offerLine.quantities[
                                      offerLines.offerLinesIndex[
                                          offerLine.index
                                      ]
                                          ? offerLines.offerLinesIndex[
                                                offerLine.index
                                            ]
                                          : 0
                                  ].discountPercent
                                : '-',
                            isOnlyComment:
                                offerLine.quantities[
                                    offerLines.offerLinesIndex[offerLine.index]
                                        ? offerLines.offerLinesIndex[
                                              offerLine.index
                                          ]
                                        : 0
                                ]?.isOnlyComment,
                            status: details.status,
                            uuid: offerLine.uuid,
                        },
                        deletableRow: {
                            lineId: offerLine.lineId,
                            deletableRow:
                                offerLines.offerLines.length === 1
                                    ? false
                                    : true,
                            index: offerLine.index,
                        },
                    },
                };
            }
        );

        dataHasDiscount = removedOffersWithNoPrice?.some((item) => {
            return item.quantities?.some((subItem) => subItem.discountPercent);
        });

        offerSingleOrderHasOrigin = removedOffersWithNoPrice?.some((item) => {
            return item.quantities?.some(
                (subItem) => subItem.originCountry || subItem.hsCode
            );
        });

        setTableData(filteredOfferLines);
        setOfferHasDiscount(dataHasDiscount);
        setSingleOrderHasOrigin(offerSingleOrderHasOrigin);

        let offerFrameworkAgreementsHasDiscount;
        const offerFALineWithIndex = offerLines.frameworkOfferLines?.map(
            (offerLine, index) => {
                offerLine.index = index;
                return offerLine;
            }
        );

        // filter for framework orders
        //  remove offers with no price
        const removedFaOffersWithNoPrice = filterLineOffers(
            offerFALineWithIndex,
            true
        );

        const filteredFrameworkOfferLines = removedFaOffersWithNoPrice?.map(
            (offerLine, index) => {
                return {
                    showCertificates: offerLine.certificateLines?.length,
                    certificates: offerLine.certificateLines?.map(
                        (certificate) => {
                            const object = {
                                index: '',
                                certificateName: {
                                    certificate: certificate?.partName
                                        ? certificate.partName
                                        : '-',
                                },
                                articleNumber: '',
                                material: {
                                    material: certificate?.material
                                        ? certificate.material
                                        : '-',
                                    materialGroup: certificate?.materialGroup
                                        ? certificate.materialGroup
                                        : '-',
                                },
                                productionTime: '',
                                runningTime: '-',
                                totalQuantity: certificate?.quantity
                                    ? certificate.quantity +
                                      ` ${t('global_pieces-short')}`
                                    : '-',
                                retrieval: '-',
                                quantity: '-',
                                origin: '',
                                delivery: '-',
                                discount: certificate?.discountPercent
                                    ? certificate?.discountPercent + '%'
                                    : '-',
                                totalPriceNet: {
                                    price:
                                        certificate?.totalPriceNet ||
                                        certificate?.totalPriceNet === 0
                                            ? certificate?.totalPriceNet
                                            : '-',
                                    priceBeforeDiscount:
                                        certificate.totalPriceNetBeforeDiscount,
                                    discount: certificate?.discountPercent
                                        ? certificate?.discountPercent + '%'
                                        : null,
                                    targetPrice: '-',
                                    isOnlyComment:
                                        offerLine.certificateLines
                                            ?.isOnlyComment,
                                    noPriceAvailableComment:
                                        offerLine.certificateLines
                                            ?.noPriceAvailableComment,
                                    status: details.status,
                                    priceStatus:
                                        offerLine.certificateLines?.status,
                                    noTargetPrice: true,
                                },
                                deleteRowSpace: '',
                            };
                            return object;
                        }
                    ),
                    data: {
                        index: `${index + 1 < 10 ? 0 : ''}${index + 1}`,
                        partName: offerLine.partName ? offerLine.partName : '-',
                        articleNumber: offerLine.articleNumber
                            ? offerLine.articleNumber
                            : '-',
                        material: {
                            material: offerLine.material
                                ? offerLine.material
                                : '-',
                            materialGroup: offerLine.materialGroup
                                ? offerLine.materialGroup
                                : '-',
                            materialComments: offerLine.materialComments,
                        },
                        surfaceDetails: offerLine.surfaceDetails
                            ? offerLine.surfaceDetails
                            : '-',
                        deliveryTime: offerLine.quantities[0]?.deliveryTime
                            ? offerLine.quantities[0].deliveryTime
                            : '-',
                        quantity: {
                            selectQuantity: offerLine.quantities[0]?.quantity
                                ? offerLine.quantities[0]?.quantity
                                : '-',
                        },
                        abrufe: {
                            sample: {
                                sample: t('global_sample'),
                                quantitySample:
                                    offerLine.quantities[0]?.quantitySample,
                            },
                            firstBatch: {
                                firstBatch: t('global_first-batch'),
                                quantityFirstBatch:
                                    offerLine.quantities[0]?.quantityFirstBatch,
                            },
                            perBatch: {
                                perBatch: t('global_per-batch'),
                                quantityPerBatches:
                                    offerLine.quantities[0]?.quantityPerBatches,
                            },
                        },
                        amount: {
                            quantitySample: offerLine.quantities[0]
                                ?.quantitySample
                                ? offerLine.quantities[0]?.quantitySample
                                : '-',
                            quantityFirstBatch: offerLine.quantities[0]
                                ?.quantityFirstBatch
                                ? offerLine.quantities[0]?.quantityFirstBatch
                                : '-',
                            quantityPerBatches: offerLine.quantities[0]
                                ?.quantityPerBatches
                                ? offerLine.quantities[0]?.quantityPerBatches
                                : '-',
                        },
                        origin: {
                            originCountry: offerLine.quantities[0]
                                ?.originCountry
                                ? offerLine.quantities[0]?.originCountry
                                : '-',
                            hsCode: offerLine.quantities[0]?.HSCode
                                ? offerLine.quantities[0]?.HSCode
                                : '-',
                        },
                        delivery: {
                            deliveryTimeSample: {
                                deliveryTimeSample: offerLine.quantities[0]
                                    ?.deliveryTimeSample
                                    ? offerLine.quantities[0]
                                          ?.deliveryTimeSample
                                    : '-',
                                quantitySample:
                                    offerLine.quantities[0]?.quantitySample,
                            },
                            deliveryTimeFirstBatch: {
                                deliveryTimeFirstBatch: offerLine.quantities[0]
                                    ?.deliveryTimeFirstBatch
                                    ? offerLine.quantities[0]
                                          ?.deliveryTimeFirstBatch
                                    : '-',
                                quantityFirstBatch:
                                    offerLine.quantities[0]?.quantityFirstBatch,
                            },
                            deliveryTimePerBatch: {
                                deliveryTimePerBatch: offerLine.quantities[0]
                                    ?.deliveryTimePerBatch
                                    ? offerLine.quantities[0]
                                          ?.deliveryTimePerBatch
                                    : '-',
                                quantityPerBatches:
                                    offerLine.quantities[0]?.quantityPerBatches,
                            },
                        },
                        discountPercent: offerLine.quantities[
                            offerLines.offerLinesIndex[offerLine.index]
                                ? offerLines.offerLinesIndex[offerLine.index]
                                : 0
                        ]?.discountPercent
                            ? offerLine.quantities[
                                  offerLines.offerLinesIndex[offerLine.index]
                                      ? offerLines.offerLinesIndex[
                                            offerLine.index
                                        ]
                                      : 0
                              ].discountPercent + '%'
                            : '-',
                        totalPriceNet: {
                            price: offerLine.quantities[
                                offerLines.offerLinesIndex[offerLine.index]
                                    ? offerLines.offerLinesIndex[
                                          offerLine.index
                                      ]
                                    : 0
                            ]?.totalPriceNet
                                ? offerLine.quantities[
                                      offerLines.offerLinesIndex[
                                          offerLine.index
                                      ]
                                          ? offerLines.offerLinesIndex[
                                                offerLine.index
                                            ]
                                          : 0
                                  ].totalPriceNet
                                : '-',
                            priceBeforeDiscount: offerLine.quantities[
                                offerLines.offerLinesIndex[offerLine.index]
                                    ? offerLines.offerLinesIndex[
                                          offerLine.index
                                      ]
                                    : 0
                            ]?.totalPriceNetBeforeDiscount
                                ? offerLine.quantities[
                                      offerLines.offerLinesIndex[
                                          offerLine.index
                                      ]
                                          ? offerLines.offerLinesIndex[
                                                offerLine.index
                                            ]
                                          : 0
                                  ].totalPriceNetBeforeDiscount
                                : '-',
                            targetPrice: offerLine.quantities[
                                offerLines.offerLinesIndex[offerLine.index]
                                    ? offerLines.offerLinesIndex[
                                          offerLine.index
                                      ]
                                    : 0
                            ]?.targetPriceNet
                                ? offerLine.quantities[
                                      offerLines.offerLinesIndex[
                                          offerLine.index
                                      ]
                                          ? offerLines.offerLinesIndex[
                                                offerLine.index
                                            ]
                                          : 0
                                  ].targetPriceNet
                                : '-',
                            discount: offerLine.quantities[
                                offerLines.offerLinesIndex[offerLine.index]
                                    ? offerLines.offerLinesIndex[
                                          offerLine.index
                                      ]
                                    : 0
                            ]?.discountPercent
                                ? offerLine.quantities[
                                      offerLines.offerLinesIndex[
                                          offerLine.index
                                      ]
                                          ? offerLines.offerLinesIndex[
                                                offerLine.index
                                            ]
                                          : 0
                                  ].discountPercent
                                : '-',
                            isOnlyComment:
                                offerLine.quantities[
                                    offerLines.offerLinesIndex[offerLine.index]
                                        ? offerLines.offerLinesIndex[
                                              offerLine.index
                                          ]
                                        : 0
                                ]?.isOnlyComment,
                            status: details.status,
                            uuid: offerLine.uuid,
                        },
                        deletableRow: {
                            lineId: offerLine.lineId,
                            deletableRow:
                                removedFaOffersWithNoPrice.length === 1
                                    ? false
                                    : true,
                            index: index,
                        },
                    },
                };
            }
        );

        offerFrameworkAgreementsHasDiscount = removedFaOffersWithNoPrice?.some(
            (item) => {
                return item.quantities?.some(
                    (subItem) => subItem.discountPercent
                );
            }
        );

        offerFrameworkOrderHasOrigin = removedFaOffersWithNoPrice?.some(
            (item) => {
                return item.quantities?.some(
                    (subItem) => subItem.originCountry || subItem.hsCode
                );
            }
        );

        setFrameworkTableData(filteredFrameworkOfferLines);
        setFrameworkOrderHasDiscount(offerFrameworkAgreementsHasDiscount);
        setFrameworkOrderHasOrigin(offerFrameworkOrderHasOrigin);
        //eslint-disable-next-line
    }, [offerLines]);

    useEffect(() => {
        setTableTitles([
            {
                id: 1,
                name: t('wizard-table_pos'),
            },
            {
                id: 2,
                name: t('wizard-table_designation'),
            },
            {
                id: 3,
                name: t('wizard-table_article-num'),
            },
            {
                id: 4,
                name: t('wizard-table_material'),
            },
            {
                id: 5,
                name: t('wizard-table_additional-prod-processes'),
            },
            {
                id: 6,
                name: t('wizard-table_delivery-time'),
            },
            {
                id: 7,
                name: t('offers-single-order-sidebar_origin'),
                sortable: false,
            },
            {
                id: 8,
                name: t('wizard-table_total-quantity'),
            },
            {
                id: 9,
                name: t('wizard-table_price-per-piece'),
            },
            {
                id: 10,
                name: t('wizard-table_discount'),
            },
            {
                id: 11,
                name: t('wizard-table_total-price'),
            },
            {
                id: 12,
                name: '',
            },
        ]);
        setFrameworkTableTitles([
            {
                id: 1,
                name: t('wizard-table_pos'),
            },
            {
                id: 2,
                name: t('wizard-table_designation'),
            },
            {
                id: 3,
                name: t('wizard-table_article-num'),
            },
            {
                id: 4,
                name: t('wizard-table_material'),
            },
            {
                id: 5,
                name: t('wizard-table_additional-prod-processes'),
            },
            {
                id: 6,
                name: t('wizard-table_running-time'),
            },
            {
                id: 7,
                name: t('wizard-table_total-quantity'),
            },
            {
                id: 8,
                name: t('offers-fa-table_retrieval-2'),
            },
            {
                id: 9,
                name: t('offers-single-order-table-columns_quantity'),
            },
            {
                id: 10,
                name: t('offers-single-order-sidebar_origin'),
            },
            {
                id: 11,
                name: t('wizard-table_delivery-time'),
            },
            {
                id: 12,
                name: t('wizard-table_discount'),
            },
            {
                id: 13,
                name: t('summary-table_total-netto'),
            },
            {
                id: 14,
                name: '',
            },
        ]);
        //eslint-disable-next-line
    }, []);

    return (
        <>
            {(initialOfferLinesLength > tableData.length ||
                initialFaOfferLinesLength > frameworkTableData.length) && (
                <RestoreItems />
            )}
            {offerLines.wizzardType === 'single-offer' ? (
                <Table
                    tableTitles={tableTitles}
                    tableData={tableData}
                    tableType="wizardOffers"
                    emptyStHeading="You have removed all items from your offer"
                    hasDiscount={offerHasDiscount}
                    hasOrigin={singleOrderHasOrigin}
                />
            ) : null}
            {offerLines.wizzardType === 'framework-agreement' ? (
                <Table
                    tableTitles={frameworkTableTitles}
                    tableData={frameworkTableData}
                    tableType="wizardFaOffers"
                    emptyStHeading="You have removed all items from your offer"
                    hasDiscount={frameworkOrderHasDiscount}
                    hasOrigin={frameworkOrderHasOrigin}
                />
            ) : null}
            <WizzardFooter
                nextButtonText={t('global_continue-btn')}
                nextButtonDisabled={false}
                url={`/offers/${details.offerId}/wizard/order-details?type=${offerLines.wizzardType}`}
            />
        </>
    );
}

export default Components;
